import React, { useEffect } from "react";
import './sidebar.scss';

import { LargeLogo } from '../../uiKit/assets/svg/logoLarge';

import CommunityMenu from "./CommunityMenu/communityMenu";
import Settings from './PersonalSettings/personalSettings';
import Title from "../../uiKit/Title/Title";
import { useHistory } from "react-router-dom";

const Sidebar = () => {

    const history = useHistory();

    useEffect(() => {
        const ls = window.localStorage;
        const ss = window.sessionStorage;
        const remember = JSON.parse(ls.getItem('remember'));

        if (!remember) {
            setTimeout(() => {
                const tabsArray = JSON.parse(ls.getItem('tabs'));
                const tab = ss.getItem('id');
                if (!tabsArray && !!tab) {
                    const tabs = [tab];
                    ls.setItem('tabs', JSON.stringify(tabs))
                }

                if (!!tabsArray && !!tab && !tabsArray.some(item => item === tab)) {
                    tabsArray.push(tab);
                    ls.setItem('tabs', JSON.stringify(tabsArray))
                }
            }, [1000])
        }
    }, [])

    window.onbeforeunload = () => {
        const ls = window.localStorage;
        const ss = window.sessionStorage;

        const remember = JSON.parse(ls.getItem('remember'));

        if (!remember) {
            const tabsArray = JSON.parse(ls.getItem('tabs'));

            if (!!tabsArray && tabsArray.length > 1) {
                const tab = ss.getItem('id');
                const tabs = tabsArray.filter(item => item !== tab);
                ls.setItem('tabs', JSON.stringify(tabs))
            }

            if(!!tabsArray && tabsArray.length === 1){
                window.localStorage.clear()
            }
        }
    }

    return (
        <div className='sidebar'>
            <div className='mainLogo' onClick={() => history.push('/campaigns')}>
                <LargeLogo />
            </div>
            <div className='communityMenu'>
                <Title
                    text='Community'
                    lineHeight={32}
                    fontSize={20}
                    className='sidebarTitle'
                />
                <CommunityMenu />
            </div>
            <div className='personalSettings'>
                <Title
                    text='Personal Settings'
                    lineHeight={32}
                    fontSize={20}
                    className='sidebarTitle'
                />
                <Settings />
            </div>
        </div>
    )
}

export default Sidebar;