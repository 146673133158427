import React, { useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ReactGA from 'react-ga4';

import CustomPagination from "../../../uiKit/CustomTable/pagination";
import ProgressBar from "../../../uiKit/ProgressBar/ProgressBar";
import NoCampaigns from "./noCampaigns";

const CampaignsDesktopList = ({
    communityId,
    data = [],
    setData,
    color,
    campaignType,
    showNoData = true,
    campaignClick = null,
    tableUrl = '/campaigns'
}) => {

    const history = useHistory();

    const { search } = useLocation();

    useEffect(() => {
        if (!!setData && !!communityId) {
            const query = new URLSearchParams(search);
            const page = parseInt(query.get('page') ?? 1);

            setData({
                communityId,
                page,
                pageSize: 8,
            })
        }
    }, [search, setData, communityId])

    const onCampaignClick = useCallback((id) => {
        ReactGA.event(`${campaignType}_campaign_click`)
        history.push(`/campaigns/campaign/${campaignType}/${id}`)
    }, [history, campaignType])

    return (
        <div className='desktopProgressBar'>
            {
                !!data.items && !!data.items.length ? (
                    <>
                        {data.items
                            .map((campaign, index) => (
                                <ProgressBar
                                    key={index}
                                    campaignId={campaign.id}
                                    communityId={campaign.communityId}
                                    title={campaign.name}
                                    target={campaign.amount}
                                    color={color}
                                    clickable={true}
                                    onClick={!campaignClick ? () => onCampaignClick(campaign.id) : () => campaignClick(campaign.id)}
                                />
                            ))
                        }
                        <CustomPagination
                            data={data}
                            tableUrl={tableUrl}
                        />
                    </>
                ) : (
                    showNoData && <NoCampaigns title={campaignType} />
                )
            }
        </div>
    )
}

export default CampaignsDesktopList;