import React, { useEffect, useState } from "react";
import './securitySettings.scss'
import ReactGA from 'react-ga4';

import Header from "../../../components/Header/header";
import Title from "../../../uiKit/Title/Title";

import SecurityPhoto from "../../../uiKit/assets/svg/securitySettings.svg";
import CustomInput from "../../../uiKit/Input/CustomInput";
import { BluePen, Eye, Key, Mail } from "../../../uiKit/assets/svg/icons";
import { PASSWORD_ERROR } from "../../../helpers/constants";
import { validateEmail, validatePassword } from "../../../helpers/validation";
import CustomButton from "../../../uiKit/Button/CustomButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    checkPassword,
    sendChangeEmail,
    startChangePassword
} from "../../../redux/state/accountSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const SecuritySettings = () => {

    const isLoading = useSelector(state => state.account.isLoading.checkPassword, shallowEqual);
    const isLoadingStart = useSelector(state => state.account.isLoading.startChangePassword, shallowEqual);

    const dispatch = useDispatch();
    const history = useHistory();

    const userEmail = useSelector(state => state.account.userDetails.email, shallowEqual);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);
    const [showPassword, setShowPassword] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);

    const [changeEmail, setChangeEmail] = useState(false);
    const [changePassword, setChangePassword] = useState(false);

    const [confirmChangeEmail, setConfirmChangeEmail] = useState(false);

    const onPasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        if (!validatePassword(value)) {
            setPasswordError(PASSWORD_ERROR);
        } else {
            setPasswordError(null);
        }
    }

    const checkPasswordHandler = () => {
        if (!!password) {
            setPasswordError(null)
            dispatch(checkPassword({ password }))
                .then(unwrapResult)
                .then(response => {
                    if (response.isSuccessful) {
                        setConfirmChangeEmail(true);
                        setPasswordError('');
                    } else {
                        setPasswordError('Incorrect password');
                        setConfirmChangeEmail(false)
                    }
                })
                .catch(({ title }) => {
                    setConfirmChangeEmail(false)
                    toast.info(title)
                })
        } else {
            setPasswordError('Required')
        }
    }

    const emailHandler = (e) => {
        const value = e.target.value;
        setEmail(e.target.value)
        if (!validateEmail(value)) {
            setEmailError('Email is not valid')
        } else {
            setEmailError(null);
        }
    }

    const changeEmailHandler = () => {
        if (!!password && !!email) {
            dispatch(sendChangeEmail({
                password,
                newEmail: email
            }))
                .then(unwrapResult)
                .then(() => {
                    history.push({
                        pathname: '/change-email/verification',
                        state: {
                            password,
                            newEmail: email,
                        }
                    })
                })
                .catch(({ title, errors }) => {
                    if (!!errors && !!errors.NewEmail) {
                        toast.info(errors.NewEmail[0])
                    } else {
                        toast.info(title)
                    }
                })
        }
    }

    const sendResetPassword = () => {
        dispatch(startChangePassword())
            .then(unwrapResult)
            .then((response) => {
                toast.info('Sent');
                setChangePassword(!response.isSuccessful);
            })
            .catch(({ title }) => toast.info(title))
    }

    
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/login-and-security` })
    }, [])

    return (
        <>
            <Header title={`Login & security`} clickable={false} />
            <div className='communityInfoHeaderWrapper'>
                <div className='campaignMobileHeader' style={{ marginBottom: 0 }}>
                    <img src={SecurityPhoto} alt="" className='securityPhotoWrapper' />
                    <Title
                        text={`Login & Security`}
                        fontSize={screenWidth < 1200 ? 24 : 32}
                        lineHeight={screenWidth < 1200 ? 32 : 40}
                        className="ourBalanceTitle"
                    />
                </div>

                {
                    !changeEmail && !changePassword && (
                        <div className="loginInputs">
                            <div className='inputRow'>
                                <CustomInput
                                    value={userEmail}
                                    StartAdornment={Mail}
                                    EndAdornment={screenWidth < 900 ? BluePen : null}
                                    endAdormentClick={() => setChangeEmail(true)}
                                    readOnly={true}
                                    baseStyles={screenWidth < 900 ? { border: 'none' } : null}
                                />
                                {screenWidth > 900 &&
                                    <CustomButton
                                        title='Change'
                                        isWhite
                                        onClick={() => setChangeEmail(true)}
                                        classNames='margin16to0'
                                    />}
                            </div>

                            <div className='inputRow'>
                                <CustomInput
                                    value='password'
                                    type='password'
                                    StartAdornment={Key}
                                    EndAdornment={screenWidth < 900 ? BluePen : null}
                                    endAdormentClick={() => setChangePassword(true)}
                                    readOnly={true}
                                    baseStyles={{ border: screenWidth < 900 ? 'none' : null, fontSize: '26px', letterSpacing: '-1px' }}
                                />
                                {screenWidth > 900 &&
                                    <CustomButton
                                        title='Change'
                                        isWhite
                                        onClick={() => setChangePassword(true)}
                                        classNames='margin16to0'
                                    />}
                            </div>
                        </div>
                    )
                }

                {
                    changeEmail && !confirmChangeEmail && (
                        <>
                            <div className="loginInputs">
                                <Title
                                    text="To confirm changing your email address please enter your current password"
                                    fontSize={16}
                                    fontWeight={400}
                                    className="currentPasswordText"
                                />
                                <CustomInput
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Current password"
                                    onChange={onPasswordChange}
                                    value={password}
                                    error={!!passwordError}
                                    errorMessage={passwordError}
                                    StartAdornment={Key}
                                    EndAdornment={Eye}
                                    endAdormentClick={() => setShowPassword(!showPassword)}
                                />

                            </div>
                            <div className="securityButtons">
                                <CustomButton
                                    title='Next'
                                    onClick={checkPasswordHandler}
                                    disabled={!!passwordError || isLoading}
                                    loading={isLoading}
                                />
                                {
                                    !isLoading && (
                                        <CustomButton
                                            title='Cancel'
                                            isWhite
                                            onClick={() => setChangeEmail(false)}
                                            classNames='margin16to0'
                                        />
                                    )
                                }
                            </div>
                        </>
                    )
                }
                {
                    confirmChangeEmail && (
                        <>
                            <div className="loginInputs">
                                <CustomInput
                                    StartAdornment={Mail}
                                    placeholder="Enter new email"
                                    onChange={emailHandler}
                                    error={!!emailError}
                                    errorMessage={emailError}
                                />
                            </div>
                            <div className="securityButtons">
                                <CustomButton
                                    title='Change email'
                                    onClick={changeEmailHandler}
                                    disabled={isLoading}
                                    loading={isLoading}
                                />
                                <CustomButton
                                    title='Cancel'
                                    isWhite
                                    classNames='margin16to0'
                                    onClick={() => setConfirmChangeEmail(false)}
                                />
                            </div>
                        </>
                    )
                }
                {
                    changePassword && (
                        <>
                            <div className="loginInputs">
                                <Title
                                    text="Password reset instructions will be sent to your registered email address"
                                    fontSize={16}
                                    fontWeight={400}
                                    className="currentPasswordText"
                                />
                                <CustomInput
                                    value={userEmail}
                                    StartAdornment={Mail}
                                    readOnly={true}
                                />
                            </div>

                            <div className="securityButtons">
                                <CustomButton
                                    title='Send'
                                    onClick={sendResetPassword}
                                    disabled={isLoadingStart}
                                    loading={isLoadingStart}
                                />
                                {
                                    !isLoadingStart && (
                                        <CustomButton
                                            title='Cancel'
                                            isWhite
                                            onClick={() => setChangePassword(false)}
                                            classNames='margin16to0'
                                        />
                                    )
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </>
    )
}

export default SecuritySettings;