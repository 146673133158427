import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import Title from "../../../uiKit/Title/Title";

import CommunityCard from "../../../components/CommunityCard/communityCard";

const MobileList = ({ data }) => {

    const isLoadingCommunity = useSelector(state => state.community.isLoading.getCommunities, shallowEqual);

    const admins = data?.filter(e => e.role === 1);
    const members = data?.filter(e => e.role === 0);

    return (
        !isLoadingCommunity && (
            <div className="mobileList">
                <Title
                    text={`Admin (${!!admins?.length ? admins.length : 0})`}
                    lineHeight={30}
                    fontSize={20}
                    style={{
                        marginTop: '24px',
                        marginBottom: '16px'
                    }}
                />
                {
                    !!admins?.length && admins.map((community, index) => (
                        <div style={{ position: 'relative' }} key={index} >
                            <CommunityCard
                                community={community}
                                hasStatus={true}
                            />
                        </div>
                    ))
                }
                <Title
                    text={`Member (${!!members?.length ? members.length : 0})`}
                    lineHeight={30}
                    fontSize={20}
                    style={{
                        marginTop: '24px',
                        marginBottom: '16px'
                    }}
                />
                <div style={{ marginTop: '24px' }}>
                    {
                        !!members?.length && members.map((community, index) => (
                            <div style={{ position: 'relative' }} key={index}>
                                <CommunityCard
                                    community={community}
                                    hasStatus={true}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    )
}

export default MobileList;