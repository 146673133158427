import React, { useCallback, useEffect, useState } from "react";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import FacebookLogo from "../../uiKit/assets/svg/facebookLogo.svg";
import { externalLogin } from "../../redux/state/accountSlice";
import Title from "../../uiKit/Title/Title";
import { gray010 } from "../../uiKit/assets/colors/colors";
import Loading from "../../uiKit/Loading/loading";

const AlternativeLogin = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [data, setData] = useState(null);
    const [googleInit, setGoogleInit] = useState(false);

    const googleLoginSuccess = useCallback((response) => {

        const { credential } = response;

        if (!credential) {
            toast.info("We haven't received your google login data to proceed, please try again.");
            return;
        };

        const data = {
            accessToken: credential,
            provider: 'google'
        }

        setData(data);

    }, [])

    const facebookLogin = useCallback((response) => {
        
        const { accessToken } = response;

        if (!accessToken) {
            toast.info("We haven't received your facebook login data to proceed, please try again.");
            return;
        };

        const data = {
            accessToken: accessToken,
            provider: 'facebook'
        }

        setData(data);

    }, [])

    useEffect(() => {
        if (!window.google) return;

        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_ID,
            callback: googleLoginSuccess
        })

        window.google.accounts.id.renderButton(
            document.getElementById('googleSignIn'),
            { theme: 'outline', type: 'icon', shape: 'circle' }
        )

        const timeout = setTimeout(() => {
            setGoogleInit(true)
        }, 2000);

        return () => clearTimeout(timeout);

    }, [googleLoginSuccess])

    useEffect(() => {
        if (!!data) {
            dispatch(externalLogin(data))
                .then(unwrapResult)
                .then((response) => {
                    const { token, nextAction } = response;
                    if (nextAction === 'log_in') history.push('/campaigns')
                    if (nextAction === 'complete_registration' && !!token?.token) history.push(`/create-account?token=${encodeURIComponent(token.token)}`)
                    if (nextAction === 'email_required') history.push({
                        pathname: `/email-required`,
                        search: `?provider=${data.provider}&token=${encodeURIComponent(data.accessToken)}`
                    })

                    if (nextAction === 'verification') history.push({
                        pathname: `/email-required`,
                        search: `?type=verification&provider=${data.provider}&token=${encodeURIComponent(data.accessToken)}`
                    })
                })
                .catch(({ title }) => toast.info(title))
        }
    }, [data, dispatch, history])

    return (
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '16px 0',
                width: '100%',
                position: 'relative',
                height: '20px'
            }}>
                <div
                    style={{
                        width: '50%',
                        height: '0.5px',
                        background: 'rgba(60, 60, 67, 0.36)',
                    }}
                />
                <Title
                    text="Or continue with"
                    fontWeight={400}
                    style={{
                        width: '210px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'transparent'
                    }}
                />
                <div
                    style={{
                        width: '50%',
                        height: '0.5px',
                        background: 'rgba(60, 60, 67, 0.36)',
                    }}
                />
            </div>
            <div style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                height: '38px',
            }}>
                <div
                    style={{
                        marginRight: '40px',
                        opacity: googleInit ? 1 : 0,
                        transition: 'ease-in 1s',
                        width: '38px',
                        height: '38px'
                    }}>
                    <div
                        id='googleSignIn'
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                    />
                </div>
                <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_ID}
                    callback={facebookLogin}
                    isMobile={false}
                    redirectUri={window.location.origin}
                    disableMobileRedirect={true}
                    cookie={true}
                    xfbml={true}
                    render={props => (
                        <img
                            alt='facebook logo'
                            src={FacebookLogo}
                            onClick={props.onClick}
                            width='38px'
                            height='38px'
                            style={{
                                cursor: 'pointer',
                                opacity: googleInit ? 1 : 0,
                                transition: 'ease-in 1s',
                            }}
                        />
                    )}
                />
                {!googleInit && <Loading backgroundColor={gray010} />}
            </div>
        </>
    )
}

export default AlternativeLogin;