import React, { useEffect, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Bike from '../../../uiKit/assets/svg/bike.svg';
import {
    Vector01,
    Vector01Mobile,
    Vector02,
    Vector02Mobile,
    Vector03,
    Vector03Mobile,
    Vector04,
    Vector04Mobile
} from '../../../uiKit/assets/svg/vectors';
import Title from "../../../uiKit/Title/Title";

const Advantages = ({ setRef }) => {

    const myRef = useRef(null);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        if (!!myRef && !!setRef) {
            setRef(prev => ({ ...prev, signUp: myRef }))
        }
    }, [myRef, setRef])

    return (
        <div className="advantages" ref={myRef}>
            <div className='advantagesBackground' >
                <div>
                    <Title
                        text="It's a small world after all"
                        lineHeight={screenWidth > 900 ? 67 : 57.6}
                        fontSize={screenWidth > 900 ? 60 : 48}
                    />
                    <div style={{ marginTop: '34px' }}>
                        <Title
                            text="We worked hard to make getting started simple."
                            fontWeight={300}
                            fontSize={18}
                            lineHeight={25.2}
                        />
                        <Title
                            text="Here's how it works:"
                            fontWeight={300}
                            fontSize={18}
                            lineHeight={25.2}
                        />
                    </div>
                </div>
                <div className="imageBike">
                    <img alt='' src={Bike} style={{ width: '100%' }} />
                </div>
                <div className="blueCircle" />
                <div className="grayBlueCircle" />
                <div className="yellowCircle" />
                <div className="blueLittleCircle" />
            </div>
            <div className="advantagesSteps" >
                <div style={{ position: 'relative' }}>
                    <div className="padding4Vector" style={{
                        width: '50%',
                        marginLeft: '10.5%',
                        paddingTop: '4.9%',
                        paddingBottom: '11.4%'
                    }}>
                        <Title
                            text="01"
                            fontWeight={800}
                            fontSize={screenWidth > 900 ? 48 : 36}
                            lineHeight={screenWidth > 900 ? 72 : 54}
                        />
                        <Title
                            text={`Upon signup, each community/group will receive their unique URL & back-end for OurCommunity.One. They will select an admin (or two) to manage the group.`}
                            fontWeight={400}
                        />
                    </div>
                    <div className='vector01' >
                        <Vector01 />
                    </div>
                    <div className='vector01Mobile' >
                        <Vector01Mobile />
                    </div>
                    <div className='vector02' >
                        <Vector02 />
                    </div>
                </div>
                <div style={{ position: 'relative' }} >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',

                    }}>
                        <div className="padding4Vector" style={{
                            width: '42%',
                            marginRight: '10.4%',
                            paddingBottom: '11.4%'
                        }}>
                            <Title
                                text="02"
                                fontWeight={800}
                                fontSize={screenWidth > 900 ? 48 : 36}
                                lineHeight={screenWidth > 900 ? 72 : 54}
                            />
                            <Title
                                text="When an individual within a group reaches out to their admin in financial need, the admin can create and launch a live campaign for the rest of the group."
                                fontWeight={400}
                            />
                        </div>
                    </div>
                    <div className='vector02Mobile' >
                        <Vector02Mobile />
                    </div>
                    <div className="vector03" >
                        <Vector03 />
                    </div>
                </div>
                <div style={{ position: 'relative' }} >
                    <div className="padding4Vector" style={{
                        width: '54%',
                        marginLeft: '10.5%',
                        paddingBottom: '9.1%'
                    }}>
                        <Title
                            text="03"
                            fontWeight={800}
                            fontSize={screenWidth > 900 ? 48 : 36}
                            lineHeight={screenWidth > 900 ? 72 : 54}
                        />
                        <Title
                            text="This will send out an email/text blast to all the group's members except for the individual in question."
                            fontWeight={400}
                        />
                        <Title
                            text="(This avoids potential embarassment they may have by seeing a live fundraising campaign being run on their behalf)"
                            fontWeight={400}
                        />
                    </div>
                    <div className='vector03Mobile' >
                        <Vector03Mobile />
                    </div>
                    <div className="vector04" >
                        <Vector04 />
                    </div>
                </div>
                <div style={{ position: 'relative', zIndex: 100 }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <div className="padding4Vector" style={{
                            width: '41%',
                            marginRight: '10.7%',
                            paddingBottom: '9.1%'
                        }}>
                            <Title
                                text="04"
                                fontWeight={800}
                                fontSize={screenWidth > 900 ? 48 : 36}
                                lineHeight={screenWidth > 900 ? 72 : 54}
                            />
                            <Title
                                text="Once the campaign goal is met, the funds will be moved into OurCommunity.One's bank account, and will be distributed to the individual immediately"
                                fontWeight={400}
                            />
                        </div>
                    </div>
                    <div className='vector04Mobile' >
                        <Vector04Mobile />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advantages;