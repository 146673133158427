import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import '../Campaigns/campaigns.scss';
import { Dialog, makeStyles } from "@material-ui/core";

import Title from "../../uiKit/Title/Title";
import { gray600 } from "../../uiKit/assets/colors/colors";
import CustomInput from "../../uiKit/Input/CustomInput";
import CustomCheckbox from "../../uiKit/CustomCheckbox/customCheckbox";
import CustomButton from "../../uiKit/Button/CustomButton";
import Divider from "../../uiKit/assets/svg/divider.svg";
import { onlyNumbers, validateEmail, validateName } from "../../helpers/validation";
import LoginComponent from "../Login/loginComponent";

import { campaignPaymentIntent } from "../../redux/state/paymentSlice";
import { login } from "../../redux/state/accountSlice";
import {
    clearCommunity,
    getCommunityPublic,
    setCommunityId
} from "../../redux/state/communitySlice";

const useDialogStyles = makeStyles(() => ({
    paper: {
        '&.MuiPaper-rounded': {
            borderRadius: '12px'
        }
    }
}))

const Donate = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const dialogClasses = useDialogStyles();

    const { campaignId, communityId } = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const [communityName, setCommunityName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState('');

    const [errors, setErrors] = useState({
        amountError: '',
        firstNameError: '',
        lastNameError: '',
        emailError: '',
    });

    const [isAnonymous, setIsAnonymous] = useState(false);
    const [coverFees, setCoverFees] = useState(true);
    const [loginOpen, setLoginOpen] = useState(false);
    const [joinCommunity, setJoinCommunity] = useState(true);

    useEffect(() => {
        if (!communityName && !!communityId) {
            dispatch(getCommunityPublic(communityId))
                .then(unwrapResult)
                .then(response => setCommunityName(response.name))
        }
    }, [communityName, dispatch, communityId])

    const onFirstNameChange = (e) => {
        const value = e.target.value;
        setFirstName(value);
        if (!validateName(value)) {
            setErrors(prev => ({ ...prev, firstNameError: 'Invalid first name' }));
        } else {
            setErrors(prev => ({ ...prev, firstNameError: '' }));
        }
    }

    const onLastNameChange = (e) => {
        const value = e.target.value;
        setLastName(value);
        if (!validateName(value)) {
            setErrors(prev => ({ ...prev, lastNameError: 'Invalid last name' }));
        } else {
            setErrors(prev => ({ ...prev, lastNameError: '' }));
        }
    }

    const onEmailChange = (e) => {
        const value = e.target.value;
        setEmail(e.target.value)
        if (!validateEmail(value)) {
            setErrors(prev => ({ ...prev, emailError: 'Email is not valid' }))
        } else {
            setErrors(prev => ({ ...prev, emailError: '' }))
        }
    }

    const onAmountChange = (e) => {
        const value = e.target.value;
        if (onlyNumbers(value)) {
            if (value === '0') {
                setErrors(prev => ({ ...prev, amountError: 'Insufficient amount' }));
                setAmount('');
            } else {
                setErrors(prev => ({ ...prev, amountError: '' }));
                setAmount(value);
            }
        } else {
            setErrors(prev => ({ ...prev, amountError: 'Only numbers allowed' }));
        }
    }

    const loginHandler = ({ email, password, remember }) => {
        dispatch(login({ email, password, remember }))
            .then(unwrapResult)
            .then(() => {
                dispatch(clearCommunity());
                dispatch(setCommunityId({ communityId }))
                window.localStorage.setItem('communityId', communityId);
                const query = new URLSearchParams(search);
                query.set('campaignCommunityId', communityId);
                query.delete('campaignId');
                history.push(`/campaigns/campaign/active/${campaignId}?${query.toString()}`);
            })
            .catch(({ title }) => console.error(title))
    }

    const onPayClick = () => {
        setIsLoading(true);
        const body = {
            incomingBalanceId: communityId,
            amount: parseInt(amount),
            isAnonymous,
            userInfo: {
                firstName,
                lastName,
                email
            },
            savePaymentMethod: false,
            coverFees,
            joinCommunity
        }
        dispatch(campaignPaymentIntent({
            communityId,
            campaignId,
            body
        }))
            .then(unwrapResult)
            .then(() => {
                history.push(`/anonymous/campaigns/checkout?beMember=${joinCommunity}&name=${communityName}`);
                setIsLoading(false);
            })
            .catch(error => {
                if (!!error.errors.Email) {
                    toast.info(error.errors.Email[0]);
                    setErrors(prev => ({ ...prev, emailError: 'Required' }));
                    return;
                };
                if (!!error.errors.FirstName) {
                    setErrors(prev => ({ ...prev, firstNameError: 'Required' }));
                    toast.info(error.errors.FirstName[0]);
                };
                if (!!error.errors.LastName) {
                    setErrors(prev => ({ ...prev, lastNameError: 'Required' }));
                    toast.info(error.errors.LastName[0]);
                    return;
                };
                toast.info(error.title);
                setIsLoading(false);
            })
    }

    return (
        <>
            <div className='boxShadow donationWrapper' style={{ zIndex: 1000, margin: 0 }}>
                <Title
                    text='Donation:'
                    fontSize={32}
                    lineHeight={40}
                    style={{ marginBottom: '32px' }}
                />
                <Title
                    text="Do you have account?"
                    fontWeight={400}
                    style={{ marginBottom: '24px' }}
                />
                <CustomButton
                    title="Sign in"
                    isWhite
                    style={{ marginBottom: '24px' }}
                    onClick={() => setLoginOpen(true)}
                />
                <img src={Divider} alt='divider' style={{ width: '100%' }} />
                <CustomInput
                    label='First name'
                    value={firstName}
                    onChange={onFirstNameChange}
                    error={!!errors.firstNameError}
                    errorMessage={errors.firstNameError}
                    maxLength={30}
                />
                <CustomInput
                    label='Last name'
                    value={lastName}
                    onChange={onLastNameChange}
                    error={!!errors.lastNameError}
                    errorMessage={errors.lastNameError}
                    maxLength={30}
                />
                <CustomInput
                    label="email"
                    value={email}
                    onChange={onEmailChange}
                    error={!!errors.emailError}
                    errorMessage={errors.emailError}
                />
                <CustomCheckbox
                    label='Become a member of community'
                    checked={joinCommunity}
                    onChange={() => setJoinCommunity(!joinCommunity)}
                    height='max-content'
                />
                <div className="divider" />
                <CustomInput
                    label='Donation amount'
                    value={amount}
                    onChange={onAmountChange}
                    error={!!errors.amountError}
                    errorMessage={errors.amountError}
                />
                <CustomCheckbox
                    label='I would like to cover the processing fees to this donation'
                    checked={coverFees}
                    onChange={() => setCoverFees(!coverFees)}
                    style={{ marginBottom: '16px' }}
                    height='max-content'
                />
                <CustomCheckbox
                    checked={isAnonymous}
                    onChange={() => setIsAnonymous(!isAnonymous)}
                    label='Donate Anonymously'
                    style={{ marginBottom: '16px' }}
                    height='max-content'
                />
                <CustomButton
                    title='Donate'
                    style={{ marginBottom: '16px' }}
                    disabled={Object.values(errors).some(error => !!error) || !amount.length || isLoading}
                    onClick={onPayClick}
                    loading={isLoading}
                />
                <Title
                    text="You'll be asked for your payment info on the next page."
                    fontSize={12}
                    lineHeight={14}
                    fontWeight={400}
                    color={gray600}
                />
            </div>
            <Dialog
                open={loginOpen}
                onClose={() => setLoginOpen(false)}
                disableScrollLock={true}
                classes={dialogClasses}
            >
                <div className='page'>
                    <LoginComponent
                        onLogin={loginHandler}
                        createAccount={false}
                        hasBackground={false}
                    />
                </div>
            </Dialog>
        </>
    )
}

export default Donate;