import React from "react";
import Title from "../Title/Title";
import { TableCell, TableRow } from "@material-ui/core";

const NoResults = () => {
	return (
		<TableRow key={0} >
			<TableCell colSpan={100} align='center'>
				<Title
					text='No results.'
					fontSize={16}
					lineHeight={24}
					fontWeight={400}
				/>
			</TableCell>
		</TableRow>
	)
}

export default NoResults;