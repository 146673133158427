import React from "react";

import { useSelector, shallowEqual } from 'react-redux';
import CustomTable from "../../../uiKit/CustomTable/customTable";
import { AdminRowRenderer, UserRowRenderer } from "./tableRow";

const AdminsTable = () => {

    const isLoading = useSelector(state => state.member.isLoading.getAdmins, shallowEqual);
    const admins = useSelector(state => state.member.communityAdmins, shallowEqual);
    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);

    const adminHeader = [
        { name: '' },
        { name: 'Name' },
        { name: 'Number' },
        { name: 'Email' },
        { name: 'Action' }
    ]

    const userHeader = [
        { name: '' },
        { name: 'Name' },
        { name: 'Number' },
        { name: 'Email' },
    ]

    return (
        <div className='adminsTableWrapper'>
            <CustomTable
                RowRenderer={!!isAdmin ? AdminRowRenderer : UserRowRenderer}
                headerArray={!!isAdmin ? adminHeader : userHeader}
                data={{ items: admins }}
                hasPagination={false}
                isLoading={isLoading}
            />
        </div>
    )
}

export default AdminsTable;