import React, { useState } from "react";

import { Arrow, ArrowUp } from "../../../uiKit/assets/svg/icons";
import Title from "../../../uiKit/Title/Title";

import SubMenuItem from "./SubMenuItem";

const SubMenu = ({ Icon, title, menu, selected, onClick = () => { } }) => {

    const [isSubOpen, setIsSubOpen] = useState(false);

    return (
        <>
            <div
                className={`headerWrapper ${selected ? 'selected' : ''}`}
                onClick={() => setIsSubOpen(!isSubOpen)}
            >
                <div className='adminMenu'>
                    <div className='menuIcon'>
                        <Icon />
                    </div>
                    <Title
                        text={title}
                        fontWeight={400}
                        style={{ paddingLeft: '16px' }}
                    />
                </div>
                <div className={'sideBarIconDown'}>
                    {isSubOpen ? (<ArrowUp />) : (<Arrow />)}
                </div>
            </div>
            <div style={{
                transition: `max-height 306ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                maxHeight: isSubOpen ? '92px' : 0,
                cursor: 'pointer',
                overflow: 'hidden',
            }}>
                {
                    menu.map((menuItem, index) => (
                        <SubMenuItem
                            key={index}
                            id={index}
                            title={menuItem.title}
                            url={menuItem.url}
                            onClick={() => onClick()}
                        />
                    ))
                }
            </div>
        </>
    )
}

export default SubMenu;