import React, { useCallback, useEffect, useState } from "react";
import './addMembersForm.scss';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CustomButton from '../../uiKit/Button/CustomButton';

import { addMembers } from "../../redux/state/memberSlice";
import { useHistory } from "react-router";
import AddMemberForm from "./addMemberForm/addMemberForm";
import { BluePlus } from "../../uiKit/assets/svg/icons";
import { blue500 } from "../../uiKit/assets/colors/colors";
import { unwrapResult } from "@reduxjs/toolkit";
import { uuidv4 } from "../../helpers/functions";
import { toast } from "react-toastify";

const AddMembersForm = () => {

    const isLoading = useSelector(state => state.member.isLoading.addMembers, shallowEqual);

    const dispatch = useDispatch();
    const history = useHistory();
    const [disabled, setDisabled] = useState(true);
    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const [members, setMembers] = useState([
        {
            id: uuidv4(),
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: null,
            error: true
        }
    ]);

    const addMoreMembersHandler = () => {
        setDisabled(true);
        if (!members.some(member => {
            const hasContact = !!member.email || !!member.phoneNumber;
            return member.error || !member.firstName || !member.lastName || !hasContact;
        })) {
            if (members.length < 20) {
                const newArr = [...members, {
                    id: uuidv4(),
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: null,
                    error: true
                }]
                setMembers(newArr);
            } else {
                toast.info('Can not add more than 20 member at once');
                setDisabled(false);
            }
        }
    }

    const addMembersHandler = () => {
        if (!members.some(member => member.error) && !!communityId) {
            dispatch(addMembers({ communityId, members }))
                .then(unwrapResult)
                .then(() => setTimeout(() => history.push('/members'), [1000]))
                .catch(() => setDisabled(true))
        } else {
            setDisabled(true);
        }
    }

    const updateMember = useCallback((newMember) => {
        setMembers(prev => prev.map(member => {
            if (newMember.id === member.id) return newMember;
            return member;
        }))
    }, [])

    const removeMember = (id) => {
        if (members.length === 1) {
            setMembers([
                {
                    id: uuidv4(),
                    firstName: null,
                    lastName: null,
                    email: null,
                    phoneNumber: null,
                    error: true
                }
            ])
        } else {
            setMembers(prev => prev.filter(member => member.id !== id));
        }
        setDisabled(false);
    }

    useEffect(() => {
        if (!!members && members.some(member => member.error)) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [members])

    return (
        <div className='addMembersFormWrapper'>
            <div className='memberContent'>
                {
                    members.map((member) => {
                        return (
                            <AddMemberForm
                                key={member.id}
                                id={member.id}
                                count={members.length}
                                member={member}
                                updateMember={updateMember}
                                removeMember={removeMember}
                                setMembers={setMembers}
                                setDisabled={setDisabled}
                                disabled={disabled}
                            />
                        )
                    })
                }
            </div>
            <span
                className='addMembers'
                onClick={addMoreMembersHandler}
            >
                <BluePlus /> <span style={{
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: blue500,
                    cursor: 'pointer'
                }}>Add more members</span>
            </span>
            <div className='addMemberButtons' >
                <CustomButton
                    title="Add members"
                    onClick={addMembersHandler}
                    classNames='addMemberButton'
                    disabled={disabled || isLoading}
                    loading={isLoading}
                />
            </div>
        </div>
    )
}

export default AddMembersForm