import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import Confetti from 'react-confetti';

import Header from "../../../components/Header/header";
import { blue010, blue100, gray010 } from "../../../uiKit/assets/colors/colors";
import ProgressBar from "../../../uiKit/ProgressBar/ProgressBar";
import Title from "../../../uiKit/Title/Title";
import RecentDonationsTableAnonymous from "../../UnauthorizedPages/recentDonations";
import { CompletedCheck } from '../../../uiKit/assets/svg/completed'
import {
    getCampaignPublic,
    getCompletedCampaignPublic,
    setNotFound
} from "../../../redux/state/campaignSlice";
import { setScreenWidth } from "../../../redux/state/commonSlice";

const CampaignNotFound = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const token = window.localStorage.getItem('token');

    const campaign = useSelector(state => state.campaign.campaign, shallowEqual);

    const { campaignId, communityId } = useParams();

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        window.addEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)));
        return () => window.removeEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)))
    }, [screenWidth, dispatch])

    useEffect(() => {
        if (!!communityId && !!campaignId) {
            dispatch(setNotFound(false));
            dispatch(getCampaignPublic({ communityId, campaignId }))
                .then(unwrapResult)
                .then(() => { })
                .catch(({ status }) => {
                    if (status === 404) dispatch(getCompletedCampaignPublic({ communityId, campaignId }))
                })
        }
    }, [communityId, campaignId, dispatch])

    return (
        <div className="notFoundWrapper">
            {['completed', 'stopped'].includes(campaign?.status) && <Confetti />}
            {!!token && <Header title='All campaigns' onClick={() => history.push('/campaigns')} />}
            <div className='communityInfoHeaderWrapper'>
                <div className='photoWrapperCompletedCampaign' >
                    <img
                        alt=''
                        src={screenWidth < 900 ? campaign.images.mobile : campaign.images.desktop}
                    />
                    <div className="overlay">
                        <div className="icon">
                            <CompletedCheck />
                        </div>
                        <Title
                            text="Campaign completed"
                            fontSize={24}
                            lineHeight={32}
                            color={gray010}
                        />
                    </div>
                </div>

                <Title
                    text={campaign.name}
                    fontSize={screenWidth < 1200 ? 24 : 32}
                    lineHeight={screenWidth < 1200 ? 32 : 40}
                    className="ourBalanceTitle"
                />
                <div className='campaignProgressBar'>
                    <div className='progressBar'>
                        <ProgressBar
                            campaignId={campaignId}
                            communityId={communityId}
                            target={campaign.amount}
                            color={blue100}
                            style={{
                                boxShadow: 'none',
                                padding: 0,
                                backgroundColor: screenWidth > 500 ? gray010 : blue010
                            }}
                        />
                    </div>
                </div>
                {
                    !!campaign.description && (
                        <Title
                            text={campaign.description}
                            style={{ marginBottom: '24px' }}
                            fontWeight={400}
                        />
                    )
                }
            </div>
            <div className='campaignsWrapper'>
                <Title
                    text='Recent Donations'
                    fontSize={24}
                    lineHeight={32}
                    className="recentHeader"
                />
                <RecentDonationsTableAnonymous />

            </div>
        </div>
    )
}

export default CampaignNotFound;