import './createCommunity.scss';
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import CustomButton from "../../../uiKit/Button/CustomButton";
import CustomInput from "../../../uiKit/Input/CustomInput";
import CustomSelect from "../../../uiKit/CustomSelect/CustomSelect"
import { InputFile } from "../../../uiKit/Input/InputFile";
import Title from '../../../uiKit/Title/Title';

import { clearIds, createCommunity, getTypes } from '../../../redux/state/communitySlice';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';


const CreateCommunity = ({ url = '' }) => {

    const isLoading = useSelector(state => state.community.isLoading.createCommunity, shallowEqual);
    const isLoadingTypes = useSelector(state => state.community.isLoading.getTypes, shallowEqual);

    const dispatch = useDispatch();
    const history = useHistory();

    const types = useSelector(state => state.community.types, shallowEqual);
    const [imageUrl, setImageUrl] = useState('');

    const [name, setName] = useState('');
    const [communityTypeId, setCommunityTypeId] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');

    const [backgroundImage, setBackgroundImage] = useState(null)
    const [uploaded, setUploaded] = useState(false);
    const [file, setFile] = useState(null);

    const [nameError, setNameError] = useState(null);
    const [typeError, setTypeError] = useState(null);
    const [locationError, setLocationError] = useState(null);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        if (!types) {
            dispatch(getTypes())
        }
        if (!!types) {
            const type = types.find(type => type.id === communityTypeId);
            if (!!type) {
                if (screenWidth < 900) {
                    setImageUrl(type.mobileImage);
                } else {
                    setImageUrl(type.desktopImage);
                }
            }
        }
    }, [types, communityTypeId, screenWidth, dispatch])

    const handleSubmit = useCallback(() => {
        if (
            !!name &&
            !!name.trim() &&
            !!communityTypeId
        ) {
            const body = {
                communityTypeId,
                name,
                file,
                description,
                location
            }
            dispatch(createCommunity({ data: body }))
                .then(unwrapResult)
                .then(res => {
                    history.push(`${url}/created/${res.community.id}`);
                    dispatch(clearIds());
                })
                .catch(({ title }) => toast.info(`Creation of community failed: ${title}`))
        }

        if (!communityTypeId) {
            setTypeError('Required');
        }

        if (!!name && !name.trim()) {
            setNameError('Required');
        }

        if (!name) {
            setNameError('Required');
        }

    }, [
        communityTypeId, dispatch, file,
        history, location, name, url, description
    ])

    const onNameChange = (e) => {
        setNameError('');
        setName(e.target.value);
    }

    const onChangeHandler = (e) => {
        setTypeError('');
        setCommunityTypeId(e.target.value);
    }

    const onLocationChange = (e) => {
        setLocationError('');
        setLocation(e.target.value);
    }

    const onDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    return (
        <div className="page menuVersion">
            <div style={{ flex: '1' }}>
                <Title
                    text='Create New Community'
                    fontWeight={600}
                    fontSize={24}
                    lineHeight={36}
                />
                <div className="photoUpload"
                    style={{
                        backgroundImage: `url(${!!backgroundImage ? backgroundImage : imageUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: 'center',
                        objectFit: 'cover'
                    }}>
                    <InputFile
                        uploaded={uploaded}
                        setUploaded={setUploaded}
                        setBackgroundImage={setBackgroundImage}
                        setFile={setFile}
                    />
                </div>
                <div className='inputWrapperCommunity'>
                    <CustomInput
                        label="Name of community"
                        onChange={onNameChange}
                        value={name}
                        error={!!nameError}
                        errorMessage={nameError}
                        isRequired
                        setErrorMessage={setNameError}
                    />
                    <CustomSelect
                        label="Community Type"
                        placeholder="Choose community type"
                        data={types}
                        onChange={onChangeHandler}
                        value={communityTypeId}
                        errorMessage={typeError}
                        menuWidth={screenWidth < 900 ? 330 : 368}
                        loading={isLoadingTypes}
                    />
                    <CustomInput
                        label="Location (Optional)"
                        onChange={onLocationChange}
                        value={location}
                        error={!!locationError}
                        errorMessage={locationError}
                    />
                    <CustomInput
                        multiline
                        label="Description (Optional)"
                        onChange={onDescriptionChange}
                        value={description}
                        error={false}
                        maxLength={250}
                    />
                </div>
            </div>
            <div style={{
                width: '100%',
                marginTop: '24px'
            }}>
                <CustomButton
                    onClick={handleSubmit}
                    title='Create Community'
                    disabled={isLoading}
                    loading={isLoading}
                />
            </div>
        </div>

    );
}

export default CreateCommunity;