import { Avatar } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import './communityCard.scss';

import { blue100, blue500, gray800 } from "../../uiKit/assets/colors/colors";
import Title from "../../uiKit/Title/Title";
import CustomButton from "../../uiKit/Button/CustomButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addMember, clearAdmins, getAdmins } from "../../redux/state/memberSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useLocation } from "react-router-dom";
import {
    clearCommunity,
    getCommunitiesShortInfo,
    leaveCommunity,
    setCommunityId
} from "../../redux/state/communitySlice";
import { toast } from "react-toastify";
import Approve from "../../uiKit/Verify/approve";
import CustomDialog from "../../uiKit/CustomDialog/customDialog";
import {
    clearCampaigns,
    clearDrafts,
    clearSuggestions
} from "../../redux/state/campaignSlice";
import { Confirm } from "../../uiKit/assets/svg/confirm";
import { clearNotifications } from "../../redux/state/notificationSlice";

const CommunityCard = ({
    hasStatus = false,
    community
}) => {

    const userId = window.localStorage.getItem('userId');

    const { pathname, search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const userCommunityIds = useSelector(state => state.community.memberCommunityIds, shallowEqual);
    const isLoading = useSelector(state => state.community.isLoading.leaveCommunity, shallowEqual);

    const [isMember, setIsMember] = useState(false);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmOpenAdmin, setConfirmOpenAdmin] = useState(false);

    useEffect(() => {
        if (!userCommunityIds) {
            dispatch(getCommunitiesShortInfo());
            return;
        }

        if (!!userCommunityIds) {
            setIsMember(userCommunityIds.some(id => id === community.id));
        }
    }, [community.id, userCommunityIds, dispatch])

    useEffect(() => {
        if (!userCommunityIds) return;

        if (!userCommunityIds.length) {
            window.localStorage.removeItem('communityId')
        }
    }, [userCommunityIds])

    const joinCommunity = useCallback(() => {
        if (!!community.id) {
            dispatch(addMember(community.id))
                .then(unwrapResult)
                .then(() => {
                    history.push('/campaigns');
                    dispatch(setCommunityId({ communityId: community.id }));
                    window.localStorage.setItem('communityId', community.id)

                }).catch(({ title }) => toast.info(title))
        }
    }, [community.id, dispatch, history])

    const adminLeaveHandler = useCallback(() => {
        if (!!community.id) {
            dispatch(getAdmins(community.id))
                .then(unwrapResult)
                .then(response => {
                    const { communityAdmins } = response;
                    communityAdmins.some(admin => admin.userId === userId)
                    if (communityAdmins.length === 1 && communityAdmins.some(admin => admin.userId === userId)) {
                        setConfirmOpenAdmin(true);
                    } else {
                        setConfirmOpen(true);
                    }
                })
        }
    }, [community.id, dispatch, userId])

    const leaveCommunityHandler = (id) => {
        dispatch(leaveCommunity(id));
        setConfirmOpen(false);
    }

    const onCommunityClickHandler = useCallback(() => {
        if (community.isConfirmed) {
            const communityId = community.id;

            dispatch(clearCommunity());
            dispatch(clearCampaigns());
            dispatch(clearAdmins());
            dispatch(clearDrafts());
            dispatch(clearSuggestions());
            dispatch(clearNotifications());
            dispatch(setCommunityId({ communityId }));
            window.localStorage.setItem('communityId', communityId);
            history.push('/campaigns')
        };
    }, [
        community.id,
        community.isConfirmed,
        dispatch,
        history
    ])

    const viewMoreHandler = useCallback(() => {

        if (!community.id) return;

        dispatch(clearCampaigns());

        if (isMember) {
            onCommunityClickHandler();
        } else {
            history.push({
                pathname: `/settings/my-communities/community/${community.id}`,
                state: { pathname, search }
            })
        }
    }, [
        community.id,
        isMember,
        history,
        pathname,
        search,
        onCommunityClickHandler,
        dispatch
    ])

    return (
        <div className="boxShadow communityCardWrapper" >
            <div style={{ display: 'flex' }}>
                <Avatar
                    alt=''
                    src={community.images.mobile}
                    style={{
                        backgroundColor: blue100,
                        fontSize: '12px',
                        lineHeight: '14px',
                        fontWeight: 500,
                        width: '85px',
                        height: '85px',
                        borderRadius: '12px',
                        marginRight: '12px'
                    }} >
                    {community.name.slice(0, 2)}
                </Avatar>
                <div>
                    <div style={{ marginBottom: '12px' }}>
                        {
                            community.isConfirmed && hasStatus && isMember ? (
                                <Title
                                    text={community.name}
                                    style={{ marginBottom: '6px', cursor: 'pointer' }}
                                    color={blue500}
                                    onClick={onCommunityClickHandler}
                                />
                            ) : (
                                <Title
                                    text={community.name}
                                    style={{ marginBottom: '6px' }}
                                    color={gray800}
                                />
                            )
                        }
                        {hasStatus && isMember && <Approve status={community.isConfirmed} />}
                    </div>
                    <Title
                        text={`Members: ${community.numberOfMembers}`}
                        fontSize={12}
                        fontWeight={400}
                    />
                    <Title
                        text={`Active Campaigns: ${community.numberOfActiveCampaigns}`}
                        fontSize={12}
                        fontWeight={400}
                    />
                    {
                        !!community.location && <Title
                            text={`Location: ${community.location}`}
                            fontSize={12}
                            fontWeight={400}
                        />
                    }
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingTop: '12px',
                }}
            >
                <CustomButton
                    title='View more'
                    style={{ width: '48%' }}
                    isWhite
                    onClick={viewMoreHandler}
                />
                {
                    isMember ? (
                        <CustomButton
                            title='Leave'
                            isWhite
                            style={{ width: '48%' }}
                            onClick={adminLeaveHandler}
                        />
                    ) : (
                        <CustomButton
                            title='Join'
                            onClick={joinCommunity}
                            style={{ width: '48%' }}
                        />
                    )
                }
            </div>

            <CustomDialog
                confirmOpen={confirmOpenAdmin}
                onClose={() => setConfirmOpenAdmin(false)}
                Image={Confirm}
                mainButtonTitle='Ok'
                mainButtonAction={() => setConfirmOpenAdmin(false)}
                title='Before leaving the community, you need to assign an admin'
            />

            <CustomDialog
                confirmOpen={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                Image={Confirm}
                mainButtonTitle='Confirm'
                mainButtonAction={() => leaveCommunityHandler(community?.id)}
                isLoading={isLoading}
                secondButton={true}
                title={`You're going to leave ${community?.name}`}
                secondDescription='Please confirm'
            />
        </div>
    )
}

export default CommunityCard;