import './InputFile.scss';
import { Delete, Upload } from '../assets/svg/icons';
import { toast } from 'react-toastify';

export const InputFile = ({
    setBackgroundImage,
    uploaded,
    setUploaded,
    setFile
}) => {

    const handleDelete = () => {
        setBackgroundImage(null);
        setFile(null)
        setUploaded(false);
    }

    const handleCapture = ({ target }) => {

        const file = target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (file.type === 'image/png' || file.type === 'image/jpeg') {
                    // 7Mb
                    if (file.size < 7340032) {
                        const image = URL.createObjectURL(file);
                        setBackgroundImage(image);
                        setFile(file);
                        setUploaded(true);
                    } else {
                        toast.info('Invalid image size');
                    }
                } else {
                    toast.info('Invalid image format');
                }
            });
            reader.readAsDataURL(file);
        }
    }

    return (uploaded ?
        <div className='deleteButton' onClick={handleDelete}>
            <Delete />
        </div> :
        <div className='inputFile'>
            <input
                type="file"
                accept="image/png, image/jpeg"
                id="communityImage"
                onChange={handleCapture}
                formEncType='multipart/form-data'
            />
            <label htmlFor="communityImage" style={{ cursor: 'pointer' }}>
                <Upload />
            </label>
        </div>
    );
}

export const InputFileCorner = ({
    uploaded,
    setFile,
    deleteHandler
}) => {

    const handleCapture = ({ target }) => {

        const file = target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (file.type === 'image/png' || file.type === 'image/jpeg') {
                    // 7Mb
                    if (file.size < 7340032) {
                        setFile(file);
                    } else {
                        toast.info('Invalid image size');
                    }
                } else {
                    toast.info('Invalid image format');
                }
            });
            reader.readAsDataURL(file);
        }
    }

    return (uploaded ?
        <div className='deleteButton' onClick={deleteHandler}>
            <Delete />
        </div> :
        <div className='inputFileCorner'>
            <input
                accept="image/png, image/jpeg"
                id="communityImage"
                type="file"
                onChange={handleCapture}
            />
            <label htmlFor="communityImage" style={{ cursor: 'pointer' }}>
                <Upload />
            </label>
        </div>
    );
}

export const InputFileCircle = ({
    setBackgroundImage,
    uploaded,
    setUploaded,
    setFile
}) => {

    const deleteUpload = () => {
        setBackgroundImage(null);
        setUploaded(false);
        setFile(null);
    }

    const handleCapture = ({ target }) => {

        const file = target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (file.type === 'image/png' || file.type === 'image/jpeg') {
                    // 7Mb
                    if (file.size < 7340032) {
                        const image = URL.createObjectURL(file);
                        setBackgroundImage(image);
                        setFile(file);
                        setUploaded(true);
                    } else {
                        toast.info('Invalid image size');
                    }
                } else {
                    toast.info('Invalid image format');
                }
            });
            reader.readAsDataURL(file);
        }
    }

    return (uploaded ?
        <div className='deleteButtonCircle' onClick={deleteUpload}>
            <Delete />
        </div> :
        <div className='inputFileCircle'>
            <input
                accept="image/png, image/jpeg"
                id="communityImage"
                type="file"
                onChange={handleCapture}
            />
            <label htmlFor="communityImage" style={{ cursor: 'pointer' }}>
                <Upload />
            </label>
        </div>
    );
}