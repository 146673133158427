import './CustomButton.scss'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import {
    blue300,
    blue050,
    blue100,
    gray010,
    blue600,
    blue500,
} from '../assets/colors/colors';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        border: "1px solid",
        textTransform: "none",
        borderRadius: "10px",
        color: gray010,
        background: blue300,
        borderColor: blue300,
        fontFamily: 'Poppins',
        '&:active': {
            background: blue500,
            borderColor: blue500,
            color: gray010,
        },
        '&:hover': {
            background: blue100,
            borderColor: blue100,
            color: gray010,
        },
        '&:disabled': {
            background: `${blue050} !important`,
            borderColor: blue050,
            color: gray010,
        }
    }
}));

const useStylesWhite = makeStyles(theme => ({
    root: {
        border: "1px solid",
        textTransform: "none",
        borderRadius: "10px",
        color: blue500,
        background: gray010,
        borderColor: blue300,
        fontFamily: 'Poppins',
        '&:active': {
            borderColor: '#2087EF',
            color: blue600,
        },
        '&:hover': {
            borderColor: blue100,
            color: blue300,
            background: gray010,
        },
        '&:disabled': {
            borderColor: '#B7DBFF',
            color: blue100,
        }
    }
}));

const CustomButton = ({
    isWhite = false,
    title,
    onClick,
    classNames,
    style,
    Icon = null,
    disabled = false,
    buttonStyle,
    loading = false,
    type = 'button'
}) => {

    const classes = useStyles();
    const classesWhite = useStylesWhite();

    return (
        <div className={`button ${!!classNames ? classNames : ''}`} style={{ ...style }}>
            <Button
                classes={isWhite ? classesWhite : classes}
                onClick={onClick}
                disabled={disabled}
                style={{ ...buttonStyle }}
                type={type}
            >
                {
                    loading ? (
                        <CircularProgress
                            color="inherit"
                            size={20}
                        />
                    ) : (
                        !!Icon ? (
                            <>
                                <Icon />
                                <span style={{
                                    marginLeft: '10px',
                                    fontWeight: 500,
                                    whiteSpace: 'nowrap'
                                }}>
                                    {title}
                                </span>
                            </>
                        ) : (
                            <span style={{
                                fontWeight: 500,
                                whiteSpace: 'nowrap'
                            }}>
                                {title}
                            </span>
                        )
                    )
                }
            </Button>
        </div>
    );
}

export default CustomButton