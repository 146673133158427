import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomTable from "../../uiKit/CustomTable/customTable";
import CustomMobilePagination from "../../uiKit/CustomTable/mobilePagination";
import { getCampaignDonationsPublic } from "../../redux/state/campaignSlice";
import { useLocation, useParams } from "react-router-dom";
import RowRendererUser from "../Campaigns/campaignRowUser";
import RecentDonationsListAnonymous from "./recentDonationsList";

const userHeader = [
    { name: '' },
    { name: 'Name' },
    { name: 'Amount' },
]

const RecentDonationsTableAnonymous = () => {

    const { communityId, campaignId } = useParams();
    const { pathname, search } = useLocation();

    const [items, setItems] = useState(2);
    const [paginationDataDonations, setPaginationDataDonations] = useState(null);

    const donations = useSelector(state => state.campaign.campaignDonations, shallowEqual);
    const isLoading = useSelector(state => state.campaign.isLoading.campaignDonations, shallowEqual);

    const dispatch = useDispatch();

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        if (!!paginationDataDonations && !!communityId && !!campaignId) {
            dispatch(getCampaignDonationsPublic({ ...paginationDataDonations, communityId, campaignId }))
        }
    }, [paginationDataDonations, communityId, campaignId, dispatch])

    useEffect(() => {
        const query = new URLSearchParams(search);
        const page = parseInt(query.get('page')) ?? 1;

        setPaginationDataDonations({
            pageSize: 8,
            page
        })

    }, [search])

    return (
        <>
            <div className='donationTable'>
                <CustomTable
                    RowRenderer={RowRendererUser}
                    headerArray={userHeader}
                    data={donations}
                    setPaginationData={setPaginationDataDonations}
                    isLoading={isLoading}
                    url={`/community/${communityId}/campaign/${campaignId}`}
                />
            </div>
            {
                screenWidth < 900 && (
                    <div className='donationListWrapper'>
                        <div className='donationList'>
                            {
                                !!donations.items && (
                                    <RecentDonationsListAnonymous
                                        data={donations.items}
                                        items={items}
                                    />
                                )
                            }
                        </div>
                        <CustomMobilePagination
                            data={donations}
                            tableUrl={pathname}
                            count={items}
                            setItems={setItems}
                            marginTop={16}
                        />
                    </div>
                )
            }
        </>
    )
}

export default RecentDonationsTableAnonymous;