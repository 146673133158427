import React from "react";
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga4';

import { blue300, gray010 } from "../../../uiKit/assets/colors/colors";
import NewGroup from "../../../uiKit/assets/png/newGroup.png";
import SearchGroups from "../../../uiKit/assets/png/searchGroups.png";
import Title from "../../../uiKit/Title/Title";

const Groups = () => {

    const history = useHistory();

    const onClickCreateGroup = () => {
        ReactGA.event('create_a_new_group');
        history.push('/sign-up?type=create')
    }

    const onClickSearchGroups = () => {
        ReactGA.event('search_groups');
        history.push('/sign-up?type=search');
    }

    return (
        <div className="groups" >
            <div className="groupsContainer" >
                <div className="newGroup" onClick={onClickCreateGroup}>
                    <div style={{
                        position: 'absolute',
                        top: '-15px',
                        left: '-15px',
                        width: '54px',
                        height: '54px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: blue300,
                        color: gray010
                    }}>{1}</div>

                    <img
                        alt=''
                        src={NewGroup}
                        style={{
                            width: '113px',
                            height: '200px',
                            marginBottom: '30px'
                        }}
                    />
                    <Title
                        text="Create a new group"
                        fontSize={24}
                        lineHeight={32}
                        fontWeight={500}
                    />
                </div>
                <div className="searchGroup" onClick={onClickSearchGroups}>
                    <div style={{
                        position: 'absolute',
                        top: '-15px',
                        left: '-15px',
                        width: '54px',
                        height: '54px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: blue300,
                        color: gray010
                    }}>{2}</div>
                    <img
                        alt=''
                        src={SearchGroups}
                        style={{
                            width: '160px',
                            height: '200px',
                            marginBottom: '30px'
                        }}
                    />
                    <Title
                        text="Search groups"
                        fontSize={24}
                        lineHeight={32}
                        fontWeight={500}
                    />
                </div>
            </div>
        </div>
    )
}

export default Groups;