import React, { useEffect, useState } from "react";
import { gray010 } from "../../uiKit/assets/colors/colors";
import { ArrowUp } from "../../uiKit/assets/svg/icons";

const ScrollToTopIcon = () => {

    const [scrollUp, setScrollUp] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        if (isMounted) {
            window.addEventListener('scroll', () => {
                if (window.scrollY === 0) {
                    setScrollUp(false)
                } else {
                    setScrollUp(true)
                }
            })
        }
        return () => {
            window.removeEventListener('scroll', () => {
                if (window.scrollY === 0) {
                    setScrollUp(false)
                } else {
                    setScrollUp(true)
                }
            });
            setIsMounted(false);
        }
    }, [isMounted])

    return (
        <div
            className='scrollTop'
            onClick={() => {
                setScrollUp(false);
                window.scrollTo(0, 0);
            }}
            style={scrollUp ? { display: 'flex' } : { display: 'none' }}
        >
            <ArrowUp color={gray010} />
        </div>
    )
}

export default ScrollToTopIcon;