import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

import { gray800 } from "../../../uiKit/assets/colors/colors";
import { Logo } from "../../../uiKit/assets/svg/logo";
import { Youtube } from "../../../uiKit/assets/svg/socialIcons";
import Title from "../../../uiKit/Title/Title";
import { YOUTUBE_LINK } from "../../../helpers/constants";

const Footer = ({ referances }) => {

    const history = useHistory();
    const { search, pathname } = useLocation();

    useEffect(() => {
        const { signUp, about, contact } = referances;

        if (!signUp || !about || !contact) return;
        const query = new URLSearchParams(search);
        const urlTag = query.get("page");

        switch (urlTag) {
            case 'howItWorks':
                setTimeout(() => {
                    signUp.current.scrollIntoView();
                }, [200])
                return;
            case 'about':
                setTimeout(() => {
                    about.current.scrollIntoView();
                }, [200])
                return;
            case 'contact':
                setTimeout(() => {
                    contact.current.scrollIntoView();
                }, [200])
                return;
            default:
                return;
        }
    }, [referances, search, history])

    const scrollToHowItWorks = useCallback(() => {
        const { signUp } = referances;
        if (!signUp) return

        ReactGA.event('how_it_works_footer');

        const query = new URLSearchParams(search);
        query.set("page", "howItWorks")
        history.replace({
            pathname,
            search: query.toString()
        })

        setTimeout(() => {
            signUp.current.scrollIntoView();
        }, [200])
    }, [referances, search, pathname, history])

    const scrollToAbout = useCallback(() => {
        const { about } = referances;
        if (!about) return;

        ReactGA.event('about_footer');

        const query = new URLSearchParams(search);
        query.set("page", "about")
        history.replace({
            pathname,
            search: query.toString()
        })

        setTimeout(() => {
            about.current.scrollIntoView();
        }, [200])
    }, [referances, search, pathname, history])

    const scrollToContact = useCallback(() => {
        const { contact } = referances;
        if (!contact) return;

        ReactGA.event('contact_footer');

        const query = new URLSearchParams(search);
        query.set("page", "contact")
        history.replace({
            pathname,
            search: query.toString()
        })

        setTimeout(() => {
            contact.current.scrollIntoView();
        }, [200])
    }, [referances, search, pathname, history])

    const onLogoClick = () => {
        history.push('/');
        window.scrollTo(0, 0);
    }

    const onPrivacyClick = useCallback(() => {
        ReactGA.event('privacy_policy');

        const query = new URLSearchParams(search);
        query.set("page", "privacy")
        history.replace({
            pathname,
            search: query.toString()
        })
        window.scrollTo(0, 0);
    }, [pathname, history, search])

    const onTermsClick = useCallback(() => {
        ReactGA.event('terms_and_conditions');

        const query = new URLSearchParams(search);
        query.set("page", "terms")
        history.replace({
            pathname,
            search: query.toString()
        })
        window.scrollTo(0, 0);
    }, [search, pathname, history])

    const onTutorialsClick = useCallback(() => {
        ReactGA.event('tutorials_footer');

        const query = new URLSearchParams(search);
        query.set("page", "tutorials")
        history.replace({
            pathname,
            search: query.toString()
        })
        window.scrollTo(0, 0);
    }, [search, pathname, history])

    const onFAQClick = useCallback(() => {
        ReactGA.event('faq_footer');

        const query = new URLSearchParams(search);
        query.set("page", "FAQ")
        history.replace({
            pathname,
            search: query.toString()
        })
        window.scrollTo(0, 0);
    }, [search, pathname, history])

    const onOurValuesClick = useCallback(() => {
        ReactGA.event('our_values_footer');

        const query = new URLSearchParams(search);
        query.set("page", "values")
        history.replace({
            pathname,
            search: query.toString()
        })
        window.scrollTo(0, 0);
    }, [search, pathname, history])

    return (
        <div className="footer" >
            <div className='footerMenu' >
                <div onClick={onLogoClick} style={{ cursor: 'pointer' }}>
                    <Logo />
                </div>
                <div className="dividerFooterMobile" />
                <div className="footerNavigation" >
                    <Title
                        text="How it works"
                        fontWeight={500}
                        color={gray800}
                        className='hoverNav'
                        style={{
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={scrollToHowItWorks}
                    />
                    <Title
                        text="About"
                        className='hoverNav'
                        fontWeight={500}
                        color={gray800}
                        style={{
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={scrollToAbout}
                    />
                    <Title
                        text="Contact"
                        className='hoverNav'
                        fontWeight={500}
                        color={gray800}
                        style={{
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={scrollToContact}
                    />
                    <Title
                        text="Our Values"
                        className='hoverNav'
                        fontWeight={500}
                        color={gray800}
                        style={{
                            padding: '10px',
                            marginRight: '16px',
                            cursor: 'pointer'
                        }}
                        onClick={onOurValuesClick}
                    />
                    <Title
                        text="FAQ's"
                        className='hoverNav'
                        fontWeight={500}
                        color={gray800}
                        style={{
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={onFAQClick}
                    />
                    <Title
                        text="Tutorials"
                        className='hoverNav'
                        fontWeight={500}
                        color={gray800}
                        style={{
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={onTutorialsClick}
                    />
                </div>
            </div>

            <div className="dividerFooter" />

            <div className="connections" >
                <Title
                    text={`© ${moment().format('YYYY')} OurCommunity`}
                    fontWeight={400}
                    lineHeight={24}
                />
                <Title
                    text="Privacy Policy"
                    fontWeight={400}
                    lineHeight={24}
                    onClick={onPrivacyClick}
                    style={{ cursor: 'pointer' }}
                    className='hoverNav'
                />
                <Title
                    text="Terms and Conditions"
                    fontWeight={400}
                    lineHeight={24}
                    onClick={onTermsClick}
                    style={{ cursor: 'pointer' }}
                    className='hoverNav'
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '160px' }}>
                    <a href={YOUTUBE_LINK} rel="noreferrer" target={"_blank"}>
                        <Youtube />
                    </a>
                </div>
            </div>

            <div className="connectionsMobile" >
                <div >
                    <Title
                        text="Privacy Policy"
                        fontWeight={400}
                        className='hoverNav'
                        lineHeight={24}
                        style={{ marginBottom: '19px', cursor: 'pointer' }}
                        onClick={onPrivacyClick}
                    />
                    <Title
                        text={`© ${moment(Date.now()).format('YYYY')} OurCommunity`}
                        fontWeight={400}
                        lineHeight={24}
                    />
                </div>
                <div>
                    <Title
                        text="Terms and Conditions  "
                        fontWeight={400}
                        className='hoverNav'
                        lineHeight={24}
                        style={{ marginBottom: '19px', cursor: 'pointer' }}
                        onClick={onTermsClick}
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}>
                        <a href={YOUTUBE_LINK} rel="noreferrer" target={"_blank"}>
                            <Youtube />
                        </a>
                    </div>
                </div>
            </div>

            <div className="dividerFooter" />

            <Title
                text="Dedicated in memory of Chaim Meir Halevi Lieberman o.b.m."
                fontWeight={400}
                lineHeight={24}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '24px',
                }}
            />
        </div>
    )
}

export default Footer;