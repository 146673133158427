import React, { useEffect, useState } from "react";
import Draft from "../../../../components/DraftMessage/draft";
import { Arrow, ArrowUp } from "../../../../uiKit/assets/svg/icons";
import CustomCheckbox from "../../../../uiKit/CustomCheckbox/customCheckbox";
import Loading from "../../../../uiKit/Loading/loading";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMessages } from "../../../../redux/state/campaignSlice";

const CampaignMessages = ({ messages = null, updateMessages, isLoading }) => {

    const dispatch = useDispatch();

    const [indeterminate, setIndeterminate] = useState(false);
    const [open, setOpen] = useState(false);

    const defaultMessages = useSelector(state => state.campaign.messages, shallowEqual);
    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const [messagesValue, setMessagesValue] = useState([
        {
            messageType: 2, //campaignInvitation
            content: null,
            isEnabled: true
        },
        {
            messageType: 3, //progress25
            content: null,
            isEnabled: true
        },
        {
            messageType: 4, //progress50
            content: null,
            isEnabled: true
        },
        {
            messageType: 5, //progress75
            content: null,
            isEnabled: true
        },
        {
            messageType: 6, //progress100
            content: null,
            isEnabled: true
        },
    ])

    useEffect(() => {
        if (!defaultMessages && !!communityId) {
            dispatch(getMessages(communityId))
        }
    }, [defaultMessages, communityId, dispatch])

    useEffect(() => {
        if (!!updateMessages) {
            updateMessages(messagesValue);
        }
    }, [messagesValue, updateMessages])

    useEffect(() => {
        if (messagesValue.every(e => !e.isEnabled)) {
            setIndeterminate(true);
        } else if (messagesValue.every(e => e.isEnabled)) {
            setIndeterminate(false);
        } else {
            setIndeterminate(true);
        }
    }, [messagesValue])

    useEffect(() => {
        if (!!messages && !!messages.length) {
            setMessagesValue(prev => (
                prev.map(item => ({
                    ...item,
                    isEnabled: messages.find(message => message.messageType === item.messageType).isEnabled ?? true,
                    content: messages.find(message => message.messageType === item.messageType).content
                }))
            ))
        }
    }, [messages])

    useEffect(() => {
        if (!messages && !!defaultMessages) {
            setMessagesValue(prev => (
                prev.map(item => ({
                    ...item,
                    isEnabled: defaultMessages.find(message => message.messageType === item.messageType).isEnabled ?? true,
                    content: defaultMessages.find(message => message.messageType === item.messageType).content
                }))
            ))
        }
    }, [messages, defaultMessages])

    const onChangeAllHandler = () => {
        if (indeterminate) {
            setMessagesValue(prev => (
                prev.map(item => ({
                    ...item,
                    isEnabled: true,
                }))
            ))
        } else {
            setMessagesValue(prev => (
                prev.map(item => ({
                    ...item,
                    isEnabled: false,
                }))
            ))
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <Draft
                label='Enable message blast'
                title='Campaign Launch message'
                checked={messagesValue.find(item => item.messageType === 2).isEnabled}
                content={messagesValue.find(item => item.messageType === 2).content}
                fullWidth={true}
                defaultValue={!!defaultMessages ? defaultMessages.find(item => item.messageType === 2).content : ''}
                setMessagesValue={setMessagesValue}
                messageType={2}
            />
            <div className='divider' />
            <div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <CustomCheckbox
                        label='Send all progress messages'
                        checked={
                            messagesValue.find(item => item.messageType === 3).isEnabled &&
                            messagesValue.find(item => item.messageType === 4).isEnabled &&
                            messagesValue.find(item => item.messageType === 5).isEnabled &&
                            messagesValue.find(item => item.messageType === 6).isEnabled
                        }
                        indeterminate={indeterminate}
                        onChange={onChangeAllHandler}
                    />
                    <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
                        {
                            open ? <ArrowUp /> : <Arrow />
                        }
                    </div>
                </div>
                {
                    !open ? (
                        <>
                            <div className='draftWrapper'>
                                <Draft
                                    label='25% of funds are raised'
                                    title='Assignment: Progress message. 25%'
                                    classNames='margin24To0'
                                    fullWidth={screenWidth < 900}
                                    checked={messagesValue.find(item => item.messageType === 3).isEnabled}
                                    content={messagesValue.find(item => item.messageType === 3).content}
                                    defaultValue={!!defaultMessages ? defaultMessages.find(item => item.messageType === 3).content : ''}
                                    messageType={3}
                                    setMessagesValue={setMessagesValue}
                                />
                                <Draft
                                    label='50% of funds are raised'
                                    title='Assignment: Progress message. 50%'
                                    fullWidth={screenWidth < 900}
                                    checked={messagesValue.find(item => item.messageType === 4).isEnabled}
                                    content={messagesValue.find(item => item.messageType === 4).content}
                                    defaultValue={!!defaultMessages ? defaultMessages.find(item => item.messageType === 4).content : ''}
                                    messageType={4}
                                    setMessagesValue={setMessagesValue}
                                />
                            </div>
                            <div className='draftWrapper'>
                                <Draft
                                    label='75% of funds are raised'
                                    title='Assignment: Progress message. 75%'
                                    classNames='margin24To0'
                                    fullWidth={screenWidth < 900}
                                    checked={messagesValue.find(item => item.messageType === 5).isEnabled}
                                    content={messagesValue.find(item => item.messageType === 5).content}
                                    defaultValue={!!defaultMessages ? defaultMessages.find(item => item.messageType === 5).content : ''}
                                    messageType={5}
                                    setMessagesValue={setMessagesValue}
                                />
                                <Draft
                                    label='100% of funds are raised'
                                    title='Assignment: Progress message. 100%'
                                    fullWidth={screenWidth < 900}
                                    checked={messagesValue.find(item => item.messageType === 6).isEnabled}
                                    content={messagesValue.find(item => item.messageType === 6).content}
                                    defaultValue={!!defaultMessages ? defaultMessages.find(item => item.messageType === 6).content : ''}
                                    messageType={6}
                                    setMessagesValue={setMessagesValue}
                                />
                            </div>
                        </>
                    ) : null
                }
            </div>
            {
                isLoading && <Loading />
            }
        </div>
    )
}

export default CampaignMessages;