import React, { useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { confirmInvitation, login, parseToken } from "../../redux/state/accountSlice";
import { clearCommunity, setCommunityId } from "../../redux/state/communitySlice";
import LoginComponent from "./loginComponent";
import { useHistory } from "react-router-dom";

const LinkInvitationLogin = () => {

    const user = useSelector(state => state.account.userByParseToken, shallowEqual);

    const dispatch = useDispatch();
    const history = useHistory();
    const { token } = useParams();

    useEffect(() => {
        if (!user.id) {
            dispatch(parseToken(token))
        }
    }, [user, token, dispatch])

    const loginHandler = ({ email, password }) => {
        dispatch(login({ email, password }))
            .then(unwrapResult)
            .then(() => {
                if (!!token) {
                    dispatch(confirmInvitation(token))
                        .then(unwrapResult)
                        .then((response) => {
                            if (response.isSuccessful && !!user.communityId) {
                                dispatch(clearCommunity());
                                window.localStorage.setItem('communityId', user.communityId);
                                dispatch(setCommunityId({ communityId: user.communityId }))
                            }
                        })
                        .catch(({ title }) => console.error(title))
                }
                history.push('/campaigns');
            })
            .catch(({ title }) => console.error(title))
    }

    return <LoginComponent onLogin={loginHandler} signUpUrl={`/sign-up?token=${token}`} />
}

export default LinkInvitationLogin;