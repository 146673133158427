import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import ReactGA from 'react-ga4';

import './personalInfo.scss';
import { Avatar } from "@material-ui/core";

import Verify from "../../../uiKit/Verify/verify";
import { Mail, Phone, SingleUser } from "../../../uiKit/assets/svg/icons";
import Title from "../../../uiKit/Title/Title";
import { Unplug } from "../../../uiKit/assets/svg/unplug";
import { blue100, gray300 } from "../../../uiKit/assets/colors/colors";
import CustomPhoneInput from "../../../uiKit/Input/CustomPhoneInput";

import { nameInitials } from "../../../helpers/functions";

import Header from "../../../components/Header/header";

import CustomButton from "../../../uiKit/Button/CustomButton";
import { InputFileCircle } from "../../../uiKit/Input/InputFile";
import CustomInput from "../../../uiKit/Input/CustomInput";
import { validateName } from "../../../helpers/validation";
import { Confirm } from "../../../uiKit/assets/svg/confirm";
import { deleteUser, logout, updateUser } from "../../../redux/state/accountSlice";
import { toast } from "react-toastify";
import CustomDialog from "../../../uiKit/CustomDialog/customDialog";

const PersonalInfo = () => {

    const userId = window.localStorage.getItem('userId');
    
    const isLoading = useSelector(state => state.account.isLoading.updateUser, shallowEqual);

    const dispatch = useDispatch();

    const user = useSelector(state => state.account.userDetails, shallowEqual);
    const admins = useSelector(state => state.member.communityAdmins, shallowEqual);

    const [deletedOpen, setDeletedOpen] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(null)
    const [uploaded, setUploaded] = useState(false);
    const [file, setFile] = useState(null);
    const [edit, setEdit] = useState(false);

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');

    const closeHandler = () => {
        setDeletedOpen(false);
    }

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/personal-info` })
    }, [])

    useEffect(() => {
        if (!!user.id) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setPhoneNumber(user.phoneNumber);
            setBackgroundImage(user.imageName);
            setUploaded(!!user.imageName);
        }
    }, [user])

    const deleteUserHandler = () => {
        dispatch(deleteUser())
            .then(unwrapResult)
            .then(() => dispatch(logout()))
            .catch((error) => toast.info(error.title))
    }

    const onFirstNameChange = (e) => {
        const value = e.target.value;
        setFirstName(value);
        if (validateName(value)) {
            setFirstNameError('');
        } else {
            setFirstNameError('Invalid first name');
        }
    }

    const onLastNameChange = (e) => {
        const value = e.target.value;
        setLastName(value);
        if (validateName(value)) {
            setLastNameError('');
        } else {
            setLastNameError('Invalid last name');
        }
    }

    const onPhoneNumberChange = (value) => {
        setPhoneNumber(value);
        if (!!value && isPossiblePhoneNumber(value)) {
            setPhoneNumberError('');
        } else {
            setPhoneNumberError('Invalid phone number');
        }
    }

    const onDeleteClick = () => {
        if (!!admins && admins.find(admin => admin.userId === userId)) {
            setDeleted(true);
        } else {
            setDeletedOpen(true);
        }
    }

    const onSaveHandler = () => {
        const data = {
            userId,
            file,
            firstName,
            lastName,
            phoneNumber: !!phoneNumber ? phoneNumber : '',
            preferredCommunicationMethod: user.preferredCommunicationMethod
        }
        dispatch(updateUser(data))
            .then(unwrapResult)
            .then(() => setEdit(false))
            .catch(({ title }) => toast.info(title))
    }

    const cancelHandler = () => {
        setEdit(false)
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setPhoneNumber(user.phoneNumber);
        setBackgroundImage(user.imageName);
        setUploaded(!!user.imageName);
    }

    const offPhoneFocus = useCallback(() => {
        if (!phoneNumber) {
            setPhoneNumberError('');
        }
    }, [phoneNumber])

    return (
        <div>
            <Header title='Personal Info' clickable={false} />
            <div className='personalWrapper'>
                <div className="personalInfoContent">
                    {
                        !edit ? (
                            <Avatar
                                src={user.imageName}
                                alt=''
                                className="imageWrapper"
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: blue100,
                                    fontSize: '32px',
                                    lineHeight: '40px',
                                    fontWeight: 600,
                                    width: '140px',
                                    height: '140px'
                                }} >
                                {!!user.firstName && nameInitials(`${user.firstName} ${user.lastName}`)}
                            </Avatar>
                        ) : (
                            <div
                                className="imageWrapper"
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: blue100,
                                    fontSize: '32px',
                                    lineHeight: '40px',
                                    fontWeight: 600,
                                    width: '140px',
                                    height: '140px'
                                }}
                            >
                                <Avatar
                                    src={backgroundImage}
                                    alt=''
                                    style={{
                                        borderRadius: '50%',
                                        backgroundColor: blue100,
                                        fontSize: '32px',
                                        lineHeight: '40px',
                                        fontWeight: 600,
                                        width: '140px',
                                        height: '140px'
                                    }}
                                >
                                    {!!user.firstName && nameInitials(`${user.firstName} ${user.lastName}`)}
                                </Avatar>
                                <InputFileCircle
                                    uploaded={uploaded}
                                    setUploaded={setUploaded}
                                    setBackgroundImage={setBackgroundImage}
                                    setFile={setFile}
                                />
                            </div>
                        )
                    }
                    <div style={{ marginBottom: '8px' }}>
                        <Title
                            text={!!user.firstName ? `${user.firstName} ${user.lastName}` : ''}
                            fontWeight={600}
                            fontSize={24}
                            lineHeight={32}
                        />
                    </div>
                    <Verify status={user.isVerified} />

                    <div style={{
                        border: `1px solid ${gray300}`,
                        height: '2px',
                        margin: '16px 0 16px 0',
                        width: '100%'
                    }} />

                    {
                        edit ? (
                            <div className="personalInfoInputWrapper" >
                                <CustomInput
                                    StartAdornment={SingleUser}
                                    placeholder='First Name'
                                    type='text'
                                    onChange={onFirstNameChange}
                                    value={firstName}
                                    error={!!firstNameError}
                                    errorMessage={firstNameError}
                                />
                                <CustomInput
                                    StartAdornment={SingleUser}
                                    placeholder='Second Name'
                                    type='text'
                                    onChange={onLastNameChange}
                                    value={lastName}
                                    error={!!lastNameError}
                                    errorMessage={lastNameError}
                                />
                                <CustomInput
                                    StartAdornment={Mail}
                                    placeholder='Email'
                                    type='email'
                                    value={user.email}
                                    disabled={true}
                                />
                                <CustomPhoneInput
                                    StartAdornment={Phone}
                                    placeholder='Phone'
                                    onChange={onPhoneNumberChange}
                                    value={phoneNumber}
                                    error={!!phoneNumberError}
                                    errorMessage={phoneNumberError}
                                    outFocus={offPhoneFocus}
                                />
                            </div>
                        ) : (
                            <>
                                <div className="userInfo">
                                    <div>
                                        <SingleUser />
                                    </div>
                                    <Title
                                        text={user.firstName}
                                        fontWeight={400}
                                        style={{ marginLeft: '10px' }}
                                    />
                                </div>
                                <div className="userInfo">
                                    <div>
                                        <SingleUser />
                                    </div>
                                    <Title
                                        text={user.lastName}
                                        fontWeight={400}
                                        style={{ marginLeft: '10px' }}
                                    />
                                </div>
                                <div className="userInfo">
                                    <div>
                                        <Mail />
                                    </div>
                                    <Title
                                        text={user.email}
                                        fontWeight={400}
                                        style={{ marginLeft: '10px', overflow: 'visible', wordBreak: 'break-all' }}
                                    />
                                </div>
                                <div className="userInfo" style={{ marginBottom: '24px' }}>
                                    <div>
                                        <Phone />
                                    </div>
                                    <Title
                                        fontWeight={400}
                                        style={{ marginLeft: '10px' }}
                                        text={user.phoneNumber}
                                    />
                                </div>
                            </>
                        )
                    }
                    <span className='deleteMemberButton' onClick={onDeleteClick}>
                        Delete account
                    </span>
                </div>
                <div className="personalInfoButtons">
                    {
                        !edit ? (
                            <CustomButton
                                title='Edit'
                                onClick={() => setEdit(true)}
                                classNames='infoButtonCancel'
                            />
                        ) : (
                            <>
                                <CustomButton
                                    title='Save'
                                    onClick={onSaveHandler}
                                    classNames='infoButtonCancel'
                                    disabled={
                                        !!firstNameError ||
                                        !!lastNameError ||
                                        !!phoneNumberError ||
                                        isLoading
                                    }
                                    loading={isLoading}
                                />
                                {
                                    !isLoading && (
                                        <CustomButton
                                            title='Cancel'
                                            isWhite
                                            onClick={cancelHandler}
                                            classNames='infoButtonSave'
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>
            <CustomDialog
                confirmOpen={deletedOpen}
                onClose={closeHandler}
                Image={Unplug}
                mainButtonTitle='Confirm'
                mainButtonAction={deleteUserHandler}
                secondButton={true}
                title='You are going to delete your account.'
                secondDescription='Confirm your action, please'
            />

            <CustomDialog
                confirmOpen={deleted}
                onClose={() => setDeleted(false)}
                Image={Confirm}
                mainButtonTitle='ok'
                mainButtonAction={() => setDeleted(false)}
                title={`Unfortunately, you can't delete your account, because you're admin of the community`}
            />
        </div >
    )
}

export default PersonalInfo;