import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ReactGA from 'react-ga4';
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";

import './paymentPreferences.scss';
import CardTemplate from '../../../uiKit/assets/svg/cardTemplate.svg';
import Title from "../../../uiKit/Title/Title";
import CustomCheckbox from "../../../uiKit/CustomCheckbox/customCheckbox";
import { gray010 } from "../../../uiKit/assets/colors/colors";
import { CheckIcon, Close, Stripe, WhitePen } from "../../../uiKit/assets/svg/icons";
import Loading from "../../../uiKit/Loading/loading";
import RecurringDonations from "./recurringDonations";

import {
    clearSavedCard,
    getPaymentSettings,
    getSavedCard,
    updatePaymentSettings
} from '../../../redux/state/paymentSlice';


const PLACEHOLDER = 'XXXX-XXXX-XXXX-XXXX';

const SavePaymentForm = ({ clientSecret }) => {

    const isLoading = useSelector(state => state.payment.isLoading.subscriptions, shallowEqual);

    const subscriptions = useSelector(state => state.payment.subscriptions, shallowEqual);

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const savedCard = useSelector(state => state.payment.savedCard, shallowEqual);

    const [isAnonymous, setIsAnonymous] = useState(false);
    const [placeholder, setPlaceholder] = useState(PLACEHOLDER);

    const [edit, setEdit] = useState(false);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/payment-preferences` })
    }, [])

    useEffect(() => {
        if (!savedCard) {
            dispatch(getSavedCard())
        }
    }, [savedCard, dispatch])

    useEffect(() => {
        if (!savedCard) return;
        if (!savedCard.last4) return;

        if (edit) {
            setPlaceholder(PLACEHOLDER);
        } else {
            setPlaceholder(`XXXX-XXXX-XXXX-${savedCard.last4}`);
        }
    }, [savedCard, edit])

    useEffect(() => {
        dispatch(getPaymentSettings())
            .then(unwrapResult)
            .then(response => setIsAnonymous(response))
            .catch(({ title }) => toast.info(title))
    }, [dispatch])

    const anonymousHandler = useCallback(() => {
        dispatch(updatePaymentSettings({ donateAnonymously: !isAnonymous }))
            .then(unwrapResult)
            .then(() => {
                setIsAnonymous(prev => !prev);
                toast.info('Done');
            })
            .catch(() => toast.info('Failed'))
    }, [isAnonymous, dispatch])

    const saveCard = useCallback(async () => {

        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement)
            }
        })

        if (result.setupIntent?.status === "succeeded") {
            dispatch(clearSavedCard());
            elements.getElement(CardNumberElement).clear();
            elements.getElement(CardExpiryElement).clear();
            elements.getElement(CardCvcElement).clear();
            toast.info('Saved');
            setEdit(false);
        }

        if (result.error) {
            toast.info(result.error.message);
        }

    }, [stripe, elements, clientSecret, dispatch])

    const resetPlaceHolder = useCallback(() => setPlaceholder(PLACEHOLDER), [])

    return (
        <div className='paymentPreferencesWrapper'>
            <Title
                text="Payment preferences"
                fontSize={screenWidth < 900 ? 24 : 32}
                lineHeight={screenWidth < 900 ? 32 : 40}
            />
            <div
                className="cardTamplete"
                style={{ backgroundImage: `url(${CardTemplate})`, position: 'relative' }}
            >
                <div className="titleWrapperStripe">
                    <Stripe />
                    {
                        edit ? (
                            <div style={{
                                width: '40px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <div onClick={saveCard}>
                                    <CheckIcon color={gray010} />
                                </div>
                                <div onClick={() => setEdit(false)}>
                                    <Close color={gray010} />
                                </div>
                            </div>
                        ) : (
                            <div onClick={() => setEdit(true)}>
                                <WhitePen />
                            </div>
                        )
                    }
                </div>
                <div className="customInput" style={{ paddingTop: '12px' }}>
                    <CardNumberElement
                        className="customElement width280"
                        options={{ placeholder }}
                        onFocus={resetPlaceHolder}
                    />
                </div>
                {
                    edit && (
                        <div className="customInputCVC">
                            <CardExpiryElement
                                className="customElement width132"
                                options={{ placeholder: 'MM/YY' }}

                            />
                            <CardCvcElement
                                className="customElement width132"
                                options={{ placeholder: 'CVV' }}
                            />
                        </div>
                    )
                }
            </div>
            <CustomCheckbox
                label='I want to donate anonymously'
                style={{ marginBottom: '40px' }}
                checked={isAnonymous}
                onChange={anonymousHandler}
            />
            <div>
                <Title
                    text={`Recurring donations (${subscriptions.totalNumberOfItems})`}
                    fontSize={20}
                    lineHeight={30}
                />
                <div className="recurringList" >
                    <RecurringDonations />
                    {
                        isLoading && <Loading />
                    }
                </div>
            </div>
        </div >
    )
}

export default SavePaymentForm;