import React from "react";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import Title from "../../uiKit/Title/Title";
import { blue300, gray650 } from "../assets/colors/colors";
import { RadioIcon, RadioIconChecked } from "../assets/svg/icons";
import CustomCheckbox from "../CustomCheckbox/customCheckbox";

const useStyles = makeStyles(() => ({
    root: {
        color: gray650
    },
    label: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
        fontFamily: 'Poppins'
    }
}))

const useStylesForm = makeStyles(() => ({
    colorPrimary: {
        '&.Mui-checked': {
            color: blue300,
        }
    },

}))

const CustomRadio = ({
    label,
    onChange,
    value = 0,
    data = [],
    checkBoxLabel = false,
    style,
    disable = false,
    onCheckboxChange
}) => {

    const formClasses = useStyles();
    const radioClasses = useStylesForm();

    return (
        <>
            {
                checkBoxLabel ? (
                    <CustomCheckbox
                        label={label}
                        checked={!disable}
                        onChange={onCheckboxChange}
                        style={{ marginBottom: '16px', width: 'max-content'  }}
                    />
                ) : (
                    <Title style={{
                        textTransform: 'uppercase',
                        marginBottom: '7px'
                    }}
                        fontSize={12}
                        fontWeight={600}
                        lineHeight={14}
                        text={label}
                        color={gray650}
                    />
                )
            }
            <RadioGroup
                aria-label="position"
                name="position"
                onChange={!disable ? onChange : () => { }}
                value={value}
                style={{ ...style, width: 'max-content'  }}
                color='primary'
            >
                {
                    data.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            value={item.id}
                            control={
                                <Radio
                                    color='primary'
                                    classes={radioClasses}
                                    icon={<RadioIcon disabled={disable} />}
                                    checkedIcon={<RadioIconChecked disabled={disable} />}
                                />
                            }
                            label={item.value}
                            classes={formClasses}
                        />
                    ))
                }
            </RadioGroup>
        </>
    )
}

export default CustomRadio;