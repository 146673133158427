import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Feedback from "../../components/Feedback/feedback";
import { setNotFound } from "../../redux/state/campaignSlice";
import { NotFound } from "../../uiKit/assets/svg/notFound";

const CampaignNotFoundAnonymous = () => {

    const history = useHistory();
    const dispach = useDispatch();

    return (
        <div className='createdPage'>
            <Feedback
                title="Sorry"
                firstDescription='This campaign has been completed. Thank you for your readiness to help!'
                secondDescription="Please create an account so you don't miss the chance to help our community"
                button={{
                    feedbackTitle: 'Create account',
                    feedbackAction: () => {
                        dispach(setNotFound(false));
                        history.push('/sign-up');
                    },
                    disable: false
                }}
                Image={NotFound}
                titleFontSize={24}
            />
        </div>
    )
}

export default CampaignNotFoundAnonymous;