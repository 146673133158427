import React, { useCallback, useEffect, useState } from 'react';
import { Celebration } from '../../../uiKit/assets/svg/celebration.js'
import Feedback from '../../../components/Feedback/feedback';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { checkCommunityStatus, clearCommunity, setCommunityId } from '../../../redux/state/communitySlice.js';
import { useParams } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const Created = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const { communityId } = useParams();

    const [isConfirmed, setIsConfirmed] = useState(false);

    useEffect(() => {
        if (!!communityId) {
            dispatch(checkCommunityStatus(communityId))
                .then(unwrapResult)
                .then(response => setIsConfirmed(response.isConfirmed))
                .catch(({ title }) => toast.info(title));
        }
    }, [communityId, dispatch])

    useEffect(() => {
        if (isConfirmed && !!communityId) {
            dispatch(clearCommunity());
            window.localStorage.setItem('communityId', communityId);
            dispatch(setCommunityId({ communityId }));
        }
    }, [isConfirmed, communityId, dispatch])

    const addMemberHandler = useCallback(() => {
        history.push('/campaigns');
        window.localStorage.setItem('add-members', true);
    }, [history])

    return (
        <div className='createdPage menuVersion'>
            <Feedback
                title="Thank you"
                firstDescription='Your community is pending approval.'
                secondDescription="We'll be in touch: check your email to see our confirmation."
                button={{
                    feedbackTitle: 'Add members',
                    feedbackAction: addMemberHandler,
                    disable: !isConfirmed
                }}
                Image={Celebration}
                titleFontSize={24}
            />
        </div>
    )
}

export default Created;