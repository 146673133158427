import React from "react";
import Title from "../../../uiKit/Title/Title";
import { blue300 } from "../../../uiKit/assets/colors/colors";
import { useHistory } from "react-router-dom";

const NoTransfers = () => {

    const history = useHistory();

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Title
                text="You did not transfer any amount yet."
                fontSize={16}
                lineHeight={24}
                fontWeight={400}
            />
            <Title
                text='To all campaigns'
                fontSize={16}
                lineHeight={24}
                fontWeight={400}
                color={blue300}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/campaigns`)}
            />
        </div>
    )
}

export default NoTransfers;