import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { shallowEqual, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

import './myCommunities.scss';
import { makeStyles } from '@material-ui/core';
import Title from '../../../uiKit/Title/Title';
import CustomTable from '../../../uiKit/CustomTable/customTable';
import CustomButton from '../../../uiKit/Button/CustomButton';

import Header from '../../../components/Header/header';
import MyCommunitiesSearchInput from './myCommunitiesSearchInput';
import MobileList from './mobileList';

import TableRowRenderer from './MyCommunitiesTable/tableRow';

export const useDialogStyles = makeStyles(() => ({
    paper: {
        '&.MuiPaper-rounded': {
            borderRadius: '12px'
        }
    }
}))

const MyCommunities = () => {

    const history = useHistory();

    const communities = useSelector(state => state.community.memberCommunities, shallowEqual);
    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);

    const [filteredCommunities, setFilteredCommunities] = useState([]);

    
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/my-communities` })
    }, [])

    return (
        <>
            <Header title='My Communities' clickable={false} />
            <div className='myCommunitiesWrapper'>
                <Title
                    text='My Communities'
                    fontSize={32}
                    lineHeight={40}
                    style={{ marginBottom: '24px' }}
                />
                <div className='contentWrapper'>
                    <MyCommunitiesSearchInput
                        communities={communities}
                        setCommunities={setFilteredCommunities}
                    />
                    <MobileList
                        data={filteredCommunities}
                        isAdmin={!!isAdmin}
                    />
                    <CustomButton
                        title='Join new community'
                        onClick={() => history.push('/settings/my-communities/search-community')}
                    />
                </div>
                <div className='myCommunitiesTableWrapper boxShadow'>
                    <CustomTable
                        RowRenderer={TableRowRenderer}
                        data={{ items: filteredCommunities }}
                        headerArray={[
                            { name: '' },
                            { name: 'Communities' },
                            { name: 'Status' },
                            { name: 'Role' },
                            { name: 'Members' },
                            { name: 'Location' },
                            { name: 'Action' }
                        ]}
                        hasPagination={false}
                    />
                </div>
            </div>
        </>
    )
}

export default MyCommunities;