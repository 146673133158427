import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
    Bell,
    CoinSack,
    Settings,
    SingleUser,
    Target
} from "../../../uiKit/assets/svg/icons";

import MenuItem from './MenuItem';


const GeneralMenu = ({ onClose = () => { } }) => {

    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);

    const campaignsCount = useSelector(state => state.community.community.numberOfActiveCampaigns, shallowEqual);
    const communityBalance = useSelector(state => state.community.community.ourBalance, shallowEqual);
    const membersCount = useSelector(state => state.community.community.numberOfMembers, shallowEqual);

    const adminSubMenu = [
        // { title: 'Automated messages', url: '/settings/admin/messages' },
        { title: 'Campaign drafts', url: '/settings/admin/drafts' },
        { title: 'Community admins', url: '/settings/admin/admins' }
    ]

    const memberMenu = [
        { title: 'Campaigns', url: '/campaigns', icon: Target, total: campaignsCount },
        { title: 'Notifications', url: '/notifications', icon: Bell, },
        { title: 'Admins', url: '/community-admins', icon: SingleUser, },
        { title: 'Our Balance', url: '/our-balance', icon: CoinSack, total: `$${communityBalance.toLocaleString()}` },
    ]

    const adminMenu = [
        { title: 'Campaigns', url: '/campaigns', icon: Target, total: campaignsCount },
        { title: 'Notifications', url: '/notifications', icon: Bell },
        { title: 'Members', url: '/members', icon: SingleUser, total: membersCount },
        { title: 'Admin settings', url: '/admin-settings', icon: Settings, hasSubmenu: { menu: adminSubMenu } },
        { title: 'Our Balance', url: '/our-balance', icon: CoinSack, total: `$${communityBalance.toLocaleString()}` },
    ]

    return (
        !!isAdmin ? (
            adminMenu.map((item, index) => (
                <MenuItem
                    key={index}
                    Icon={item.icon}
                    title={item.title}
                    url={item.url}
                    total={item.total}
                    hasSubMenu={!!item.hasSubmenu}
                    subMenu={item.hasSubmenu?.menu}
                    onClick={() => onClose()}
                />
            ))
        ) : (
            memberMenu.map((item, index) => (
                <MenuItem
                    key={index}
                    Icon={item.icon}
                    title={item.title}
                    url={item.url}
                    total={item.total}
                    onClick={() => onClose()}
                />
            ))
        )
    )
}

export default GeneralMenu;