import React, { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { blue500 } from '../../../uiKit/assets/colors/colors';
import Title from '../../../uiKit/Title/Title';
import { Confirm } from '../../../uiKit/assets/svg/confirm';

import { updateMemberRole } from '../../../redux/state/memberSlice';
import { toast } from 'react-toastify';
import CustomDialog from '../../../uiKit/CustomDialog/customDialog';

const RelinquishRole = ({ item }) => {

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmOpenAdmin, setConfirmOpenAdmin] = useState(false);

    const admins = useSelector(state => state.member.communityAdmins, shallowEqual);

    const dispatch = useDispatch();

    const relinquishRoleHandler = () => {
        if (admins.length === 1) {
            setConfirmOpenAdmin(true);
        } else {
            setConfirmOpen(true)
        }
    }

    const confirmHandler = useCallback(() => {
        dispatch(updateMemberRole({
            communityId: item.communityId,
            userId: item.userId,
            memberRole: 0
        }))
            .then(unwrapResult)
            .then(() => setConfirmOpen(false))
            .catch(({ title }) => toast.info(`Role is not updated: ${title}`))
    }, [item, dispatch])

    return (
        <>
            <Title
                text='Relinquish role'
                fontWeight={400}
                color={blue500}
                lineHeight={14}
                fontSize={12}
                style={{ cursor: 'pointer' }}
                onClick={relinquishRoleHandler}
            />
            <CustomDialog
                confirmOpen={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                Image={Confirm}
                mainButtonTitle='Confirm'
                mainButtonAction={confirmHandler}
                secondButton={true}
                title="You're going to relinquish the admin role. Please confirm your action."
                secondDescription='Please confirm'
            />
            <CustomDialog
                confirmOpen={confirmOpenAdmin}
                onClose={() => setConfirmOpenAdmin(false)}
                Image={Confirm}
                mainButtonTitle='Ok'
                mainButtonAction={() => setConfirmOpenAdmin(false)}
                title='To relinquish the administrator role you need to assign an administrator for the community.'
            />
        </>
    )
}

export default RelinquishRole;