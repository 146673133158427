import React from "react";
import { useHistory } from "react-router-dom";
import Feedback from "../../components/Feedback/feedback";
import Header from "../../components/Header/header";
import { Unplug } from "../../uiKit/assets/svg/unplug";
import './underConstruction.scss';

const UnderConstruction = () => {

    const history = useHistory();

    return (
        <>
            <Header title='Go back' onClick={() => history.goBack()} />
            <div className="underConstruction">
                <div className='boxShadow donationWrapper' >
                    <Feedback
                        firstDescription='This page is currently under construction'
                        button={{
                            feedbackTitle: 'Back to campaigns',
                            feedbackAction: () => history.push('/campaigns'),
                            disable: false
                        }}
                        Image={Unplug}
                    />
                </div>
            </div>
        </>
    )
}

export default UnderConstruction;