import React, { useCallback, useEffect } from "react";
import ReactGA from 'react-ga4';

import './campaigns.scss';
import Title from '../../uiKit/Title/Title';
import Balance from "../../components/Balance/balance";
import CustomButton from '../../uiKit/Button/CustomButton';
import Header from "../../components/Header/header";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CampaignsTabs from "./campaignsTabs";

const Campaigns = () => {

    const history = useHistory();

    const imageURL = useSelector(state => state.community.communityImageURL, shallowEqual);
    const communityName = useSelector(state => state.community.community.name, shallowEqual);
    const communityBalance = useSelector(state => state.community.community.ourBalance, shallowEqual);
    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);
    const activeCampaigns = useSelector(state => state.campaign.activeCampaigns.items, shallowEqual);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const createCampaignHandler = useCallback(() => {
        ReactGA.event('create_campaign');
        history.push('/campaigns/create-campaign')
    }, [history])

    const suggestCampaignHandler = useCallback(() => {
        ReactGA.event('suggest_campaign_click');
        history.push('/campaigns/suggest-campaign')
    }, [history])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/campaigns` })
    }, [])

    return (
        <>
            <div>
                <Header title='campaigns' clickable={false} />
                <div
                    className='communityInfoHeaderWrapper'
                    style={{ marginBottom: screenWidth < 900 ? '24px' : '60px' }}
                >
                    <div className='campaignMobileHeader'>
                        <div className='photoWrapper' style={{ backgroundImage: `url(${imageURL})` }} />
                        <Title text={communityName}
                            fontSize={screenWidth < 1200 ? 24 : 32}
                            lineHeight={screenWidth < 1200 ? 32 : 40}
                            className="ourBalanceTitle"
                        />
                    </div>
                    {
                        !!isAdmin && (
                            <div>
                                <Balance balance={`$ ${communityBalance.toLocaleString()}`} />
                                <CustomButton
                                    title='New Campaign'
                                    classNames='maxContentButton'
                                    onClick={createCampaignHandler}
                                />
                            </div>
                        )
                    }{
                        !isAdmin && (
                            !!activeCampaigns?.length ?
                                (
                                    <div className='memberButtons '>
                                        <CustomButton
                                            title='Suggest Campaign'
                                            isWhite
                                            onClick={suggestCampaignHandler}
                                        />
                                    </div>
                                ) : screenWidth > 900 && (
                                    <div className='memberButtons '>
                                        <CustomButton
                                            title='Suggest Campaign'
                                            isWhite
                                            onClick={suggestCampaignHandler}
                                        />
                                    </div>
                                )
                        )
                    }
                </div>
            </div>
            <CampaignsTabs />
        </>
    )
}

export default Campaigns;