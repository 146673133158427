import React from "react";

import { Checkbox, FormControlLabel, FormGroup, makeStyles } from "@material-ui/core";

import { blue300, gray300 } from "../../uiKit/assets/colors/colors";
import Title from "../Title/Title";
import { CheckboxChecked, CheckboxIcon, CheckboxIndeterminate } from "../assets/svg/icons";

const useStylesCheckbox = makeStyles(() => ({
    root: {
        color: blue300,
        padding: '0 12px 0 0',
        '&.Mui-checked': {
            borderRadius: '4px',
            padding: '0 12px 0 0',
        },
        '&.Mui-checked:hover': {
            backgroundColor: 'rgba(0,0,0,0)'
        },
        '&.Mui-disabled': {
            color: gray300,
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)'
        }
    }
}))

const CustomCheckbox = ({
    checked,
    onChange,
    label,
    indeterminate,
    disabled,
    style,
    height = '40px'
}) => {

    const useStyles = makeStyles(() => ({
        root: {
            height: height,
            alignItems: 'center'
        }
    }))    

    const formClasses = useStyles();
    const chechboxClasses = useStylesCheckbox();

    return (
        <FormGroup>
            <FormControlLabel
                disabled={disabled}
                classes={formClasses}
                control={
                    <Checkbox
                        checked={checked}
                        onChange={onChange}
                        color='primary'
                        classes={chechboxClasses}
                        indeterminate={indeterminate}
                        icon={<CheckboxIcon disabled={disabled} />}
                        checkedIcon={<CheckboxChecked disabled={disabled} />}
                        indeterminateIcon={<CheckboxIndeterminate disabled={disabled} />}
                    />
                }
                label={
                    <Title
                        text={label}
                        fontWeight={400}
                    />
                }
                style={{ ...style, marginLeft: 0 }}
            />
        </FormGroup>
    )
}

export default CustomCheckbox;