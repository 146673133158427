import React, { useCallback } from "react";
import ReactGA from 'react-ga4';
import { useHistory } from "react-router";

import { blue100 } from "../../../../uiKit/assets/colors/colors";
import ProgressBar from "../../../../uiKit/ProgressBar/ProgressBar";

const ActiveCampaignList = ({ data = [], items }) => {

    const history = useHistory();

    const onCampaignClick = useCallback((id) => {
        ReactGA.event(`active_campaign_click`)
        history.push(`campaigns/campaign/active/${id}`)
    }, [history])

    return (
        data.slice(0, items)
            .map((campaign, index) => (
                <ProgressBar
                    key={index}
                    campaignId={campaign.id}
                    communityId={campaign.communityId}
                    title={campaign.name}
                    target={campaign.amount}
                    color={blue100}
                    clickable={true}
                    onClick={() => onCampaignClick(campaign.id)}
                />
            ))
    )
}

export default ActiveCampaignList;