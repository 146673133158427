import React, { useCallback } from "react";
import ReactGA from 'react-ga4';

import { blue020, blue500, gray010 } from "../../../uiKit/assets/colors/colors";
import { Coin, Medal, Privacy } from "../../../uiKit/assets/svg/icons";
import CustomButton from "../../../uiKit/Button/CustomButton";
import Title from "../../../uiKit/Title/Title";
import Notebook from "../../../uiKit/assets/png/notebook.png";
import Notecard from "../../../uiKit/assets/png/notecard.png";
import { useHistory } from "react-router-dom";

const CardElement = ({ Icon, title }) => (
    <div style={{
        background: gray010,
        border: `2px solid ${blue020}`,
        boxShadow: '12px 0px 36px rgba(207, 230, 255, 0.7)',
        borderRadius: '12px',
        maxWidth: '398px',
        minWidth: '270px',
        width: '100%',
        marginTop: '24px'
    }}>
        <div style={{
            padding: '24px',
            display: 'flex',
            alignItems: 'center'
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <Icon />
            </div>
            <Title
                text={title}
                fontSize={24}
                lineHeight={32}
                style={{ marginLeft: '16px' }}
            />
        </div>
    </div>
)

const About = () => {

    const history = useHistory();

    const signUpHandler = useCallback(() => {
        ReactGA.event('join_now_landing');
        history.push('/sign-up')
    }, [history]);

    return (
        <div className="about">
            <div className="aboutText">
                <div className="aboutTitle" >
                    <Title
                        text="ABOUT"
                        color={blue500}
                        lineHeight={18}
                        style={{ marginBottom: '8px', cursor: 'pointer', width: 'fit-content' }}
                        onClick={() => window.scrollTo(0, 0)}
                    />
                    <Title
                        text="It's time to upgrade"
                        fontWeight={600}
                        fontSize={48}
                        lineHeight={54}
                        style={{ overflow: 'visible' }}
                    />

                </div>
            </div>
            <div className="aboutContent" >
                <div className="photosLanding" >
                    <div className="photosContainer" >
                        <div className="laptop">
                            <img alt='' src={Notebook} style={{ width: '100%', height: '100%', borderRadius: '12px' }} />
                        </div>
                        <div className="dialog">
                            <img alt='' src={Notecard} style={{ width: '100%', height: '100%' }} />
                        </div>
                    </div>
                </div>
                <div className="featuresLanding" >
                    <CardElement
                        Icon={Privacy}
                        title={`Private & secure`}
                    />
                    <CardElement
                        Icon={Medal}
                        title='Make your impact'
                    />
                    <CardElement
                        Icon={Coin}
                        title='No hidden fees'
                    />
                    <CustomButton
                        title='Join now'
                        classNames='joinNowLanding'
                        onClick={signUpHandler}
                    />
                </div>
            </div>
        </div >
    )
}

export default About;