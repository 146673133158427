import { Avatar, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import Verify from "../../uiKit/Verify/verify";
import { useHistory } from "react-router";
import { blue100, blue500, gray800 } from "../../uiKit/assets/colors/colors";
import { useStyle } from "../../uiKit/CustomTable/customTable";
import { nameInitials } from "../../helpers/functions";
import Title from "../../uiKit/Title/Title";

const RowRenderer = ({ items }) => {

    const cellClasses = useStyle();
    const history = useHistory();

    return (
        items.map((item, index) => (
            <TableRow key={index}>
                <TableCell classes={cellClasses}>
                </TableCell>
                <TableCell classes={cellClasses} onClick={() => history.push(`/members/member/${item.userId}`)}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}>
                        <Avatar
                            alt=''
                            src={item.imageName}
                            style={{
                                borderRadius: '50%',
                                backgroundColor: blue100,
                                fontSize: '8px',
                                lineHeight: '14px',
                                fontWeight: 500,
                                width: '24px',
                                height: '24px'
                            }}
                        >
                            {nameInitials(item.fullName)}
                        </Avatar>
                        <Title
                            text={item.fullName}
                            fontWeight={400}
                            color={blue500}
                            style={{ marginLeft: '20px', marginRight: '8px' }} />
                    </div>
                </TableCell>
                <TableCell classes={cellClasses}>
                    <Title
                        text={item.phoneNumber}
                        fontWeight={400}
                        color={gray800}
                        style={{ marginRight: '8px' }}
                    />
                </TableCell>
                <TableCell classes={cellClasses}>
                    <Title
                        text={item.email}
                        fontWeight={400}
                        color={gray800}
                        style={{ marginRight: '8px' }}
                    />
                </TableCell>
                <TableCell classes={cellClasses}>
                    <Verify status={item.isVerified} />
                </TableCell>
            </TableRow>
        ))
    )
}

export default RowRenderer;