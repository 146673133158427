import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Title from "../../../uiKit/Title/Title";

const SubMenuItem = ({
    id,
    title,
    url,
    onClick = () => {}
}) => {

    const history = useHistory();

    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if (history.location.pathname.includes(url)) {
            setSelected(true);
        } else {
            setSelected(false)
        }
    }, [history.location.pathname, url])

    return (
        <NavLink
            key={id}
            to={url}
            onClick={() => onClick()}
        >
            <Title
                text={title}
                fontWeight={400}
                style={{
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingLeft: '56px',
                    marginTop: '2px'
                }}
                className={`${selected && 'selected'}`}
            />
        </NavLink>
    )
}

export default SubMenuItem;