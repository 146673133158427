import './CustomInput.scss'
import CustomInputInfo from './CustomInputInfo';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Phone } from '../assets/svg/icons';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

const CustomPhoneInput = ({
    label,
    value,
    placeholder = '',
    onChange,
    error,
    errorMessage,
    description,
    outFocus,
    Icon = Phone,
}) => {

    return (
        <div className="customInput" >
            {!!label ? (
                <div className="label">{label}</div>
            ) : null}
            <PhoneInput
                defaultCountry="US"
                value={value}
                onChange={onChange}
                onBlur={outFocus}
                className="customPhoneInput"
                placeholder={placeholder}
                internationalIcon={Icon}
                maxLength={!!value && isPossiblePhoneNumber(value) ? value.length : 20}
            />
            <CustomInputInfo
                error={error}
                errorMessage={errorMessage}
                description={description}
            />
        </div>
    );

};

export default CustomPhoneInput;