import React from "react";
import Title from "../../../uiKit/Title/Title";
import TeamMemberImageOne from '../../../uiKit/assets/png/Sholom.jpg';
import TeamMemberImageTwo from '../../../uiKit/assets/png/Levy.jpg';
import { Checked } from "../../../uiKit/assets/svg/icons";

const Card = ({ Img, fullName, title }) => (
    <div className='card boxShadow' >
        <div style={{ position: 'relative', width: '142px', height: '142px' }}>
            <img
                alt=""
                src={Img}
                style={{
                    borderRadius: '50%',
                    width: '100%',
                    height: '100%'
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    bottom: '-16px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Checked />
            </div>
        </div>
        <Title
            text={fullName}
            fontSize={24}
            lineHeight={26}
            style={{ marginTop: '38px' }}
        />
        <Title
            text={title}
            color="#656464"
            lineHeight={16}
            fontSize={16}
            fontWeight={400}
            style={{
                marginTop: '14px',
                marginBottom: '50px',
                overflow: 'visible'
            }}
        />
    </div>
)

const Team = () => (
    <div className="team">
        <div className="ourTeam">
            <Title
                text="Our team"
                fontSize={36}
                lineHeight={36}
                fontWeight={700}
                style={{ marginBottom: '32px' }}
            />
            <div className="cardsWrapper" >
                <Card
                    Img={TeamMemberImageOne}
                    fullName="Sholom Pinson"
                    title="Founder"
                />
                <Card
                    Img={TeamMemberImageTwo}
                    fullName="Levy Lieberman"
                    title="Advisor"
                />
            </div>
        </div>
    </div>
)

export default Team;