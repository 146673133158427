import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ReactGA from "react-ga4";

import './App.scss';

import Home from './components/Home/home';
import LandingBackground from './components/LandingBackground/landingBackground';

import LandingPage from './pages/Account/LandingPage/landingPage';
import LandingPageForLinks from './pages/Account/LandingPageForLinks/landingPage';
import CampaignNotFound from './pages/Campaigns/Campaign/notFound';
import NotFound from './components/NotFound/notFound';

import ChooseRecivingMethod from './pages/Login/chooseRecivingMethod';
import LoginSubscription from './pages/Login/loginSubscription';

import {
  ProtectedRoutes,
  LoginChecker,
  CommunityInvitation,
  ApproveCommunity,
  RedirectActiveCampaign,
  AdminChecker,
  ConfirmInvitation
} from './helpers/AppHelpers/routeHelpers';

import {
  AccountComponents,
  AdminSettingsComponents,
  CampaignComponents,
  MemberComponents,
  OurBalanceComponents,
  SettingsComponents,
  TermsComponents,
  UnauthorizedComponents
} from './helpers/AppHelpers/components';

import MethodChosen from './pages/PersonalSettings/PaymentPreferences/receivingMethodChosen';
import { uuidv4 } from './helpers/functions';
import UnderConstruction from './pages/UnderConstruction/underConstruction';
import MyDonations from './pages/PersonalSettings/MyDonations/myDonations';
import NotificationsWrapper from './pages/Notifications/notifications';
import CampaignsWrapper from './pages/Campaigns/campaignsWrapperForAllMembers';
import AnimatedLoading from './uiKit/Loading/animatedLoading';

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

function App() {

  const loaded = useSelector(state => state.common.loaded, shallowEqual);
  const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

  const communities = useSelector(state => state.community.memberCommunities, shallowEqual);
  const token = window.localStorage.getItem('token');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const remember = JSON.parse(window.localStorage.getItem('remember'));
    const tab = window.sessionStorage.getItem('id');

    if (!tab && !remember) {
      window.sessionStorage.setItem('id', uuidv4());
    }
  }, [])

  useEffect(() => {
    setLoading(Object.values(loaded).some(x => !x))
  }, [loaded])

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path='/'>
            <LoginChecker>
              <LandingPage />
            </LoginChecker>
          </Route>

          <Route exact path='/welcome'>
            <LoginChecker>
              <LandingPageForLinks />
            </LoginChecker>
          </Route>

          <Route exact path='/login'>
            <LoginChecker>
              <LandingBackground Component={AccountComponents.Login} />
            </LoginChecker>
          </Route>
          <Route exact path='/login/:token'>
            <LoginChecker>
              <LandingBackground Component={AccountComponents.LinkInvitationLogin} />
            </LoginChecker>
          </Route>
          <Route path='/sign-up'>
            <LoginChecker>
              <LandingBackground Component={AccountComponents.SignUp} />
            </LoginChecker>
          </Route>

          <Route exact path='/email-required'>
            <LandingBackground Component={AccountComponents.EmailRequired} />
          </Route>

          <Route path='/create-account'>
            <LoginChecker>
              <LandingBackground Component={AccountComponents.CreateAccount} color='blueBackground' />
            </LoginChecker>
          </Route>

          <Route path='/confirm-invitation'>
            <ConfirmInvitation />
          </Route>

          <Route exact path='/change-email/verification'>
            <LandingBackground Component={SettingsComponents.AccountInfo.VerificationEmail} />
          </Route>

          <Route path='/change-email'>
            <LandingBackground Component={AccountComponents.Login} />
          </Route>

          <Route exact path='/reset-password'>
            <LandingBackground Component={AccountComponents.ResetPassword} />
          </Route>
          <Route path='/change-password'>
            <LandingBackground Component={AccountComponents.NewPassword} />
          </Route>

          <Route path='/profile/subscription'>
            <LandingBackground Component={LoginSubscription} />
          </Route>

          <Route exact path='/privacy-and-policy'>
            <TermsComponents.Privacy />
          </Route>
          <Route exact path='/terms-and-conditions'>
            <TermsComponents.Terms />
          </Route>

          <Route path='/community-invitation'>
            <CommunityInvitation />
          </Route>

          <Route exact path='/approved'>
            <ApproveCommunity />
          </Route>

          <Route exact path='/active-campaign'>
            <RedirectActiveCampaign />
          </Route>

          <Route exact path='/choose-receiving-method'>
            <LandingBackground Component={ChooseRecivingMethod} color="blueBackground" />
          </Route>

          <Route exact path='/settings/my-communities/community/:communityId'>
            <CampaignsWrapper />
          </Route>

          {/* UnauthorizedPages */}

          <Route path='/community/:communityId/campaign/:campaignId'>
            <LandingBackground Component={UnauthorizedComponents.Campaign} />
          </Route>
          <Route path='/campaign/anonymous/donate/:communityId/:campaignId/'>
            <LandingBackground Component={UnauthorizedComponents.Donate} color='blueBackground' />
          </Route>
          <Route exact path='/anonymous/campaigns/checkout'>
            <LandingBackground Component={UnauthorizedComponents.Checkout} color='blueBackground' />
          </Route>
          <Route exact path='/anonymous/thank-you'>
            <LandingBackground Component={UnauthorizedComponents.ThankYou} />
          </Route>
          <Route exact path='/anonymous/campaign-not-found'>
            <LandingBackground Component={UnauthorizedComponents.NotFound} />
          </Route>
          <Route exact path='/anonymous/campaign-completed/:communityId/:campaignId/'>
            <LandingBackground Component={CampaignNotFound} color='blueBackground' />
          </Route>
          <Route exact path='/anonymous/invite-friend'>
            <LandingBackground Component={UnauthorizedComponents.NotFound} />
          </Route>
          {/* UnauthorizedPages ends */}

          {/* protected routes */}

          <Route exact path={'/settings/my-donations'}>
            <ProtectedRoutes>
              <Home Component={MyDonations} />
            </ProtectedRoutes>
          </Route>

          <Route exact path={'/notifications'}>
            <ProtectedRoutes>
              <Home Component={NotificationsWrapper} color="blueBackground" />
            </ProtectedRoutes>
          </Route>

          <Route exact path={'/settings/admin/messages'}>
            <ProtectedRoutes>
              <Home Component={UnderConstruction} color="blueBackground" />
            </ProtectedRoutes>
          </Route>

          <Route exact path='/method-already-chosen'>
            <ProtectedRoutes>
              <LandingBackground Component={MethodChosen} color="blueBackground" />
            </ProtectedRoutes>
          </Route>

          <Route exact path='/not-found'>
            <ProtectedRoutes>
              <LandingBackground Component={NotFound} />
            </ProtectedRoutes>
          </Route>

          <Route exact path='/account-setup'>
            <ProtectedRoutes>
              <LandingBackground Component={AccountComponents.AccountSetup} color='blueBackground' />
            </ProtectedRoutes>
          </Route>
          <Route path='/find-community'>
            <ProtectedRoutes>
              <LandingBackground Component={AccountComponents.FindCommunity} color='blueBackground' />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/create-community'>
            <ProtectedRoutes>
              <LandingBackground Component={AccountComponents.CreatCommunity} />
            </ProtectedRoutes>
          </Route>

          <Route exact path='/created/:communityId'>
            <ProtectedRoutes>
              <LandingBackground Component={AccountComponents.Created} />
            </ProtectedRoutes>
          </Route>

          <Route exact path='/campaigns'>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.Campaigns} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/create-campaign'>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.Create} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/suggest-campaign'>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.Suggest} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/campaign/active/:campaignId'>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.Active} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/campaign/active/:campaignId/edit'>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.Edit} />
            </ProtectedRoutes>
          </Route>
          <Route exact path={'/campaigns/campaign/:campaignId/invite-friend'}>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.InviteFriend} color="blueBackground" />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/campaign/completed/:campaignId'>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.Completed} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/campaign/repeat/:campaignId'>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.Repeat} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/:campaignId/donate/:communityId'>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.Amount} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/transfer/:campaignName/:campaignId'>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.Transfer} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/checkout'>
            <ProtectedRoutes>
              <Home Component={CampaignComponents.Checkout} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/thank-you'>
            <ProtectedRoutes>
              <LandingBackground Component={CampaignComponents.ThankYou} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/campaigns/campaign-completed/:communityId/:campaignId'>
            <ProtectedRoutes>
              <Home Component={CampaignNotFound} />
            </ProtectedRoutes>
          </Route>

          <Route exact path='/community-admins'>
            <ProtectedRoutes>
              <Home Component={AdminSettingsComponents.AdminsInfo} />
            </ProtectedRoutes>
          </Route>

          <Route exact path='/our-balance'>
            <ProtectedRoutes>
              <Home Component={OurBalanceComponents.OurBalance} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/our-balance/donate'>
            <ProtectedRoutes>
              <Home Component={OurBalanceComponents.Amount} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/our-balance/checkout'>
            <ProtectedRoutes>
              <Home Component={OurBalanceComponents.Checkout} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/our-balance/thank-you'>
            <ProtectedRoutes>
              <LandingBackground Component={OurBalanceComponents.ThankYou} />
            </ProtectedRoutes>
          </Route>

          <Route exact path='/members'>
            <ProtectedRoutes>
              <AdminChecker>
                <Home Component={MemberComponents.MembersWrapper} />
              </AdminChecker>
            </ProtectedRoutes>
          </Route>
          <Route exact path='/members/add-members'>
            <ProtectedRoutes>
              <AdminChecker>
                <Home Component={MemberComponents.AddMember} />
              </AdminChecker>
            </ProtectedRoutes>
          </Route>
          <Route exact path='/members/add-members/manual'>
            <ProtectedRoutes>
              <AdminChecker>
                <Home Component={MemberComponents.AddMemberManual} />
              </AdminChecker>
            </ProtectedRoutes>
          </Route>
          <Route exact path='/members/member/:userId'>
            <ProtectedRoutes>
              <AdminChecker>
                <Home Component={MemberComponents.MemberInfo} />
              </AdminChecker>
            </ProtectedRoutes>
          </Route>

          <Route exact path={[['/settings/admin/drafts'], '/settings/admin/drafts/draft', '/settings/admin/drafts/suggestion']}>
            <ProtectedRoutes>
              <AdminChecker>
                <Home Component={AdminSettingsComponents.DraftsWrapper} />
              </AdminChecker>
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/admin/admins'>
            <ProtectedRoutes>
              <AdminChecker>
                <Home Component={AdminSettingsComponents.AdminsInfo} />
              </AdminChecker>
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/admin/drafts/draft/:campaignId'>
            <ProtectedRoutes>
              <AdminChecker>
                <Home Component={AdminSettingsComponents.DraftCampaign} />
              </AdminChecker>
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/admin/drafts/suggestion/:campaignId'>
            <CampaignComponents.Draft />
          </Route>
          <Route exact path='/settings/admin/drafts/suggestion/:communityId/:campaignId/add-member'>
            <ProtectedRoutes>
              <AdminChecker>
                <Home Component={CampaignComponents.AddMember} />
              </AdminChecker>
            </ProtectedRoutes>
          </Route>

          <Route exact path='/settings/login-security'>
            <ProtectedRoutes>
              <Home Component={SettingsComponents.AccountInfo.Security} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/personal-info'>
            <ProtectedRoutes>
              <Home Component={SettingsComponents.AccountInfo.Info} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/my-communities'>
            <ProtectedRoutes>
              <Home Component={SettingsComponents.Communities.Find} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/new-community'>
            <ProtectedRoutes>
              <Home Component={SettingsComponents.Communities.New} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/new-community/created/:communityId'>
            <ProtectedRoutes>
              <Home Component={SettingsComponents.Communities.Created} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/payment-preferences'>
            <ProtectedRoutes>
              <Home Component={SettingsComponents.PaymentPreferences.Preferences} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/payment-method'>
            <ProtectedRoutes>
              <LandingBackground Component={SettingsComponents.PaymentPreferences.RecievingMethod} color='blueBackground' />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/subscriptions'>
            <ProtectedRoutes>
              <Home Component={SettingsComponents.AccountInfo.Subscriptions} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/contact-us'>
            <ProtectedRoutes>
              <Home Component={SettingsComponents.ContactUs} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/tutorials'>
            <ProtectedRoutes>
              <Home Component={SettingsComponents.Tutorials} />
            </ProtectedRoutes>
          </Route>
          <Route exact path='/settings/faq'>
            <ProtectedRoutes>
              <Home Component={SettingsComponents.FAQ} />
            </ProtectedRoutes>
          </Route>

          <Route path='*'>
            <Redirect to='/campaigns' />
          </Route>

        </Switch>
      </Router>
      {
        !!token &&
        !!communities &&
        <AnimatedLoading
          loading={loading && !!communities.length}
          width={screenWidth < 900 ? '100%' : '40%'}
        />
      }
    </div >
  );
}

export default App;
