import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Header from "../../components/Header/header";
import { setLoaded } from "../../redux/state/commonSlice";
import Title from "../../uiKit/Title/Title";
import ArchivedNotifications from "./archivedNotifications";
import NewNotifications from "./newNotifications";

const NotificationsWrapper = () => {

    const dispatch = useDispatch();

    const isLoading = useSelector(state => state.notification.isLoading.getArchivedNotifications, shallowEqual);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/notifications` })
    }, [])

    useEffect(() => {
        dispatch(setLoaded({ type: 'page', value: !isLoading }))
    }, [isLoading, dispatch])

    return (
        <>
            <Header title='Notifications' clickable={false} />
            <div className="draftsProgressbar">
                <Title
                    text="Notifications"
                    fontSize={32}
                    lineHeight={40}
                    style={{ marginBottom: '32px' }}
                />
                <div style={{ position: 'relative', width: '100%', height: '100%', minHeight: '400px' }}>
                    <NewNotifications />
                    <ArchivedNotifications />
                </div>
            </div>
        </>
    )
}

export default NotificationsWrapper;