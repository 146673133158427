import React from "react";
import { useHistory } from "react-router-dom";
import Header from "../../../components/Header/header";
import Created from "../../Account/CreateCommunity/created";
import { shallowEqual, useSelector } from "react-redux";

const CreatedWithMenu = () => {

    const history = useHistory();
    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    return (
        <div style={{ height: '100vh', backgroundColor: screenWidth < 900 ? '#fff' : 'f1f8ff' }}>
            <Header
                title='New community'
                onClick={() => history.push('/settings/new-community')}
            />
            <Created />
        </div>
    )
}

export default CreatedWithMenu;