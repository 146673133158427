import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { makeStyles } from '@material-ui/core';
import React, { useState } from "react";
import Header from "../../../components/Header/header";
import { FAQS } from "../../../helpers/constants";
import { blue010 } from "../../../uiKit/assets/colors/colors";
import { Arrow14 } from "../../../uiKit/assets/svg/icons";
import { useSummeryStyles } from "../../../uiKit/CustomAccordion/customAccordion";
import Title from "../../../uiKit/Title/Title";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: `${blue010} !important`,
        marginBottom: '16px !important',
        borderRadius: '12px !important',
        '&:before': {
            display: 'none'
        },
    },
    expanded: {
        padding: '16px 0',
    }
}));

const FAQ = () => {
    const token = window.localStorage.getItem('token');

    const [faquestions, setFAQuestions] = useState(FAQS);
    const accordionClasses = useStyles();
    const summeryClasses = useSummeryStyles();

    const onAccordionChange = (id) => {
        setFAQuestions(prev => prev.map(x => {
            if (x.id !== id) return x;

            return { ...x, expanded: !x.expanded }

        }))
    }

    return (
        <>
            {!!token && <Header title='FAQ' clickable={false} />}
            <div className={!!token ? "communityInfoHeaderWrapper" : "policyContent"}>
                <Title
                    text="Frequently asked questions:"
                    fontSize={24}
                    lineHeight={32}
                    style={{ marginBottom: '24px' }}
                />
                {
                    faquestions.map(question => (
                        <Accordion
                            expanded={question.expanded}
                            onChange={() => onAccordionChange(question.id)}
                            classes={accordionClasses}
                        >
                            <AccordionSummary
                                classes={summeryClasses}
                                expandIcon={<Arrow14 />}
                            >
                                <Title
                                    text={question.question}
                                    fontSize={24}
                                    lineHeight={32}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Title
                                    text={question.answer}
                                    fontSize={16}
                                    lineHeight={24}
                                    overflowText={false}
                                    fontWeight={400}
                                />
                            </AccordionDetails>

                        </Accordion>
                    ))
                }

            </div>
        </>
    )
}

export default FAQ;