import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TransferInfoCard from "../../../components/TransferInfoCard/trasnferInfoCard";
import { getUserDonations } from "../../../redux/state/paymentSlice";
import CustomMobilePagination from "../../../uiKit/CustomTable/mobilePagination";
import Loading from "../../../uiKit/Loading/loading";
import NoDonations from "../../OurBalance/RecentDonations/noDonations";

const MyDonationsList = () => {

    const { search } = useLocation();
    const [items, setItems] = useState(2);

    const [paginationDataDonations, setPaginationDataDonations] = useState(null);

    const donations = useSelector(state => state.payment.userDonations, shallowEqual);
    const isLoading = useSelector(state => state.payment.isLoading.getUserDonations, shallowEqual);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!!paginationDataDonations) {
            dispatch(getUserDonations({ ...paginationDataDonations }))
        }
    }, [paginationDataDonations, dispatch])

    useEffect(() => {
        const query = new URLSearchParams(search);
        const page = parseInt(query.get('page')) ?? 1;

        setPaginationDataDonations({
            pageSize: 8,
            page
        })

    }, [search])

    return (
        <>
            <div className="boxShadow" style={{ borderRadius: '12px', position: 'relative' }}>
                {
                    !!donations && !!donations.items && donations.items.slice(0, items)
                        .map((transfer, index) => (<TransferInfoCard transfer={transfer} key={index} />))
                }
                {isLoading && <Loading />}
            </div>
            <CustomMobilePagination
                data={donations}
                tableUrl='/settings/my-donations'
                count={items}
                setItems={setItems}
                marginTop={16}
                NoData={() => <NoDonations />}
            />
        </>
    )
}

export default MyDonationsList;