import React from 'react';

import { Avatar, TableCell, TableRow } from '@material-ui/core';
import { gray800, blue500, blue100 } from '../../../uiKit/assets/colors/colors';
import Title from '../../../uiKit/Title/Title';
import { useStyle } from '../../../uiKit/CustomTable/customTable';
import RelinquishRole from './relinquishRole';
import { nameInitials } from '../../../helpers/functions';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearMember } from '../../../redux/state/memberSlice';


export const AdminRowRenderer = ({ items }) => {

    const cellClasses = useStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = window.localStorage.getItem('userId');

    const onAdminClick = (userId) => {
        dispatch(clearMember());
        history.push(`/members/member/${userId}`)
    }

    return (
        <>
            {
                items.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell classes={cellClasses}>
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            <div
                                onClick={() => onAdminClick(item.userId)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}>
                                <Avatar
                                    src={item.imageName}
                                    alt=''
                                    style={{
                                        borderRadius: '50%',
                                        backgroundColor: blue100,
                                        fontSize: '8px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        width: '24px',
                                        height: '24px',
                                    }}
                                >
                                    {
                                        nameInitials(item.fullName)
                                    }
                                </Avatar>
                                <Title
                                    text={item.fullName}
                                    fontWeight={400}
                                    color={blue500}
                                    style={{
                                        marginLeft: '20px',
                                        marginRight: '8px'
                                    }}
                                />

                            </div>
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            <Title
                                text={item.phoneNumber}
                                fontWeight={400}
                                color={gray800}
                            />
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            <Title
                                text={item.email}
                                fontWeight={400}
                                color={gray800}
                                style={{ marginRight: '16px' }}
                            />
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            {
                                item.userId === userId && (
                                    <RelinquishRole item={item} />
                                )
                            }
                        </TableCell>
                    </TableRow>
                ))
            }
        </>
    )
}


export const UserRowRenderer = ({ items }) => {

    const cellClasses = useStyle();

    return (
        <>
            {
                items.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell classes={cellClasses}>
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Avatar
                                    src={item.imageName}
                                    alt=''
                                    style={{
                                        borderRadius: '50%',
                                        backgroundColor: blue100,
                                        fontSize: '8px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        width: '24px',
                                        height: '24px',
                                    }}
                                >
                                    {
                                        nameInitials(item.fullName)
                                    }
                                </Avatar>
                                <Title
                                    text={item.fullName}
                                    style={{ marginLeft: '20px', marginRight: '8px' }} />
                            </div>
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            <Title
                                text={item.phoneNumber}
                                fontWeight={400}
                                color={gray800}
                            />
                        </TableCell>
                        <TableCell
                            classes={cellClasses}
                            style={{
                                paddingRight: '16px'
                            }}
                        >
                            <Title
                                text={item.email}
                                fontWeight={400}
                                color={gray800}
                            />
                        </TableCell>
                    </TableRow>
                ))
            }
        </>
    )
}