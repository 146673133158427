import React from "react";
import TermsImage from '../../../../uiKit/assets/svg/terms.svg';
import {
    acknowledgment,
    ageRequirements,
    APPLICATION_PROCESS,
    arbitration,
    blogs,
    compliance,
    copyright,
    description,
    disclaimer,
    GRANT_APPLICATION_PDF,
    indemnity,
    liability,
    license,
    miscellaneous,
    payments,
    persenalInformation,
    prohibited,
    prohibitedIntro,
    reservationRights,
    termination,
    termsIntro,
    thirdPartyLinks,
    trademark
} from "../../../../helpers/constants";
import Title from "../../../../uiKit/Title/Title";
import { blue500 } from "../../../../uiKit/assets/colors/colors";
import { shallowEqual, useSelector } from "react-redux";

const Terms = () => {

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);
    
    return (
        <div className="policyContent">
            <Title
                text="Lift Our Own Inc. Terms of Use"
                fontSize={screenWidth > 900 ? 48 : 24}
                lineHeight={screenWidth > 900 ? 56 : 32}
                style={{ marginBottom: '24px' }}
            />
            <Title
                text="Date last revised: January 1, 2022"
                fontWeight={400}
                fontSize={14}
                lineHeight={24}
                style={{ marginBottom: '40px' }}
            />
            <div style={{
                width: '151.26px',
                height: '214.21px',
                marginBottom: '56px'
            }}>
                <img alt='' src={TermsImage} style={{ height: '100%', width: '100%' }} />
            </div>
            <div style={{ marginBottom: '40px' }}>
                {
                    termsIntro.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            fontSize={14}
                            lineHeight={24}
                            style={{ marginBottom: '40px' }}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="Terms and Conditions"
                fontSize={screenWidth > 900 ? 32 : 24}
                lineHeight={screenWidth > 900 ? 40 : 32}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text="Description of ourcommunity.one and The Website"
                    fontSize={screenWidth > 900 ? 24 : 16}
                    lineHeight={screenWidth > 900 ? 32 : 20}
                    style={{ marginBottom: '16px' }}
                />
                <Title
                    text={description}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text="Personal Information"
                    fontSize={screenWidth > 900 ? 24 : 16}
                    lineHeight={screenWidth > 900 ? 32 : 20}
                    style={{ marginBottom: '16px' }}
                />
                <Title
                    text={persenalInformation}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text="Age Requirements"
                    fontSize={24}
                    lineHeight={32}
                    style={{ marginBottom: '16px' }}
                />
                {
                    ageRequirements.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            fontSize={14}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text="Intellectual property rights"
                    fontSize={24}
                    lineHeight={32}
                    style={{ marginBottom: '24px' }}
                />
                <Title
                    text={'Trademarks and Service Marks'}
                    fontWeight={600}
                    fontSize={screenWidth > 900 ? 18 : 16}
                    lineHeight={screenWidth > 900 ? 24 : 20}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={trademark}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Copyright'}
                    fontWeight={600}
                    fontSize={screenWidth > 900 ? 18 : 16}
                    lineHeight={screenWidth > 900 ? 24 : 20}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={copyright}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Ourcommunity.one License Grant'}
                    fontWeight={600}
                    fontSize={screenWidth > 900 ? 18 : 16}
                    lineHeight={screenWidth > 900 ? 24 : 20}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={license}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Reservation of Rights'}
                    fontWeight={600}
                    fontSize={screenWidth > 900 ? 18 : 16}
                    lineHeight={screenWidth > 900 ? 24 : 20}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={reservationRights}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Payments and Payments Processing; Rules for All Payments'}
                    fontWeight={600}
                    fontSize={screenWidth > 900 ? 18 : 16}
                    lineHeight={screenWidth > 900 ? 24 : 20}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={payments}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Content and Blogs'}
                    fontWeight={600}
                    fontSize={screenWidth > 900 ? 18 : 16}
                    lineHeight={screenWidth > 900 ? 24 : 20}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={blogs}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Additional Acknowledgments'}
                    fontWeight={600}
                    fontSize={screenWidth > 900 ? 18 : 16}
                    lineHeight={screenWidth > 900 ? 24 : 20}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={acknowledgment}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Prohibited Use/Activities'}
                    fontWeight={600}
                    fontSize={18}
                    lineHeight={32}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={prohibitedIntro}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    style={{ marginBottom: '24px' }}
                    overflowText={false}
                />
                {
                    prohibited.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            fontSize={14}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Links To Third-Party Websites/Materials/Content'}
                    fontWeight={600}
                    fontSize={18}
                    lineHeight={24}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={thirdPartyLinks}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Service Provided In United States; Compliance With Laws'}
                    fontWeight={600}
                    fontSize={18}
                    lineHeight={32}
                    style={{ marginBottom: '8px' }}
                />
                {
                    compliance.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            fontSize={14}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'General Disclaimer'}
                    fontWeight={600}
                    fontSize={18}
                    lineHeight={32}
                    style={{ marginBottom: '8px' }}
                    overflowText={false}
                />
                {
                    disclaimer.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            fontSize={14}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Limitation Of Liability'}
                    fontWeight={600}
                    fontSize={18}
                    lineHeight={32}
                    style={{ marginBottom: '8px' }}
                    overflowText={false}
                />
                {
                    liability.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            fontSize={14}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Indemnity'}
                    fontWeight={600}
                    fontSize={18}
                    lineHeight={32}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={indemnity}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Arbitration'}
                    fontWeight={600}
                    fontSize={18}
                    lineHeight={32}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={arbitration}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Term and Termination'}
                    fontWeight={600}
                    fontSize={18}
                    lineHeight={32}
                    style={{ marginBottom: '8px' }}
                />
                <Title
                    text={termination}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={'Miscellaneous'}
                    fontWeight={600}
                    fontSize={18}
                    lineHeight={32}
                    style={{ marginBottom: '8px' }}
                />
                {
                    miscellaneous.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            fontSize={14}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text="Application Process"
                    fontSize={screenWidth > 900 ? 32 : 24}
                    lineHeight={screenWidth > 900 ? 40 : 32}
                    style={{ marginBottom: '16px' }}
                />
                <div>
                    <Title
                        text={'Grant Applicatin For Direct Cash Relief'}
                        fontSize={16}
                        fontWeight={400}
                        lineHeight={24}
                        overflowText={false}
                        color={blue500}
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open(GRANT_APPLICATION_PDF)}
                    />
                    <Title
                        text={'Federal Poverty Level'}
                        fontSize={16}
                        lineHeight={24}
                        overflowText={false}
                        color={blue500}
                        style={{ cursor: 'pointer' }}
                        fontWeight={400}
                        onClick={() => window.open(APPLICATION_PROCESS)}
                    />
                </div>
            </div>
        </div>
    )
}

export default Terms;