import React, { useEffect, useRef } from "react";
import Campaign from '../../../uiKit/assets/png/campaign.png';
import { blue500, gray800 } from "../../../uiKit/assets/colors/colors";
import Title from "../../../uiKit/Title/Title";

const HowItWorks = ({ setRef }) => {

    const myRef = useRef(null);

    useEffect(() => {
        if (!!myRef && !!setRef) {
            setRef(prev => ({ ...prev, howItWorks: myRef }))
        }
    }, [myRef, setRef])

    return (
        <div className="howItWorks" ref={myRef}>
            <div className="campaignInBackground">
                <img alt='' src={Campaign} style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="howItWorksText">
                <Title
                    text="How it works"
                    fontSize={16}
                    lineHeight={36}
                    color={blue500}
                    style={{ marginBottom: '24px', cursor: 'pointer', width: 'fit-content' }}
                    onClick={() => window.scrollTo(0, 0)}
                />
                <div className="howItWorksTitle">
                    <span>100%</span> of donations go directly to those in need
                </div>

                <div style={{ width: '96%' }}>
                    <Title
                        text="We take $0 for using our platform and distributing the funds."
                        color={gray800}
                        fontSize={18}
                        lineHeight={27}
                        fontWeight={300}

                    />
                    <Title
                        text="The only fees that will come off are the credit card fees."
                        color={gray800}
                        fontSize={18}
                        lineHeight={27}
                        fontWeight={300}

                    />
                    <Title
                        text="Everything else is entirely free"
                        color={gray800}
                        fontSize={18}
                        lineHeight={27}
                        fontWeight={300}

                    />
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;