import React from "react";
import { useHistory } from "react-router-dom";

import { blue300 } from "../../../uiKit/assets/colors/colors";

const NoCampaigns = ({ title }) => {

    const history = useHistory();

    return (
        <div>
            <span
                style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 400,
                    marginRight: '5px'
                }}
            >
                {`We don't have any ${title} campaigns currently, but you can support community by donating to`}
            </span>
            <span
                style={{
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    cursor: 'pointer',
                    color: blue300
                }}
                onClick={() => history.push('/our-balance/donate')}
            >
                community balance
            </span>
        </div>
    )
}

export default NoCampaigns;