import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { validateEmail } from "../../helpers/validation";
import { forgetPassword } from "../../redux/state/accountSlice";
import CustomButton from "../../uiKit/Button/CustomButton";
import CustomInput from "../../uiKit/Input/CustomInput";
import Title from "../../uiKit/Title/Title";
import './login.scss';

const ResetPassword = () => {

    const isLoading = useSelector(state => state.account.isLoading.forgetPassword, shallowEqual);

    const dispatch = useDispatch();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const userEmail = query.get('email');

    const [email, setEmail] = useState(userEmail ?? '');
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(null);

    const resetPasswordHandler = () => {
        dispatch(forgetPassword(encodeURIComponent(email)))
            .then(unwrapResult)
            .then(() => {
                setDisabled(true)
                toast.info('Sent')
            })
            .catch(error => setError(error.title))
    }

    useEffect(() => {
        if (!validateEmail(email)) {
            setError(`${email.length === 0 ? 'Required' : 'Invalid email address'}`);
            setDisabled(true);
        } else {
            setError(null);
            setDisabled(false);
        }
    }, [email])

    return (
        <>
            <div className='signUpBackground' />
            <div className='landingContentWrapper'>
                <Title
                    text='Reset Password'
                    fontSize={32}
                    lineHeight={40}
                    style={{ marginBottom: '32px' }}
                />
                <CustomInput
                    label='Your Email'
                    description='Password reset instructions will be sent to your registered email address'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!error}
                    errorMessage={error}
                />
                <div className='buttonsWrapper'>
                    <CustomButton
                        title='Send'
                        classNames='marginTop24To0'
                        onClick={resetPasswordHandler}
                        disabled={disabled || isLoading}
                        loading={isLoading}
                    />
                </div>
            </div>
        </>
    )
}

export default ResetPassword;