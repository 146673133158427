import React from 'react';
import './verify.scss';
import { GreenDot, RedDot } from '../assets/svg/icons';
import Title from '../Title/Title';

const Verify = ({ status }) => {

    return (
        <div className={`status ${status ? 'verified' : 'notVerified'}`}>
            <div style={{
                fontSize: '12px',
                lineHeight: '14px'
            }}>{status ? <GreenDot /> : <RedDot />}</div>
            <Title
                style={{ marginLeft: '6px' }}
                text={status ? 'verified' : 'not verified'}
                fontSize={12}
                lineHeight={14}
                fontWeight={400}
            />
        </div>
    )
}

export default Verify;