import { FetchAPI } from "./main";

export const DraftAPI = {
    createCampaignDraft: (
        communityId,
        data
    ) => FetchAPI.postJson(`/community/communities/${communityId}/campaigns/drafts`, data),
    deleteCampaignDraft: ({
        communityId,
        campaignId
    }) => FetchAPI.deleteJson(`/community/communities/${communityId}/campaigns/drafts/${campaignId}`),
    deleteCampaignSuggestion: ({
        communityId,
        campaignId
    }) => FetchAPI.deleteJson(`/community/communities/${communityId}/campaigns/suggestions/${campaignId}`),
    getCampaignDrafts: ({
        communityId,
        page,
        pageSize
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/drafts/${pageSize}/${page}`),
    getCampaignDraft: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/drafts/${campaignId}`),
    updateCampaignDraft: ({
        communityId,
        campaignId,
        data
    }) => FetchAPI.putJson(`/community/communities/${communityId}/campaigns/drafts/${campaignId}`, data),
    launchCampaignDraft: ({
        communityId,
        campaignId,
        data
    }) => FetchAPI.postJson(`/community/communities/${communityId}/campaigns/drafts/${campaignId}/launch`, data),
    getCampaignSuggestion: ({
        communityId,
        campaignId,
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/suggestions/${campaignId}`),
    getCampaignSuggestions: ({
        communityId,
        page,
        pageSize,
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/suggestions/${pageSize}/${page}`),
    suggestDraft: ({
        communityId,
        data,
    }) => FetchAPI.postJson(`/community/communities/${communityId}/campaigns/suggestions`, data),
    updateSuggestion: ({
        communityId,
        campaignId,
        data
    }) => FetchAPI.putJson(`/community/communities/${communityId}/campaigns/suggestions/${campaignId}`, data),
    launchSuggestion: ({
        communityId,
        campaignId,
        data
    }) => FetchAPI.postJson(`/community/communities/${communityId}/campaigns/suggestions/${campaignId}/launch`, data),

}