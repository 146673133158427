import React, { useCallback, useEffect, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import Header from '../../components/Header/header';
import { validateEmail, validateName } from '../../helpers/validation';
import { User, Mail } from '../../uiKit/assets/svg/icons';
import CustomButton from '../../uiKit/Button/CustomButton';
import CustomInput from '../../uiKit/Input/CustomInput';
import CustomPhoneInput from "../../uiKit/Input/CustomPhoneInput";
import Title from '../../uiKit/Title/Title';

import { addMembers } from '../../redux/state/memberSlice';
import { getCampaignSuggestion } from '../../redux/state/campaignSlice';

const AddSingleMember = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { communityId, campaignId } = useParams();

    const suggestedDraft = useSelector(state => state.campaign.suggestedDraft, shallowEqual);
    const isLoading = useSelector(state => state.member.isLoading.addMembers, shallowEqual);

    const [firstName, setFirstName] = useState(suggestedDraft?.suggestedRecipient.firstName);
    const [firstNameError, setFirstNameError] = useState('');

    const [lastName, setLastNameValue] = useState(suggestedDraft?.suggestedRecipient.lastName);
    const [lastNameError, setLastNameError] = useState('');

    const [email, setEmailValue] = useState(suggestedDraft?.suggestedRecipient.email);
    const [emailError, setEmailError] = useState('');

    const [phoneNumber, setPhoneNumberValue] = useState(suggestedDraft?.suggestedRecipient.phoneNumber);
    const [phoneNumberError, setPhoneNumberError] = useState('');

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (!suggestedDraft && !!communityId && !!campaignId) {
            dispatch(getCampaignSuggestion({ communityId, campaignId }))
                .then(unwrapResult)
                .then(response => {
                    const { suggestedRecipient } = response;

                    setFirstName(suggestedRecipient.firstName);
                    setLastNameValue(suggestedRecipient.lastName);
                    setEmailValue(suggestedRecipient.email);
                    setPhoneNumberValue(suggestedRecipient.phoneNumber);

                })
                .catch(({ title }) => toast.info(title))
        }
    }, [suggestedDraft, communityId, campaignId, dispatch])

    useEffect(() => {
        window.addEventListener('resize', () => setScreenWidth(window.innerWidth));
        return () => window.removeEventListener('resize', () => setScreenWidth(window.innerWidth))
    }, [screenWidth])

    useEffect(() => {
        if (!firstName) setFirstNameError('Required');
        if (!lastName) setLastNameError('Required');
    }, [firstName, lastName])

    const onFirstNameChange = useCallback((e) => {
        const value = e.target.value;
        setFirstName(value);
        if (!value) {
            setFirstNameError('Required');
            return;
        }

        if (!validateName(value)) {
            setFirstNameError('Invalid first name');
        } else {
            setFirstNameError('');
        }
    }, [])

    const onLastNameChange = useCallback((e) => {
        const value = e.target.value;
        setLastNameValue(value);
        if (!value) {
            setLastNameError('Required');
            return;
        }

        if (!validateName(value)) {
            setLastNameError('Invalid last name')
        } else {
            setLastNameError('')
        }
    }, [])

    const onEmailChange = useCallback((e) => {
        const value = e.target.value;
        setEmailValue(value);

        if (!value) {
            if (!phoneNumberError) {
                setEmailError('');
            }

            if (!phoneNumber) {
                setEmailError('Email or Phone required');
            }
            return;
        }

        if (!validateEmail(value)) {
            setEmailError('Invalid email address');
        } else {
            setEmailError('');
            setPhoneNumberError('');
        }

    }, [phoneNumber, phoneNumberError])

    const onPhoneNumberChange = useCallback((value) => {
        setPhoneNumberValue(value);

        if (!value) {
            if (!email) {
                setEmailError('Email or Phone required');
            } else {
                setPhoneNumberError('');
            }

            return;
        }

        if (!isPossiblePhoneNumber(value)) {
            setPhoneNumberError('Invalid phone number');
        } else {
            setEmailError('');
            setPhoneNumberError('')
        }
    }, [email])

    const addMemberHandler = useCallback(() => {
        const hasContact = !!email || !!phoneNumber;

        if (
            !!firstName &&
            !!lastName &&
            !!hasContact &&
            !!communityId
        ) {
            const members = [{
                firstName,
                lastName,
                email,
                phoneNumber
            }]
            dispatch(addMembers({ communityId, members }))
                .then(unwrapResult)
                .then(() => {
                    toast.info('Invitation was sent');
                    history.push('/settings/admin/drafts/suggestion');
                })
                .catch(({ title }) => {
                    toast.info(title);
                    history.push('/settings/admin/drafts/suggestion');
                })
        }
    }, [
        email,
        phoneNumber,
        firstName,
        lastName,
        communityId,
        dispatch,
        history
    ])

    return (
        <>
            <Header title='Suggested draft' onClick={() => history.goBack()} />
            <div className='addMemberFormWrapperSingle' >
                <Title
                    text='Fill in the Forms to Add Members'
                    fontSize={screenWidth < 900 ? 24 : 32}
                    lineHeight={42}
                    style={{ width: '270px', marginBottom: '20px' }}
                />
                <div className="inputWrapper" style={{ marginBottom: '20px' }}>
                    <CustomInput
                        StartAdornment={User}
                        placeholder='First Name'
                        type='text'
                        onChange={onFirstNameChange}
                        value={firstName}
                        errorMessage={firstNameError}
                        isRequired={true}
                        error={!!firstNameError}
                        setErrorMessage={setFirstNameError}
                        maxLength={30}
                    />
                    <CustomInput
                        StartAdornment={User}
                        placeholder='Second Name'
                        type='text'
                        onChange={onLastNameChange}
                        value={lastName}
                        isRequired={true}
                        error={!!lastNameError}
                        errorMessage={lastNameError}
                        setErrorMessage={setLastNameError}
                        maxLength={30}
                    />
                    <CustomInput
                        StartAdornment={Mail}
                        placeholder='Email'
                        type='email'
                        onChange={onEmailChange}
                        value={email}
                        error={!!emailError}
                        errorMessage={emailError}
                    />
                    <CustomPhoneInput
                        placeholder='Phone'
                        onChange={onPhoneNumberChange}
                        value={phoneNumber}
                        error={!!phoneNumberError}
                        errorMessage={phoneNumberError}
                    />
                </div>
                <div className='addMemberButtons' >
                    <CustomButton
                        title="Add member"
                        onClick={addMemberHandler}
                        classNames='addMemberButton'
                        disabled={
                            firstNameError ||
                            lastNameError ||
                            emailError ||
                            phoneNumberError
                        }
                        loading={isLoading}
                    />
                </div>
            </div>
        </>

    )
}

export default AddSingleMember;