import React from 'react';
import ScrollToTopIcon from '../../../../components/ScrollToTopIcon/scrollToTopIcon';
import Footer from '../footer';
import LandingHeader from '../landingHeader';
import '../landingPage.scss';
import Terms from '../Terms/terms';


const TermsAndConditions = () => {

    return (
        <div className='wrapper' >
            <div className='container'>
                <LandingHeader referances={{}} />
                <Terms />
                <Footer referances={{}} />
            </div>
            <ScrollToTopIcon />
        </div>
    )
}

export default TermsAndConditions;