import React, { useEffect, useState } from "react";
import './drafts.scss';
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteCampaignSuggestion, getCampaignSuggestions } from "../../redux/state/campaignSlice";
import { gray300 } from "../../uiKit/assets/colors/colors";
import ProgressBarDraft from "../../uiKit/ProgressBar/ProgressBarDraft";
import Title from "../../uiKit/Title/Title";
import CustomPagination from "../../uiKit/CustomTable/pagination";
import { useLocation } from "react-router-dom";

const Suggestions = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { search, pathname } = useLocation();

    const [paginationDataSuggestion, setPaginationDataSuggestion] = useState(null);

    const isLoading = useSelector(state => state.campaign.isLoading.drafts, shallowEqual);
    const isLoadingSuggestions = useSelector(state => state.campaign.isLoading.suggestions, shallowEqual);

    const suggestedData = useSelector(state => state.campaign.suggestedDrafts, shallowEqual);
    const suggestedDrafts = useSelector(state => state.campaign.suggestedDrafts.items, shallowEqual);
    const total = useSelector(state => state.campaign.suggestedDrafts.totalNumberOfItems, shallowEqual);

    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    useEffect(() => {
        if (!suggestedDrafts) {
            setPaginationDataSuggestion({
                page: 1,
                pageSize: 8,
            })
        }
    }, [suggestedDrafts])

    useEffect(() => {
        if (!!paginationDataSuggestion && !!communityId) {
            dispatch(getCampaignSuggestions({ ...paginationDataSuggestion, communityId }))
        }
    }, [paginationDataSuggestion, communityId, dispatch])

    useEffect(() => {
        const query = new URLSearchParams(search);
        const page = parseInt(query.get('page') ?? 1);

        if (pathname.includes('suggestions')) {
            setPaginationDataSuggestion({
                page,
                pageSize: 8,
            })
        }
    }, [search, pathname])

    if (isLoading) return null;

    return (
        !isLoadingSuggestions && (
            <>
                <Title
                    text={`Suggested Drafts (${total})`}
                    fontSize={20}
                    lineHeight={30}
                    style={{ margin: '20px 0' }}
                />
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    {
                        !!suggestedDrafts && suggestedDrafts.map((draft, index) => (
                            <ProgressBarDraft
                                key={index}
                                communityId={communityId}
                                campaignId={draft.id}
                                color={gray300}
                                target={!!draft.amount ? draft.amount : 0}
                                title={!!draft.name ? draft.name : ''}
                                onClick={() => history.push(`/settings/admin/drafts/suggestion/${draft.id}?community=${communityId}`)}
                                createdBy={draft.createdBy}
                                deleteHandler={deleteCampaignSuggestion}
                            />
                        ))
                    }
                </div>
                {
                    total > 8 ? (
                        <CustomPagination
                            data={suggestedData}
                            tableUrl={'/settings/admin/drafts/suggestion'}
                        />
                    ) : null
                }
            </>
        )
    )
}

export default Suggestions;