import React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { login } from "../../redux/state/accountSlice";
import { setCommunityId } from "../../redux/state/communitySlice";
import LoginComponent from "./loginComponent";

const ChooseRecivingMethod = () => {

    const { search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const token = window.localStorage.getItem('refresh-token');

    const communityId = new URLSearchParams(search).get('communityId');

    if (!!token) {
        history.push(`/settings/payment-method${search}`)
    }

    const loginHandler = ({ email, password }) => {
        dispatch(login({ email, password }))
            .then(unwrapResult)
            .then(() => {
                if (!!communityId) {
                    window.localStorage.setItem('communityId', communityId);
                    dispatch(setCommunityId({ communityId }));
                }
                history.push(`/settings/payment-method${search}`);
            })
            .catch(({ title }) => console.error(title))
    }

    return <LoginComponent onLogin={loginHandler} createAccount={false} />
}

export default ChooseRecivingMethod;