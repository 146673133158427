import React from "react";
import './personalSettings.scss';

import {
    Donation,
    Exit,
    Info,
    Magnificer,
    Mails,
    Message,
    User,
    UserPlus,
    Users,
    Verified,
    Wallet
} from "../../../uiKit/assets/svg/icons";
import { useHistory } from "react-router";
import Title from "../../../uiKit/Title/Title";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/state/accountSlice";
import MenuItem from "./settingsMenuItem";
import { gray800 } from "../../../uiKit/assets/colors/colors";

export const settings = [
    { Icon: Users, title: 'My Communities', url: '/settings/my-communities' },
    { Icon: UserPlus, title: 'Create New Community', url: '/settings/new-community' },
    { Icon: User, title: 'Personal Info', url: '/settings/personal-info' },
    { Icon: Mails, title: 'Subscriptions', url: '/settings/subscriptions' },
    { Icon: Wallet, title: 'Payment Preferences', url: '/settings/payment-preferences' },
    { Icon: Donation, title: 'My Donations', url: '/settings/my-donations' },
    { Icon: Verified, title: 'Login & Security', url: '/settings/login-security' },
    { Icon: Message, title: 'Contact Us', url: '/settings/contact-us' },
    { Icon: () => Info({ color: gray800 }), title: 'Tutorials', url: '/settings/tutorials' },
    { Icon: () => Magnificer({ color: gray800 }), title: 'FAQ', url: '/settings/faq' },
]

const PersonalSettings = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className='settingsWrapper'>
            {settings.map((item, index) => (
                <MenuItem Icon={item.Icon} title={item.title} key={index} url={item.url} />
            ))}
            <div
                className='menuItem'
                onClick={() => {
                    dispatch(logout());
                    history.push('/');
                }
                }
            >
                <div style={{ display: 'flex' }}>
                    <div className='menuIcon'>
                        <Exit />
                    </div>
                    <Title
                        text='Log out'
                        fontWeight={400}
                        style={{ paddingLeft: '16px' }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PersonalSettings;