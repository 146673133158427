import React from 'react';
import './addMember.scss'

import AddMembersForm from '../../components/AddMembersForm/addMembersForm';
import Header from '../../components/Header/header';
import Title from '../../uiKit/Title/Title';
import { useHistory } from 'react-router';
import { shallowEqual, useSelector } from "react-redux";

const AddMemberManual = () => {

    const history = useHistory();

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    return (
        <>
            <Header title='Members' onClick={() => history.push('/members/add-members')} />

            <div className='addMemberFormWrapper'>
                <Title
                    text='Fill in the Forms to Add Members'
                    fontSize={screenWidth < 900 ? 24 : 32}
                    lineHeight={42}
                    style={{ width: '270px' }}
                />
                <AddMembersForm />
            </div>
        </>
    )
}

export default AddMemberManual;