import API from '../API/main';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialAccountState = {
    isLoading: {
        startRegistration: false,
        resendLink: false,
        completeRegistration: false,
        login: false,
        forgetPassword: false,
        changePassword: false,
        updateUser: false,
        refreshToken: false,
        checkPassword: false,
        sendChangeEmail: false,
        startChangePassword: false,
        parseToken: false,
        common: false
    },
    error: null,
    startRegistrationError: null,
    loginError: null,
    userDetails: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        emailConfirmed: true,
        phoneNumber: null,
        phoneNumberConfirmed: false,
        imageName: '',
        isVerified: true,
        preferredCommunicationMethod: 0
    },
    userByParseToken: {
        id: 0,
        token: '',
        isVerified: false,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: null,
        communityId: '',
        userId: ''
    },
    isAdmin: false,
}

export const externalLogin = createAsyncThunk(
    'account/login/external',
    async (data, thunkAPI) => {
        const response = await API.AccountAPI.externalLogin(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error.errors ?? response.error);
        }
        return response;
    }
)

export const startRegistration = createAsyncThunk(
    '/account/registration/start',
    async ({ email, invitationToken }, thunkAPI) => {
        const response = await API.AccountAPI.startRegistration({ email, invitationToken });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error.errors ?? response.error);
        }
        return response;
    }
)

export const resendLink = createAsyncThunk(
    '/account/registration/resend',
    async ({ email, invitationToken }, thunkAPI) => {
        const response = await API.AccountAPI.resendLink({ email, invitationToken });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error.errors);
        }
        return response;
    }
)

export const completeRegistration = createAsyncThunk(
    '/account/registration/complete',
    async (data, thunkAPI) => {
        const response = await API.AccountAPI.completeRegistration(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { user: response.user, token: response.token };
    }
)

export const login = createAsyncThunk(
    '/account/login',
    async (data, thunkAPI) => {
        const response = await API.AccountAPI.login(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { user: response.user, token: response.token };
    }
)

export const forgetPassword = createAsyncThunk(
    '/account/password/forget',
    async (email, thunkAPI) => {
        const response = await API.AccountAPI.forgetPassword(email);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const changePassword = createAsyncThunk(
    '/account/password/change',
    async (data, thunkAPI) => {
        const response = await API.AccountAPI.completeChangePassword(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const parseToken = createAsyncThunk(
    '/token/parse',
    async (token, thunkAPI) => {
        const response = await API.AccountAPI.parseToken(token);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { user: response };
    }
)

export const confirmInvitation = createAsyncThunk(
    '/invitation/confirm',
    async (token, thunkAPI) => {
        const response = await API.AccountAPI.confirmInvitation(token);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getUser = createAsyncThunk(
    '/account/user/get',
    async (_, thunkAPI) => {
        const response = await API.AccountAPI.getUser();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { user: response };
    }
)

export const updateUser = createAsyncThunk(
    '/account/user/update',
    async (data, thunkAPI) => {
        const response = await API.AccountAPI.updateUser(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { user: response };
    }
)

export const deleteUser = createAsyncThunk(
    '/account/user/delete',
    async (_, thunkAPI) => {
        const response = await API.AccountAPI.deleteUser();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const refreshToken = createAsyncThunk(
    '/account/refresh',
    async (refreshToken, thunkAPI) => {
        const response = await API.AccountAPI.refreshToken(refreshToken);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { token: response.token };
    }
)

export const checkPassword = createAsyncThunk(
    '/account/password/check',
    async ({ password }, thunkAPI) => {
        const response = await API.AccountAPI.checkPassword({ password });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const sendChangeEmail = createAsyncThunk(
    '/account/email/change',
    async (data, thunkAPI) => {
        const response = await API.AccountAPI.startChangeEmail(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const completeChangeEmail = createAsyncThunk(
    '/account/email/complete',
    async (data, thunkAPI) => {
        const response = await API.AccountAPI.completeChangeEmail(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const startChangePassword = createAsyncThunk(
    '/account/password/change',
    async (_, thunkAPI) => {
        const response = await API.AccountAPI.startChangePassword();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const accountSlice = createSlice({
    name: 'account',
    initialState: initialAccountState,
    reducers: {
        logout(state) {
            state.error = initialAccountState.error;
            state.isLoading = initialAccountState.isLoading;
            state.userByParseToken = initialAccountState.userByParseToken;
            state.token = initialAccountState.token;
            state.userDetails = initialAccountState.userDetails;
        },
        setMemberRole(state, action) {
            state.isAdmin = action.payload;
        }
    },
    extraReducers: {
        [refreshToken.pending]: (state) => {
            state.isLoading.refreshToken = true;
            state.error = null;
        },
        [refreshToken.fulfilled]: (state, action) => {
            state.isLoading.refreshToken = false;
            state.error = null;
        },
        [refreshToken.rejected]: (state, action) => {
            state.isLoading.refreshToken = false;
            state.error = action.payload;
        },
        [startRegistration.pending]: (state) => {
            state.isLoading.startRegistration = true;
            state.isSuccessfull = false;
            state.startRegistrationError = null;
        },
        [startRegistration.fulfilled]: (state, action) => {
            state.isLoading.startRegistration = false;
            state.isSuccessfull = action.payload.isSuccessfull;
            state.startRegistrationError = null;
        },
        [startRegistration.rejected]: (state, action) => {
            state.isLoading.startRegistration = false;
            state.isSuccessfull = false;
            state.startRegistrationError = action.payload.Email ?? action.payload.title;
        },
        [resendLink.pending]: (state) => {
            state.isLoading.resendLink = true;
            state.isSuccessfull = false;
            state.error = null;
        },
        [resendLink.fulfilled]: (state, action) => {
            state.isLoading.resendLink = false;
            state.isSuccessfull = action.payload.isSuccessfull;
            state.error = null;
        },
        [resendLink.rejected]: (state, action) => {
            state.isLoading.resendLink = false;
            state.isSuccessfull = false;
            state.error = action.payload;
        },
        [completeRegistration.pending]: (state) => {
            state.isLoading.completeRegistration = true;
            state.error = null;
        },
        [completeRegistration.fulfilled]: (state, action) => {
            state.isLoading.completeRegistration = false;
            state.error = null;
        },
        [completeRegistration.rejected]: (state, action) => {
            state.isLoading.completeRegistration = false;
            state.error = action.payload;
        },
        [login.pending]: (state) => {
            state.isLoading.login = true;
            state.loginError = null;
        },
        [login.fulfilled]: (state, action) => {
            state.isLoading.login = false;
            state.loginError = null;
        },
        [login.rejected]: (state, action) => {
            state.isLoading.login = false;
            state.loginError = true;
        },
        [externalLogin.pending]: (state) => {
            state.isLoading.login = true;
            state.loginError = null;
        },
        [externalLogin.fulfilled]: (state, action) => {
            state.isLoading.login = false;
            state.loginError = null;
        },
        [externalLogin.rejected]: (state, action) => {
            state.isLoading.login = false;
            state.loginError = true;
        },
        [parseToken.pending]: (state) => {
            state.isLoading.parseToken = true;
            state.userByParseToken = initialAccountState.userByParseToken;
            state.error = null;
        },
        [parseToken.fulfilled]: (state, action) => {
            state.isLoading.parseToken = false;
            state.userByParseToken = action.payload.user;
            state.error = null;
        },
        [parseToken.rejected]: (state, action) => {
            state.isLoading.parseToken = false;
            state.userByParseToken = initialAccountState.userByParseToken;
            state.error = action.payload;
        },
        [getUser.pending]: (state) => {
            state.isLoading.common = true;
            state.userDetails = initialAccountState.userDetails;
            state.error = null;
        },
        [getUser.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.userDetails = action.payload.user;
            state.error = null;
        },
        [getUser.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.userDetails = initialAccountState.userDetails;
            state.error = action.payload;
        },
        [updateUser.pending]: (state) => {
            state.isLoading.updateUser = true;
            state.userDetails = initialAccountState.userDetails;
            state.error = null;
        },
        [updateUser.fulfilled]: (state, action) => {
            state.isLoading.updateUser = false;
            state.userDetails = action.payload.user;
            state.error = null;
        },
        [updateUser.rejected]: (state, action) => {
            state.isLoading.updateUser = false;
            state.userDetails = initialAccountState.userDetails;
            state.error = action.payload;
        },
        [deleteUser.pending]: (state) => {
            state.isLoading.common = true;
            state.error = null;
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.error = null;
        },
        [deleteUser.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.payload;
        },
        [forgetPassword.pending]: (state) => {
            state.isLoading.forgetPassword = true;
            state.error = null;
        },
        [forgetPassword.fulfilled]: (state, action) => {
            state.isLoading.forgetPassword = false;
            state.error = null;
        },
        [forgetPassword.rejected]: (state, action) => {
            state.isLoading.forgetPassword = false;
            state.error = action.payload;
        },
        [changePassword.pending]: (state) => {
            state.isLoading.changePassword = true;
            state.error = null;
        },
        [changePassword.fulfilled]: (state, action) => {
            state.isLoading.changePassword = false;
            state.error = null;
        },
        [changePassword.rejected]: (state, action) => {
            state.isLoading.changePassword = false;
            state.error = action.payload;
        },
        [confirmInvitation.pending]: (state) => {
            state.isLoading.common = true;
            state.error = null;
        },
        [confirmInvitation.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.error = null;
        },
        [confirmInvitation.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.payload;
        },
        [checkPassword.pending]: (state) => {
            state.isLoading.checkPassword = true;
            state.error = null;
        },
        [checkPassword.fulfilled]: (state, action) => {
            state.isLoading.checkPassword = false;
            state.error = null;
        },
        [checkPassword.rejected]: (state, action) => {
            state.isLoading.checkPassword = false;
            state.error = action.payload;
        },
        [sendChangeEmail.pending]: (state) => {
            state.isLoading.sendChangeEmail = true;
            state.error = null;
        },
        [sendChangeEmail.fulfilled]: (state, action) => {
            state.isLoading.sendChangeEmail = false;
            state.error = null;
        },
        [sendChangeEmail.rejected]: (state, action) => {
            state.isLoading.sendChangeEmail = false;
            state.error = action.payload;
        },
        [completeChangeEmail.pending]: (state) => {
            state.isLoading.common = true;
            state.error = null;
        },
        [completeChangeEmail.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.error = null;
        },
        [completeChangeEmail.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.payload;
        },
        [startChangePassword.pending]: (state) => {
            state.isLoading.startChangePassword = true;
            state.error = null;
        },
        [startChangePassword.fulfilled]: (state, action) => {
            state.isLoading.startChangePassword = false;
            state.error = null;
        },
        [startChangePassword.rejected]: (state, action) => {
            state.isLoading.startChangePassword = false;
            state.error = action.payload;
        },
    }
})

export const {
    logout,
    setUserId,
    setMemberRole,
    setTokenState
} = accountSlice.actions;
export default accountSlice.reducer;