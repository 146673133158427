import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMembers, searchMembers } from "../../redux/state/memberSlice";
import { Magnificer } from "../../uiKit/assets/svg/icons";
import CustomInput from "../../uiKit/Input/CustomInput";
import { debounce } from 'lodash';

const MemberSearchInput = ({ totalMembers }) => {

    const dispatch = useDispatch();
    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    const [search, setSearch] = useState('');
    const [startSearch, setStartSearch] = useState(false);

    const debounceData = debounce((e) => setSearch(e.target.value), [1000]);

    useEffect(() => {
        if (startSearch) {
            if (search.length > 0) {
                dispatch(searchMembers({
                    communityId,
                    pageSize: totalMembers,
                    page: 1,
                    searchString: search
                }))
            } else {
                dispatch(getMembers({
                    communityId,
                    pageSize: 8,
                    page: 1
                }))
            }
        }
    }, [search, communityId, totalMembers, startSearch, dispatch])

    return (
        <div className='memberSearch'>
            <CustomInput
                placeholder='Search'
                StartAdornment={Magnificer}
                style={{
                    marginBottom: '0',
                    display: 'flex',
                    alignItems: 'center'
                }}
                onChange={debounceData}
                type='search'
                onFocus={() => setStartSearch(true)}
                outFocus={() => setStartSearch(false)}
            />

        </div>
    )
}

export default MemberSearchInput;