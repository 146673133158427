import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

import { Laptop } from "../../uiKit/assets/svg/laptop";
import CustomButton from "../../uiKit/Button/CustomButton";
import Title from "../../uiKit/Title/Title";

const Verification = ({
    resendLink,
    isLoading,
    data,
    description,
    secondDescription,
    resendTime = 30
}) => {

    const dispatch = useDispatch();

    const [count, setCount] = useState(resendTime - 1);
    const [resendDisable, setResendDisable] = useState(true);

    useEffect(() => {
        if (resendDisable) {
            const interval = setInterval(() => {
                setCount(prev => prev - 1);
            }, [1000])

            setTimeout(() => setResendDisable(false), resendTime * 1000);
            return () => clearInterval(interval);
        }
    }, [resendDisable, resendTime])

    const resendLinkHandler = () => {
        dispatch(resendLink(data))
            .then(unwrapResult)
            .then(() => {
                setResendDisable(true);
                setCount(resendTime - 1);
                setTimeout(() => setResendDisable(false), resendTime * 1000);
            })
            .catch(error => toast.info(error.title ?? error));
    }

    return (
        <div className='landingContentWrapper'>
            <div className='verificationImage'>
                <Laptop />
            </div>
            <Title
                text='Verification'
                fontSize={32}
                lineHeight={40}
                style={{ margin: '32px 0 24px 0' }}
            />
            <div className='verificationTextWrapper'>
                <Title
                    text={description}
                    fontWeight={400}
                />
                <Title
                    text={secondDescription}
                    fontWeight={400}
                />
            </div>
            <CustomButton
                title={resendDisable ? `Resend link in ${count} seconds` : 'Resend a link'}
                onClick={resendLinkHandler}
                disabled={resendDisable || isLoading}
                loading={isLoading}
                classNames='buttonsWrapper'
            />
        </div>
    )
}

export default Verification;