import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { useHistory } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import Title from "../../uiKit/Title/Title";
import { gray600 } from "../../uiKit/assets/colors/colors";
import CustomInput from "../../uiKit/Input/CustomInput";
import CustomCheckbox from "../../uiKit/CustomCheckbox/customCheckbox";
import CustomButton from "../../uiKit/Button/CustomButton";
import { onlyNumbers } from "../../helpers/validation";
import Header from "../../components/Header/header";
import CustomRadio from "../../uiKit/CustomRadio/customRadio";
import { DONATION_FEE, RECURRING_DATA } from "../../helpers/constants";
import { getPaymentSettings, getSavedCard } from "../../redux/state/paymentSlice";

const DonationAmountOurBalance = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const isLoading = useSelector(state => state.payment.isLoading.ourBalancePaymentIntent, shallowEqual);

    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');

    const [coverFees, setCoverFees] = useState(true);
    const [savePaymentMethod, setSavePaymentMethod] = useState(true);
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [recurring, setRecurring] = useState(1);
    const [disableRadio, setDisableRadio] = useState(true);

    const onChangeHandler = (e) => {
        const value = e.target.value;
        if (onlyNumbers(value)) {
            if (value === '0') {
                setError('Insufficient amount');
                setAmount('');
            } else {
                setError('');
                setAmount(value);
            }
        } else {
            setError('Only numbers allowed');
        }
    }

    const onPayClick = () => {
        ReactGA.event('donate_to_our_balance_enter_amount')
        const body = {
            incomingBalanceId: communityId,
            amount: parseInt(amount),
            isAnonymous,
            savePaymentMethod,
            coverFees,
            donationPeriod: !disableRadio ? recurring : null
        }
        history.push({
            pathname: '/our-balance/checkout',
            state: { body, balanceType: 0 }
        })
    }

    useEffect(() => {
        dispatch(getPaymentSettings())
            .then(unwrapResult)
            .then(response => setIsAnonymous(response))
            .catch(({ title }) => toast.info(title))
    }, [dispatch])

    useEffect(() => {
        dispatch(getSavedCard());
    }, [dispatch])

    return (
        <>
            <Header title='Our balance' onClick={() => history.push('/our-balance')} />
            <div className='boxShadow donationWrapper'>
                <Title
                    text='Donation amount:'
                    fontSize={32}
                    lineHeight={40}
                    style={{
                        marginBottom: '16px'
                    }}
                />
                <CustomInput
                    value={amount}
                    onChange={onChangeHandler}
                    error={!!error}
                    errorMessage={error}
                    placeholder='0'
                />
                <CustomCheckbox
                    label={DONATION_FEE}
                    checked={coverFees}
                    onChange={() => setCoverFees(!coverFees)}
                    style={{ marginBottom: '16px' }}
                />
                <CustomCheckbox
                    label='Please save my credit card for future payments'
                    checked={savePaymentMethod}
                    onChange={() => setSavePaymentMethod(!savePaymentMethod)}
                    style={{ marginBottom: '16px' }}
                />
                <CustomCheckbox
                    checked={isAnonymous}
                    onChange={() => setIsAnonymous(!isAnonymous)}
                    label='Donate Anonymously'
                    style={{ marginBottom: '16px' }}
                />
                <CustomRadio
                    label='Make this a recurring donation'
                    data={RECURRING_DATA}
                    checkBoxLabel={true}
                    onChange={(e) => setRecurring(parseInt(e.target.value))}
                    value={recurring}
                    style={{ marginLeft: '24px', marginBottom: '16px' }}
                    disable={disableRadio}
                    onCheckboxChange={() => setDisableRadio(!disableRadio)}
                />
                <CustomButton
                    title='Donate'
                    style={{ marginBottom: '16px' }}
                    disabled={!!error || !amount.length || isLoading}
                    classNames='margin48to24'
                    onClick={onPayClick}
                    loading={isLoading}
                />
                <Title
                    text="You'll be asked for your payment info on the next page."
                    fontSize={12}
                    lineHeight={14}
                    fontWeight={400}
                    color={gray600}
                />
            </div>

        </>
    )
}

export default DonationAmountOurBalance;