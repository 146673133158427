import React from "react";
import { Avatar } from "@material-ui/core";
import { nameInitials } from "../../helpers/functions";
import { blue100, blue500, gray800 } from "../../uiKit/assets/colors/colors";
import Title from "../../uiKit/Title/Title";

const RecentDonationsListAnonymous = ({ data = [], items }) => (
    <div className="boxShadow">
        {
            data.slice(0, items)
                .map((item, index) => {

                    let isMember = false;

                    if (!!item.from && item.from.type === 'member') {
                        isMember = true;
                    }

                    return (
                        <div className='donationListOurBalanceRow' key={index}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: blue500,
                                overflow: 'hidden',
                                width: 'calc(100% - 56px)'
                            }}>
                                <Avatar
                                    src={item.from?.imageName}
                                    alt=''
                                    style={{
                                        borderRadius: '50%',
                                        backgroundColor: blue100,
                                        fontSize: '8px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        width: '24px',
                                        height: '24px'
                                    }}  >
                                    {
                                        !!item.from ? isMember ? nameInitials(item.from.name) : item.from.name.charAt(0) : 'A'
                                    }
                                </Avatar>
                                <Title
                                    text={!!item.from ? item.from.name : 'Anonymous'}
                                    fontWeight={400}
                                    style={{
                                        marginLeft: '20px',
                                        marginRight: '8px'
                                    }}
                                    color={isMember ? blue500 : gray800}
                                />
                            </div>
                            <div>
                                {`$${item.amount.toLocaleString()}`}
                            </div>
                        </div>
                    )
                })
        }
    </div>
)

export default RecentDonationsListAnonymous;