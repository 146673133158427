import React from "react";

const CustomInputInfo = ({ error, errorMessage, description }) => (
    <>
        <div
            className="errorMessage"
            style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                height: error ? '28px' : 0,
            }}
        >
            {errorMessage}
        </div>
        {
            !!description && !error && (
                <div className="inputDescription" >
                    {description}
                </div>
            )
        }
    </>
)

export default CustomInputInfo;