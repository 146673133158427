import React from 'react';
import './addMember.scss'

import { Trumpet } from '../../uiKit/assets/svg/trumpet';

import Header from '../../components/Header/header';
import Title from '../../uiKit/Title/Title';
import { useHistory } from 'react-router';
import { ADD_MEMBERS_DESCRIPTION } from '../../helpers/constants';
import CustomButton from '../../uiKit/Button/CustomButton';
import CopyLinkButton from "../../uiKit/Button/CopyLinkButton";
import { gray010, gray300, gray500, gray600, gray800 } from '../../uiKit/assets/colors/colors';
import { shallowEqual, useSelector } from 'react-redux';
import Loading from '../../uiKit/Loading/loading';

const AddMember = () => {

    const history = useHistory();

    const message = useSelector(state => state.notification.invitationMessage, shallowEqual);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const shareMessageHandler = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Invitation to community',
                text: message,
            })
        }
    }

    return (
        <>
            <Header title='Members' onClick={() => history.push('/members')} />
            <div className='addMemberWrapper'>
                <Title
                    text='Add members'
                    fontSize={screenWidth < 900 ? 20 : 32}
                    lineHeight={screenWidth < 900 ? 28 : 40}
                    style={{ marginBottom: '16px' }}
                />
                <Title
                    text={ADD_MEMBERS_DESCRIPTION}
                    fontWeight={400}
                    color={gray800}
                    style={{ marginBottom: '24px' }}
                />
                <div className='trumpet'>
                    <Trumpet />
                </div>
                <CustomButton
                    title='Fill the Forms Manually'
                    onClick={() => history.push('/members/add-members/manual')}
                    style={{ marginBottom: '16px' }}
                    classNames='copyToClipboard'
                />
                <div style={{ position: 'relative' }}>
                    {
                        !!message ? (
                            <>
                                <Title
                                    text='or'
                                    fontWeight={400}
                                    style={{ margin: '16px 0 8px 0' }}
                                    color={gray600}
                                    className='copyToClipboard'
                                />
                                <Title
                                    text='Send Invitation message'
                                    fontWeight={400}
                                    style={{ margin: '8px 0' }}
                                    color={gray600}
                                    className='copyToClipboard'
                                />
                                <div className='shareMessage'>

                                    <div style={{
                                        width: "100%",
                                        border: "1px solid",
                                        borderRadius: "10px",
                                        textTransform: "none",
                                        background: gray010,
                                        color: gray800,
                                        borderColor: gray300,
                                        height: "100%",
                                        padding: "12px 16px",
                                        fontFamily: 'Poppins',
                                        borderBottom: `1px solid ${gray500}`,
                                        fontSize: '14px',
                                        marginBottom: '16px'
                                    }}>
                                        {message}
                                    </div>
                                    <div className='shareMessageMobile'>
                                        {
                                            !!navigator.share ? (
                                                <CustomButton
                                                    isWhite
                                                    title='Share message'
                                                    onClick={shareMessageHandler}
                                                    style={{ width: '100%' }}
                                                />
                                            ) : (
                                                <CopyLinkButton message={message} />
                                            )
                                        }
                                    </div>
                                    <div className='copyToClipboard'>
                                        <CopyLinkButton message={message} />
                                    </div>
                                </div>
                            </>
                        ) : <Loading height='200px' />
                    }
                </div>

                <CustomButton
                    title='Fill the Forms Manually'
                    onClick={() => history.push('/members/add-members/manual')}
                    style={{ marginBottom: '40px' }}
                    classNames='shareMessageMobile'
                />
            </div>
        </>
    )
}

export default AddMember;