import { Avatar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { blue100, gray800 } from "../../uiKit/assets/colors/colors";
import Title from "../../uiKit/Title/Title";
import Verify from "../../uiKit/Verify/verify";
import CustomMobilePagination from '../../uiKit/CustomTable/mobilePagination';
import { nameInitials } from "../../helpers/functions";
import { clearMember, getMembers } from "../../redux/state/memberSlice";
import Loading from "../../uiKit/Loading/loading";

const MobileList = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { search } = useLocation();

    const members = useSelector(state => state.member.members, shallowEqual);
    const isLoading = useSelector(state => state.member.isLoading.members, shallowEqual);
    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    const [items, setItems] = useState(2);

    const [paginationData, setPaginationData] = useState(null);

    useEffect(() => {
        dispatch(clearMember());
    }, [dispatch])

    useEffect(() => {
        if (!!paginationData && !!communityId) {
            dispatch(getMembers({ ...paginationData, communityId }))
        }
    }, [paginationData, communityId, dispatch])

    useEffect(() => {
        const query = new URLSearchParams(search);
        const page = parseInt(query.get('page')) ?? 1;

        setPaginationData({
            pageSize: 8,
            page
        })
    }, [search])

    return (
        <div style={{ position: 'relative' }}>
            {
                !!members.items && members.items
                    .slice(0, items)
                    .map((user, index) => (
                        <div
                            key={index}
                            className='memberMobile'
                            onClick={() => { history.push(`/members/member/${user.userId}`) }}
                        >
                            <Avatar
                                src={user.imageName}
                                alt=''
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: blue100,
                                    fontSize: '8px',
                                    lineHeight: '14px',
                                    fontWeight: 500,
                                    width: '24px',
                                    height: '24px'
                                }} >
                                {nameInitials(user.fullName)}
                            </Avatar>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                padding: '0 10px',
                                width: 'calc(100% - 24px)',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }} >
                                    <Title
                                        text={user.fullName}
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                        }}
                                    />
                                    <Verify status={user.isVerified} />
                                </div>
                                <Title
                                    text={user.email}
                                    fontWeight={400}
                                    color={gray800}
                                    fontSize={12}
                                    style={{ overflow: 'hidden' }}
                                />
                            </div>
                        </div>
                    ))
            }
            <CustomMobilePagination
                data={members}
                tableUrl='/members'
                count={items}
                setItems={setItems}
                marginTop={16}
            />
            {isLoading &&  <Loading height={`${members.numberOfItems*55}px`} />}
        </div>
    )
}

export default MobileList;