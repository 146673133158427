import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import './findCommunity.scss';

import { Filter, Magnificer } from "../../../../uiKit/assets/svg/icons";
import CustomInput from "../../../../uiKit/Input/CustomInput";
import Header from "../../../../components/Header/header";
import Title from "../../../../uiKit/Title/Title";
import CommunityCard from "../../../../components/CommunityCard/communityCard";
import CustomButton from "../../../../uiKit/Button/CustomButton";
import CustomCheckbox from "../../../../uiKit/CustomCheckbox/customCheckbox";
import { blue500, gray010, gray800 } from "../../../../uiKit/assets/colors/colors";

import {
    getApprovedCommunities,
    resetApprovedCommunities
} from "../../../../redux/state/communitySlice";
import AnimatedLoading from "../../../../uiKit/Loading/animatedLoading";


const FindCommunityWithSidebar = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { search, pathname } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);

    const [pageSizeMember, setPageSizeMember] = useState(2);
    const [pageSizeAdmin, setPageSizeAdmin] = useState(2);
    const [pageSizeNew, setPageSizeNew] = useState(2);

    const communities = useSelector(state => state.community.approvedCommunities, shallowEqual);
    const memberCommunities = useSelector(state => state.community.memberCommunities, shallowEqual);

    const isLoading = useSelector(state => state.community.isLoading.getApprovedCommunities, shallowEqual);
    const isLoadingJoin = useSelector(state => state.member.isLoading.addMember, shallowEqual);

    const [communitiesAdminRole, setCommunitiesAdminRole] = useState([]);
    const [communitiesMemberRole, setCommunitiesMemberRole] = useState([]);
    const [newCommunities, setNewCommunities] = useState([]);

    const [searchOptions, setSearchOptions] = useState({
        my: true,
        new: true,
    })
    const [showFilter, setShowFilter] = useState(false);

    useEffect(() => {
        if (!!memberCommunities && !communities.items) {
            const communitiesAdminRole = memberCommunities.filter(e => e.role === 1);
            setCommunitiesAdminRole(communitiesAdminRole);

            const communitiesMemberRole = memberCommunities.filter(e => e.role === 0);
            setCommunitiesMemberRole(communitiesMemberRole);

        }
    }, [memberCommunities, communities.items])

    useEffect(() => {

        const total = pageSizeAdmin + pageSizeMember + pageSizeNew;
        const pageSize = parseInt(query.get('pageSize')) ?? 10;

        if (pageSize - total < 3) {
            query.set('pageSize', pageSize + 10);
            history.replace({
                pathname,
                search: query.toString(),
            })
        };
    }, [
        query,
        pageSizeAdmin,
        pageSizeMember,
        pageSizeNew,
        history,
        pathname
    ])

    useEffect(() => {

        const pageSize = parseInt(query.get('pageSize')) ?? 10;
        const searchString = query.get('searchString') ?? '';

        if (!query.get('pageSize') && !!searchString) {
            query.set('pageSize', 10);
            history.replace({
                pathname,
                search: query.toString()
            })
        }

        if (!!searchString) {
            dispatch(getApprovedCommunities({
                page: 1,
                pageSize,
                searchString,
            }))
        } else {
            dispatch(resetApprovedCommunities());
        }
    }, [query, dispatch, history, pathname])

    useEffect(() => {
        if (!!communities && !!communities.items && !!memberCommunities) {
            const communitiesAdminRole = memberCommunities.filter(e => e.role === 1 && communities.items.find(x => x.id === e.id));
            setCommunitiesAdminRole(communitiesAdminRole);

            const communitiesMemberRole = memberCommunities.filter(e => e.role === 0 && communities.items.find(x => x.id === e.id));
            setCommunitiesMemberRole(communitiesMemberRole);

            const newCommunities = communities.items.filter(e => !memberCommunities.find(x => x.id === e.id));
            setNewCommunities(newCommunities);
        }
    }, [communities, memberCommunities])

    const selectOptionsHandler = () => setShowFilter(!showFilter);

    const searchCommunityHandler = (e) => {
        const value = e.target.value;
        query.set('searchString', value);

        if (!value) {
            query.delete('pageSize');
            query.delete('searchString')
        }

        history.replace({
            pathname,
            search: query.toString(),
        })

    }

    return (
        <>
            <Header title='My Communities' onClick={() => history.push('/settings/my-communities')} />
            <div className="contentWrapperSearch">
                <div className="findCommunitySidebarWrapper">
                    <Title
                        text="My Communities"
                        fontSize={24}
                        lineHeight={32}
                        className="findCommunityTitleMobile"
                    />
                    <div className="mobileSearchCommunity" style={{ position: 'relative' }}>
                        <CustomInput
                            placeholder='Search community'
                            StartAdornment={Magnificer}
                            onChange={searchCommunityHandler}
                            EndAdornment={() => <Filter color={showFilter ? blue500 : gray800} />}
                            endAdormentClick={selectOptionsHandler}
                            onFocus={() => setShowFilter(false)}
                            value={query.get('searchString')}
                        />
                        {
                            showFilter && (
                                <div style={{
                                    position: 'absolute',
                                    top: '48px',
                                    left: 0,
                                    backgroundColor: gray010,
                                    width: '100%',
                                    zIndex: 100,
                                    padding: '16px'
                                }}
                                >
                                    <Title
                                        text="Filter"
                                    />
                                    <CustomCheckbox
                                        label='Search by All'
                                        checked={searchOptions.my && searchOptions.new}
                                        onChange={() => setSearchOptions({
                                            my: true,
                                            new: true,
                                        })}
                                    />
                                    <CustomCheckbox
                                        label='Search by my communities'
                                        checked={searchOptions.my}
                                        onChange={() => setSearchOptions({
                                            my: true,
                                            new: false,
                                        })}
                                    />
                                    <CustomCheckbox
                                        label='Search by new communities'
                                        checked={searchOptions.new}
                                        onChange={() => setSearchOptions({
                                            my: false,
                                            new: true,
                                        })}
                                    />
                                    <CustomButton
                                        title='Submit'
                                        style={{ margin: '16px 0' }}
                                        onClick={() => setShowFilter(false)}
                                    />
                                    <CustomButton
                                        title='Clear all'
                                        isWhite
                                        onClick={() => setSearchOptions({
                                            my: false,
                                            new: false,
                                        })}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className="communityCards">
                        <>
                            {
                                searchOptions.my && (
                                    <>
                                        {
                                            !!communitiesMemberRole.length && (
                                                <>
                                                    <Title
                                                        text={`Member (${communitiesMemberRole.length})`}
                                                        style={{ marginBottom: '8px' }}
                                                    />
                                                    {
                                                        communitiesMemberRole.slice(0, pageSizeMember)
                                                            .map((community, index) => (
                                                                <CommunityCard
                                                                    key={index}
                                                                    community={community}
                                                                    hasStatus
                                                                />
                                                            ))
                                                    }
                                                    {
                                                        communitiesMemberRole.length > 2 && (
                                                            <CustomButton
                                                                isWhite
                                                                title={"Show more"}
                                                                onClick={() => setPageSizeMember(prev => prev + 2)}
                                                                disabled={pageSizeAdmin >= communitiesMemberRole.length}
                                                                style={{ marginBottom: '16px' }}
                                                            />
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        {
                                            !!communitiesAdminRole.length && (
                                                <>
                                                    <Title
                                                        text={`Admin (${communitiesAdminRole.length})`}
                                                        style={{ marginBottom: '8px' }}
                                                    />
                                                    {
                                                        communitiesAdminRole.slice(0, pageSizeAdmin)
                                                            .map((community, index) => (
                                                                <CommunityCard
                                                                    key={index}
                                                                    community={community}
                                                                    hasStatus
                                                                />
                                                            ))
                                                    }
                                                    {
                                                        communitiesAdminRole.length > 2 && (
                                                            <CustomButton
                                                                isWhite
                                                                title={"Show more"}
                                                                onClick={() => setPageSizeAdmin(prev => prev + 2)}
                                                                disabled={pageSizeAdmin >= communitiesAdminRole.length}
                                                                style={{ marginBottom: '16px' }}
                                                            />
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                !!query.get('searchString') && searchOptions.new && (
                                    <>
                                        <Title
                                            text={`New (${newCommunities.length})`}
                                            style={{ marginBottom: '8px' }}
                                        />
                                        {
                                            !!newCommunities.length && (
                                                <>
                                                    {
                                                        newCommunities.slice(0, pageSizeNew)
                                                            .map((community, index) => (
                                                                <CommunityCard
                                                                    key={index}
                                                                    community={community}
                                                                    hasStatus={false}
                                                                />
                                                            ))
                                                    }
                                                    {
                                                        newCommunities.length > 2 && (
                                                            <CustomButton
                                                                isWhite
                                                                title={"Show more"}
                                                                onClick={() => setPageSizeNew(prev => prev + 2)}
                                                                disabled={pageSizeNew >= newCommunities.length}
                                                                style={{ marginBottom: '16px' }}
                                                            />
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </>
                        <AnimatedLoading loading={isLoading || isLoadingJoin} full={false}  />
                    </div>
                </div>
            </div>
        </>

    )
}

export default FindCommunityWithSidebar;