import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { blue300, gray900 } from "../../uiKit/assets/colors/colors";
import CustomButton from "../../uiKit/Button/CustomButton";
import CustomCheckbox from "../../uiKit/CustomCheckbox/customCheckbox";
import CustomInput from "../../uiKit/Input/CustomInput";
import Title from "../../uiKit/Title/Title";
import Verification from "../../components/Verification/verification";
import {
    VALIDATION_TEXT_REGISTER,
    VALIDATION_TEXT_REGISTER_SECOND
} from "../../helpers/constants";
import { externalLogin } from "../../redux/state/accountSlice";
import { toast } from "react-toastify";

const EmailRequired = () => {

    const isLoading = useSelector(state => state.account.isLoading.externalLogin, shallowEqual);

    const { search, pathname } = useLocation();
    const query = new URLSearchParams(search);

    const type = query.get('type');
    const provider = query.get('provider');
    const accessToken = decodeURIComponent(query.get('token'));

    const [agreed, setAgreed] = useState(false);

    const [email, setEmailValue] = useState(null);
    const [emailError, setEmailError] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const onEmailChange = (e) => {
        setEmailValue(e.target.value);
        setEmailError(null);
    }

    const signUpHandler = () => {

        if (!provider) return;
        if (!accessToken) return;
        if (!email) return;

        dispatch(externalLogin({
            accessToken,
            provider,
            email
        }))
            .then(unwrapResult)
            .then(() => {

                toast.info('Verification was sent');

                query.set('type', 'verification');
                history.replace({
                    pathname,
                    search: query.toString()
                })
            })
            .catch(({ title }) => setEmailError(title))
    }

    if (!!type) {
        return (
            <Verification
                resendLink={externalLogin}
                isLoading={isLoading}
                data={{
                    accessToken,
                    provider,
                    email
                }}
                description={VALIDATION_TEXT_REGISTER}
                secondDescription={VALIDATION_TEXT_REGISTER_SECOND}
            />
        )
    }

    return (
        <>
            <div className='signUpBackground' />
            <div className='landingContentWrapper'>
                <Title
                    text='Create Account'
                    fontSize={32}
                    lineHeight={40}
                    style={{ marginBottom: '32px' }}
                />
                <CustomInput
                    label='Your email'
                    value={email}
                    error={!!emailError}
                    errorMessage={emailError}
                    onChange={onEmailChange}
                    description='Your Facebook account doesn`t include email address, please provide your email.'
                />
                <div style={{ 
                    display: 'flex', 
                    alignItems: 'center',
                     marginBottom: '16px',
                     marginTop: '8px'
                      }}>
                    <CustomCheckbox
                        checked={agreed}
                        style={{ margin: 0 }}
                        onChange={() => setAgreed(!agreed)}
                    />
                    <div style={{
                        fontWeight: 400,
                        lineHeight: '20px',
                        fontSize: '14px',
                        color: gray900
                    }}>
                        I agree to the <span
                            onClick={() => window.open('/terms-and-conditions', '_blank')}
                            style={{
                                color: blue300,
                                cursor: 'pointer'
                            }}>Terms and Conditions </span>
                        and <span
                            onClick={() => window.open('/privacy-and-policy', '_blank')}
                            style={{
                                color: blue300,
                                cursor: 'pointer'
                            }}>Privacy Policy</span>
                    </div>
                </div>
                <div className='buttonsWrapper'>
                    <CustomButton
                        title='Sign up'
                        onClick={signUpHandler}
                        disabled={!agreed || !!emailError || isLoading}
                        loading={isLoading}
                    />
                </div>
            </div>
        </>
    )
}

export default EmailRequired;