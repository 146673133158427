import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getCommunityDonations, getCommunityTransfers } from "../../redux/state/paymentSlice";
import './ourBalance.scss';
import CustomAccordion from "../../uiKit/CustomAccordion/customAccordion";
import RecentDonationsList from "./RecentDonations/recentDonationsList";
import RecentTransfersList from "./RecentTransfers/recentTransfersList";
import Title from "../../uiKit/Title/Title";
import CustomMobilePagination from "../../uiKit/CustomTable/mobilePagination";
import NoDonations from "./RecentDonations/noDonations";
import NoTransfers from "./RecentTransfers/noTransfer";
import Loading from "../../uiKit/Loading/loading";

const BalanceAccordion = ({ isAdmin }) => {

    const { search } = useLocation();
    const dispatch = useDispatch();

    const [items, setItems] = useState(2);

    const [paginationDataDonations, setPaginationDataDonations] = useState({
        pageSize: 8,
        page: 1
    });
    const [paginationDataTransfers, setPaginationDataTransfers] = useState({
        pageSize: 8,
        page: 1
    });

    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const donations = useSelector(state => state.payment.donations, shallowEqual);
    const transfers = useSelector(state => state.payment.transfers, shallowEqual);

    const isDonationsLoading = useSelector(state => state.payment.isLoading.getCommunityDonations, shallowEqual);
    const isTransfersLoading = useSelector(state => state.payment.isLoading.getCommunityTransfers, shallowEqual);

    useEffect(() => {
        if (!!paginationDataDonations && !!communityId) {
            dispatch(getCommunityDonations({ ...paginationDataDonations, communityId }))
        }
    }, [paginationDataDonations, communityId, dispatch])

    useEffect(() => {
        if (!!paginationDataTransfers && !!communityId) {
            dispatch(getCommunityTransfers({ ...paginationDataTransfers, communityId }))
        }
    }, [paginationDataTransfers, communityId, dispatch])

    useEffect(() => {
        const query = new URLSearchParams(search);
        const page = parseInt(query.get('page')) || 1;
        const type = query.get('dataType') ?? '0';

        if (type === '0') {
            setPaginationDataDonations({
                pageSize: 8,
                page
            })
        }

        if (type === '1') {
            setPaginationDataTransfers({
                pageSize: 8,
                page
            })
        }

    }, [search])

    return (
        isAdmin ? (
            <CustomAccordion
                firstDropdownTitle='Recent Donations'
                FirstDropdown={RecentDonationsList}
                firstDropdownData={donations}
                firstListLoading={isDonationsLoading}
                FirstNoData={() => <NoDonations />}
                seconDropdownTitle='Recent Transfers'
                SeconDropdown={RecentTransfersList}
                secondDropdownData={transfers}
                secondListLoading={isTransfersLoading}
                SecondNoData={() => <NoTransfers />}
                tableUrl='/our-balance'
            />
        ) : (
            !!donations.items && (
                <div style={{
                    position: 'relative',
                    display: "flex",
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    <Title
                        text='Recent Donations'
                        fontSize={20}
                        lineHeight={30}
                        style={{ marginBottom: '16px' }}
                    />
                    <RecentDonationsList data={donations.items} items={items} />
                    <CustomMobilePagination
                        data={donations}
                        tableUrl='/our-balance'
                        count={items}
                        setItems={setItems}
                        marginTop={16}
                        NoData={() => <NoDonations />}
                    />
                    {isDonationsLoading && <Loading />}
                </div>
            )
        )
    )
}

export default BalanceAccordion;