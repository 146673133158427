import React, { useEffect, useState } from 'react';
import { blue050, blue300, gray050 } from '../../uiKit/assets/colors/colors';
import { ConfirmationGray, GreyPenSmall } from '../../uiKit/assets/svg/icons';
import CustomCheckbox from '../../uiKit/CustomCheckbox/customCheckbox';
import Title from '../../uiKit/Title/Title';
import CustomButton from '../../uiKit/Button/CustomButton';
import CustomInput from '../../uiKit/Input/CustomInput';
import { useSelector } from 'react-redux';

const Draft = ({
    label,
    title,
    content,
    style,
    checked,
    classNames,
    fullWidth,
    defaultValue,
    messageType,
    setMessagesValue,
}) => {

    const [isEdit, setIsEdit] = useState(false);
    const communityName = useSelector(state => state.community.community.name);

    useEffect(() => {
        if (!checked) {
            setIsEdit(false);
        }
    }, [checked])

    const onChecked = (e) => {
        const value = e.target.checked;
        setMessagesValue(prev => (
            prev.map(item => {
                if (item.messageType === messageType) {
                    return { ...item, isEnabled: value }
                }
                return item;
            })
        ))
    }

    const onContentChange = (value) => {
        setMessagesValue(prev => (
            prev.map(item => {
                if (item.messageType === messageType) {
                    return { ...item, content: value }
                }
                return item;
            })
        ))
    }

    return (
        <div style={{ ...style }} className={classNames}>
            <CustomCheckbox
                checked={checked}
                label={label}
                onChange={onChecked}
                style={{ margin: '14px 0 2px 0' }}
            />
            <div style={!checked ? {
                opacity: 0.7,
                backgroundColor: gray050,
                borderBottomLeftRadius: '16px',
                borderBottomRightRadius: '16px',
                maxWidth: fullWidth ? '100%' : '320px',
                minWidth: '272px',
                width: '100%',
                borderRadius: '20px'
            } : {
                backgroundColor: gray050,
                borderBottomLeftRadius: '16px',
                borderBottomRightRadius: '16px',
                maxWidth: fullWidth ? '100%' : '320px',
                minWidth: '272px',
                width: '100%',
                borderRadius: '20px'
            }}
                className='boxShadow'
            >
                <div style={{
                    display: 'flex',
                    backgroundColor: blue050,
                    padding: '16px 16px 10px 16px',
                    borderTopRightRadius: '16px',
                    borderTopLeftRadius: '16px',
                    justifyContent: 'space-between',
                }}>
                    <Title
                        text={title}
                    />
                    {isEdit ? (
                        <div onClick={() => setIsEdit(!isEdit)}>
                            <ConfirmationGray />
                        </div>
                    ) : (
                        <div onClick={() => checked ? setIsEdit(!isEdit) : null}>
                            <GreyPenSmall />
                        </div>
                    )}
                </div>
                <div style={{
                    padding: '16px'
                }}>
                    {
                        isEdit ? (
                            <CustomInput
                                multiline
                                variant='standard'
                                fullWidth
                                onChange={(e) => onContentChange(e.target.value)}
                                value={content}
                                maxLength={400}
                                style={{
                                    marginBottom: '12px'
                                }}
                            />
                        ) : (
                            <Title
                                text={content?.replaceAll("{{communityName}}", communityName)}
                                fontWeight={400}
                                style={{
                                    marginBottom: '12px'
                                }}
                            />
                        )
                    }
                    <Title
                        text='Click here'
                        fontWeight={400}
                        color={blue300}
                        style={{ display: 'inline', cursor: 'pointer' }}
                    />
                    <Title
                        text=',  to navigate to the campaign page.'
                        style={{ display: 'inline' }}
                        fontWeight={400}
                    />
                    {
                        isEdit ? <CustomButton
                            title='Reset message to default'
                            isWhite
                            style={{
                                marginTop: '8px'
                            }}
                            buttonStyle={{
                                padding: '10px 33px'
                            }}
                            onClick={() => onContentChange(defaultValue)}
                        /> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Draft;