export const Logo = () => (
    <svg width="93" height="64" viewBox="0 0 93 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.1138 18.2738C25.6266 17.4526 24.391 16.2442 23.5388 14.7777C22.6568 13.2817 22.2158 11.5996 22.2158 9.73148C22.2158 7.86332 22.6568 6.18589 23.5388 4.69921C24.3944 3.23889 25.6295 2.03587 27.1138 1.21709C28.6348 0.395889 30.3371 -0.0341797 32.0668 -0.0341797C33.7965 -0.0341797 35.4989 0.395889 37.0198 1.21709C38.5001 2.03162 39.7271 3.23624 40.5668 4.69921C41.4335 6.18656 41.8668 7.86398 41.8668 9.73148C41.8668 11.599 41.4302 13.2811 40.5568 14.7777C39.7152 16.2428 38.4889 17.4515 37.0098 18.2738C35.4914 19.0948 33.7913 19.5248 32.0638 19.5248C30.3364 19.5248 28.6363 19.0948 27.1178 18.2738H27.1138ZM35.7368 13.7569C36.6548 12.7414 37.1142 11.3993 37.1148 9.73048C37.1148 8.04442 36.6555 6.69796 35.7368 5.69111C34.8182 4.68425 33.5948 4.18282 32.0668 4.18681C30.5195 4.18681 29.2862 4.68525 28.3668 5.68213C27.4475 6.67902 26.9882 8.03013 26.9888 9.73547C26.9888 11.4222 27.4482 12.769 28.3668 13.7758C29.2855 14.7827 30.5188 15.2858 32.0668 15.2851C33.5948 15.2825 34.8182 14.7731 35.7368 13.7569Z" fill="#D87F30" />
        <path d="M50.3993 0.238281V11.6636C50.3993 12.806 50.6813 13.6856 51.2453 14.3023C51.8093 14.9191 52.6369 15.2274 53.7283 15.2274C54.8203 15.2274 55.6573 14.9191 56.2393 14.3023C56.8213 13.6856 57.1136 12.806 57.1163 11.6636V0.238281H61.7823V11.6367C61.7823 13.3413 61.4186 14.7828 60.6913 15.9611C59.992 17.1137 58.973 18.0398 57.7573 18.6278C56.5293 19.2259 55.1603 19.525 53.6503 19.525C52.1403 19.525 50.7899 19.2306 49.5993 18.6418C48.4107 18.0561 47.4267 17.1272 46.7753 15.9761C46.0833 14.7885 45.7376 13.3423 45.7383 11.6377V0.238281H50.3993Z" fill="#0779D8" />
        <path d="M76.1909 19.3396L72.2069 12.1311H71.0879V19.3396H66.4219V0.238281H74.2529C75.7629 0.238281 77.0502 0.501127 78.1149 1.02682C79.1795 1.55251 79.9745 2.27359 80.4999 3.19006C81.0322 4.12184 81.3056 5.17829 81.2919 6.25049C81.3073 7.46982 80.9294 8.66184 80.2139 9.65087C79.4952 10.6478 78.4355 11.3549 77.0349 11.7722L81.4579 19.3396H76.1909ZM71.0909 8.8394H73.9839C74.8379 8.8394 75.4789 8.63072 75.9069 8.21336C76.3349 7.796 76.5489 7.20684 76.5489 6.44588C76.5489 5.72015 76.3349 5.1486 75.9069 4.73124C75.4789 4.31388 74.8379 4.10553 73.9839 4.10619H71.0879L71.0909 8.8394Z" fill="#0779D8" />
        <path d="M5.62208 44.6821V56.1074C5.62208 57.2499 5.90408 58.1294 6.46808 58.7462C7.03208 59.3629 7.85974 59.6713 8.95108 59.6713C10.0424 59.6713 10.8791 59.3629 11.4611 58.7462C12.0431 58.1294 12.3344 57.2499 12.3351 56.1074V44.6821H17.0001V56.0805C17.0001 57.7852 16.6361 59.227 15.9081 60.406C15.2096 61.5586 14.1908 62.4846 12.9751 63.0717C11.7471 63.6698 10.3781 63.9689 8.86808 63.9689C7.35808 63.9689 6.00741 63.6744 4.81608 63.0856C3.6274 62.4997 2.64331 61.5705 1.99208 60.419C1.30008 59.2313 0.954412 57.7852 0.955079 56.0805V44.6821H5.62208Z" fill="#0779D8" />
        <path d="M37.4114 63.7785H32.7454L24.9454 52.0003V63.7785H20.2744V44.6821H24.9404L32.7404 56.5152V44.6821H37.4064L37.4114 63.7785Z" fill="#0779D8" />
        <path d="M45.4618 44.6821V63.7785H40.7998V44.6821H45.4618Z" fill="#0779D8" />
        <path d="M62.6256 44.6821V48.4095H57.5496V63.7785H52.8836V48.4095H47.8076V44.6821H62.6256Z" fill="#0779D8" />
        <path d="M81.3997 44.6821L74.7687 57.4682V63.7785H70.0997V57.4682L63.4707 44.6821H68.7647L72.4757 52.6801L76.1597 44.6821H81.3997Z" fill="#0779D8" />
        <path d="M22.2432 31.8007C22.2532 29.9592 22.6892 28.3003 23.5512 26.8243C24.3928 25.3583 25.6199 24.1492 27.1002 23.3272C28.6185 22.5062 30.3184 22.0762 32.0457 22.0762C33.7729 22.0762 35.4729 22.5062 36.9912 23.3272C38.479 24.148 39.7149 25.3568 40.5662 26.8243C41.4382 28.3017 41.8788 29.9605 41.8882 31.8007H37.1122C37.1002 30.1459 36.6412 28.8221 35.7352 27.8292C34.8172 26.8216 33.5848 26.3179 32.0382 26.3179C30.5102 26.3179 29.2868 26.8256 28.3682 27.8411C27.4622 28.844 27.0035 30.1632 26.9922 31.7987L22.2432 31.8007Z" fill="#D87F30" />
        <path d="M1.33061 26.7919C2.12254 25.3415 3.30993 24.144 4.75561 23.3377C6.22228 22.5123 7.88228 22.0996 9.73561 22.0996C12.0096 22.0996 13.9563 22.6977 15.5756 23.894C17.1949 25.0903 18.2773 26.7225 18.8226 28.7907H13.6926C13.3474 28.034 12.7815 27.3986 12.0686 26.9674C11.3453 26.5444 10.5191 26.3282 9.68061 26.3423C8.24328 26.3423 7.07928 26.8408 6.18861 27.8377C5.29794 28.8346 4.85228 30.1674 4.85161 31.8362C4.85161 33.505 5.29728 34.8378 6.18861 35.8347C7.07994 36.8316 8.24394 37.33 9.68061 37.33C10.5191 37.3442 11.3453 37.1279 12.0686 36.705C12.7815 36.2737 13.3474 35.6383 13.6926 34.8817H18.8226C18.2766 36.9492 17.1943 38.5774 15.5756 39.7664C13.9569 40.9553 12.0103 41.5495 9.73561 41.5488C7.87961 41.5488 6.21961 41.1361 4.75561 40.3107C3.31214 39.5074 2.12507 38.3147 1.33061 36.8694C0.509942 35.402 0.0996094 33.7249 0.0996094 31.8382C0.0996094 29.9514 0.509942 28.2693 1.33061 26.7919Z" fill="#0779D8" />
        <path d="M67.0612 22.3149V41.4113H62.4002V29.9591L58.1112 41.4113H54.3452L50.0342 29.9321V41.4113H45.3682V22.3149H50.8802L56.2552 35.5356L61.5772 22.3149H67.0612Z" fill="#0779D8" />
        <path d="M92.112 22.3149V41.4113H87.446V29.9591L83.161 41.4113H79.4L75.084 29.9321V41.4113H70.418V22.3149H75.93L81.306 35.5356L86.627 22.3149H92.112Z" fill="#0779D8" />
    </svg>
)