import React, { useEffect, useMemo, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Title from "../../uiKit/Title/Title";

import {
    getArchivedNotifications,
    getCountArchivedNotifications
} from "../../redux/state/notificationSlice";
import NotificationBar from "../../uiKit/NotificationBar/notificationBar";
import CustomButton from "../../uiKit/Button/CustomButton";
import Feedback from "../../components/Feedback/feedback";
import { Finder } from "../../uiKit/assets/svg/find";

const ArchivedNotifications = () => {

    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const history = useHistory();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    const [paginationData, setPaginationData] = useState(null);

    const totalArchive = useSelector(state => state.notification.notifications.countArchive, shallowEqual);
    const notificationsArchive = useSelector(state => state.notification.notifications.archive, shallowEqual);
    const notificationsNew = useSelector(state => state.notification.notifications.new, shallowEqual);

    const isLoading = useSelector(state => state.notification.isLoading.getArchivedNotifications, shallowEqual);
    const isLoadingNew = useSelector(state => state.notification.isLoading.getNewNotifications, shallowEqual);

    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    useEffect(() => {
        if (!!paginationData && !!communityId) {
            dispatch(getArchivedNotifications({ ...paginationData, communityId }));
            dispatch(getCountArchivedNotifications(communityId))
        }
    }, [paginationData, communityId, dispatch])

    useEffect(() => {
        const pageSize = parseInt(query.get('pageSize') ?? 5);

        setPaginationData({
            page: 1,
            pageSize,
        })

    }, [query])

    const showMoreHandler = () => {
        const pageSize = parseInt(query.get('pageSize') ?? 5);

        query.set('pageSize', pageSize + 10);
        history.replace({
            pathname,
            search: query.toString()
        })
    }

    if (!notificationsArchive) return null;
    if (!notificationsNew) return null;

    if (
        !isLoading &&
        !isLoadingNew &&
        !notificationsNew.items.length &&
        !notificationsArchive.items.length
    ) {
        return (
            <div className="page">
                <Feedback
                    Image={Finder}
                    title='Oops!'
                    secondDescription="You don't have any notifications yet."
                    button={{
                        feedbackAction: () => history.push('/campaigns'),
                        feedbackTitle: 'Campaigns'
                    }}
                />
            </div>
        )
    }

    if (!notificationsArchive.items.length) return null;

    return (
        <>
            <Title
                text={`Archive (${totalArchive})`}
                fontSize={20}
                lineHeight={30}
                style={{ marginBottom: '20px' }}
            />
            <div style={{ position: 'relative', width: '100%', height: 'max-content', marginBottom: '60px' }}>
                {
                    notificationsArchive.items.map((item, index) => (
                        <NotificationBar
                            key={index}
                            title={item.message}
                            date={item.createdAt}
                        />
                    ))
                }
                <CustomButton
                    title='Show more'
                    onClick={showMoreHandler}
                    isWhite
                    style={{ marginTop: 0 }}
                    disabled={!notificationsArchive.hasNextPage}
                    loading={isLoading}
                />
            </div>
        </>
    )
}

export default ArchivedNotifications;