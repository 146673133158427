import React from "react";

import { Avatar, TableCell, TableRow } from "@material-ui/core";
import { blue100, gray800 } from "../../uiKit/assets/colors/colors";

import { nameInitials } from "../../helpers/functions";

import { useStyle } from "../../uiKit/CustomTable/customTable";
import Title from "../../uiKit/Title/Title";

const RowRendererUser = ({ items }) => {

    const cellClasses = useStyle();

    return (
        items.map((item, index) => {

            let isMember = false;

            if (!!item.from && item.from.type === 'member') {
                isMember = true;
            }

            return (
                <TableRow key={index}>
                    <TableCell classes={cellClasses} style={{ width: '16px' }}>
                    </TableCell>
                    <TableCell classes={cellClasses}>
                        <div style={{
                            color: gray800,
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Avatar
                                src={item.from?.imageName}
                                alt=''
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: blue100,
                                    fontSize: '8px',
                                    lineHeight: '14px',
                                    fontWeight: 500,
                                    width: '24px',
                                    height: '24px'
                                }}
                            >
                                {
                                    !!item.from ? isMember ? nameInitials(item.from.name) : item.from.name.charAt(0) : 'A'
                                }
                            </Avatar>
                            <Title
                                text={!!item.from ? item.from.name : 'Anonymous'}
                                style={{ marginLeft: '20px', marginRight: '8px' }}
                                fontWeight={400}
                                color={gray800}
                            />
                        </div>
                    </TableCell>
                    <TableCell classes={cellClasses}>
                        <Title
                            text={`$${item.amount.toLocaleString()}`}
                            fontWeight={400}
                            style={{ marginRight: '8px' }}
                            color={gray800}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    )
}

export default RowRendererUser;