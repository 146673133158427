import React from "react"
import { red100, red500 } from "../../uiKit/assets/colors/colors"
import { Info } from "../../uiKit/assets/svg/icons"
import Title from "../../uiKit/Title/Title"

const LoginError = () => (
    <div style={{
        display: 'flex',
        borderRadius: '20px',
        marginTop: '16px'
    }}
        className='boxShadow'
    >
        <div style={{
            backgroundColor: red500,
            padding: '17.5px 10px',
            borderRadius: '12px 0px 0px 12px',
        }}
        >
            <Info />
        </div>
        <div style={{
            backgroundColor: red100,
            padding: '10px',
            borderRadius: '0px 12px 12px 0px',
            alignSelf: 'center',
            width: '100%'
        }}
        >
            <Title
                text='The email address or password is not correct. Please, try again'
                fontWeight={400}
            />
        </div>
    </div>
)


export default LoginError;