import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import './customTable.scss';
import { makeStyles } from "@material-ui/styles";
import CustomPagination from './pagination';
import { gray800 } from "../assets/colors/colors";
import Loading from "../Loading/loading";
import { useLocation } from "react-router-dom";
import NoResults from "./noResults";

export const useStyle = makeStyles(() => ({
    root: {
        borderBottom: 'none',
        padding: '0',
        fontFamily: 'Poppins',
        height: '40px',
        minWidth: '130px',
        maxWidth: '242px',
        width: 'max-content',
        color: `${gray800}`,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&:first-child': {
            width: '16px',
            minWidth: '16px'
        },
        '&:last-child': {
            width: '130px'
        },
        '&:nth-child(4)': {
            width: 'max-content !important'
        }
    }
}))

const CustomTable = ({
    data,
    headerArray,
    RowRenderer,
    setPaginationData,
    hasPagination = true,
    isLoading = false,
    url,
}) => {

    const cellClasses = useStyle();
    const { search } = useLocation();

    const {
        items,
    } = data;

    const [lastSearchString, setLastSearchString] = useState('');

    useEffect(() => {
        if (!!setPaginationData) {
            const query = new URLSearchParams(search);
            const page = parseInt(query.get('page') ?? 1);
            const pageSize = parseInt(query.get('pageSize') ?? 8);
            const searchString = query.get('searchString') ?? '';

            // if search query was changed show first page of result
            if (searchString !== lastSearchString) {
                setPaginationData({
                    pageSize: 8,
                    page: 1,
                    searchString
                })
                setLastSearchString(searchString);
            } else {
                setPaginationData({
                    page,
                    pageSize,
                    searchString
                })
            }
        }

    }, [search, setPaginationData, lastSearchString])

	const headerRender = () => (
		headerArray.map((item, index) => (
			<TableCell key={index} classes={cellClasses}>
				<div>{item.name}</div>
			</TableCell>
		))
	)
	return (
		<div style={{ position: 'relative' }}>
			{
				<>
					<TableContainer>
						<Table>
							<TableHead className='tableHeader'>
								<TableRow>
									{headerRender()}
								</TableRow>
							</TableHead>
							<TableBody className='tableBody'>
								{RowRenderer && !!items && !!items.length ?
									(
										<RowRenderer
											items={items}
										/>
									) : <NoResults />}
							</TableBody>
						</Table>
					</TableContainer>
					{
						hasPagination &&
						!!items &&
						<CustomPagination
							data={data}
							tableUrl={url}
						/>
					}
					{
						isLoading && <Loading />
					}
				</>
			}
		</div>
	)
}

export default CustomTable;