import React, { useEffect, useState } from "react";
import './drafts.scss';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { getCommunityDrafts } from "../../redux/state/campaignSlice";

import { gray300 } from "../../uiKit/assets/colors/colors";
import ProgressBarDraft from "../../uiKit/ProgressBar/ProgressBarDraft";
import Title from "../../uiKit/Title/Title";
import CustomPagination from "../../uiKit/CustomTable/pagination";

import NoData from "./noData";
import { setLoaded } from "../../redux/state/commonSlice";

const Drafts = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { search, pathname } = useLocation();

    const [paginationData, setPaginationData] = useState(null);

    const data = useSelector(state => state.campaign.drafts, shallowEqual);
    const drafts = useSelector(state => state.campaign.drafts.items, shallowEqual);
    const suggestedDrafts = useSelector(state => state.campaign.suggestedDrafts.items, shallowEqual);

    const total = useSelector(state => state.campaign.drafts.totalNumberOfItems, shallowEqual);

    const isLoading = useSelector(state => state.campaign.isLoading.drafts, shallowEqual);
    const isLoadingSuggestions = useSelector(state => state.campaign.isLoading.suggestions, shallowEqual);

    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    useEffect(() => {
        if (!drafts) {
            setPaginationData({
                page: 1,
                pageSize: 8,
            })
        }
    }, [drafts])

    useEffect(() => {
        if (!!paginationData && !!communityId) {
            dispatch(getCommunityDrafts({ ...paginationData, communityId }))
        }
    }, [paginationData, communityId, dispatch])

    useEffect(() => {
        const query = new URLSearchParams(search);
        const page = parseInt(query.get('page') ?? 1);

        if (pathname.includes('drafts')) {
            setPaginationData({
                page,
                pageSize: 8,
            })
        }
    }, [search, pathname])

    useEffect(() => {
        dispatch(setLoaded({ type: 'page', value: !isLoading }))
    }, [isLoading, dispatch])

    if (
        !!drafts &&
        !drafts.length &&
        !!suggestedDrafts &&
        !suggestedDrafts.length &&
        !isLoading &&
        !isLoadingSuggestions
    ) return <NoData />;

    return !isLoading && (
        <>
            <Title
                text={`My Drafts (${total})`}
                fontSize={20}
                lineHeight={30}
                style={{ marginBottom: '20px' }}
            />
            <div style={{ position: 'relative', width: '100%', height: 'max-content' }}>
                {
                    !!drafts && drafts.map((draft, index) => (
                        <ProgressBarDraft
                            key={index}
                            communityId={communityId}
                            campaignId={draft.id}
                            color={gray300}
                            target={!!draft.amount ? draft.amount : 0}
                            title={!!draft.name ? draft.name : ''}
                            onClick={() => history.push(`/settings/admin/drafts/draft/${draft.id}`)}
                        />
                    ))
                }
            </div>
            {
                total > 8 ? (
                    <CustomPagination
                        data={data}
                        tableUrl={'/settings/admin/drafts/draft'}
                    />
                ) : null
            }
        </>
    )
}

export default Drafts;