import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';

import Header from "../../../components/Header/header";
import Title from "../../../uiKit/Title/Title";
import CustomButton from "../../../uiKit/Button/CustomButton";
import ContactUsImage from '../../../uiKit/assets/svg/contactUs.svg';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CustomInput from "../../../uiKit/Input/CustomInput";
import CustomSelect from "../../../uiKit/CustomSelect/CustomSelect";
import { DeleteBlack, Pin } from "../../../uiKit/assets/svg/icons";
import './contactUs.scss';
import { blue300 } from "../../../uiKit/assets/colors/colors";
import { contactUs } from "../../../redux/state/notificationSlice";
import { MailMan } from "../../../uiKit/assets/svg/mailMan";
import CustomDialog from "../../../uiKit/CustomDialog/customDialog";

const ContactUs = () => {

    const dispatch = useDispatch();

    const communities = useSelector(state => state.community.memberCommunities, shallowEqual);
    const email = useSelector(state => state.account.userDetails.email, shallowEqual);
    const firstName = useSelector(state => state.account.userDetails.firstName, shallowEqual);
    const lastName = useSelector(state => state.account.userDetails.lastName, shallowEqual);
    const isLoading = useSelector(state => state.notification.isLoading.contactUs, shallowEqual);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const [files, setFiles] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const [list, setList] = useState(null)

    const [communityId, setCommunityId] = useState('0');
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('')

    useEffect(() => {
        if (files.length === 3) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [files])

    useEffect(() => {
        if (!!communities && !list) {
            setList([...communities, { id: '0', name: 'Other' }])
        }
    }, [communities, list])

    const uploadAttachment = ({ target }) => {

        const file = target.files[0];

        if (file && files.length < 3) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (
                    file.type === 'image/png' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'application/pdf' ||
                    file.type === 'image/tiff' ||
                    file.type === 'image/bmp' ||
                    file.type === 'image/gif' ||
                    file.type === 'application/msword' ||
                    file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ) {
                    // 6Mb
                    if (file.size < 6291456) {
                        setFiles(prev => [...prev, file]);
                    } else {
                        toast.info('The attached file is too large. Please add file up to 6MB');
                    }
                } else {
                    toast.info('Invalid format. You can add PDF, TIFF (.tif, .tiff), Bitmap (.bmp), JPEG (.jpg, .jpeg), GIF (.gif), PNG (.png) files as attachments');
                }
            });
            reader.readAsDataURL(file);
        }

        if (files.length === 3) {
            toast.info('Up to 3 attachments');
        }
    }

    const onChangeMessage = (e) => {
        const value = e.target.value;
        setMessage(value);
        setMessageError('')
        if (!value.length) {
            setMessageError('Required')
        }
    }

    const deleteAttachment = (index) => {
        if (!!files.length) {
            setFiles(pref => pref.filter((_, i) => i !== index))
        }
    }

    const sendMessage = () => {
        if (!!email && !!message) {
            dispatch(contactUs({
                userName: `${firstName} ${lastName}`,
                email,
                message,
                communityId: communityId === '0' ? '' : communityId,
                attachments: files
            }))
                .then(unwrapResult)
                .then(() => {
                    setConfirmOpen(true);
                    setMessage('');
                    setFiles([]);
                    setCommunityId('0');
                })
                .catch(({ message, title }) => toast.info(message ?? title))
        } else {
            setMessageError('Required')
        }
    }
    
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/contact-us` })
    }, [])

    return (
        <>
            <Header title='Contact Us' clickable={false} />
            <div className='communityInfoHeaderWrapper'>
                <div className='campaignMobileHeader'>
                    <img
                        alt=''
                        src={ContactUsImage}
                        className='photoWrapperOurBalance'
                        style={{ backgroundColor: '#CFEEFF' }}
                    />
                </div>
                <Title
                    text='Contact Us'
                    fontWeight={500}
                    fontSize={screenWidth < 1200 ? 36 : 32}
                    lineHeight={screenWidth < 1200 ? 42 : 40}
                    className="ourBalanceTitle"
                />
                <div style={{ maxWidth: '664px', width: '100%' }}>
                    <Title
                        text='Fill out the form to contact the technical support of the service'
                        fontWeight={400}
                        fontSize={16}
                        style={{ marginBottom: '24px' }}
                    />
                    <div className="contactusMobileWrapper">
                        <div className="contactusInputs">
                            <div className='contactusEmail' >
                                <CustomInput
                                    label='Email'
                                    value={email}
                                />
                            </div>
                            <div style={{ maxWidth: '320px', width: '100%' }}>
                                <CustomSelect
                                    label='Community'
                                    data={list}
                                    menuWidth={screenWidth < 900 ? 280 : 320}
                                    placeholder='Choose community'
                                    value={communityId}
                                    onChange={(e) => setCommunityId(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <CustomInput
                                label='Message'
                                placeholder="Enter your message"
                                multiline
                                rows={6}
                                maxLength={1500}
                                value={message}
                                onChange={onChangeMessage}
                                error={!!messageError}
                                errorMessage={messageError}
                            />

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px' }}>
                                {
                                    files.map((_, index) => (
                                        <div style={{ display: 'flex' }}>
                                            <Pin color='#2087EF' />
                                            <Title
                                                text={`FILE_00${index + 1}`}
                                                color={blue300}
                                                style={{ marginLeft: '8px' }}
                                            />
                                            <div
                                                onClick={() => deleteAttachment(index)}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginLeft: '16px'
                                                }}>
                                                <DeleteBlack />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>

                        <div className="contactUsButtons">
                            <CustomButton
                                title={isLoading && !!files.length ? 'Uploading attachments' : 'Send'}
                                classNames='mr16to0 contactusSend'
                                onClick={sendMessage}
                                disabled={!!messageError || isLoading}
                                loading={isLoading && !files.length}
                            />

                            <div>
                                <input
                                    accept=".pdf, .tif, .tiff, .bmp, .jpg, .jpeg, .gif, .png, .doc, .docx"
                                    id="attachments"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={uploadAttachment}
                                    value={''} // for enabling select same file again. setting empty value trigger onchage event even so file path is same
                                />
                                <label htmlFor="attachments" style={disabled ? { display: 'none' } : null}>
                                    <div className={disabled ? 'attachmentDisabled' : "attachments"}>
                                        <Pin color="#2087EF" />
                                        <span>Add attachment</span>
                                    </div>
                                </label>
                                <div className='attachmentDisabled' style={!disabled ? { display: 'none' } : null}>
                                    <Pin color='#7DBEFF' />
                                    <span>Add attachment</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <CustomButton
                            title={isLoading && !!files.length ? 'Uploading attachments' : 'Send'}
                            classNames='mr16to0 contactusSendMobile'
                            onClick={sendMessage}
                            disabled={!!messageError || isLoading}
                            loading={isLoading && !files.length}
                        />
                    </div>
                </div>
            </div>
            <CustomDialog
                confirmOpen={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                Image={MailMan}
                mainButtonTitle='ok'
                mainButtonAction={() => setConfirmOpen(false)}
                title={`Your message is sent to OurCommunity admins, we will be glad to help you.`}
                firstDescription='You will receive a reply regarding your question to your registered email address.'
                secondDescription='Please check spam/junk folders.'
            />
        </>
    )
}

export default ContactUs;