import React from "react"
import { useHistory } from "react-router-dom";
import { blue300, gray800 } from "../../uiKit/assets/colors/colors";
import Title from "../../uiKit/Title/Title";


const PasswordDescription = ({ email }) => {

    const history = useHistory();

    return (
        <div style={{ display: 'flex' }}>
            <Title
                text='Forgot your password?'
                color={gray800}
                fontWeight={400}
                fontSize={12}
                lineHeight={14}
            />
            <Title
                text='Reset password'
                color={blue300}
                fontWeight={400}
                style={{ marginLeft: '5px', cursor: 'pointer' }}
                fontSize={12}
                lineHeight={14}
                onClick={() => history.push(`/reset-password?email=${encodeURIComponent(email)}`)}
            />
        </div>
    )
}

export default PasswordDescription;