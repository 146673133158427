import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../uiKit/CustomTable/customTable";
import RowRenderer from '../../Campaigns/campaignRow';
import { getCommunityDonations } from "../../../redux/state/paymentSlice";
import RowRendererUser from "../../Campaigns/campaignRowUser";
import NoDonations from "./noDonations";

const RecentDonationsTableOurBalance = ({ isAdmin }) => {

    const [paginationDataDonations, setPaginationDataDonations] = useState({
        pageSize: 8,
        page: 1
    });

    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const donations = useSelector(state => state.payment.donations, shallowEqual);

    const isLoading = useSelector(state => state.payment.isLoading.getCommunityDonations, shallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!!paginationDataDonations && !!communityId) {
            dispatch(getCommunityDonations({
                ...paginationDataDonations,
                communityId,
                isAdmin,
            }))
        }
    }, [
        paginationDataDonations,
        communityId,
        isAdmin,
        dispatch]
    )

    const adminHeader = [
        { name: '' },
        { name: 'Name' },
        { name: 'Operation time' },
        { name: 'Amount' },
        { name: 'Anonymous' }
    ];

    const userHeader = [
        { name: '' },
        { name: 'Name' },
        { name: 'Amount' },
    ]

    return (
        !!donations && !!donations.items && !!donations.items.length ? (
            <div className='donationTable'>
                <CustomTable
                    RowRenderer={isAdmin ? RowRenderer : RowRendererUser}
                    headerArray={isAdmin ? adminHeader : userHeader}
                    data={donations}
                    url='/our-balance'
                    isLoading={isLoading}
                    setPaginationData={setPaginationDataDonations}
                />
            </div>
        ) : (
            <div className="displayNone">
                <NoDonations />
            </div>
        )
    )
}

export default RecentDonationsTableOurBalance;