import React from "react";
import Lottie from "lottie-react";
import data from './data';
import { blue010 } from "../assets/colors/colors";

const AnimatedLoading = ({
    loading = false,
    color = blue010,
    width = '100%',
    full = true,
}) => (
    <div
        style={{
            position: full ? 'fixed' : 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: '100%',
            minHeight: '100%',
            backgroundColor: color,
            opacity: loading ? 0.85 : 0,
            transition: 'ease-in 0.5s',
            zIndex: 1000,
            display: loading ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <Lottie animationData={data} loop={true} style={{ width, overflow: 'hidden' }} />
    </div>
)

export default AnimatedLoading;