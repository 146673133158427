import { Avatar, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import moment from 'moment';

import { blue100, blue500, gray800 } from "../../uiKit/assets/colors/colors";
import { useStyle } from "../../uiKit/CustomTable/customTable";
import Title from "../../uiKit/Title/Title";
import { Verified } from "../../uiKit/assets/svg/icons";

import { nameInitials } from "../../helpers/functions";
import { clearMember } from "../../redux/state/memberSlice";

const RowRenderer = ({ items }) => {

    const cellClasses = useStyle();
    const history = useHistory();
    const dispatch = useDispatch();

    const onClickHandler = (item) => {
        if (!!item.from && item.from.type === 'member' && item.from.isMember) {
            dispatch(clearMember());
            history.push(`/members/member/${item.from.id}`)
        }
    }

    return (
        items.map((item, index) => {

            let isMember = false;

            if (!!item.from && item.from.type === 'member' && item.from.isMember) {
                isMember = true;
            }

            return (
                <TableRow key={index}>
                    <TableCell classes={cellClasses} style={{ width: '16px' }}>
                    </TableCell>
                    <TableCell classes={cellClasses} onClick={() => onClickHandler(item)}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: isMember ? 'pointer' : 'default',
                        }}>
                            <Avatar
                                alt=''
                                src={item.from?.imageName}
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: blue100,
                                    fontSize: '8px',
                                    lineHeight: '14px',
                                    fontWeight: 500,
                                    width: '24px',
                                    height: '24px'
                                }}
                            >
                                {
                                    !!item.from ? isMember ? nameInitials(item.from.name) : item.from.name.charAt(0) : 'A'
                                }
                            </Avatar>
                            <Title
                                text={!!item.from ? item.from.name : 'Anonymous'}
                                style={{ marginLeft: '20px', marginRight: '8px' }}
                                fontWeight={400}
                                color={isMember ? blue500 : gray800}
                            />
                        </div>
                    </TableCell>
                    <TableCell classes={cellClasses}>
                        <Title
                            text={`${moment(item.date).format('MM/DD/YYYY')} ${moment(item.date).format('h:mm A')}`}
                            fontWeight={400}
                            style={{ marginRight: '8px' }}
                            color={gray800}
                        />
                    </TableCell>
                    <TableCell classes={cellClasses}>
                        <Title
                            text={`$${item.amount.toLocaleString()}`}
                            fontWeight={400}
                            style={{ marginRight: '8px' }}
                            color={gray800}
                        />
                    </TableCell>
                    <TableCell classes={cellClasses}>
                        <Title
                            text={item.anonymous ? <Verified /> : "-"}
                            fontWeight={400}
                            style={{ marginRight: '8px' }}
                            color={gray800}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    )
}

export default RowRenderer;