import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header/header";
import { getCampaignMessage } from "../../../redux/state/notificationSlice";
import CopyLinkButton from "../../../uiKit/Button/CopyLinkButton";
import CustomButton from "../../../uiKit/Button/CustomButton";
import Title from "../../../uiKit/Title/Title";
import { toast } from 'react-toastify';
import { shallowEqual, useSelector } from "react-redux";
import AnimatedLoading from "../../../uiKit/Loading/animatedLoading";

const InviteFriend = () => {

    const { campaignId } = useParams();

    const history = useHistory();
    const dispatch = useDispatch();

    const communityId = window.localStorage.getItem('communityId');

    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        if (!!campaignId && !!communityId) {
            setIsLoading(true);
            dispatch(getCampaignMessage({ communityId, campaignId }))
                .then(unwrapResult)
                .then(response => {
                    setMessage(response.message)
                    setIsLoading(false);
                })
                .catch(({ title }) => toast.info(title))
        }
    }, [communityId, campaignId, dispatch])

    const shareMessageHandler = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Invitation to community',
                text: message,
            })
        }
    }

    return (
        <>
            <Header
                title='Campaign page'
                onClick={() => history.push(`/campaigns/campaign/active/${campaignId}`)}
            />
            <div className="donationWrapper" >
                <Title
                    text="You may invite your friend(s) to donate in this campaign."
                    lineHeight={32}
                    fontSize={24}
                />

                <div className="progressWrapper" style={{ marginTop: '24px' }}>
                    <Title
                        text={message}
                        fontWeight={400}
                        overflowText={false}
                    />
                </div>
                {
                    !!navigator.share && screenWidth < 900 ? (
                        <CustomButton
                            title='Share'
                            onClick={shareMessageHandler}
                            style={{ width: '100%' }}
                            disabled={isLoading}
                            loading={isLoading}
                        />
                    ) : (<CopyLinkButton message={message} />)
                }
                {
                    <AnimatedLoading loading={isLoading} full={false} />
                }
            </div>
        </>
    )
}

export default InviteFriend;