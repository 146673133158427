import React from "react";
import TransferInfoCard from "../../../components/TransferInfoCard/trasnferInfoCard";

const RecentTransfersList = ({ data = [], items }) => {

    return (
        <div className="boxShadow">
            {
                data.slice(0, items)
                    .map((transfer, index) => (<TransferInfoCard transfer={transfer} key={index} />))
            }
        </div>
    )
}

export default RecentTransfersList;