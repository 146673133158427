import API from '../API/main';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialMemberState = {
    isLoading: {
        members: false,
        addMembers: false,
        addMember: false,
        getVerifiedMembers: false,
        getAllMembersExclude: false,
        getMember: false,
        common: false,
    },
    member: {
        userId: null,
        communityId: null,
        memberRole: 0,
        imageName: null,
        fullName: null,
        email: null,
        phoneNumber: null,
        isVerified: false,
        preferredCommunicationMethod: 0
    },
    members: {
        items: null,
        numberOfItems: 0,
        page: 1,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    error: null,
    verifiedMembers: null,
    allMembers: {
        items: null,
        numberOfItems: 0,
        page: 1,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    communityAdmins: null,
    membersCount: 0,
}

export const getMembers = createAsyncThunk(
    '/members/all',
    async (data, thunkAPI) => {
        const response = await API.MemberAPI.getMembers(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { members: response }
    }
)

export const addMembers = createAsyncThunk(
    '/members/create',
    async ({ communityId, members }, thunkAPI) => {
        const response = await API.MemberAPI.addMembers(communityId, members);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { members: response }
    }
)

export const addMember = createAsyncThunk(
    '/members/member/add',
    async (communityId, thunkAPI) => {
        const response = await API.MemberAPI.addMember(communityId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getMember = createAsyncThunk(
    '/members/member',
    async ({ communityId, userId }, thunkAPI) => {
        const response = await API.MemberAPI.getMember(communityId, userId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { member: response }
    }
)

export const getVerifiedMembers = createAsyncThunk(
    'members/verified',
    async ({ communityId, searchString }, thunkAPI) => {
        const response = await API.MemberAPI.getVerifiedMembers({ communityId, searchString });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { verifiedMembers: response }
    }
)

export const getAllMembersExclude = createAsyncThunk(
    'members/exclude',
    async (data, thunkAPI) => {
        const response = await API.MemberAPI.searchMembers(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getAdmins = createAsyncThunk(
    '/members/admins',
    async (communityId, thunkAPI) => {
        const response = await API.MemberAPI.getCommunityAdmins(communityId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { communityAdmins: response }
    }
)

export const updateMemberRole = createAsyncThunk(
    '/members/updateRole',
    async (data, thunkAPI) => {
        const response = await API.MemberAPI.updateMemberRole(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { member: response }
    }
)

export const deleteMember = createAsyncThunk(
    '/members/delete',
    async (data, thunkAPI) => {
        const response = await API.MemberAPI.deleteMember(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { member: response }
    }
)

export const searchMembers = createAsyncThunk(
    '/members/search',
    async (data, thunkAPI) => {
        const response = await API.MemberAPI.searchMembers(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { members: response }
    }
)

const memberSlice = createSlice({
    name: 'member',
    initialState: initialMemberState,
    reducers: {
        clearMembers(state) {
            state.members = initialMemberState.members;
        },
        clearMember(state) {
            state.member = initialMemberState.member;
        },
        setMembersCount(state, action) {
            state.membersCount = action.payload;
        },
        clearAdmins(state) {
            state.communityAdmins = initialMemberState.communityAdmins;
        }
    },
    extraReducers: {
        [getMembers.pending]: (state) => {
            state.isLoading.members = true;
        },
        [getMembers.fulfilled]: (state, action) => {
            state.isLoading.members = false;
            state.members = action.payload.members;
            state.error = null;
        },
        [getMembers.rejected]: (state, action) => {
            state.isLoading.members = false;
            state.error = action.error || action.payload;
        },
        [addMembers.pending]: (state) => {
            state.isLoading.addMembers = true;
        },
        [addMembers.fulfilled]: (state) => {
            state.isLoading.addMembers = false;
            state.error = null;
        },
        [addMembers.rejected]: (state, action) => {
            state.isLoading.addMembers = false;
            state.error = action.error || action.payload;
        },
        [addMember.pending]: (state) => {
            state.isLoading.addMember = true;
        },
        [addMember.fulfilled]: (state) => {
            state.isLoading.addMember = false;
            state.error = null;
        },
        [addMember.rejected]: (state, action) => {
            state.isLoading.addMember = false;
            state.error = action.error || action.payload;
        },
        [getMember.pending]: (state) => {
            state.isLoading.getMember = true;
        },
        [getMember.fulfilled]: (state, action) => {
            state.isLoading.getMember = false;
            state.member = action.payload.member;
            state.error = null;
        },
        [getMember.rejected]: (state, action) => {
            state.isLoading.getMember = false;
            state.error = action.error || action.payload;
        },
        [updateMemberRole.pending]: (state) => {
            state.isLoading.common = true;
        },
        [updateMemberRole.fulfilled]: (state) => {
            state.isLoading.common = false;
            state.error = null;
        },
        [updateMemberRole.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        },
        [deleteMember.pending]: (state) => {
            state.isLoading.common = true;
        },
        [deleteMember.fulfilled]: (state) => {
            state.isLoading.common = false;
            state.error = null;
        },
        [deleteMember.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        },
        [getVerifiedMembers.pending]: (state) => {
            state.isLoading.getVerifiedMembers = true;
        },
        [getVerifiedMembers.fulfilled]: (state, action) => {
            state.isLoading.getVerifiedMembers = false;
            state.verifiedMembers = action.payload.verifiedMembers;
            state.error = null;
        },
        [getVerifiedMembers.rejected]: (state, action) => {
            state.isLoading.getVerifiedMembers = false;
            state.error = action.error || action.payload;
        },
        [getAllMembersExclude.pending]: (state) => {
            state.isLoading.getAllMembersExclude = true;
        },
        [getAllMembersExclude.fulfilled]: (state, action) => {
            state.isLoading.getAllMembersExclude = false;
            state.allMembers = action.payload;
            state.error = null;
        },
        [getAllMembersExclude.rejected]: (state, action) => {
            state.isLoading.getAllMembersExclude = false;
            state.error = action.error || action.payload;
        },
        [searchMembers.pending]: (state) => {
            state.isLoading.members = true;
        },
        [searchMembers.fulfilled]: (state, action) => {
            state.isLoading.members = false;
            state.members = action.payload.members;
            state.error = null;
        },
        [searchMembers.rejected]: (state, action) => {
            state.isLoading.members = false;
            state.error = action.error || action.payload;
        },
        [getAdmins.pending]: (state) => {
            state.isLoading.common = true;
        },
        [getAdmins.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.communityAdmins = action.payload.communityAdmins;
            state.error = null;
        },
        [getAdmins.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        }
    }
})

export const {
    clearMembers,
    clearMember,
    setMembersCount,
    clearAdmins,
} = memberSlice.actions;

export default memberSlice.reducer;