import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ReactGA from 'react-ga4';

import Header from "../../components/Header/header";
import Members from "./members";
// import NoMembers from "./noMembers";

const MembersWrapper = () => {

    const membersCount = useSelector(state => state.community.community.numberOfMembers, shallowEqual);

    const history = useHistory();

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/members` })
    }, [])

    return (
        <>
            <Header title='Add members' onClick={() => history.push('/members/add-members')} />
            {
                !!membersCount && <Members />
            }
        </ >
    )
}

export default MembersWrapper;