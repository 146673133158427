import React from "react";

import { blue300, gray900 } from "../../../uiKit/assets/colors/colors";
import { useHistory } from "react-router-dom";

const NoDonations = () => {

    const history = useHistory();

    return (
        <div style={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '400',
            color: gray900,
        }}>
            You haven't donated yet using this account.
            If you have any questions of difficulties, please
            <span
                style={{ cursor: 'pointer', color: blue300 }}
                onClick={() => history.push('/settings/contact-us')}
            > contact us. </span>
        </div>
    )
}

export default NoDonations;