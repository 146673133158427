import React, { useEffect } from "react";
import '../Campaigns/campaigns.scss';

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import Title from "../../uiKit/Title/Title";
import ProgressBar from "../../uiKit/ProgressBar/ProgressBar";
import { blue010, blue100, gray010 } from "../../uiKit/assets/colors/colors";
import CustomButton from "../../uiKit/Button/CustomButton";
import { getCampaign, setNotFound } from "../../redux/state/campaignSlice";
import { setCommunityId, setImageURL } from "../../redux/state/communitySlice";
import RecentDonationsTableAnonymous from "./recentDonations";
import Header from "../../components/Header/header";

const ActiveCampaignUnauthorized = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const { communityId, campaignId } = useParams();
    const { state } = useLocation();

    const imageURL = useSelector(state => state.community.communityImageURL, shallowEqual);
    const campaign = useSelector(state => state.campaign.campaign, shallowEqual);
    const notFound = useSelector(state => state.campaign.notFound, shallowEqual);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        if (!!communityId) {
            window.localStorage.setItem('communityId', communityId);
            dispatch(setCommunityId({ communityId }))
        }
    }, [communityId, dispatch])

    useEffect(() => {
        if (notFound) {
            history.push(`/anonymous/campaign-completed/${communityId}/${campaignId}`)
        }
    }, [notFound, history, campaignId, communityId])

    useEffect(() => {
        if (!!communityId && !!campaignId) {
            dispatch(getCampaign({ communityId, campaignId }))
                .then(unwrapResult)
                .then(response => {
                    if (screenWidth < 900) {
                        dispatch(setImageURL(response.campaign.images.mobile));
                    }
                    else {
                        dispatch(setImageURL(response.campaign.images.desktop));
                    }
                })
                .catch(({ status }) => {
                    if (status === 404) dispatch(setNotFound(true))
                });
        }
    }, [communityId, campaignId, screenWidth, dispatch])

    return (
        <>
            <div className="blueBackground campaignWrapperNotLoged">
                {
                    !!state && (
                        <div style={{
                            maxWidth: '872px',
                            width: screenWidth > 1200 ? 'calc(100% - 80px)' : '100%'
                        }}
                        >
                            <Header
                                title='search community'
                                onClick={() => history.push(`${state.pathname}${state.search}`)}
                                hasMenu={false}
                            />
                        </div>
                    )
                }
                <div className='communityInfoHeaderWrapper'>
                    <div className='photoWrapper' style={{ backgroundImage: `url(${imageURL})` }} />
                    <Title
                        text={campaign.name}
                        fontSize={screenWidth < 1200 ? 24 : 32}
                        lineHeight={screenWidth < 1200 ? 32 : 40}
                        className='ourBalanceTitle'
                    />
                    <div className='campaignProgressBar' style={{ marginBottom: 0 }}>
                        <div className='progressBar'>
                            <ProgressBar
                                campaignId={campaignId}
                                communityId={communityId}
                                target={campaign.amount}
                                color={blue100}
                                style={{
                                    boxShadow: 'none',
                                    padding: 0,
                                    backgroundColor: screenWidth > 900 ? gray010 : blue010
                                }}
                            />
                        </div>
                    </div>
                    {
                        !!campaign.description ? (
                            <Title
                                text={campaign.description}
                                style={{ marginBottom: '24px', marginTop: '20px' }}
                                fontWeight={400}
                            />
                        ) : (<div style={{ height: '8px' }} />)
                    }

                    <div className='transferButtons'>
                        <CustomButton
                            title='Donate'
                            onClick={() => history.push(`/campaign/anonymous/donate/${communityId}/${campaignId}`)}
                        />
                    </div>
                </div>
                <div className='campaignsWrapper'>
                    <Title
                        text='Recent Donations'
                        fontSize={24}
                        lineHeight={32}
                        className="recentHeader"
                    />
                    <RecentDonationsTableAnonymous />
                </div>
            </div>
        </>
    )
}

export default ActiveCampaignUnauthorized;