export const LargeLogo = () => (
    <svg width="296" height="64" viewBox="0 0 296 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1945_11300)">
            <path d="M51.1138 18.2738C49.6266 17.4526 48.391 16.2442 47.5388 14.7777C46.6568 13.2817 46.2158 11.5996 46.2158 9.73148C46.2158 7.86332 46.6568 6.18589 47.5388 4.69921C48.3944 3.23889 49.6295 2.03587 51.1138 1.21709C52.6348 0.395889 54.3371 -0.0341797 56.0668 -0.0341797C57.7965 -0.0341797 59.4989 0.395889 61.0198 1.21709C62.5001 2.03162 63.7271 3.23624 64.5668 4.69921C65.4335 6.18656 65.8668 7.86398 65.8668 9.73148C65.8668 11.599 65.4302 13.2811 64.5568 14.7777C63.7152 16.2428 62.4889 17.4515 61.0098 18.2738C59.4914 19.0948 57.7913 19.5248 56.0638 19.5248C54.3364 19.5248 52.6363 19.0948 51.1178 18.2738H51.1138ZM59.7368 13.7569C60.6548 12.7414 61.1142 11.3993 61.1148 9.73048C61.1148 8.04442 60.6555 6.69796 59.7368 5.69111C58.8182 4.68425 57.5948 4.18282 56.0668 4.18681C54.5195 4.18681 53.2862 4.68525 52.3668 5.68213C51.4475 6.67902 50.9882 8.03013 50.9888 9.73547C50.9888 11.4222 51.4482 12.769 52.3668 13.7758C53.2855 14.7827 54.5188 15.2858 56.0668 15.2851C57.5948 15.2825 58.8182 14.7731 59.7368 13.7569Z" fill="#D87F30" />
            <path d="M74.3998 0.238281V11.6636C74.3998 12.806 74.6818 13.6856 75.2458 14.3023C75.8098 14.9191 76.6374 15.2274 77.7288 15.2274C78.8208 15.2274 79.6578 14.9191 80.2398 14.3023C80.8218 13.6856 81.1141 12.806 81.1168 11.6636V0.238281H85.7828V11.6367C85.7828 13.3413 85.4191 14.7828 84.6918 15.9611C83.9924 17.1137 82.9734 18.0398 81.7578 18.6278C80.5298 19.2259 79.1608 19.525 77.6508 19.525C76.1408 19.525 74.7904 19.2306 73.5998 18.6418C72.4112 18.0561 71.4272 17.1272 70.7758 15.9761C70.0838 14.7885 69.7381 13.3423 69.7388 11.6377V0.238281H74.3998Z" fill="#0779D8" />
            <path d="M100.191 19.3396L96.2071 12.1311H95.0881V19.3396H90.4221V0.238281H98.2531C99.7631 0.238281 101.05 0.501127 102.115 1.02682C103.18 1.55251 103.975 2.27359 104.5 3.19006C105.032 4.12184 105.306 5.17829 105.292 6.25049C105.308 7.46982 104.93 8.66184 104.214 9.65087C103.495 10.6477 102.436 11.3549 101.035 11.7722L105.458 19.3396H100.191ZM95.0911 8.8394H97.9841C98.8381 8.8394 99.4791 8.63072 99.9071 8.21336C100.335 7.796 100.549 7.20684 100.549 6.44588C100.549 5.72015 100.335 5.1486 99.9071 4.73124C99.4791 4.31388 98.8381 4.10553 97.9841 4.10619H95.0881L95.0911 8.8394Z" fill="#0779D8" />
            <path d="M29.6221 44.6821V56.1074C29.6221 57.2499 29.9041 58.1294 30.4681 58.7462C31.0321 59.3629 31.8597 59.6713 32.9511 59.6713C34.0424 59.6713 34.8791 59.3629 35.4611 58.7462C36.0431 58.1294 36.3344 57.2499 36.3351 56.1074V44.6821H41.0001V56.0805C41.0001 57.7852 40.6361 59.227 39.9081 60.406C39.2096 61.5586 38.1908 62.4846 36.9751 63.0717C35.7471 63.6698 34.3781 63.9689 32.8681 63.9689C31.3581 63.9689 30.0074 63.6744 28.8161 63.0856C27.6274 62.4997 26.6433 61.5705 25.9921 60.419C25.3001 59.2313 24.9544 57.7852 24.9551 56.0805V44.6821H29.6221Z" fill="#0779D8" />
            <path d="M61.4119 63.7785H56.7459L48.9459 52.0003V63.7785H44.2749V44.6821H48.9409L56.7409 56.5152V44.6821H61.4069L61.4119 63.7785Z" fill="#0779D8" />
            <path d="M69.4618 44.6821V63.7785H64.7998V44.6821H69.4618Z" fill="#0779D8" />
            <path d="M86.6261 44.6821V48.4095H81.5501V63.7785H76.8841V48.4095H71.8081V44.6821H86.6261Z" fill="#0779D8" />
            <path d="M105.4 44.6821L98.7689 57.4682V63.7785H94.0999V57.4682L87.4709 44.6821H92.7649L96.4759 52.6801L100.16 44.6821H105.4Z" fill="#0779D8" />
            <path d="M46.2432 31.8007C46.2532 29.9592 46.6892 28.3003 47.5512 26.8243C48.3928 25.3583 49.6199 24.1492 51.1002 23.3272C52.6185 22.5062 54.3184 22.0762 56.0457 22.0762C57.7729 22.0762 59.4729 22.5062 60.9912 23.3272C62.479 24.148 63.7149 25.3568 64.5662 26.8243C65.4382 28.3017 65.8788 29.9605 65.8882 31.8007H61.1122C61.1002 30.1459 60.6412 28.8221 59.7352 27.8292C58.8172 26.8216 57.5848 26.3179 56.0382 26.3179C54.5102 26.3179 53.2868 26.8256 52.3682 27.8411C51.4622 28.844 51.0035 30.1632 50.9922 31.7987L46.2432 31.8007Z" fill="#D87F30" />
            <path d="M25.3309 26.7919C26.1228 25.3415 27.3102 24.144 28.7559 23.3377C30.2225 22.5123 31.8825 22.0996 33.7359 22.0996C36.0099 22.0996 37.9565 22.6977 39.5759 23.894C41.1952 25.0903 42.2775 26.7225 42.8229 28.7907H37.6929C37.3477 28.034 36.7817 27.3986 36.0689 26.9674C35.3455 26.5444 34.5193 26.3282 33.6809 26.3424C32.2435 26.3424 31.0795 26.8408 30.1889 27.8377C29.2982 28.8346 28.8525 30.1674 28.8519 31.8362C28.8519 33.505 29.2975 34.8378 30.1889 35.8347C31.0802 36.8316 32.2442 37.33 33.6809 37.33C34.5193 37.3442 35.3455 37.1279 36.0689 36.705C36.7817 36.2737 37.3477 35.6383 37.6929 34.8817H42.8229C42.2769 36.9492 41.1945 38.5774 39.5759 39.7664C37.9572 40.9553 36.0105 41.5495 33.7359 41.5488C31.8799 41.5488 30.2199 41.1361 28.7559 40.3107C27.3124 39.5074 26.1253 38.3147 25.3309 36.8694C24.5102 35.402 24.0999 33.7249 24.0999 31.8382C24.0999 29.9514 24.5102 28.2693 25.3309 26.7919Z" fill="#0779D8" />
            <path d="M91.0612 22.3149V41.4113H86.4002V29.9591L82.1112 41.4113H78.3452L74.0342 29.9321V41.4113H69.3682V22.3149H74.8802L80.2552 35.5356L85.5772 22.3149H91.0612Z" fill="#0779D8" />
            <path d="M116.112 22.3149V41.4113H111.446V29.9591L107.161 41.4113H103.4L99.084 29.9321V41.4113H94.418V22.3149H99.93L105.306 35.5356L110.627 22.3149H116.112Z" fill="#0779D8" />
        </g>
        <defs>
            <clipPath id="clip0_1945_11300">
                <rect width="296" height="64" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
