import React, { useEffect } from "react";
import ReactGA from 'react-ga4';

import Header from "../../../components/Header/header";
import CreateCommunity from "../../Account/CreateCommunity/createCommunity";

const NewCommunity = () => {

    
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/create-new-community` })
    }, [])

    return (
        <>
            <Header title='New Community' clickable={false} />
            <CreateCommunity url='/settings/new-community'/>
        </>
    )
}

export default NewCommunity;