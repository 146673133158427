import { useState } from 'react';
import { gray900 } from '../assets/colors/colors';
import './Title.scss';

const Title = ({
    text = '',
    fontSize = 14,
    fontWeight = 600,
    style,
    lineHeight = 20,
    color = gray900,
    onClick,
    className = '',
    overflowText = true
}) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <div
            className={`title ${className}`}
            style={{
                ...style,
                fontSize: `${fontSize}px`,
                lineHeight: `${lineHeight}px`,
                fontWeight,
                color,
                textOverflow: 'ellipsis',
                whiteSpace: 'pre-line'
            }}
            onClick={onClick}
        >
            {
                !!text && text.length > 200 && overflowText ?
                    (
                        !showMore ? (
                            <>
                                {text.slice(0, 100)}
                                <span
                                    style={{
                                        display: showMore ? 'inline' : 'hidden'
                                    }}
                                    className='descriptionShowMore'
                                    onClick={() => setShowMore(!showMore)}
                                >
                                    Show more...
                                </span>
                            </>
                        ) : (
                            <>
                                {text}
                                <span
                                    style={{
                                        display: showMore ? 'inline' : 'hidden'
                                    }}
                                    className='descriptionShowMore'
                                    onClick={() => setShowMore(!showMore)}
                                >
                                    Show less...
                                </span>
                            </>
                        )
                    ) : text
            }
        </div>
    )
}
export default Title;
