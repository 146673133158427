import React, { useCallback, useEffect, useState } from "react";
import '../campaign.scss';
import ReactGA from 'react-ga4';

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import Header from "../../../../components/Header/header";
import Title from "../../../../uiKit/Title/Title";
import ProgressBar from "../../../../uiKit/ProgressBar/ProgressBar";
import {
    blue010,
    blue100,
    blue300,
    gray010,
    gray650
} from "../../../../uiKit/assets/colors/colors";
import CustomButton from "../../../../uiKit/Button/CustomButton";
import { BlueMail, SingleUser } from "../../../../uiKit/assets/svg/icons";
import { getCampaign, stopCampaign } from "../../../../redux/state/campaignSlice";
import { MailMan } from "../../../../uiKit/assets/svg/mailMan";
import RecentDonationsTable from "../RecentDonations/recentDonationsTable";
import { getMember } from "../../../../redux/state/memberSlice";
import { toast } from "react-toastify";
import {
    getCampaignBalance,
    getCampaignNetBalance
} from "../../../../redux/state/paymentSlice";
import CustomDialog from "../../../../uiKit/CustomDialog/customDialog";
import { GreyPenSmall } from '../../../../uiKit/assets/svg/icons';

const ActiveCampaign = () => {

    const isLoading = useSelector(state => state.campaign.isLoading.stopCampaign, shallowEqual);

    const history = useHistory();
    const dispatch = useDispatch();

    const { search, state, pathname } = useLocation();
    const { campaignId } = useParams();
    const query = new URLSearchParams(search);

    const campaignCommunityId = query.get('campaignCommunityId');

    const communityIdState = useSelector(state => state.community.community.id, shallowEqual);
    const communities = useSelector(state => state.community.memberCommunities, shallowEqual);
    const campaign = useSelector(state => state.campaign.campaign, shallowEqual);
    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);

    const [previousRoute, setPreviousRoute] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [communityId, setCommunityId] = useState(false);
    const [balance, setBalance] = useState(0);
    const [netBalance, setNetBalance] = useState(0);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        if (!state) {
            setPreviousRoute('/campaigns');
            return;
        }

        setPreviousRoute(`${state.pathname}${state.search}`);

    }, [state])

    useEffect(() => {
        if (!!campaignCommunityId) {
            setCommunityId(campaignCommunityId);
            return
        };
        if (!!communityIdState) {
            setCommunityId(communityIdState);
            return
        };
    }, [campaignCommunityId, communityIdState])

    useEffect(() => {

        if (!!communityId && !!campaignId) {
            dispatch(getCampaign({ communityId, campaignId }))
                .then(unwrapResult)
                .then(() => { })
                .catch(({ status }) => {
                    if (status === 404) {
                        history.push(`/campaigns/campaign-completed/${communityId}/${campaignId}`)
                    }
                })
        }
    }, [communityId, search, campaignId, dispatch, history])

    useEffect(() => {
        if (!!campaignId && !!communityId) {
            dispatch(getCampaignBalance({ communityId, campaignId }))
                .then(unwrapResult)
                .then(response => {
                    const value = response.campaignBalance.balance;
                    setBalance(value);
                })
                .catch(({ title }) => console.error(title))
        }

    }, [campaignId, communityId, dispatch])

    useEffect(() => {
        if (!!campaignId && !!communityId) {
            dispatch(getCampaignNetBalance({ communityId, campaignId }))
                .then(unwrapResult)
                .then(response => {
                    const value = response.campaignBalance.balance;
                    setNetBalance(value);
                })
                .catch(({ title }) => console.error(title))
        }

    }, [campaignId, communityId, dispatch])

    const stopCampaignHandler = () => {
        if (!!communityId && !!campaignId) {
            dispatch(stopCampaign({ communityId, campaignId }))
                .then(unwrapResult)
                .then(() => history.push('/campaigns'))
                .catch(() => setConfirmOpen(false))
        }
    }

    const onRecipientClick = () => {
        if (!!campaign.recipient && !!campaign.recipient.id) {
            dispatch(getMember({ communityId, userId: campaign.recipient.id }))
                .then(unwrapResult)
                .then(() => history.push(`/members/member/${campaign.recipient.id}`))
                .catch(() => toast.info('User is not a member of this community'))
        }
    }

    const donateHandler = useCallback(() => {
        ReactGA.event('donate_campaign_start')
        history.push(`/campaigns/${campaignId}/donate/${communityId}`)
    }, [history, campaignId, communityId])

    const transferFromOurBalanceHandler = useCallback(() => {
        ReactGA.event('transfer_from_our_balance');
        history.push(`/campaigns/transfer/${campaign.name}/${campaignId}`);
    }, [history, campaign, campaignId])

    const inviteFriendHandler = useCallback(() => {
        ReactGA.event('invite_friend_to_donate');
        history.push(`/campaigns/campaign/${campaignId}/invite-friend`)
    }, [history, campaignId])

    const navigateToActiveCampaignEdit = () => {
        history.push(`${pathname}/edit`)
    }

    return (
        <>
            <Header title='All campaigns' onClick={() => history.push(previousRoute)} hasMenu={!!communities?.length} clickable={!!communities?.length} />
            <div className='communityInfoHeaderWrapper'>
                <img
                    className='photoWrapperCampaign'
                    alt=''
                    src={screenWidth < 900 ? campaign.images.mobile : campaign.images.desktop}
                />
                <div className="recipient">
                    <Title
                        text={campaign.name}
                        fontSize={screenWidth > 1200 ? 32 : 24}
                        lineHeight={screenWidth > 1200 ? 40 : 32}
                        className='ourBalanceTitle'
                    />
                    {!!isAdmin && (
                        <div className="menuItem" onClick={navigateToActiveCampaignEdit}>
                            <GreyPenSmall />
                        </div>
                    )}
                </div>
                <div className='campaignProgressBar' style={{ marginBottom: 0 }}>
                    <div className='progressBar'>
                        <ProgressBar
                            campaignId={campaignId}
                            communityId={communityId}
                            target={campaign.amount}
                            color={blue100}
                            style={{
                                boxShadow: 'none',
                                padding: 0,
                                backgroundColor: screenWidth > 900 ? gray010 : blue010
                            }}
                        />
                    </div>
                    {
                        !!isAdmin && !campaignCommunityId && (
                            <div className='progressBarButton'>
                                <CustomButton
                                    title='Send to recipient'
                                    isWhite
                                    onClick={() => setConfirmOpen(true)}
                                    disabled={!balance}
                                />
                            </div>
                        )
                    }
                </div>
                {
                    !!isAdmin &&
                    !!campaign &&
                    !!campaign.recipient && (
                        <div className='recipient' >
                            <div className='icon'><SingleUser /></div>
                            <div className='word'>
                                <Title text='Recipient:' fontSize={16} color={gray650} />
                            </div>
                            <Title
                                text={campaign.recipient.fullName}
                                fontSize={16}
                                color={blue300}
                                style={{ marginLeft: '16px', cursor: 'pointer' }}
                                onClick={onRecipientClick}
                            />
                        </div>
                    )
                }
                {
                    !!campaign.description ? (
                        <Title
                            text={campaign.description}
                            style={{ marginBottom: '24px', marginTop: '20px' }}
                            fontWeight={400}
                        />
                    ) : (<div style={{ height: '8px' }} />)
                }
                <div className='transferButtons'>
                    <CustomButton
                        title='Donate'
                        onClick={donateHandler}
                    />
                    {
                        !!isAdmin && !campaignCommunityId && (
                            <CustomButton
                                title='Transfer from Our Balance'
                                isWhite
                                classNames='margin16to0'
                                onClick={transferFromOurBalanceHandler}
                            />
                        )
                    }
                    {
                        !campaignCommunityId && (
                            <CustomButton
                                title='Invite friend to donate'
                                isWhite
                                Icon={BlueMail}
                                classNames='margin16to0'
                                onClick={inviteFriendHandler}
                            />
                        )
                    }
                </div>
            </div>
            <div className='campaignsWrapper'>
                <Title
                    text='Recent Donations'
                    fontSize={24}
                    lineHeight={32}
                    className="recentHeader"
                />
                <RecentDonationsTable
                    isAdmin={!!isAdmin}
                    communityId={communityId}
                />
            </div>

            <CustomDialog
                confirmOpen={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                Image={MailMan}
                mainButtonTitle='Confirm'
                mainButtonAction={stopCampaignHandler}
                isLoading={isLoading}
                secondButton={true}
                title={`Please note, this action will stop the ${campaign.name}.`}
                firstDescription={`You're sending $${balance.toLocaleString()} to recipient. Please note that the amount the recipient will actually receive will be $${netBalance.toLocaleString()} (after the credit card processing fees are deducted)`}
                secondDescription={`Do you confirm this action?`}
            />
        </>
    )
}

export default ActiveCampaign;