import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { SUBSCRIPTION_LABELS } from "../../../helpers/constants";

import { blue300 } from "../../../uiKit/assets/colors/colors";
import { CheckIcon, Close } from "../../../uiKit/assets/svg/icons";
import CustomCheckbox from "../../../uiKit/CustomCheckbox/customCheckbox";
import Title from "../../../uiKit/Title/Title";

const TabPanel = ({
    tabValue,
    index,
    data,
    setData,
    allData,
    ...other
}) => {

    const types = useSelector(state => state.notification.types, shallowEqual);
    const isLoading = useSelector(state => state.notification.isLoading.updateSubscriptions, shallowEqual);

    const onChangeHandler = (item) => {
        const { messageType, communicationMethod, isEnabled } = item;
        const updatedData = allData.map(x => {
            if (x.messageType === messageType && x.communicationMethod === communicationMethod) {
                return { ...x, isEnabled: !isEnabled }
            } else {
                return x
            }
        });

        setData(updatedData);
    }

    const selectAllHandler = (isEnabled) => {
        const communicationMethod = data[0].communicationMethod;
        const changeAll = allData.map(item => {
            if (item.communicationMethod === communicationMethod) {
                return { ...item, isEnabled }
            } else {
                return item;
            }
        })
        setData(changeAll);
    }

    return (
        <div style={{ position: 'relative', width: '100%', height: 'max-content' }}>
            <div
                role="tabpanel"
                hidden={tabValue !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {
                    tabValue === index && (
                        <div style={{ marginTop: '6px', marginLeft: '16px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    width: 'max-content',
                                    marginTop: '16px'
                                }}
                                onClick={() => selectAllHandler(true)}
                            >
                                <CheckIcon color={blue300} />
                                <Title
                                    text="Select all"
                                    fontWeight={400}
                                    color={blue300}
                                    style={{ marginLeft: '8px' }}
                                />
                            </div>
                            <div className="dividerSubscription" style={{ marginBottom: '6px' }} />
                            <div style={{ minHeight: '208px' }}>
                                {
                                    !!data && data.map((item, index) => {

                                        const { messageType, isEnabled } = item;

                                        if (!!types && !!types.find(x => x.id === messageType)) {
                                            return (
                                                <CustomCheckbox
                                                    key={index}
                                                    label={SUBSCRIPTION_LABELS.find(x => x.id === messageType).title}
                                                    checked={isEnabled}
                                                    onChange={() => onChangeHandler(item)}
                                                    disabled={isLoading}
                                                    style={{ width: 'max-content', maxWidth: '220px' }}
                                                />
                                            )
                                        }

                                        return null;

                                    })
                                }
                            </div>
                            <div className="dividerSubscription" style={{ marginTop: '6px' }} />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    width: 'max-content'
                                }}
                                onClick={() => selectAllHandler(false)}
                            >
                                <Close color={blue300} />
                                <Title
                                    text="Clear all"
                                    fontWeight={400}
                                    color={blue300}
                                    style={{ marginLeft: '8px' }}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default TabPanel;