import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Title from "../../../uiKit/Title/Title";

const MenuItem = ({ Icon, title, url }) => {

    const [selected, setSelected] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (history.location.pathname.includes(url)) {
            setSelected(true);
        } else {
            setSelected(false)
        }
    }, [history.location.pathname, url])

    return (
        <NavLink
            className={`menuItem ${selected ? 'selected' : ''}`}
            to={url}
        >
            <div style={{ display: 'flex' }}>
                <div className='menuIcon'>
                    <Icon />
                </div>
                <Title
                    text={title}
                    fontWeight={400}
                    style={{ paddingLeft: '16px' }}
                />
            </div>
        </NavLink>
    )
}

export default MenuItem;