import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import './campaigns.scss';
import Title from '../../uiKit/Title/Title';
import ProgressBar from "../../uiKit/ProgressBar/ProgressBar";
import { blue100 } from "../../uiKit/assets/colors/colors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomAccordion from "../../uiKit/CustomAccordion/customAccordion";
import { getActiveCampaigns, getCompletedCampaigns } from "../../redux/state/campaignSlice";
import CustomMobilePagination from "../../uiKit/CustomTable/mobilePagination";
import { useHistory } from "react-router";
import ActiveCampaignDropdown from "./Campaign/ActiveCampaign/activeCampaignList";
import CompletedCampaignDropdown from "./Campaign/CompletedCampaign/completedCampaignList";
import NoCampaigns from "./CampaignsDesktopList/noCampaigns";
import CustomButton from "../../uiKit/Button/CustomButton";
import Loading from "../../uiKit/Loading/loading";

const MobileCampaigns = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();

    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const isLoadingActive = useSelector(state => state.campaign.isLoading.getActiveCampaigns)
    const isLoadingCompleted = useSelector(state => state.campaign.isLoading.getCompletedCampaigns)

    const activeCampaigns = useSelector(state => state.campaign.activeCampaigns, shallowEqual);
    const completedCampaigns = useSelector(state => state.campaign.completedCampaigns, shallowEqual);
    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);

    const [items, setItems] = useState(2);

    const [paginationDataActive, setPaginationDataActive] = useState({
        pageSize: 8,
        page: 1
    });
    const [paginationDataCompleted, setPaginationDataCompleted] = useState({
        pageSize: 8,
        page: 1
    });

    useEffect(() => {
        if (!!paginationDataActive && !!communityId) {
            dispatch(getActiveCampaigns({ ...paginationDataActive, communityId }));
        }
    }, [paginationDataActive, communityId, dispatch])

    useEffect(() => {
        if (!!paginationDataCompleted && !!communityId && !!isAdmin) {
            dispatch(getCompletedCampaigns({ ...paginationDataCompleted, communityId }));
        }
    }, [paginationDataCompleted, communityId, isAdmin, dispatch])

    useEffect(() => {
        const query = new URLSearchParams(search);
        const page = parseInt(query.get('page')) || 1;
        const type = query.get('dataType') ?? '0';

        if (type === '0') {
            setPaginationDataActive({
                pageSize: 8,
                page
            })
        }

        if (type === '1') {
            setPaginationDataCompleted({
                pageSize: 8,
                page
            })
        }


    }, [search])

    return (
        <div className='mobileProgressBar'>
            {
                !!isAdmin ? (
                    <CustomAccordion
                        firstDropdownTitle={`Active Campaigns (${activeCampaigns?.totalNumberOfItems})`}
                        FirstDropdown={ActiveCampaignDropdown}
                        firstDropdownData={activeCampaigns}
                        firstListLoading={isLoadingActive}
                        FirstNoData={() => <NoCampaigns title='active' />}
                        seconDropdownTitle={`Completed Campaigns (${completedCampaigns?.totalNumberOfItems})`}
                        SeconDropdown={CompletedCampaignDropdown}
                        secondDropdownData={completedCampaigns}
                        secondListLoading={isLoadingCompleted}
                        SecondNoData={() => <NoCampaigns title="completed" />}
                        tableUrl='/campaigns'
                    />
                ) : (
                    !!activeCampaigns.items && (
                        <div style={{
                            display: "flex",
                            flexDirection: 'column',
                            width: '100%'
                        }}>
                            <Title
                                text={`Active Campaigns (${activeCampaigns.totalNumberOfItems})`}
                                fontSize={20}
                                lineHeight={30}
                                style={{ marginBottom: '16px' }}
                            />
                            <div style={{ position: 'relative' }}>
                                {
                                    activeCampaigns.items
                                        .slice(0, items)
                                        .map((campaign, index) => (
                                            <ProgressBar
                                                key={index}
                                                campaignId={campaign.id}
                                                communityId={campaign.communityId}
                                                title={campaign.name}
                                                target={campaign.amount}
                                                color={blue100}
                                                clickable={true}
                                                onClick={() => history.push(`/campaigns/campaign/active/${campaign.id}`)}
                                            />
                                        ))
                                }
                                {
                                    isLoadingActive && <Loading />
                                }
                            </div>
                            <CustomMobilePagination
                                data={activeCampaigns}
                                tableUrl='/campaigns'
                                count={items}
                                setItems={setItems}
                                NoData={() => (
                                    <>
                                        <NoCampaigns title='active' />
                                        <div className='memberButtons '>
                                            <CustomButton
                                                title='Suggest Campaign'
                                                isWhite
                                                onClick={() => history.push('/campaigns/suggest-campaign')}
                                            />
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                    )
                )
            }
        </div>
    )
}

export default MobileCampaigns;