import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getActiveCampaigns, getCompletedCampaigns } from "../../redux/state/campaignSlice";
import { blue100, gray500 } from "../../uiKit/assets/colors/colors";
import CampaignsDesktopList from "./CampaignsDesktopList/campaignsDesktopList";
import MobileCampaigns from "./campaignsListMobile";

const CampaignsTabs = () => {

    const dispatch = useDispatch();
    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);

    const activeCampaigns = useSelector(state => state.campaign.activeCampaigns, shallowEqual);
    const completedCampaigns = useSelector(state => state.campaign.completedCampaigns, shallowEqual);

    const [isActive, setIsActive] = useState(true);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const [paginationDataActive, setPaginationDataActive] = useState(null);

    const [paginationDataCompleted, setPaginationDataCompleted] = useState(null);

    useEffect(() => {
        if (!!paginationDataActive && !!communityId && screenWidth > 900) {
            dispatch(getActiveCampaigns({ communityId, ...paginationDataActive }));
        }
    }, [
        communityId,
        paginationDataActive,
        screenWidth,
        dispatch
    ])

    useEffect(() => {
        if (!communityId) {
            setPaginationDataActive(null);
            setPaginationDataCompleted(null);
        }
    }, [communityId])

    useEffect(() => {
        if (
            !!paginationDataCompleted &&
            !!communityId &&
            !!isAdmin &&
            screenWidth > 900
        ) {
            dispatch(getCompletedCampaigns({ communityId, ...paginationDataCompleted }));
        }
    }, [
        communityId,
        paginationDataCompleted,
        isAdmin,
        screenWidth,
        dispatch
    ])

    return (
        <div className='campaignsWrapper '>
            <>
                {
                    !!isAdmin ? (
                        <div className='campaignButton'>
                            <div className={`${isActive ? 'activeCampaigns' : 'deactiveCampaigns'}`}
                                onClick={() => setIsActive(true)}
                            >
                                Active Campaigns
                            </div>
                            <div className={`${!isActive ? 'activeCampaigns' : 'deactiveCampaigns'}`}
                                onClick={() => setIsActive(false)}
                            >
                                Completed Campaigns
                            </div>
                        </div>
                    ) : (
                        <div className='campaignButton'>
                            <div className='userActiveCampaigns'>
                                Active Campaigns
                            </div>
                        </div>
                    )
                }
                <div style={{ display: isActive ? 'block' : 'none' }}>
                    <CampaignsDesktopList
                        communityId={communityId}
                        color={blue100}
                        data={activeCampaigns}
                        setData={setPaginationDataActive}
                        campaignType='active'
                    />
                </div>
                <div style={{ display: isActive ? 'none' : 'block' }}>
                    <CampaignsDesktopList
                        communityId={communityId}
                        color={gray500}
                        data={completedCampaigns}
                        setData={setPaginationDataCompleted}
                        campaignType='completed'
                    />
                </div>
                {screenWidth < 900 && <MobileCampaigns />}
            </>
        </div>
    )
}

export default CampaignsTabs;