import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";

import './memberInfo.scss';
import { Avatar } from "@material-ui/core";
import Verify from "../../uiKit/Verify/verify";
import { Mail, Phone } from "../../uiKit/assets/svg/icons";
import Title from "../../uiKit/Title/Title";
import CustomRadio from "../../uiKit/CustomRadio/customRadio";
import { Confirm } from '../../uiKit/assets/svg/confirm';
import { Juggling } from "../../uiKit/assets/svg/juggling";
import { Unplug } from "../../uiKit/assets/svg/unplug";
import CustomCheckbox from "../../uiKit/CustomCheckbox/customCheckbox";
import { blue100, gray300 } from "../../uiKit/assets/colors/colors";

import Header from "../Header/header";

import {
    clearMember,
    clearMembers,
    deleteMember,
    getMember,
    updateMemberRole
} from "../../redux/state/memberSlice";

import { nameInitials } from "../../helpers/functions";
import { COMMUNICATION_METHOD } from "../../helpers/constants";
import { toast } from "react-toastify";
import CustomDialog from "../../uiKit/CustomDialog/customDialog";
import { setLoaded } from "../../redux/state/commonSlice";

const MemberInfo = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const isLoading = useSelector(state => state.member.isLoading.getMember, shallowEqual);
    const member = useSelector(state => state.member.member, shallowEqual);
    const admins = useSelector(state => state.member.communityAdmins, shallowEqual);
    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const communityName = useSelector(state => state.community.community.name, shallowEqual);

    const [isAdmin, setIsAdmin] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [deletedOpen, setDeletedOpen] = useState(false);
    const [adminsOpen, setAdminsOpen] = useState(false);

    const { userId } = useParams();
    
    useEffect(() => {
        dispatch(setLoaded({ type: 'page', value: !isLoading }))
    }, [isLoading, dispatch])

    useEffect(() => {
        if (!!communityId && !!userId && !member.userId) {
            dispatch(getMember({ communityId, userId }));
        }
    }, [communityId, userId, member, dispatch])

    const closeHandler = () => {
        setDeletedOpen(false);
    }

    const saveRole = (memberRole) => {
        dispatch(updateMemberRole({
            communityId,
            userId,
            memberRole,
        }))
            .then(unwrapResult)
            .then(() => {
                dispatch(clearMember());
                setTimeout(() => history.push('/members'), [1000])
            })
            .catch(({ title }) => {
                setConfirmOpen(false);
                toast.info(`Role is not updated: ${title}`);
            })
    }

    const onChangeHandler = () => {
        if (!!admins && admins.length === 3) {
            setAdminsOpen(true);
        } else {
            setConfirmOpen(true);
        }
    }

    const deleteMemberHandler = () => {
        setDeletedOpen(false)
        dispatch(deleteMember({ communityId, userId }))
            .then(unwrapResult)
            .then(() => {
                dispatch(clearMembers());
                setTimeout(() => history.push('/members'), [1000])
            })
            .catch(({ title }) => {
                closeHandler();
                toast.info(`Member is not deleted: ${title}`);
            })
    }

    const grandAdminCancel = () => {
        setConfirmOpen(false);
        setIsAdmin(false);
    }

    const saveAdminRole = () => {
        saveRole(1);
        setConfirmOpen(false);
    }

    const adminsFullCancel = () => {
        setAdminsOpen(false)
        setIsAdmin(false)
    }

    return (
        <div>
            <Header title='Members' onClick={() => history.push('/members')} />
            <div className='memberInfoWrapper'>
                <div className='memberInfoContentWrapper'>
                    {
                        !isLoading && (
                            <Avatar
                                src={member.imageName}
                                alt=''
                                className="imageWrapper"
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: blue100,
                                    fontSize: '32px',
                                    lineHeight: '40px',
                                    fontWeight: 600,
                                    width: '140px',
                                    height: '140px'
                                }}>
                                {nameInitials(member.fullName)}
                            </Avatar>
                        )
                    }
                    {
                        !!member && !!member.fullName && (
                            <div style={{ marginBottom: '8px' }}>
                                <Title
                                    text={member.fullName}
                                    fontWeight={600}
                                    fontSize={24}
                                    lineHeight={32}
                                />
                            </div>
                        )
                    }
                    <Verify status={member.isVerified} />

                    <div style={{
                        border: `1px solid ${gray300}`,
                        height: '2px',
                        margin: '16px 0 16px 0',
                        width: '100%'
                    }} />

                    <div className="userInfo">
                        <div>
                            <Mail />
                        </div>
                        <Title
                            text={member.email}
                            fontWeight={400}
                            style={{ marginLeft: '10px', overflow: 'visible', wordBreak: 'break-all' }}
                        />
                    </div>
                    <div className="userInfo" style={{ marginBottom: '24px' }}>
                        <div>
                            <Phone />
                        </div>
                        <Title
                            fontWeight={400}
                            style={{ marginLeft: '10px' }}
                            text={member.phoneNumber}
                        />
                    </div>
                    <div style={{ marginBottom: '16px' }}>
                        <CustomRadio
                            label='Preferred communication method'
                            data={COMMUNICATION_METHOD}
                            disable={true}
                            value={member.preferredCommunicationMethod}
                        />
                    </div>
                    <div style={{
                        marginBottom: '15px',
                    }}>
                        <Title
                            fontSize={12}
                            lineHeight={14}
                            text='ROLE'
                        />
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                            <CustomCheckbox
                                checked={isAdmin || !!member.memberRole}
                                label='Community Admin'
                                onChange={onChangeHandler}
                                disabled={!!member.memberRole || !member.isVerified}

                            />
                        </div>
                    </div>
                    {
                        !member.memberRole && (
                            <span className='deleteMemberButton' onClick={() => setDeletedOpen(true)}>
                                Delete member
                            </span>
                        )
                    }
                </div>
                <CustomDialog
                    confirmOpen={confirmOpen}
                    onClose={grandAdminCancel}
                    Image={Confirm}
                    mainButtonTitle='Confirm'
                    mainButtonAction={saveAdminRole}
                    secondButton={true}
                    title={`You're going to grant admin role of ${communityName} to ${member.fullName}.`}
                    secondDescription='Please confirm'
                />

                <CustomDialog
                    confirmOpen={adminsOpen}
                    onClose={() => setAdminsOpen(false)}
                    Image={Juggling}
                    mainButtonTitle='OK'
                    mainButtonAction={adminsFullCancel}
                    title='Your community already has 3 admins.'
                    secondDescription='You can not add more admins here.'
                />

                <CustomDialog
                    confirmOpen={deletedOpen}
                    onClose={closeHandler}
                    Image={Unplug}
                    mainButtonTitle='Confirm'
                    mainButtonAction={deleteMemberHandler}
                    secondButton={true}
                    title={`You're going to delete member ${member.fullName} from ${communityName}`}
                    secondDescription='Confirm your action, please'
                />

            </div>
        </div>

    )
}

export default MemberInfo;