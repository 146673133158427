import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';

import OurBalanceImage from '../../../uiKit/assets/svg/ourBalance.svg';

import Header from "../../../components/Header/header";
import Title from "../../../uiKit/Title/Title";
import Balance from "../../../components/Balance/balance";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MyDonationsTable from "./myDonationsTable";
import MyDonationsList from "./myDonationsList";
import { getTotalDonation } from "../../../redux/state/paymentSlice";
import { blue500, gray900 } from "../../../uiKit/assets/colors/colors";
import { useHistory } from "react-router-dom";

const MyDonations = () => {

    const totalDonation = useSelector(state => state.payment.userTotalDonation, shallowEqual);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/my-donations` })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', () => setScreenWidth(window.innerWidth));
        return () => window.removeEventListener('resize', () => setScreenWidth(window.innerWidth))
    }, [screenWidth])

    useEffect(() => {
        if (!totalDonation) {
            dispatch(getTotalDonation())
        }
    }, [totalDonation, dispatch])

    return (
        <div>
            <Header title='my donations' clickable={false} />
            <div className='communityInfoHeaderWrapper'>
                <div className='campaignMobileHeader'>
                    <img alt='' src={OurBalanceImage} className='photoWrapperOurBalance' style={{ backgroundColor: '#B2F2C4' }} />
                    <Title
                        text='My donations'
                        fontSize={screenWidth < 1200 ? 24 : 32}
                        lineHeight={screenWidth < 1200 ? 32 : 40}
                        className="ourBalanceTitle"
                    />
                </div>
                <Balance
                    balance={!!totalDonation ? `$ ${totalDonation.toLocaleString()}` : `$ 0`}
                    title='Total amount'
                    clickable={false}
                />
                <div className='ourBalanceDescription'>
                    <span
                        style={{
                            fontStyle: 'normal',
                            margin: '0',
                            alignItems: 'center',
                            fontFamily: "Poppins",
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '400',
                            color: gray900,
                            marginRight: '5px'
                        }}
                    >
                        To manage your recurring payments, visit 
                    </span>
                    <span
                        style={{
                            fontStyle: 'normal',
                            margin: '0',
                            alignItems: 'center',
                            fontFamily: '"Poppins"',
                            cursor: 'pointer',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '400',
                            color: blue500,
                        }}
                        onClick={() => history.push('/settings/payment-preferences') }
                    >
                        Payment Preferences page
                    </span>
                </div>
                {
                    screenWidth < 900 && (
                        <div style={{ marginTop: '16px' }}>
                            <MyDonationsList />
                        </div>
                    )
                }
            </div>
            <div className='campaignsWrapper '>
                <MyDonationsTable />
            </div>
        </div>
    )
}

export default MyDonations;