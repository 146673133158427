import './CustomInput.scss'
import { InputBase, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    gray010,
    gray100,
    gray300,
    gray500,
    gray800,
    red900
} from '../assets/colors/colors';
import CustomInputInfo from './CustomInputInfo';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        border: "1px solid",
        borderRadius: "10px !important",
        textTransform: "none",
        background: gray010,
        color: gray800,
        borderColor: gray300,
        height: "40px",
        padding: "0 20px",
        fontFamily: 'Poppins',
        borderBottom: `1px solid ${gray500}`,
        fontSize: '14px',
        marginBottom: '28px'
    },
    error: {
        borderBottom: `1px solid ${red900}`,
        borderRadius: '4px'
    },
    disabled: {
        border: "1px solid",
        borderRadius: "10px",
        textTransform: "none",
        background: gray100,
        color: gray800,
        borderColor: gray100,
    },
    multiline: {
        minHeight: '56px',
        height: 'max-content',
        padding: '10px'
    }
}));

const CustomInput = ({
    label,
    value,
    type,
    placeholder = '',
    StartAdornment,
    EndAdornment,
    endAdormentClick = () => { },
    onChange,
    error,
    errorMessage,
    onFocus,
    style,
    baseStyles,
    outFocus,
    description,
    multiline,
    readOnly = false,
    maxLength = 60,
    disabled = false,
    rows = 2,
    autoComplete = ''
}) => {
    const classes = useStyles();

    return (
        <div className="customInput" style={{ ...style }}>
            {!!label ? (
                <div className="label">{label}</div>
            ) : null}
            <InputBase
                multiline={multiline}
                minRows={rows}
                classes={classes}
                style={{ ...baseStyles }}
                error={error}
                value={value}
                type={type}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={outFocus}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete={autoComplete}
                startAdornment={
                    StartAdornment ? (
                        <InputAdornment position="start">
                            <div className='startAdornment'>
                                <StartAdornment />
                            </div>
                        </InputAdornment>
                    ) : null
                }
                endAdornment={EndAdornment ? (
                    <InputAdornment position="end" >
                        <div className='endAdornment' onClick={endAdormentClick}>
                            <EndAdornment />
                        </div>
                    </InputAdornment>
                ) : null}
                inputProps={{
                    maxLength,
                    readOnly
                }}
            />
            <CustomInputInfo
                error={error}
                errorMessage={errorMessage}
                description={description}
            />
        </div>
    );

};

export default CustomInput;