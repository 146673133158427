import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";

import './paymentPreferences.scss';

import Header from "../../../components/Header/header";

import { saveUserCard } from '../../../redux/state/paymentSlice';
import SavePaymentForm from "./savePaymentForm";
import { setLoaded } from "../../../redux/state/commonSlice";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {locale: 'en'});

const PaymentPreferences = () => {

    const isLoadingSavedCard = useSelector(state => state.payment.isLoading.getSavedCard);

    const [clientSecret, setClientSecret] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!clientSecret) {
            dispatch(saveUserCard())
                .then(unwrapResult)
                .then(response => setClientSecret(response.clientSecret))
                .catch(({ title }) => toast.info(title))
        }
    }, [clientSecret, dispatch])

    useEffect(() => {
        dispatch(setLoaded({ type: 'page', value: !isLoadingSavedCard && !!clientSecret }))
    }, [isLoadingSavedCard, clientSecret, dispatch])

    if (!clientSecret) {
        return (
            <Header title='Payment preferences' clickable={false} />
        )
    }

    return (
        <>
            <Header title='Payment preferences' clickable={false} />
            <Elements stripe={stripePromise} options={{ clientSecret }}>
                <SavePaymentForm clientSecret={clientSecret} />
            </Elements>
        </>

    )
}

export default PaymentPreferences;