import React, { useState } from "react";
import Title from "../../../uiKit/Title/Title";
import CustomButton from "../../../uiKit/Button/CustomButton";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { RadioIcon, RadioIconChecked } from "../../../uiKit/assets/svg/icons";
import './accountSetup.scss';
import { Greeting } from "../../../uiKit/assets/svg/greeting";
import { Member } from "../../../uiKit/assets/svg/member";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
    root: {
        margin: 0,
        padding: 0,
        width: '100%'
    },
    label: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
        fontFamily: 'Poppins'
    }
}))


const AccountSetup = () => {

    const history = useHistory();
    const radioClasses = useStyles();

    const [userType, setUserType] = useState(0);

    const accountTypeHandler = () => {
        if (!userType) {
            history.push('/create-community');
        } else {
            history.push('/find-community');
        }
    }

    return (
        <div className='landingContentWrapper'>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <div style={{ width: '100%' }}>
                    <Title
                        text='Account Setup'
                        fontSize={32}
                        lineHeight={40}
                        style={{ marginBottom: '32px' }}
                    />
                    <Title
                        text='You are...'
                        fontSize={16}
                        lineHeight={40}
                        style={{ marginBottom: '20px' }}
                    />
                </div>
                <RadioGroup classes={radioClasses}>
                    <div className='cards'>
                        <div className={userType ? 'cardWrapper' : 'cardWrapper selectedCard'} onClick={() => setUserType(0)}>
                            <div className='cardRadioButton'>
                                <FormControlLabel
                                    value={0}
                                    control={
                                        <Radio
                                            classes={radioClasses}
                                            icon={<RadioIcon />}
                                            checkedIcon={<RadioIconChecked />}

                                        />
                                    }
                                    classes={radioClasses}
                                    checked={!userType}
                                />
                            </div>
                            <div className='greetingWrapper'>
                                <div className='greeting'>
                                    <Greeting />
                                </div>
                                <div className='greetingText'>
                                    <Title
                                        text='Admin'
                                        lineHeight={14}
                                        fontSize={12}
                                    />
                                    <Title
                                        text='for a new community'
                                        lineHeight={14}
                                        fontSize={12}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={userType ? 'cardWrapper selectedCard' : 'cardWrapper'} onClick={() => setUserType(1)}>
                            <div className='cardRadioButton'>
                                <FormControlLabel
                                    value={1}
                                    control={
                                        <Radio
                                            classes={radioClasses}
                                            icon={<RadioIcon />}
                                            checkedIcon={<RadioIconChecked />}
                                        />
                                    }
                                    classes={radioClasses}
                                    checked={!!userType}
                                />
                            </div>
                            <div className='greetingWrapper'>
                                <div className='greeting'>
                                    <Member />
                                </div>
                                <div className='memberText'>
                                    <Title
                                        text='Member'
                                        lineHeight={14}
                                        fontSize={12}
                                    />
                                    <Title
                                        text='of an existing community'
                                        lineHeight={14}
                                        fontSize={12}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </RadioGroup>
                <div style={{ width: '100%' }}>
                    <CustomButton
                        title='Next'
                        onClick={accountTypeHandler}
                        classNames='buttonsWrapper'
                    />
                </div>
            </div>
        </div>
    )
}

export default AccountSetup;