import React, { useEffect, useState } from "react";
import { Magnificer } from "../../../uiKit/assets/svg/icons";
import CustomInput from "../../../uiKit/Input/CustomInput";
import { debounce } from 'lodash';

const MyCommunitiesSearchInput = ({
    communities,
    setCommunities
}) => {

    const [search, setSearch] = useState('');
    const [startSearch, setStartSearch] = useState(false);

    const debounceData = debounce((e) => setSearch(e.target.value), [500]);

    useEffect(() => {
        if (startSearch && search.length > 0) {
            const result = communities
                .filter(e => e.name.toLowerCase().search(search.toLowerCase()) > -1);
            setCommunities(result);
        } else {
            setCommunities(communities);
        }
    }, [search, startSearch, communities, setCommunities])

    return (
        <div className='memberSearch' style={{ minWidth: '272px' }}>
            <CustomInput
                placeholder='Search community'
                StartAdornment={Magnificer}
                style={{
                    marginBottom: '0',
                    display: 'flex',
                    alignItems: 'center'
                }}
                onChange={debounceData}
                type='search'
                onFocus={() => setStartSearch(true)}
            />

        </div>
    )
}

export default MyCommunitiesSearchInput;