import React, { useCallback } from 'react';
import { Celebration } from '../../uiKit/assets/svg/celebration.js'
import Feedback from '../../components/Feedback/feedback';
import { useLocation, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from 'react-redux';

const AnonymousThankYou = () => {

    const history = useHistory();
    const { search } = useLocation();

    const beMember = JSON.parse(new URLSearchParams(search).get('beMember'));
    const name = new URLSearchParams(search).get('name') || '';

    const paymentInfo = useSelector(state => state.payment.paymentInfo, shallowEqual);

    const createAccountHandler = useCallback(() => history.push('/sign-up'), [history]);

    const loginHandler = useCallback(() => {
        if (!!paymentInfo.donorInfo.email) {
            history.push(`/login?email=${paymentInfo.donorInfo.email}`)
        }
    }, [history, paymentInfo.donorInfo.email]);

    const okClickHandler = useCallback(() => history.push('/'), [history])

    if (!paymentInfo.clientSecret) history.push('/');

    if (!beMember) {
        return (
            <div className='createdPage'>
                {
                    paymentInfo.donorInfo.isVerified ? (
                        <Feedback
                            title="Thank you"
                            firstDescription='You have helped our community. Be proud.'
                            secondDescription="Log in for more."
                            button={{
                                feedbackTitle: "Log in",
                                feedbackAction: loginHandler,
                                disable: false
                            }}
                            Image={Celebration}
                            titleFontSize={24}
                        />
                    ) : (
                        <Feedback
                            title="Thank you"
                            firstDescription='You have helped our community. Be proud.'
                            secondDescription='Create account for future helping.'
                            button={{
                                feedbackTitle: 'Create account',
                                feedbackAction: createAccountHandler,
                                disable: false
                            }}
                            Image={Celebration}
                            titleFontSize={24}
                        />
                    )
                }
            </div>
        )
    }

    return (
        <div className='createdPage'>
            {
                paymentInfo.donorInfo.isVerified ? (
                    <Feedback
                        title="Thank you"
                        firstDescription="Thank you for supporting our community."
                        secondDescription='Please log in to check what is new.'
                        button={{
                            feedbackTitle: 'Log in',
                            feedbackAction: loginHandler,
                            disable: false
                        }}
                        Image={Celebration}
                        titleFontSize={24}
                    />
                ) : (
                    <Feedback
                        title="Thank you"
                        firstDescription={`We are glad that you decided to join our community ${name}.`}
                        secondDescription='The invitation has been sent to your email. Please check spam/junk folder if you cannot find it.'
                        button={{
                            feedbackTitle: 'Ok',
                            feedbackAction: okClickHandler,
                            disable: false
                        }}
                        Image={Celebration}
                        titleFontSize={24}
                    />
                )
            }
        </div>
    )
}

export default AnonymousThankYou;