import React from "react";
import './header.scss';

import MobileMenu from "../Sidebar/MobileMenu/mobileMenu";

import { ArrowBack } from "../../uiKit/assets/svg/icons";
import Title from "../../uiKit/Title/Title";

const Header = ({ title, onClick, clickable = true, hasMenu = true }) => {

    return (
        <div className='menu'>
            <div
                className='menuHeader'
                onClick={clickable ? onClick : () => { }}
            >
                {clickable ? <ArrowBack /> : null}
                <Title
                    fontSize={16}
                    text={title}
                    style={{
                        marginLeft: clickable ? '12px' : 0,
                        textTransform: 'uppercase',
                        cursor: clickable ? 'pointer' : 'default'
                    }}
                />
            </div>
            {hasMenu && <MobileMenu />}
        </div>
    )
}

export default Header;