import React from 'react';
import { Celebration } from '../../../uiKit/assets/svg/celebration.js'
import Feedback from '../../../components/Feedback/feedback';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { clearSavedCard } from '../../../redux/state/paymentSlice.js';

const CampaignThankYou = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <div className='createdPage'>
            <Feedback
                title="Thank you"
                firstDescription='You have helped your community.'
                secondDescription='Be proud.'
                button={{
                    feedbackTitle: 'Campaigns page',
                    feedbackAction: () => {
                        dispatch(clearSavedCard())
                        history.push('/campaigns')
                    },
                    disable: false
                }}
                Image={Celebration}
                titleFontSize={24}
            />
        </div>
    )
}

export default CampaignThankYou;