import React from 'react';
import './landingBackground.scss';
import { Logo } from '../../uiKit/assets/svg/logo';

const LandingBackground = ({ Component, color = 'whiteBackground' }) => {

    return (
        <div className={`landingBackground ${color}`}>
            <div className='logoWrapper'>
                <Logo />
            </div>
            <div className="contentWrapper">
                {!!Component ? (<Component />) : null}
            </div>
            <div className='downWave' />
        </div>
    )
}

export default LandingBackground;