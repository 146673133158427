import React, { useCallback, useEffect, useState } from "react";
import ReactGA from 'react-ga4';

import Header from "../../../components/Header/header";
import Title from "../../../uiKit/Title/Title";
import MailMan from '../../../uiKit/assets/svg/subscriptions.svg';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    clearSubscriptions,
    getSubscriptions,
    getSubscriptionTypes,
    updateSubscriptions
} from "../../../redux/state/notificationSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import './subscriptions.scss';
import { Tab, Tabs } from "@mui/material";
import TabPanel from "./tabPanel";
import { blue500, gray800 } from "../../../uiKit/assets/colors/colors";
import { setLoaded } from "../../../redux/state/commonSlice";

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Subscriptions = () => {

    const dispatch = useDispatch();

    const types = useSelector(state => state.notification.types, shallowEqual);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/subscriptions` })
    }, [])

    useEffect(() => {
        if (!types) {
            dispatch(getSubscriptionTypes())
        }
    }, [types, dispatch]);

    return !!types && <SubscriptionsContent types={types} />
}

const SubscriptionsContent = ({ types }) => {
    const dispatch = useDispatch();

    const communityName = useSelector(state => state.community.community.name, shallowEqual);
    const isLoading = useSelector(state => state.notification.isLoading.getSubscriptions, shallowEqual);

    const subscriptions = useSelector(state => state.notification.subscriptions, shallowEqual);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);
    const [subscribed, setSubscribed] = useState(false);

    const [value, setValue] = useState(0);
    const [smsData, setSmsData] = useState(null);
    const [emailData, setEmailData] = useState(null);
    const [updatedData, setUpdatedData] = useState([]);

    const updateData = useCallback(({ isSubscribed, subscriptionMessages }) => {
        setSubscribed(isSubscribed);
        const smses = subscriptionMessages.filter(item => !!item.communicationMethod);
        const emails = subscriptionMessages.filter(item => !item.communicationMethod);

        setUpdatedData(subscriptionMessages.filter(subs => subs.messageType < types.length));
        setSmsData(smses);
        setEmailData(emails);
    }, [types])

    useEffect(() => {
        if (!subscriptions) {
            dispatch(getSubscriptions())
                .then(unwrapResult)
                .then(response => updateData(response))
                .catch(({ title }) => toast.info(title))
        }
    }, [subscriptions, updateData, dispatch])

    useEffect(() => {
        if (updatedData.length === 0) {
            dispatch(clearSubscriptions());
            return;
        }

        dispatch(updateSubscriptions(updatedData))
            .then(unwrapResult)
            .then(response => {
                const { isSubscribed, subscriptionMessages } = response;

                setSubscribed(isSubscribed);
                const smses = subscriptionMessages.filter(item => !!item.communicationMethod);
                const emails = subscriptionMessages.filter(item => !item.communicationMethod);

                setSmsData(smses);
                setEmailData(emails);
            })
            .catch(({ title }) => toast.info(title))
    }, [updatedData, dispatch])

    useEffect(() => {
        dispatch(setLoaded({ type: 'page', value: !isLoading}))
    }, [isLoading, dispatch])

    return (
        <>
            <Header title='Subscriptions' clickable={false} />
            <div className='communityInfoHeaderWrapper' style={{ position: 'relative' }}>
                <div className='campaignMobileHeader'>
                    <img
                        alt=''
                        src={MailMan}
                        className='photoWrapperOurBalance'
                        style={{ backgroundColor: '#FFF6D6' }}
                    />
                </div>
                <Title
                    text='Subscriptions'
                    fontWeight={500}
                    fontSize={screenWidth < 1200 ? 36 : 32}
                    lineHeight={screenWidth < 1200 ? 42 : 40}
                    className="ourBalanceTitle"
                />
                {
                    subscribed ? (
                        <div>
                            <Title
                                text={`You are subscribed to all messages from ${communityName}.`}
                                fontWeight={400}
                                fontSize={16}
                                style={{ marginBottom: '24px' }}
                            />
                        </div>
                    ) : (
                        <div>
                            <Title
                                text={`You are unsubscribed from all messages of ${communityName}.`}
                                fontWeight={400}
                                fontSize={16}
                                style={{ marginBottom: '24px' }}
                            />
                        </div>
                    )
                }
                <div className="checkboxesWrapper">
                    <Tabs
                        value={value}
                        onChange={(_, newValue) => setValue(newValue)}
                        style={{
                            width: 'max-content',
                            margin: screenWidth > 900 ? 0 : 'auto'
                        }}
                    >
                        <Tab
                            value={0}
                            label='SMS (Text)'
                            style={{
                                width: '50%',
                                minWidth: '133px',
                                fontWeight: 500,
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: value === 0 ? blue500 : gray800,
                            }}
                            {...a11yProps(0)}
                        />
                        <Tab
                            value={1}
                            label='Email'
                            style={{
                                width: '50%',
                                minWidth: '133px',
                                fontWeight: 500,
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: value === 1 ? blue500 : gray800,
                            }}
                            {...a11yProps(1)}
                        />
                    </Tabs>
                    <TabPanel
                        tabValue={value}
                        index={0}
                        data={smsData}
                        setData={setUpdatedData}
                        allData={updatedData}
                    />
                    <TabPanel
                        tabValue={value}
                        index={1}
                        data={emailData}
                        setData={setUpdatedData}
                        allData={updatedData}
                    />
                </div>
            </div>
        </>
    )
}

export default Subscriptions;