import React from 'react';
import { Celebration } from '../../uiKit/assets/svg/celebration.js'
import Feedback from '../../components/Feedback/feedback';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { clearCommunity } from '../../redux/state/communitySlice.js';
import { clearSavedCard } from '../../redux/state/paymentSlice.js';

const OurBalanceThankYou = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <div className='createdPage'>
            <Feedback
                title="Thank you"
                firstDescription='You have helped your community.'
                secondDescription='Be proud.'
                button={{
                    feedbackTitle: 'Our balance page',
                    feedbackAction: () => {
                        dispatch(clearCommunity());
                        dispatch(clearSavedCard())
                        history.push('/our-balance');
                    },
                    disable: false
                }}
                Image={Celebration}
                titleFontSize={24}
            />
        </div>
    )
}

export default OurBalanceThankYou;