export const nameInitials = (fullName) => {
    if (!!fullName) {
        const nameChar = fullName.split(' ')[0].charAt(0);
        let lastNameChar = '';
        if (!!fullName.split(' ')[1]) {
            lastNameChar = fullName.split(' ')[1].charAt(0);
        }
        return `${nameChar}${lastNameChar}`.toUpperCase();
    }
}

export const uuidv4 = () => (
    [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    )

export const capitalizer = (input) => {
    return input.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
}