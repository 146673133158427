import { TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTable from "../../../uiKit/CustomTable/customTable";
import { useStyle } from "../../../uiKit/CustomTable/customTable";
import { useHistory } from "react-router";
import moment from 'moment';
import { blue500, gray800 } from "../../../uiKit/assets/colors/colors";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { getCommunityTransfers } from "../../../redux/state/paymentSlice";
import Title from "../../../uiKit/Title/Title";
import { clearMember } from "../../../redux/state/memberSlice";
import NoTransfers from "./noTransfer";

const RowRenderer = ({ items }) => {

    const cellClasses = useStyle();
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        items.map((item, index) => {

            const status = item.campaign.isEnded ? 'completed' : 'active';

            return (
                <TableRow key={index}>
                    <TableCell classes={cellClasses} style={{ width: '16px' }}>
                    </TableCell>
                    <TableCell classes={cellClasses} onClick={() => history.push(`/campaigns/campaign/${status}/${item.campaign.id}`)}>
                        <Title
                            text={item.campaign.name}
                            color={blue500}
                            fontWeight={400}
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                        />
                    </TableCell>
                    <TableCell classes={cellClasses} onClick={() => {
                        dispatch(clearMember());
                        history.push(`/members/member/${item.user.id}`)
                    }}>
                        <Title
                            text={item.user.fullName}
                            color={blue500}
                            fontWeight={400}
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                        />
                    </TableCell>
                    <TableCell classes={cellClasses}>
                        <Title
                            text={`${moment(item.date).format('MM/DD/YYYY')} ${moment(item.date).format('h:mm A')}`}
                            fontWeight={400}
                            style={{ marginRight: '8px' }}
                            color={gray800}
                        />
                    </TableCell>
                    <TableCell classes={cellClasses}>
                        <Title
                            text={`$${item.amount.toLocaleString()}`}
                            fontWeight={400}
                            style={{ marginRight: '8px' }}
                            color={gray800}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    )
}

const RecentTransfersTable = () => {

    const dispatch = useDispatch();

    const [paginationDataTransfers, setPaginationDataTransfers] = useState({
        pageSize: 8,
        page: 1
    });

    const isTransfersLoading = useSelector(state => state.payment.isLoading.getCommunityTransfers, shallowEqual);
    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const transfers = useSelector(state => state.payment.transfers, shallowEqual);

    useEffect(() => {
        if (!!paginationDataTransfers && !!communityId) {
            dispatch(getCommunityTransfers({ ...paginationDataTransfers, communityId }))
        }
    }, [paginationDataTransfers, communityId, dispatch])

    return (
        transfers && !!transfers.items && !!transfers.items.length ? (
            <div className='donationTable'>
                <CustomTable
                    RowRenderer={RowRenderer}
                    headerArray={[
                        { name: '' },
                        { name: 'Campaign title' },
                        { name: 'Admin' },
                        { name: 'Operation time' },
                        { name: 'Amount' },
                    ]}
                    data={transfers}
                    url='/our-balance'
                    setPaginationData={setPaginationDataTransfers}
                    isLoading={isTransfersLoading}
                />
            </div>
        ) : (
            <div className="displayNone">
                <NoTransfers />
            </div>
        )
    )
}

export default RecentTransfersTable;