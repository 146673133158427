import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Feedback from "../../components/Feedback/feedback";
import { setNotFoundCommon } from "../../redux/state/commonSlice";
import { NotFound } from "../../uiKit/assets/svg/notFound";

const NotFoundCommon = () => {

    const history = useHistory();
    const dispach = useDispatch();

    return (
        <div className='createdPage'>
            <Feedback
                title="Error"
                firstDescription='The content is not found'
                button={{
                    feedbackTitle: 'Back to campaigns',
                    feedbackAction: () => {
                        dispach(setNotFoundCommon(false));
                        history.push('/campaigns');
                    },
                    disable: false
                }}
                Image={NotFound}
                titleFontSize={24}
            />
        </div>
    )
}

export default NotFoundCommon;