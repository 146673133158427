import API from './API/main';

import {
    login,
    completeRegistration,
    logout,
    refreshToken,
    confirmInvitation,
    externalLogin,
} from './state/accountSlice';
import {
    clearCampaigns,
    clearDrafts,
    clearSuggestions,
    createCampaign,
    createCampaignDraft,
    deleteCampaignDraft,
    deleteCampaignSuggestion,
    getCampaign,
    getCampaignPublic,
    launchCampaignDraft,
    launchCampaignSuggestion,
    saveCampaignSuggestion,
    stopCampaign,
    updateCampaignDraft
} from './state/campaignSlice';
import { clearLoaded, setNotFoundCommon } from './state/commonSlice';
import {
    checkCommunityStatus,
    clearCommunity,
    clearId,
    clearIds,
    clearMemberCommunities,
    createCommunity,
    leaveCommunity,
    resetApprovedCommunities,
    updateCommunity
} from './state/communitySlice';
import {
    addMember,
    addMembers,
    clearAdmins,
    clearMembers,
    deleteMember,
    getMember,
    updateMemberRole
} from './state/memberSlice';
import { clearNotifications } from './state/notificationSlice';
import {
    campaignPaymentIntent,
    campaignPaymentIntentSaved,
    clearSubscriptions,
    clearUserDonations,
    ourBalancePaymentIntent,
    ourBalancePaymentIntentSaved,
    transferFromOurBalance
} from './state/paymentSlice';


const localStorageMiddleware = store => next => action => {

    const { dispatch } = store;

    const ls = window.localStorage;

    if (action.type === login.fulfilled.type) {
        dispatch(clearId());
        if (!!action.payload.token) {
            ls.setItem('userId', action.payload.user.id);
            ls.setItem('token', JSON.stringify(action.payload.token));
            ls.setItem('refresh-token', action.payload.token.refresh_token);
            API.setToken(action.payload.token.access_token);
        }
    }

    if (action.type === externalLogin.fulfilled.type) {
        dispatch(clearId());
        if (!!action.payload.token) {
            if (!!action.payload.token.refresh_token) {
                ls.setItem('refresh-token', action.payload.token.refresh_token);
            }

            if (!!action.payload.token.access_token) {
                ls.setItem('token', JSON.stringify(action.payload.token));
                API.setToken(action.payload.token.access_token);
            }
        }

        if (!!action.payload.user) {
            ls.setItem('userId', action.payload.user.id);
        }
    }

    if (action.type === completeRegistration.fulfilled.type) {
        if (!!action.payload.token) {
            ls.setItem('userId', action.payload.user.id);
            ls.setItem('token', JSON.stringify(action.payload.token));
            ls.setItem('refresh-token', action.payload.token.refresh_token);
            API.setToken(action.payload.token.access_token);
        }
    }

    if (action.type === refreshToken.fulfilled.type) {
        if (!!action.payload.token) {
            ls.setItem('token', JSON.stringify(action.payload.token));
            ls.setItem('refresh-token', action.payload.token.refresh_token);
            API.setToken(action.payload.token.access_token);
        }
    }

    if (action.type === refreshToken.rejected.type) {
        dispatch(logout());
    }

    if (action.type === logout.type) {
        dispatch(clearCommunity());
        dispatch(clearCampaigns());
        dispatch(clearMemberCommunities());
        dispatch(clearMembers());
        dispatch(clearId());
        dispatch(clearAdmins());
        dispatch(clearUserDonations());
        dispatch(clearSubscriptions());
        dispatch(clearNotifications());
        dispatch(clearDrafts());
        dispatch(clearSuggestions());
        dispatch(clearLoaded());
        ls.clear();
        API.setToken(null);
    }

    //refreshing data

    if (
        action.type === ourBalancePaymentIntentSaved.fulfilled.type ||
        action.type === campaignPaymentIntentSaved.fulfilled.type ||
        action.type === ourBalancePaymentIntent.fulfilled.type ||
        action.type === campaignPaymentIntent.fulfilled.type
    ) {
        dispatch(clearSubscriptions());
        dispatch(clearUserDonations());
    }

    if (action.type === updateMemberRole.fulfilled.type) {
        dispatch(clearAdmins());
    }

    if (
        action.type === createCampaign.fulfilled.type ||
        action.type === stopCampaign.fulfilled.type ||
        action.type === addMembers.fulfilled.type ||
        action.type === deleteMember.fulfilled.type ||
        action.type === transferFromOurBalance.fulfilled.type ||
        action.type === ourBalancePaymentIntentSaved.fulfilled.type ||
        action.type === campaignPaymentIntentSaved.fulfilled.type ||
        action.type === addMember.fulfilled.type ||
        action.type === updateMemberRole.fulfilled.type
    ) {
        dispatch(clearCommunity());
    }

    if (action.type === leaveCommunity.fulfilled.type) {
        dispatch(clearId());
    }

    if (
        action.type === leaveCommunity.fulfilled.type ||
        action.type === createCommunity.fulfilled.type ||
        action.type === updateCommunity.fulfilled.type ||
        action.type === addMember.fulfilled.type ||
        action.type === confirmInvitation.fulfilled.type ||
        action.type === checkCommunityStatus.fulfilled.type
    ) {
        dispatch(clearMemberCommunities());
        dispatch(resetApprovedCommunities());
        dispatch(clearIds());
    }

    if (
        action.type === createCampaignDraft.fulfilled.type ||
        action.type === updateCampaignDraft.fulfilled.type ||
        action.type === deleteCampaignDraft.fulfilled.type ||
        action.type === launchCampaignDraft.fulfilled.type
    ) {
        dispatch(clearDrafts());
    }

    if (
        action.type === saveCampaignSuggestion.fulfilled.type ||
        action.type === deleteCampaignSuggestion.fulfilled.type ||
        action.type === launchCampaignSuggestion.fulfilled.type
    ) {
        dispatch(clearSuggestions());
    }

    if (action.payload?.status === 404) {
        if (action.type === getCampaign.rejected.type) return
        if (action.type === getCampaignPublic.rejected.type) return
        if (action.type === getMember.rejected.type) return;
        dispatch(setNotFoundCommon(true));
    }

    if (action.type === login.fulfilled.type) {
        dispatch(setNotFoundCommon(false))
    }
    next(action);
};

export { localStorageMiddleware }