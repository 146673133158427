import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { completeChangeEmail, login } from "../../redux/state/accountSlice";
import { setCommunityId } from "../../redux/state/communitySlice";
import LoginComponent from "./loginComponent";

const Login = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const changeEmailToken = encodeURIComponent(query.get('token'));
    const userId = query.get('userId');
    const id = query.get('communityId');
    const type = query.get('type');

    const communityId = useSelector(state => state.account.userByParseToken.communityId, shallowEqual);

    const loginHandler = ({ email, password }) => {
        dispatch(login({ email, password }))
            .then(unwrapResult)
            .then(() => {
                if (!!communityId) {
                    dispatch(setCommunityId({ communityId }));
                    window.localStorage.setItem('communityId', communityId);
                }
                if (!!id) {
                    dispatch(setCommunityId({ communityId: id }));
                    window.localStorage.setItem('communityId', id);
                    window.localStorage.setItem('add-members', true);
                    history.push('/campaigns')
                } else {
                    switch (type) {
                        case 'search':
                            history.push('/settings/my-communities/search-community');
                            break;
                        case 'create':
                            history.push('/settings/new-community');
                            break;
                        default: history.push('/campaigns')
                    }
                }
            })
            .catch(({ title }) => console.error(title))
    }

    useEffect(() => {
        if (!!changeEmailToken && !!userId) {
            dispatch(completeChangeEmail({ userId, token: changeEmailToken }))
        }
    }, [userId, changeEmailToken, dispatch])

    return <LoginComponent onLogin={loginHandler} />
}

export default Login;