import { FetchAPI } from "./main";

export const MemberAPI = {
    addMembers: (
        communityId,
        data
    ) => FetchAPI.postJson(`/community/communities/${communityId}/members`, data),
    addMember: (communityId) => FetchAPI.postJson(`/community/communities/${communityId}/join`),
    getVerifiedMembers: ({
        communityId,
        searchString
    }) => FetchAPI.getJson(`/community/communities/${communityId}/members/verified?searchString=${searchString}`),
    getCommunityAdmins: (communityId) => FetchAPI.getJson(`/community/communities/${communityId}/admins`),
    //pagination
    getMembers: ({
        communityId,
        pageSize,
        page
    }) => FetchAPI.getJson(`/community/communities/${communityId}/members/${pageSize}/${page}`),
    searchMembers: ({
        communityId,
        pageSize,
        page,
        searchString
    }) => FetchAPI.getJson(`/community/communities/${communityId}/members/${pageSize}/${page}?searchString=${searchString}`),
    //userId
    getMember: (
        communityId,
        userId
    ) => FetchAPI.getJson(`/community/communities/${communityId}/members/${userId}`),
    updateMemberRole: (data) => FetchAPI.putJson(`/community/communities/${data.communityId}/members/${data.userId}`, data),
    deleteMember: ({
        communityId,
        userId
    }) => FetchAPI.deleteJson(`/community/communities/${communityId}/members/${userId}`),
}