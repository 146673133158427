import React, { useEffect, useRef } from "react";
import { blue500 } from "../../../uiKit/assets/colors/colors";
import Title from "../../../uiKit/Title/Title";
import Shtetl from '../../../uiKit/assets/svg/shtetl.svg';
import { shallowEqual, useSelector } from "react-redux";

const AboutGradient = ({ setRef }) => {

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const myRef = useRef(null);

    useEffect(() => {
        if (!!myRef && !!setRef) {
            setRef(prev => ({ ...prev, about: myRef }))
        }
    }, [myRef, setRef])

    return (
        <div className="aboutGradient" ref={myRef}>
            <div className="background">
                <div className="shtetlText">
                    <Title
                        text="ABOUT"
                        color={blue500}
                        lineHeight={18}
                        style={{ marginBottom: '16px', cursor: 'pointer', width: 'fit-content' }}
                        onClick={() => window.scrollTo(0, 0)}
                    />
                    <Title
                        text='It takes a shtetl'
                        fontSize={screenWidth > 900 ? 64 : 48}
                        lineHeight={screenWidth > 900 ? 89.6 : 67.2}
                        style={{ marginBottom: '32px' }}
                    />
                    <Title
                        text="It turns out that people like giving to their own."
                        fontWeight={300}
                        fontSize={18}
                        lineHeight={25.2}
                    />
                    <Title
                        text="OurCommunity.One is a platform that facilitates group giving within local communities."
                        fontWeight={300}
                        fontSize={18}
                        lineHeight={25.2}
                    />
                </div>
                <div className="shtetlImage" >
                    <img alt='' src={Shtetl} style={{ width: '100%', height: '100%' }} />
                </div>
            </div>
        </div >
    )

}
export default AboutGradient;