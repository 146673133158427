import { unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { cancelSubscription, getSubscriptions } from "../../../redux/state/paymentSlice";

import { blue500 } from "../../../uiKit/assets/colors/colors";
import { Unplug } from "../../../uiKit/assets/svg/unplug";
import CustomButton from "../../../uiKit/Button/CustomButton";
import CustomDialog from "../../../uiKit/CustomDialog/customDialog";
import Title from "../../../uiKit/Title/Title";

const RecurringDonations = () => {

    const dispatch = useDispatch();

    const subscriptions = useSelector(state => state.payment.subscriptions, shallowEqual);

    const [pageSize, setPageSize] = useState(3);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [recurringData, setRecurringData] = useState(null);

    useEffect(() => {
        dispatch(getSubscriptions({ page: 1, pageSize }))
    }, [pageSize, dispatch])

    const cancelHandler = useCallback((id) => {
        setConfirmOpen(false);
        dispatch(cancelSubscription(id))
            .then(unwrapResult)
            .then(() => {
                if (pageSize > 3) {
                    setPageSize(prev => prev - 1);
                    dispatch(getSubscriptions({ page: 1, pageSize: pageSize - 1 }))

                } else {
                    setPageSize(3)
                    dispatch(getSubscriptions({ page: 1, pageSize: 3 }))
                }
            })
            .catch(({ title }) => toast.info(title));
    }, [pageSize, dispatch])

    if (!subscriptions || !subscriptions.items) return null;

    if (!subscriptions.items.length) {
        return (
            <Title
                text="No recurring donations"
                fontWeight={400}
            />
        )
    }

    return (
        <>
            {
                subscriptions.items.map((subs, index) => (
                    <div className='subscriptionItem' key={index}>
                        <Title
                            text={subs.community.name}
                            fontSize={16}
                            style={{ marginBottom: '8px' }}
                        />
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '16px', marginBottom: '12px' }}>
                                <Title
                                    text={`Recurring:`}
                                    fontWeight={400}
                                    style={{ marginBottom: '8px' }}
                                />
                                <Title
                                    text={`Amount:`}
                                    fontWeight={400}
                                />
                            </div>
                            <div>
                                <Title
                                    text={subs.recurring}
                                    fontWeight={400}
                                    style={{ marginBottom: '8px' }}
                                />
                                <Title
                                    text={`$${subs.amount.toLocaleString()}`}
                                    fontWeight={400}
                                />
                            </div>
                        </div>

                        <Title
                            text="Cancel"
                            fontWeight={400}
                            color={blue500}
                            onClick={() => {
                                setRecurringData(subs);
                                setConfirmOpen(true);
                            }}
                            style={{
                                cursor: 'pointer',
                                width: '100px'
                            }}
                        />
                    </div>
                ))
            }
            {
                subscriptions.totalNumberOfItems > 3 && (
                    subscriptions.hasNextPage ? (
                        <CustomButton
                            title='Show more'
                            isWhite
                            onClick={() => setPageSize(prev => prev + 2)}
                        />
                    ) : (
                        <CustomButton
                            title='Show less'
                            isWhite
                            onClick={() => setPageSize(prev => {
                                if (prev > 4) {
                                    return prev - 2
                                }
                                else return 3
                            })}
                        />
                    )
                )}
            <CustomDialog
                confirmOpen={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                Image={Unplug}
                mainButtonTitle='Confirm'
                mainButtonAction={() => cancelHandler(recurringData.id)}
                secondButton={true}
                title={`You are going to cancel recurring payment for ${recurringData?.community.name} community`}
                secondDescription='Confirm your action, please'
            />
        </>
    )
}

export default RecurringDonations;