import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { gray600 } from "../../uiKit/assets/colors/colors";
import Title from "../../uiKit/Title/Title";

const CheckoutInfo = () => {

    const paymentInfo = useSelector(state => state.payment.paymentInfo, shallowEqual);

    return (
        <div className='feeWrapper'>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '8px'
            }}>
                <Title
                    text="Amount"
                    fontWeight={400}
                    color={gray600}
                />
                <Title
                    text={`$ ${paymentInfo.net.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}`}
                    fontWeight={400}
                    color={gray600}
                />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '8px'
            }}>
                <Title
                    text="Processing fee"
                    fontWeight={400}
                    color={gray600}
                />
                <Title
                    text={`$ ${paymentInfo.fee.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}`}
                    fontWeight={400}
                    color={gray600}
                />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Title
                    text="Total amount"
                    fontWeight={400}
                />
                <Title
                    text={`$ ${paymentInfo.amount.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}`}
                    fontWeight={400}
                    lineHeight={32}
                    fontSize={24}
                />
            </div>
        </div>
    )
}

export default CheckoutInfo;