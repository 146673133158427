import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';

import Title from "../../uiKit/Title/Title";
import NotificationBar from "../../uiKit/NotificationBar/notificationBar";

import {
    getCountNewNotifications,
    getNewNotifications,
    readNotifications
} from "../../redux/state/notificationSlice";

const NewNotifications = () => {

    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(5);

    const [notificationsList, setNotificationsList] = useState(null);

    const totalNew = useSelector(state => state.notification.notifications.countNew, shallowEqual);
    const notifications = useSelector(state => state.notification.notifications.new, shallowEqual);

    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    useEffect(() => {

        if (!communityId) return;

        dispatch(getNewNotifications({ page: 1, pageSize, communityId }));
        dispatch(getCountNewNotifications(communityId))

        return () => dispatch(readNotifications(communityId))

    }, [pageSize, communityId, dispatch])

    useEffect(() => {
        if (!notifications) return;

        setNotificationsList(notifications.items.map((item, index) => (
            <NotificationBar
                title={item.message}
                date={item.createdAt}
                key={index}
            />
        )))
    }, [notifications])

    return !!notifications && !!notifications.items.length && (
        <>
            <Title
                text={`New (${totalNew})`}
                fontSize={20}
                lineHeight={30}
                style={{ marginBottom: '20px' }}
            />
            <div style={{ position: 'relative', width: '100%', height: 'max-content', marginBottom: '16px' }}>
                {
                    notifications.items.length < 5 ? (
                        notificationsList
                    ) : (
                        <InfiniteScroll
                            dataLength={notifications.numberOfItems}
                            next={() => setPageSize(prev => prev + 5)}
                            hasMore={notifications.hasNextPage}
                            height={350}
                        >
                            {notificationsList}
                        </InfiniteScroll>
                    )
                }
            </div>
        </>
    )
}

export default NewNotifications;