import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import PasswordDescription from "./passwordDescription";
import { blue300, gray800 } from "../../uiKit/assets/colors/colors";
import { Eye } from "../../uiKit/assets/svg/icons";
import CustomButton from "../../uiKit/Button/CustomButton";
import CustomCheckbox from "../../uiKit/CustomCheckbox/customCheckbox";
import CustomInput from "../../uiKit/Input/CustomInput";
import Title from "../../uiKit/Title/Title";
import './login.scss';
import LoginError from "./loginError";
import AlternativeLogin from "../../components/AlternativeLogin/alternativeLogin";

const LoginComponent = ({
    onLogin,
    createAccount = true,
    hasBackground = true,
    signUpUrl = '/sign-up'
}) => {

    const isLoading = useSelector(state => state.account.isLoading.login, shallowEqual);

    const history = useHistory();
    const { search } = useLocation();

    const query = new URLSearchParams(search);
    const emailPrefilled = query.get('email');

    const [email, setEmail] = useState(emailPrefilled || '');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [remember, setRemember] = useState(true);

    const loginError = useSelector(state => state.account.loginError, shallowEqual);

    const onEmailChange = (e) => {
        setEmail((e.target.value).trim());
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const loginHandler = (e) => {
        e.preventDefault();
        if (!!onLogin) {
            onLogin({ email, password, remember });
        }
    }

    useEffect(()=>{
        window.localStorage.setItem('remember', remember);
    },[remember])

    return (
        <>
            {hasBackground && <div className='loginBackground' />}
            <form
                onSubmit={loginHandler}
                className='landingContentWrapper'
            >
                <Title
                    text='Log In'
                    fontSize={32}
                    lineHeight={40}
                    style={{ marginBottom: '32px' }}
                />
                <CustomInput
                    label='Your Email'
                    value={email}
                    onChange={onEmailChange}
                    type={'email'}
                />
                <CustomInput
                    label='Pasword'
                    onChange={onPasswordChange}
                    description={<PasswordDescription email={email} />}
                    value={password}
                    type={showPassword ? 'text' : 'password'}
                    EndAdornment={Eye}
                    endAdormentClick={() => setShowPassword(!showPassword)}
                />

                <CustomCheckbox
                    label='Remember me'
                    style={{ marginTop: '16px' }}
                    value={remember}
                    onChange={() => setRemember(!remember)}
                    checked={remember}
                />
                {
                    !!loginError && <LoginError />
                }
                <div className='buttonsWrapper'>
                    <CustomButton
                        title='Log In'
                        classNames='marginTop24To0'
                        disabled={isLoading}
                        loading={isLoading}
                        type='submit'
                    />
                    <AlternativeLogin />
                    {
                        !!createAccount && (
                            <div className='buttonDescription'>
                                <Title
                                    text="Don't have an account?"
                                    color={gray800}
                                    fontWeight={400}
                                />
                                <Title
                                    text='Create account'
                                    color={blue300}
                                    fontWeight={400}
                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={() => history.push(signUpUrl)}
                                />
                            </div>
                        )
                    }
                </div>
            </form>
        </>

    )
}

export default LoginComponent;