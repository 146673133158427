import React from 'react';
import './CustomButton.scss'
import Title from '../Title/Title';
import {
    blue300,
    blue500,
    gray010,
} from '../assets/colors/colors';
import { Copy } from '../assets/svg/icons';
import { ClickAwayListener, makeStyles, Tooltip } from '@material-ui/core';

const useStyle = makeStyles(() => ({
    tooltip: {
        margin: '5px'
    }
}))

const CopyLinkButton = ({ message }) => {

    const clickHandler = () => {
        if (!!message) {
            setOpen(true);
            window.navigator.clipboard.writeText(message)
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const classes = useStyle();

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                open={open}
                onClose={handleTooltipClose}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Link copied"
                placement='top'
                classes={classes}
                onClick={clickHandler}
                arrow
            >
                <div className='copyLink' >
                    <div
                        style={{
                            display: 'flex',
                            height: '40px',
                            alignItems: 'center',
                            paddingRight: 0,
                            border: "1px solid",
                            borderRadius: "10px",
                            background: gray010,
                            borderColor: blue300,
                            width: '100%',
                            padding: '10px 20px',
                            justifyContent: 'center',
                            whiteSpace: 'nowrap'
                        }}
                    >

                        <Title
                            text='Copy Message to the Clipboard'
                            fontWeight={400}
                            color={blue500}
                            style={{ marginRight: '10px' }}
                        />
                        <div style={{
                            width: '16px',
                            height: '14px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Copy />
                        </div>
                    </div>
                </div>
            </Tooltip>
        </ClickAwayListener>
    );
}

export default CopyLinkButton;