import React from "react";
import Title from "../../../../uiKit/Title/Title";
import { blue300 } from "../../../../uiKit/assets/colors/colors";
import { useHistory } from "react-router-dom";

const NoDonations = ({ campaignId }) => {

    const history = useHistory();

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Title
                text="This campaign haven't received any donations yet."
                fontSize={16}
                lineHeight={24}
                fontWeight={400}
            />
            <Title
                text='Donate to campaign'
                fontSize={16}
                lineHeight={24}
                fontWeight={400}
                color={blue300}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/campaigns/${campaignId}/donate`)}
            />
        </div>
    )
}

export default NoDonations;