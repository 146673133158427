import React from 'react';

import { Avatar, TableCell, TableRow } from '@material-ui/core';
import { blue300, gray800 } from '../../../../uiKit/assets/colors/colors';
import Title from '../../../../uiKit/Title/Title';
import { useStyle } from '../../../../uiKit/CustomTable/customTable';

import LeaveCommunityCell from './leaveCommunity';
import { useDispatch } from 'react-redux';
import { clearCommunity, setCommunityId } from '../../../../redux/state/communitySlice';
import { clearCampaigns, clearDrafts, clearSuggestions } from '../../../../redux/state/campaignSlice';
import { clearAdmins } from '../../../../redux/state/memberSlice';
import { useHistory } from 'react-router-dom';
import Approve from '../../../../uiKit/Verify/approve';
import { clearNotifications } from '../../../../redux/state/notificationSlice';

const TableRowRenderer = ({ items }) => {

    const cellClasses = useStyle();
    const dispatch = useDispatch();
    const history = useHistory();

    const onCommunityClickHandler = (item) => {
        if (item.isConfirmed) {
            const communityId = item.id;

            dispatch(clearCommunity());
            dispatch(clearCampaigns());
            dispatch(clearAdmins());
            dispatch(clearDrafts());
            dispatch(clearSuggestions());
            dispatch(clearNotifications());
            dispatch(setCommunityId({ communityId }));
            window.localStorage.setItem('communityId', communityId);
            history.push('/campaigns')
        };
    }

    return (
        <>
            {
                items.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell classes={cellClasses}>
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() => onCommunityClickHandler(item)}
                            >
                                <Avatar
                                    src={item.images.mobile}
                                    alt=''
                                    style={{
                                        borderRadius: '50%',
                                        fontSize: '8px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        width: '24px',
                                        height: '24px',
                                    }} />
                                <Title
                                    text={item.name}
                                    color={item.isConfirmed ? blue300 : gray800}
                                    style={{
                                        marginLeft: '20px',
                                        marginRight: '8px',
                                        cursor: 'pointer'
                                    }}
                                />
                            </div>
                        </TableCell>
                        <TableCell classes={cellClasses} style={{ minWidth: '160px' }}>
                            <Approve status={item.isConfirmed} />
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            <Title
                                text={!!item.role ? 'Admin' : 'Member'}
                                fontWeight={400}
                                color={gray800}
                            />
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            <Title
                                text={item.numberOfMembers}
                                fontWeight={400}
                                color={gray800}
                            />
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            <Title
                                text={item.location}
                                fontWeight={400}
                                color={gray800}
                                lineHeight={14}
                                fontSize={12}
                                style={{ marginRight: '16px' }}
                            />
                        </TableCell>
                        <TableCell classes={cellClasses}>
                            <LeaveCommunityCell item={item} />
                        </TableCell>
                    </TableRow>
                ))
            }
        </>
    )
}

export default TableRowRenderer;