import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';

import './findCommunity.scss';
import { Magnificer } from "../../../uiKit/assets/svg/icons";
import CustomInput from "../../../uiKit/Input/CustomInput";
import { Finder } from "../../../uiKit/assets/svg/find";
import CommunityCard from "../../../components/CommunityCard/communityCard";
import Title from "../../../uiKit/Title/Title";
import Loading from "../../../uiKit/Loading/loading";

import {
    getApprovedCommunities,
    resetApprovedCommunities
} from "../../../redux/state/communitySlice";

const FindCommunity = () => {

    const dispatch = useDispatch();
    const history = useHistory()

    const { pathname, search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);

    const communities = useSelector(state => state.community.approvedCommunities, shallowEqual);
    const isLoading = useSelector(state => state.community.isLoading.getApprovedCommunities, shallowEqual);
    const isLoadingJoin = useSelector(state => state.member.isLoading.addMember, shallowEqual);

    useEffect(() => {
        const searchString = query.get('searchString');
        const pageSize = query.get('pageSize') ?? 3;

        if (!query.get('pageSize') && !!searchString) {
            query.set('pageSize', 3);
            history.replace({
                pathname,
                search: query.toString()
            })
        }

        if (!!searchString) {
            dispatch(getApprovedCommunities({
                page: 1,
                pageSize,
                searchString,
            }))
        } else {
            dispatch(resetApprovedCommunities());
        }
    }, [query, dispatch, pathname, history])

    const searchCommunityHandler = useCallback((e) => {
        const value = e.target.value;

        query.set('searchString', value);
        history.replace({
            pathname,
            search: query.toString()
        })

    }, [query, history, pathname])

    const nextDataHandler = useCallback(() => {
        const pageSize = parseInt(query.get('pageSize'));

        query.set('pageSize', pageSize + 3)
        history.replace({
            pathname,
            search: query.toString()
        })
    }, [query, history, pathname])

    return (
        <div className="findCommunityWrapper">
            <div className="findCommunityContent" >
                <Title
                    text="My Communities"
                    fontSize={24}
                    lineHeight={32}
                    className="findCommunityTitleMobile"
                />
                <div className="mobileSearchCommunity">
                    <CustomInput
                        placeholder='Search community'
                        StartAdornment={Magnificer}
                        onChange={searchCommunityHandler}
                        value={query.get('searchString')}
                    />
                </div>
                <div className="communityCards">
                    {
                        !query.get('searchString') ? (
                            <div className='find'>
                                <Finder />
                            </div>
                        ) : (
                            <InfiniteScroll
                                dataLength={communities.numberOfItems}
                                next={nextDataHandler}
                                hasMore={communities.hasNextPage}
                                height={450}
                            >
                                {
                                    !!communities.items?.length && communities.items.map((community, index) => (
                                        <CommunityCard
                                            key={index}
                                            community={community}
                                        />
                                    ))
                                }
                            </InfiniteScroll>
                        )
                    }
                    {
                        (isLoading || isLoadingJoin) && <Loading />
                    }
                </div>
            </div>
        </div>

    )
}

export default FindCommunity;