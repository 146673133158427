import React from "react";
import Header from "../../../components/Header/header";
import { OUR_VALUES } from "../../../helpers/constants";
import Title from "../../../uiKit/Title/Title";

const OurValues = () => {
    const token = window.localStorage.getItem('token');

    return (
        <>
            {!!token && <Header title='Our Values' clickable={false} />}
            <div className={!!token ? "ourValuesUser" : 'ourValues'}>
                {
                    !!OUR_VALUES && OUR_VALUES.map(item => (<div >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <div style={{ marginRight: '8px' }}>
                                <item.Icon />
                            </div>
                            <Title
                                text={item.title}
                                fontSize={20}
                                lineHeight={32}
                            />
                        </div>
                        <Title
                            text={item.description}
                            overflowText={false}
                            fontSize={16}
                            fontWeight={400}
                        />
                    </div>))
                }
            </div>
        </>

    )
}

export default OurValues;