import React, { useCallback, useEffect, useState } from "react";
import './ourBalance.scss';
import OurBalanceImage from '../../uiKit/assets/svg/ourBalance.svg';

import Header from "../../components/Header/header";
import Title from "../../uiKit/Title/Title";
import Balance from "../../components/Balance/balance";
import CustomButton from "../../uiKit/Button/CustomButton";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ReactGA from 'react-ga4';

import { OUR_BALANCE_DESCRIPTION, OUR_BALANCE_DESCRIPTION_NOTE } from "../../helpers/constants";
import RecentTransfersTable from "./RecentTransfers/recentTransfersTable";
import RecentDonationsTable from "./RecentDonations/recentDonationsTable"
import BalanceAccordion from "./balanceAccordion";

const OurBalance = () => {

    const [isActive, setIsActive] = useState(true);

    const history = useHistory();

    const communityBalance = useSelector(state => state.community.community.ourBalance, shallowEqual);

    const communityName = useSelector(state => state.community.community.name, shallowEqual);

    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const donateHandler = useCallback(() => {
        ReactGA.event('donate_to_our_balance_start')
        history.push(`/our-balance/donate`);
    }, [history])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/our-balance` })
    }, [])

    return (
        <div>
            <Header title='community bank' clickable={false} />
            <div className='communityInfoHeaderWrapper'>
                <div className='campaignMobileHeader'>
                    <img alt='' src={OurBalanceImage} className='photoWrapperOurBalance' />
                    <Title
                        text='Our Balance'
                        fontSize={screenWidth < 1200 ? 24 : 32}
                        lineHeight={screenWidth < 1200 ? 32 : 40}
                        className="ourBalanceTitle"
                    />
                </div>
                <Balance balance={`$ ${communityBalance.toLocaleString()}`} title={communityName} />
                <div className='ourBalanceDescription'>
                    <Title
                        text={OUR_BALANCE_DESCRIPTION}
                        fontWeight={400}
                    />
                    <Title
                        text={OUR_BALANCE_DESCRIPTION_NOTE}
                        fontWeight={400}
                    />
                </div>
                <CustomButton
                    title='Donate to Our Balance'
                    classNames='maxContentButton'
                    onClick={donateHandler}
                />
            </div>
            <div className='campaignsWrapper '>
                {
                    !!isAdmin ? (
                        <div className='campaignButton'>
                            <div className={`${isActive ? 'activeCampaigns' : 'deactiveCampaigns'}`}
                                onClick={() => {
                                    setIsActive(true);
                                    history.push('/our-balance')
                                }}
                            >
                                Recent Donations
                            </div>
                            <div className={`${!isActive ? 'activeCampaigns' : 'deactiveCampaigns'}`}
                                onClick={() => {
                                    setIsActive(false)
                                    history.push('/our-balance')
                                }}
                            >
                                Recent Transfers
                            </div>
                        </div>
                    ) : (
                        <div className='campaignButton'>
                            <div className='userActiveCampaigns'>
                                Recent Donations
                            </div>
                        </div>
                    )
                }
                {
                    !!isAdmin ? (
                        isActive ? <RecentDonationsTable isAdmin={true} />
                            : (
                                <RecentTransfersTable />
                            )
                    ) : (
                        <RecentDonationsTable isAdmin={false} />
                    )

                }
                {screenWidth < 900 && <BalanceAccordion isAdmin={!!isAdmin} />}
            </div>
        </div>
    )
}

export default OurBalance;