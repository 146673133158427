import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Verification from "../../../components/Verification/verification";
import {
    VALIDATION_TEXT_CHANGE_EMAIL,
    VALIDATION_TEXT_CHANGE_EMAIL_SECOND
} from "../../../helpers/constants";
import { sendChangeEmail } from "../../../redux/state/accountSlice";

const VerificationEmail = () => {

    const { state } = useLocation();
    const history = useHistory();

    const isLoading = useSelector(state => state.account.isLoading.sendChangeEmail, shallowEqual);

    if (!state) {
        history.push('/settings/login-security');
    }

    return (
        <Verification
            resendLink={sendChangeEmail}
            isLoading={isLoading}
            data={state}
            description={VALIDATION_TEXT_CHANGE_EMAIL}
            secondDescription={VALIDATION_TEXT_CHANGE_EMAIL_SECOND}
            resendTime={300}
        />
    )
}

export default VerificationEmail;