import React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { login } from "../../redux/state/accountSlice";
import LoginComponent from "./loginComponent";

const LoginSubscription = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const token = window.localStorage.getItem('token');

    if (!!token) {
        history.push('/settings/subscriptions');
    }

    const loginHandler = ({ email, password, remember }) => {
        dispatch(login({ email, password, remember }))
            .then(unwrapResult)
            .then(() => history.push('/settings/subscriptions'))
            .catch(({ title }) => console.error(title))
    }

    return <LoginComponent onLogin={loginHandler} />
}

export default LoginSubscription;