import { combineReducers } from '@reduxjs/toolkit';
import CommunityReducer from './state/communitySlice';
import MemberReducer from './state/memberSlice';
import CampaignReducer from './state/campaignSlice';
import PaymentReducer from './state/paymentSlice';
import AccountReducer from './state/accountSlice';
import NotificationReducer from './state/notificationSlice';
import CommonReducer from './state/commonSlice';

const rootReducer = combineReducers({
    community: CommunityReducer,
    member: MemberReducer,
    campaign: CampaignReducer,
    payment: PaymentReducer,
    account: AccountReducer,
    notification: NotificationReducer,
    common: CommonReducer
});

export default rootReducer;