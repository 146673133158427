import React, { useEffect, useState } from "react";
import './members.scss';

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getTypes, setImageURL, updateCommunity } from "../../redux/state/communitySlice";

import { Users } from "../../uiKit/assets/svg/icons";
import { InputFileCorner } from "../../uiKit/Input/InputFile";
import CustomButton from "../../uiKit/Button/CustomButton";
import Title from "../../uiKit/Title/Title";
import MobileList from "./mobileList";
import MemberSearchInput from "./memberSearchInput";
import MembersTable from "./membersTable";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AnimatedLoading from "../../uiKit/Loading/animatedLoading";

const Members = () => {

    const types = useSelector(state => state.community.types, shallowEqual);

    const isLoading = useSelector(state => state.community.isLoading.common, shallowEqual);

    const community = useSelector(state => state.community.community, shallowEqual);
    const membersCount = useSelector(state => state.community.community.numberOfMembers, shallowEqual);

    const imageURL = useSelector(state => state.community.communityImageURL, shallowEqual);

    const [file, setFile] = useState(null);
    const [isDefaultImage, setIsDefaultImage] = useState(false);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!types) {
            dispatch(getTypes())
        }

        if (!!types) {
            const isDefault = types.some(item => item.desktopImage === imageURL || item.mobileImage === imageURL);
            setIsDefaultImage(isDefault);
        }
    }, [types, imageURL, dispatch])

    useEffect(() => {
        if (
            !!community.communityTypeId &&
            !!community.id &&
            !!file
        ) {
            dispatch(updateCommunity({
                communityTypeId: community.communityTypeId,
                communityId: community.id,
                name: community.name,
                file
            }))
                .then(unwrapResult)
                .then((response) => {
                    toast.info('Uploaded');
                    if (screenWidth > 900) {
                        dispatch(setImageURL(response.community.images.desktop))
                    } else {
                        dispatch(setImageURL(response.community.images.mobile))
                    }
                    setTimeout(() => history.push('/members'), [1000]);
                })
                .catch((error) => toast.info(error.message))

        }
        // eslint-disable-next-line
    }, [file, community, history, dispatch])

    const communityImageDeleteHandler = () => {
        if (
            !!community.communityTypeId &&
            !!community.id
        ) {
            dispatch(updateCommunity({
                communityTypeId: community.communityTypeId,
                communityId: community.id,
                name: community.name,
            }))
                .then(unwrapResult)
                .then((response) => {
                    toast.info('Deleted')
                    if (screenWidth > 900) {
                        dispatch(setImageURL(response.community.images.desktop))
                    } else {
                        dispatch(setImageURL(response.community.images.mobile))
                    }
                    setTimeout(() => history.push('/members'), [1000]);
                })
                .catch((error) => toast.info(error.message))
        }
    }

    return (
        <div className='membersWrapper'>
            <div className='communityInfoWrapper' style={{position: 'relative'}}>
                <div
                    className="photoWrapper"
                    style={{ backgroundImage: `url(${imageURL})` }}
                >
                    <InputFileCorner
                        uploaded={!isDefaultImage}
                        setFile={setFile}
                        deleteHandler={communityImageDeleteHandler}
                    />
                    
                </div>
                <div style={{ marginTop: '16px' }}>
                    <Title
                        text={community.name}
                        fontSize={32}
                        lineHeight={40}
                        fontWeight={600}
                    />
                    <div className='flexMargin'>
                        <div className='displayIcon'><Users /></div>
                        <Title
                            text={`${membersCount} members`}
                            fontWeight={400}
                        />
                    </div>
                </div>
                <AnimatedLoading loading={isLoading} full={false} />
            </div>
            <div className='membersList'>
                <MemberSearchInput totalMembers={membersCount} />
                {
                    screenWidth < 1200 && (
                        <div className='memberButton'>
                            <CustomButton
                                title='Add Member'
                                onClick={() => history.push('/members/add-members')}
                                style={{ marginTop: '16px' }}
                            />
                        </div>
                    )
                }
                {
                    screenWidth <= 1200 && (
                        <div className='listMobile'>
                            <MobileList />
                        </div>
                    )
                }
                {
                    screenWidth >= 1200 && (
                        <div className='memberButton'>
                            <CustomButton
                                title='Add Member'
                                onClick={() => history.push('/members/add-members')}
                            />
                        </div>
                    )
                }
            </div>
            <MembersTable />
        </div>
    )
}

export default Members;