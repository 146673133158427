import React, { useEffect, useState } from "react";

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { clearMember, getMembers } from "../../redux/state/memberSlice";
import CustomTable from "../../uiKit/CustomTable/customTable";
import RowRenderer from "./tableRow";

const MembersTable = () => {

    const dispatch = useDispatch();

    const members = useSelector(state => state.member.members, shallowEqual);
    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const isLoading = useSelector(state => state.member.isLoading.members, shallowEqual);

    const [paginationData, setPaginationData] = useState({
        pageSize: 8,
        page: 1
    });
    useEffect(() => {
        dispatch(clearMember());
    }, [dispatch])

    useEffect(() => {
        if (!!paginationData && !!communityId) {
            dispatch(getMembers({ ...paginationData, communityId }))
        }
    }, [paginationData, communityId, dispatch])

    return (
        <div className='membersTableWrapper'>
            <CustomTable
                RowRenderer={RowRenderer}
                headerArray={[
                    { name: '' },
                    { name: 'Name' },
                    { name: 'Phone' },
                    { name: 'Email' },
                    { name: 'Status' }
                ]}
                data={members}
                url='/members'
                setPaginationData={setPaginationData}
                isLoading={isLoading}
            />
        </div>
    )
}

export default MembersTable;