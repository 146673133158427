import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Title from "../../../uiKit/Title/Title";

import SubMenu from "./SubMenu";

const MenuItem = ({
    Icon,
    title,
    url,
    total,
    hasSubMenu = false,
    subMenu = [],
    onClick = () => { }
}) => {

    const [selected, setSelected] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (history.location.pathname.includes(url)) {
            setSelected(true);
        } else {
            setSelected(false)
        }
    }, [history.location.pathname, url])

    return (
        hasSubMenu ? (
            <SubMenu
                Icon={Icon}
                title={title}
                menu={subMenu}
                selected={selected}
                onClick={() => onClick()}
            />
        ) : (
            <NavLink
                className={`menuItem ${selected ? 'selected' : ''}`}
                to={url}
                onClick={() => onClick()}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='menuIcon'>
                        <Icon />
                    </div>
                    <Title
                        text={title}
                        fontWeight={400}
                        style={{ paddingLeft: '16px' }}
                    />
                </div>
                <Title
                    text={total}
                />
            </NavLink>
        )

    )
}

export default MenuItem;