import React, { useState } from 'react';
import './ProgressBar.scss';
import LinearProgress from '@material-ui/core/LinearProgress';

import Title from '../Title/Title';
import { blue300, gray300, gray800 } from '../assets/colors/colors';
import { Trash } from '../assets/svg/icons';
import { makeStyles } from "@material-ui/core";
import { Unplug } from '../assets/svg/unplug';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { deleteCampaignDraft } from '../../redux/state/campaignSlice';
import { toast } from 'react-toastify';
import CustomDialog from '../CustomDialog/customDialog';
import { useHistory } from 'react-router-dom';
import { clearMember } from '../../redux/state/memberSlice';

const ProgressBarDraft = ({
  title,
  target,
  color,
  onClick,
  communityId,
  campaignId,
  createdBy,
  deleteHandler = deleteCampaignDraft
}) => {

  const useStyles = makeStyles(theme => ({
    root: {
      height: 8,
      borderRadius: 4,
      margin: '12px 0'
    },
    colorPrimary: {
      backgroundColor: gray300,
    },
    bar: {
      borderRadius: 4,
      backgroundColor: color,
    }
  }));

  const [deletedOpen, setDeletedOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();

  const deleteMemberHandler = () => {
    if (!!campaignId && !!communityId) {
      dispatch(deleteHandler({ communityId, campaignId }))
        .then(unwrapResult)
        .then(() => setDeletedOpen(false))
        .catch(({ title }) => toast.info(title))
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        className='progressWrapper'
        onClick={onClick}
      >
        <div>
          <Title
            text={title}
            fontSize={16}
          />
          {
            !!createdBy && (
              <div style={{
                position: 'relative',
                fontFamily: 'Poppins',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '14px',
                color: gray800,
                marginTop: '8px'
              }}>
                By
              </div>
            )
          }
        </div>
        <LinearProgress classes={classes} variant="determinate" value={0} />
        <Title
          text={` $${target.toLocaleString()}`}
          fontWeight={400}
          style={{
            marginLeft: '4px'
          }}
        />
      </div>
      <div
        style={{ position: 'absolute', right: '12px', top: '14px', cursor: 'pointer' }}
        onClick={() => setDeletedOpen(true)}>
        <Trash />
      </div>
      {
        !!createdBy && (
          <span
            style={{
              position: 'absolute',
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              color: blue300,
              cursor: 'pointer',
              marginLeft: '4px',
              top: '40px',
              left: '26px'
            }}
            onClick={() => {
              dispatch(clearMember());
              history.push(`/members/member/${createdBy.id}`)
            }}
          >
            {`${createdBy.firstName} ${createdBy.lastName}`}
          </span>
        )
      }
      <CustomDialog
        confirmOpen={deletedOpen}
        onClose={() => setDeletedOpen(false)}
        Image={Unplug}
        mainButtonTitle='Confirm'
        mainButtonAction={deleteMemberHandler}
        secondButton={true}
        title='You are going to delete draft campaign.'
        secondDescription='Confirm your action, please'
      />
    </div>
  );
}

export default ProgressBarDraft;