import React from "react";
import Title from "../../../../uiKit/Title/Title";
import PolicyImage from '../../../../uiKit/assets/svg/policy.svg';
import {
    eighthParagraph,
    fifthParagraph,
    fifthParagraphOne,
    firstParagraph,
    fourthParagraph,
    ninthParagraph,
    policyIntro,
    secondParagraph,
    seventhParagraph,
    sixthParagraph,
    thirdParagraph,
    thirdParagraphOne
} from "../../../../helpers/constants";
import { shallowEqual, useSelector } from "react-redux";

const Policy = () => {

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    return (
        <div className="policyContent">
            <Title
                text="Privacy Policy"
                fontSize={screenWidth > 900 ? 48 : 24}
                lineHeight={screenWidth > 900 ? 56 : 32}
                style={{ marginBottom: '24px' }}
            />
            <Title
                text={policyIntro}
                overflowText={false}
                fontWeight={400}
                fontSize={14}
                lineHeight={24}
                style={{ marginBottom: '24px' }}
            />
            <Title
                text="Date last revised: January 1, 2022"
                fontWeight={400}
                fontSize={14}
                lineHeight={24}
            />
            <Title
                text="We respect your personal data"
                fontWeight={400}
                fontSize={14}
                lineHeight={24}
                style={{ marginBottom: '40px' }}
            />
            <div style={{
                width: '151.26px',
                height: '214.21px',
                marginBottom: '56px'
            }}>
                <img alt='' src={PolicyImage} style={{ height: '100%', width: '100%' }} />
            </div>
            <Title
                text="1. We respect your privacy"
                fontSize={24}
                lineHeight={32}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                {
                    firstParagraph.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="2. Who We Are and How To Contact Us"
                fontSize={24}
                lineHeight={32}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                {
                    secondParagraph.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="3. Types of Personal Information We Collect and How We Collect Them"
                fontSize={24}
                lineHeight={32}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                {
                    thirdParagraph.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="3.1 The Purposes for which We Use Personal Information"
                fontSize={screenWidth > 900 ? 18 : 16}
                lineHeight={screenWidth > 900 ? 24 : 20}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                {
                    thirdParagraphOne.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="3.2 How We Use and Transfer Personal Information"
                fontSize={screenWidth > 900 ? 18 : 16}
                lineHeight={screenWidth > 900 ? 24 : 20}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                {
                    fourthParagraph.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="3.3 Lawful Basis for Processing Personal Information"
                fontSize={screenWidth > 900 ? 18 : 16}
                lineHeight={screenWidth > 900 ? 24 : 20}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                {
                    fifthParagraph.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="3.4 Disclosure of Personal Information to third parties"
                fontSize={screenWidth > 900 ? 18 : 16}
                lineHeight={screenWidth > 900 ? 24 : 20}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                {
                    fifthParagraphOne.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="3.5 Personal Information Protection and Security"
                fontSize={screenWidth > 900 ? 18 : 16}
                lineHeight={screenWidth > 900 ? 24 : 20}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={sixthParagraph.value}
                    fontWeight={400}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
            <Title
                text="3.6 Data Retention Period"
                fontSize={screenWidth > 900 ? 18 : 16}
                lineHeight={screenWidth > 900 ? 24 : 20}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                {
                    seventhParagraph.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="4. Your Individual Rights"
                fontSize={24}
                lineHeight={32}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                {
                    eighthParagraph.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="4.1 Accessing your Personal Information"
                fontSize={screenWidth > 900 ? 18 : 16}
                lineHeight={screenWidth > 900 ? 24 : 20}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                {
                    ninthParagraph.map(item => (
                        <Title
                            key={item.id}
                            text={item.value}
                            fontWeight={400}
                            lineHeight={24}
                            overflowText={false}
                        />
                    ))
                }
            </div>
            <Title
                text="5. Amendments to this Privacy Policy"
                fontSize={24}
                lineHeight={32}
                style={{ marginBottom: '16px' }}
            />
            <div style={{ marginBottom: '40px' }}>
                <Title
                    text={`We may revise this Privacy Policy from time
                            to time. If we make any substantial change to our
                            Privacy Policy, we will notify you by posting a
                            prominent announcement on our website and will
                            post the updated Privacy Policy here. We will bring
                            these changes to your attention where reasonably possible.`}
                    fontWeight={400}
                    lineHeight={24}
                    overflowText={false}
                />
            </div>
        </div>
    )
}

export default Policy;