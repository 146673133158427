import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import ReactGA from 'react-ga4';

import Header from "../../../../components/Header/header";
import { getCompletedCampaign } from "../../../../redux/state/campaignSlice";
import { getMember } from "../../../../redux/state/memberSlice";
import { blue010, blue100, blue300, gray010, gray650, gray800 } from "../../../../uiKit/assets/colors/colors";
import { Donation, SingleUser } from "../../../../uiKit/assets/svg/icons";
import CustomButton from "../../../../uiKit/Button/CustomButton";
import ProgressBar from "../../../../uiKit/ProgressBar/ProgressBar";
import Title from "../../../../uiKit/Title/Title";
import RecentDonationsTable from "../RecentDonations/recentDonationsTable";

const CompletedCampaign = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const { campaignId } = useParams();
    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    const campaign = useSelector(state => state.campaign.completedCampaign, shallowEqual);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        if (!!communityId && !!campaignId) {
            dispatch(getCompletedCampaign({ communityId, campaignId }))
        }
    }, [communityId, campaignId, dispatch])

    const onRecipientClick = () => {
        if (!!campaign.recipient && !!campaign.recipient.id) {
            dispatch(getMember({ communityId, userId: campaign.recipient.id }))
                .then(unwrapResult)
                .then(() => history.push(`/members/member/${campaign.recipient.id}`))
                .catch(() => toast.info('User is not a member of this community'))
        }
    }

    const repeatCampaignHandler = useCallback(() => {
        ReactGA.event('repeat_campaign');
        history.push(`/campaigns/campaign/repeat/${campaignId}`)
    }, [history, campaignId])

    return (
        <>
            <Header title='All campaigns' onClick={() => history.push('/campaigns')} />
            <div className='communityInfoHeaderWrapper'>
                <img
                    className='photoWrapperCampaign'
                    alt=''
                    src={screenWidth < 900 ? campaign.images.mobile : campaign.images.desktop}
                />
                <Title
                    text={campaign.name}
                    fontSize={screenWidth < 1200 ? 24 : 32}
                    lineHeight={screenWidth < 1200 ? 32 : 40}
                    className="ourBalanceTitle"
                />
                <div className='campaignProgressBar'>
                    <div className='progressBar'>
                        <ProgressBar
                            campaignId={campaignId}
                            communityId={communityId}
                            target={campaign.amount}
                            color={blue100}
                            style={{
                                boxShadow: 'none',
                                padding: 0,
                                backgroundColor: screenWidth > 900 ? gray010 : blue010
                            }}
                        />
                    </div>
                </div>
                <div className='recipientCompleted'>
                    <div className='icon'><SingleUser /></div>
                    <div className='word'>
                        <Title text='Recipient:' fontSize={16} color={gray650} />
                    </div>
                    <Title
                        text={campaign?.recipient?.fullName}
                        fontSize={16}
                        color={blue300}
                        style={{ marginLeft: '16px', cursor: 'pointer' }}
                        onClick={onRecipientClick}
                    />
                </div>
                <div className='recipient'>
                    <div className='icon'><Donation /></div>
                    <div className='word'>
                        <Title text='Transaction time:' fontSize={16} color={gray650} />
                    </div>
                    <Title
                        text={
                            !!campaign.transactionTime ? (
                                `${moment(campaign.transactionTime).format('MM/DD/YY')} - ${moment(campaign.transactionTime).format('h:mm A')}`
                            ) : (
                                '-'
                            )
                        }
                        fontSize={16}
                        color={gray800}
                        style={{ marginLeft: '16px' }}
                    />
                </div>
                {
                    !!campaign.description && (
                        <Title
                            text={campaign.description}
                            style={{ marginBottom: '24px' }}
                            fontWeight={400}
                        />
                    )
                }
                <div className='transferButtons'>
                    <CustomButton
                        title='Repeat campaign'
                        onClick={repeatCampaignHandler}
                    />
                </div>
            </div>
            <div className='campaignsWrapper'>
                <Title
                    text='Recent Donations'
                    fontSize={24}
                    lineHeight={32}
                    className="recentHeader"
                />
                <RecentDonationsTable communityId={communityId} />
            </div>
        </>
    )
}

export default CompletedCampaign;