import React from "react";
import { useHistory } from "react-router-dom";
import Feedback from "../../components/Feedback/feedback";
import { Finder } from "../../uiKit/assets/svg/find";

const NoData = () => {

    const history = useHistory();

    return (
        <div className="page">
            <Feedback
                Image={Finder}
                title='Oops!'
                secondDescription="You don't have any campaign drafts yet.  Create new to start."
                button={{
                    feedbackAction: () => history.push('/campaigns/create-campaign'),
                    feedbackTitle: 'Create new'
                }}
            />
        </div>
    )
}

export default NoData;