import React from "react";

import { blue010 } from "../../../uiKit/assets/colors/colors";
import Title from "../../../uiKit/Title/Title";
import Header from "../../../components/Header/header";
import { YOUTUBE_IDS } from "../../../helpers/constants";

const Tutorials = () => {

    const token = window.localStorage.getItem('token');

    return (
        <>
            {!!token && <Header title='Tutorials' clickable={false} />}
            <div className={!!token ? "communityInfoHeaderWrapper" : 'shortsWrapper'}>
                <Title
                    text="How to:"
                    fontSize={31}
                    lineHeight={39}
                    style={{ marginBottom: '24px' }}
                />
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}>
                    {
                        YOUTUBE_IDS.map((item, index) => (
                            <div key={index} style={{ width: '300px', marginRight: '24px' }} >
                                <Title
                                    text={item.title}
                                    fontSize={21}
                                    lineHeight={27}
                                    style={{
                                        marginBottom: '24px',
                                        height: '81px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-around'
                                    }}
                                />
                                <iframe
                                    title={item.title}
                                    style={{
                                        backgroundColor: blue010,
                                        border: 'none',
                                        marginBottom: '40px',
                                        height: '534px',
                                        width: '300px'
                                    }}
                                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                    src={`https://youtube.com/embed/${item.id}?autoplay=0`}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </>

    )
}

export default Tutorials;