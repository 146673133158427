import React, { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { blue500 } from '../../../../uiKit/assets/colors/colors';
import Title from '../../../../uiKit/Title/Title';
import { Confirm } from '../../../../uiKit/assets/svg/confirm';

import { getAdmins } from '../../../../redux/state/memberSlice';
import { leaveCommunity } from '../../../../redux/state/communitySlice';
import { toast } from 'react-toastify';
import CustomDialog from '../../../../uiKit/CustomDialog/customDialog';

const LeaveCommunityCell = ({ item }) => {

    const isLoading = useSelector(state => state.community.isLoading.leaveCommunity, shallowEqual);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmOpenAdmin, setConfirmOpenAdmin] = useState(false);

    const dispatch = useDispatch();

    const leaveCommunityHandler = () => {
        dispatch(getAdmins(item.id))
            .then(unwrapResult)
            .then(response => {
                const { communityAdmins } = response;
                if (communityAdmins.length === 1 && !!item.role) {
                    setConfirmOpenAdmin(true);
                } else {
                    setConfirmOpen(true)
                }
            })
            .catch(({ title }) => toast.info(title))
    }

    const confirmHandler = useCallback(() => {
        dispatch(leaveCommunity(item.id))
            .then(unwrapResult)
            .then(() => setConfirmOpen(false))
            .catch(({ title }) => toast.info(`Could not leave community: ${title}`))
    }, [item, dispatch])

    return (
        <>
            <Title
                text='Leave community'
                fontWeight={400}
                color={blue500}
                lineHeight={14}
                fontSize={12}
                style={{ cursor: 'pointer' }}
                onClick={leaveCommunityHandler}
            />
            <CustomDialog
                confirmOpen={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                Image={Confirm}
                mainButtonTitle='Confirm'
                mainButtonAction={confirmHandler}
                isLoading={isLoading}
                secondButton={true}
                title={`You're going to leave ${item.name}`}
                secondDescription='Please confirm'
            />
            <CustomDialog
                confirmOpen={confirmOpenAdmin}
                onClose={() => setConfirmOpenAdmin(false)}
                Image={Confirm}
                mainButtonTitle='Ok'
                mainButtonAction={() => setConfirmOpenAdmin(false)}
                title='Before leaving the community, you need to assign an admin'
            />
        </>
    )
}

export default LeaveCommunityCell;