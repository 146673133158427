import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactGA from 'react-ga4';

import { validateEmail } from "../../../helpers/validation";
import { contactUs } from "../../../redux/state/notificationSlice";
import { blue500, gray800 } from "../../../uiKit/assets/colors/colors";
import Background from '../../../uiKit/assets/png/contactUsScreen.png';
import CustomButton from "../../../uiKit/Button/CustomButton";
import CustomInput from "../../../uiKit/Input/CustomInput";
import Title from "../../../uiKit/Title/Title";

const ContactUs = ({ setRef }) => {

    const myRef = useRef(null);

    const dispatch = useDispatch();

    const [userName, setUserName] = useState('');
    const [userNameError, setUserNameError] = useState('');

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);

    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState(null);

    const isLoading = useSelector(state => state.notification.isLoading.contactUs, shallowEqual);

    useEffect(() => {
        if (!!myRef && !!setRef) {
            setRef(prev => ({ ...prev, contact: myRef }))
        }
    }, [myRef, setRef])

    const contactUsHandler = () => {
        if (!userName) setUserNameError('Required')
        if (!email) setEmailError('Required')
        if (!message) setMessageError('Required')

        ReactGA.event('send_message_landing');
        if (!!email && !!userName && !!message) {
            const data = {
                email,
                message,
                userName
            }
            dispatch(contactUs(data))
                .then(unwrapResult)
                .then(() => {
                    toast.info('Message was sent');
                    setEmail('');
                    setUserName('');
                    setMessage('');
                })
                .catch(({ message, title }) => toast.info(message ?? title))
        }
    }

    const userNameHandler = (e) => {
        const value = e.target.value;
        setUserName(value);
        if (!!userName) {
            setUserNameError('')
        } else {
            setUserNameError('Required')
        }
    }

    const emailChangeHandler = (e) => {
        const value = e.target.value;
        setEmail(value);
        if (validateEmail(value)) {
            setEmailError('')
        } else {
            if (!value) {
                setEmailError('Required')
            } else {
                setEmailError('Invalid email address')
            }
        }
    }

    const messageChangeHandler = (e) => {
        const value = e.target.value;
        setMessage(value);
        if (!!value) {
            setMessageError('')
        } else {
            setMessageError('Required')
        }
    }

    return (
        <div className="contactUs" ref={myRef}>
            <div className="lightBlueCircle" />
            <div className="littleYellowCircle" />
            <div className="bigYellowCircle" />
            <div className="bigBlueCircle" />

            <div className="questionsMobile">
                <Title
                    text="CONTACT US"
                    color={blue500}
                    fontSize={18}
                    lineHeight={36}
                    fontWeight={700}
                    style={{ marginBottom: '24px', cursor: 'pointer' }}
                    onClick={() => window.scrollTo(0, 0)}
                />
                <Title
                    text="Comments?"
                    fontSize={48}
                    lineHeight={67}
                />
                <Title
                    text="Questions?"
                    fontSize={48}
                    lineHeight={67}
                    style={{ marginBottom: '32px' }}
                />
            </div>
            <div className="contactUsImage">
                <img alt='' src={Background} style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="contactUsDesktop">
                <img src={Background} alt='' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="sendMessageForm" >
                <div className="questionsDesktop">
                    <Title
                        text="CONTACT US"
                        color={blue500}
                        fontSize={18}
                        lineHeight={36}
                        fontWeight={700}
                        style={{ marginBottom: '24px', cursor: 'pointer', width: 'fit-content' }}
                        onClick={() => window.scrollTo(0, 0)}
                    />
                    <Title
                        text="Comments?"
                        fontSize={60}
                        lineHeight={67}
                    />
                    <Title
                        text="Questions?"
                        fontSize={60}
                        lineHeight={67}
                        style={{ marginBottom: '32px' }}
                    />
                </div>
                <Title
                    text="Get in touch with us"
                    fontSize={24}
                    lineHeight={32}
                    color={gray800}
                    fontWeight={400}
                    style={{ marginBottom: '32px' }}
                />
                <CustomInput
                    label='Name'
                    onChange={userNameHandler}
                    value={userName}
                    error={!!userNameError}
                    errorMessage={userNameError}
                    maxLength={60}
                />
                <CustomInput
                    label='Email'
                    value={email}
                    onChange={emailChangeHandler}
                    error={!!emailError}
                    errorMessage={emailError}
                    maxLength={60}
                />
                <CustomInput
                    label='Message'
                    multiline={true}
                    rows={7}
                    placeholder='Enter your message'
                    maxLength={250}
                    value={message}
                    onChange={messageChangeHandler}
                    error={!!messageError}
                    errorMessage={messageError}
                />
                <CustomButton
                    title='Send message'
                    style={{ width: 'max-content' }}
                    onClick={contactUsHandler}
                    disabled={!!emailError || !!messageError || !!userNameError || isLoading}
                    loading={isLoading}
                />
            </div>
        </div>
    )
}

export default ContactUs;