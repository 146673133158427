import { Dialog, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Feedback from '../../../components/Feedback/feedback.js';
import {
    validateEmail,
    validateName,
} from '../../../helpers/validation.js';
import { refreshToken } from '../../../redux/state/accountSlice.js';
import {
    getReceivingMethod,
    receivePaperCheck,
    receivePayPal
} from '../../../redux/state/paymentSlice.js';
import { blue300 } from '../../../uiKit/assets/colors/colors.js';
import { Celebration } from '../../../uiKit/assets/svg/celebration.js';
import {
    House,
    Location,
    Mail,
    MobilePhone,
    RadioIcon,
    RadioIconChecked,
    SingleUser
} from '../../../uiKit/assets/svg/icons.js';
import CustomButton from '../../../uiKit/Button/CustomButton.js';
import CustomInput from '../../../uiKit/Input/CustomInput.js';
import CustomPhoneInput from '../../../uiKit/Input/CustomPhoneInput.js';
import Title from '../../../uiKit/Title/Title.js';
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import Loading from "../../../uiKit/Loading/loading";
import { setCommunityId } from '../../../redux/state/communitySlice.js';

const useStyles = makeStyles(() => ({
    colorPrimary: {
        '&.Mui-checked': {
            color: blue300,
        }
    },
    label: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
        fontFamily: 'Poppins'
    }
}))

const useDialogStyles = makeStyles(() => ({
    paper: {
        '&.MuiPaper-rounded': {
            borderRadius: '12px'
        }
    }
}))

const RecievingMethod = () => {

    const { search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const dialogClasses = useDialogStyles();
    const radioClasses = useStyles();

    const communityId = new URLSearchParams(search).get('communityId');
    const campaignId = new URLSearchParams(search).get('campaignId');

    if (!communityId && !campaignId) {
        history.push('/');
    }

    const isLoading = useSelector(state => state.payment.isLoading.getReceivingMethod, shallowEqual);
    const isLoadingPayPal = useSelector(state => state.payment.isLoading.receivePayPal, shallowEqual);
    const isLoadingPaperCheck = useSelector(state => state.payment.isLoading.receivePaperCheck, shallowEqual);

    const [methodValue, setMethodValue] = useState(0);

    const [confirm, setConfirm] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [email, setEmail] = useState(null);
    const [confirmEmail, setConfirmEmail] = useState(null);

    const [emailError, setEmailError] = useState('required');
    const [confirmEmailError, setConfirmEmailError] = useState('required');

    const [paperCheckInfo, setPaperCheckInfo] = useState({
        name: null,
        phoneNumber: null,
        zipCode: null,
        addressLine: null,
        city: null,
        state: null,
        country: null
    })

    const [paperCheckError, setPaperCheckError] = useState({
        name: 'required',
        phoneNumber: 'required',
        zipCode: 'required',
        addressLine: 'required',
        city: 'required',
        state: 'required',
        country: 'required'
    })

    const [autocomplete, setAutocomplete] = useState(null);

    useEffect(() => {
        if (!!communityId) {
            dispatch(getReceivingMethod({ communityId, campaignId }))
                .then(unwrapResult)
                .then(() => {
                    dispatch(setCommunityId({ communityId }));
                    window.localStorage.setItem('communityId', communityId);
                    history.push('/method-already-chosen');
                })
                .catch((error) => {
                    if (error.status !== 400)
                        toast.info(error.message);
                });
        }
    }, [dispatch, communityId, campaignId, history]);

    useEffect(() => {
        const hasError = Object.values(paperCheckError).some(error => !!error);
        if ((hasError && !!methodValue) || (emailError && !methodValue) || (confirmEmailError && !methodValue)) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [paperCheckError, confirmEmailError, emailError, methodValue])

    useEffect(() => {
        const storedToken = window.localStorage.getItem('token');
        if (!!storedToken) {
            const parsedtoken = JSON.parse(storedToken);
            const interval = setInterval(() => {
                const token = window.localStorage.getItem('refresh-token');
                dispatch(refreshToken(token))
            }, [(parsedtoken.expires_in - 10) * 1000])

            return () => clearInterval(interval)
        }
    }, [dispatch])

    const onEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        if (validateEmail(value)) {
            setEmailError('')
            if (value === confirmEmail) {
                setConfirmEmailError('')
            } else {
                setConfirmEmailError('Email does not match')
            }
        } else {
            if (!value) {
                setEmailError('Required');
            } else {
                setEmailError('Invalid email address');
            }
        }
    }

    const onConfirmEmailChange = (e) => {
        const value = e.target.value;
        setConfirmEmail(value);
        if (validateEmail(value)) {
            if (value === email) {
                setConfirmEmailError('')
            } else {
                setConfirmEmailError('Email does not match')
            }
        } else {
            if (!value) {
                setConfirmEmailError('Required');
            } else {
                setConfirmEmailError('Invalid email address');
            }
        }
    }

    const onNameChange = (e) => {
        const name = e.target.value;
        setPaperCheckInfo(prev => ({ ...prev, name }))
        if (validateName(name)) {
            setPaperCheckError(prev => ({ ...prev, name: '' }))
        } else {
            setPaperCheckError(prev => ({ ...prev, name: 'Invalid name' }))

        }
    }

    const onPhoneNumberChange = (phoneNumber) => {
        setPaperCheckInfo(prev => ({ ...prev, phoneNumber }))
        if (!!phoneNumber && isPossiblePhoneNumber(phoneNumber)) {
            setPaperCheckError(prev => ({ ...prev, phoneNumber: '' }))
        } else {
            setPaperCheckError(prev => ({ ...prev, phoneNumber: 'Invalid phone number' }))
        }
    }

    const onZipCodeChange = (e) => {
        const zipCode = e.target.value;
        setPaperCheckInfo(prev => ({ ...prev, zipCode }))
        if (!!zipCode) {
            setPaperCheckError(prev => ({ ...prev, zipCode: '' }))
        } else {
            setPaperCheckError(prev => ({ ...prev, zipCode: 'Required' }))
        }
    }

    const onCityChange = (e) => {
        const city = e.target.value;
        setPaperCheckInfo(prev => ({ ...prev, city }))
        if (!!city) {
            setPaperCheckError(prev => ({ ...prev, city: '' }))
        } else {
            setPaperCheckError(prev => ({ ...prev, city: 'Required' }))
        }
    }

    const onStateChange = (e) => {
        const state = e.target.value;
        setPaperCheckInfo(prev => ({ ...prev, state }))
        if (!!state) {
            setPaperCheckError(prev => ({ ...prev, state: '' }))
        } else {
            setPaperCheckError(prev => ({ ...prev, state: 'Required' }))
        }
    }

    const onCountryChange = (e) => {
        const country = e.target.value;
        setPaperCheckInfo(prev => ({ ...prev, country }))
        if (!!country) {
            setPaperCheckError(prev => ({ ...prev, country: '' }))
        } else {
            setPaperCheckError(prev => ({ ...prev, country: 'Required' }))
        }
    }

    const onSaveClick = () => {
        if (methodValue === 0) {
            if (!!email) {
                setEmailError('');
                dispatch(receivePayPal({
                    communityId,
                    campaignId,
                    email
                }))
                    .then(unwrapResult)
                    .then((response) => setConfirm(response.isSuccessful))
                    .catch(({ title }) => toast.info(title))
            }
        }
        if (methodValue === 1) {
            if (!disabled) {
                const data = {
                    ...paperCheckInfo,
                    communityId,
                    campaignId,
                }
                dispatch(receivePaperCheck({
                    communityId,
                    campaignId,
                    data
                }))
                    .then(unwrapResult)
                    .then(response => setConfirm(response.isSuccessful))
                    .catch(({ title }) => toast.info(title))
            }
        }
    }

    const onChangeMethod = (e) => {
        const value = parseInt(e.target.value);
        setMethodValue(value);
    }

    const placeChangeHandler = () => {

        if (!!autocomplete) {

            const { address_components, formatted_address } = autocomplete.getPlace();

            setPaperCheckInfo(prev => ({ ...prev, addressLine: formatted_address }));
            setPaperCheckError(prev => ({ ...prev, addressLine: '' }))
            const zipcode = address_components.find(component => component.types[0] === 'postal_code');
            if (!!zipcode) {
                setPaperCheckInfo(prev => ({ ...prev, zipCode: zipcode.long_name }))
                setPaperCheckError(prev => ({ ...prev, zipCode: '' }))
            } else {
                setPaperCheckInfo(prev => ({ ...prev, zipCode: null }));
                setPaperCheckError(prev => ({ ...prev, zipCode: 'Required' }))
            }

            const country = address_components.find(component => component.types[0] === 'country');
            if (!!country) {
                setPaperCheckInfo(prev => ({ ...prev, country: country.long_name }))
                setPaperCheckError(prev => ({ ...prev, country: '' }))
            } else {
                setPaperCheckInfo(prev => ({ ...prev, country: null }))
                setPaperCheckError(prev => ({ ...prev, country: 'Required' }))
            }

            const state = address_components.find(component => component.types[0] === 'administrative_area_level_1');
            if (!!state) {
                setPaperCheckInfo(prev => ({ ...prev, state: state.long_name }))
                setPaperCheckError(prev => ({ ...prev, state: '' }))
            } else {
                setPaperCheckInfo(prev => ({ ...prev, state: null }))
                setPaperCheckError(prev => ({ ...prev, state: 'Required' }))
            }

            const city = address_components.find(component => component.types[0] === 'locality');
            if (!!city) {
                setPaperCheckInfo(prev => ({ ...prev, city: city.long_name }))
                setPaperCheckError(prev => ({ ...prev, city: '' }))
            } else {
                setPaperCheckInfo(prev => ({ ...prev, city: null }))
                setPaperCheckError(prev => ({ ...prev, city: 'Required' }))
            }

        }
    }

    return (
        <div className='createdPage' id='methodBackground'>
            <div className='feedback'>
                <div className='feedbackContainer'>
                    <Title
                        text='Payment receiving method'
                        fontSize={32}
                        lineHeight={40}
                        style={{ marginBottom: '16px' }}
                    />
                    <Title
                        text='We do not store your details to receive funds. Please indicate the method of receiving funds convenient for you (one-time).'
                        fontWeight={400}
                        style={{ marginBottom: '24px' }}
                    />
                    <RadioGroup
                        aria-label="position"
                        name="position"
                        value={methodValue}
                        onChange={onChangeMethod}
                    >
                        <FormControlLabel
                            label='PayPal'
                            value={0}
                            control={
                                <Radio
                                    classes={radioClasses}
                                    icon={<RadioIcon />}
                                    checkedIcon={<RadioIconChecked />}
                                />
                            }
                            classes={radioClasses}
                            style={{ marginBottom: '6px' }}
                        />
                        {
                            !methodValue && (
                                <>
                                    <CustomInput
                                        StartAdornment={Mail}
                                        value={email}
                                        onChange={onEmailChange}
                                        error={!!emailError}
                                        errorMessage={emailError}
                                        placeholder='Email address'
                                    />
                                    <CustomInput
                                        StartAdornment={Mail}
                                        value={confirmEmail}
                                        onChange={onConfirmEmailChange}
                                        error={!!confirmEmailError}
                                        errorMessage={confirmEmailError}
                                        placeholder='Confirm email address'
                                    />
                                </>
                            )
                        }
                        <FormControlLabel
                            label='Paper check'
                            value={1}
                            control={
                                <Radio
                                    classes={radioClasses}
                                    icon={<RadioIcon />}
                                    checkedIcon={<RadioIconChecked />}
                                />
                            }
                            classes={radioClasses}
                            style={{ marginBottom: '6px' }}
                        />
                        {
                            !!methodValue && (
                                <div style={{ width: '100%' }}>
                                    <CustomInput
                                        placeholder='Person or business name'
                                        StartAdornment={SingleUser}
                                        maxLength={300}
                                        value={paperCheckInfo.name}
                                        onChange={onNameChange}
                                        error={!!paperCheckError.name}
                                        errorMessage={paperCheckError.name}
                                    />
                                    <CustomPhoneInput
                                        placeholder='Phone number'
                                        Icon={MobilePhone}
                                        onChange={onPhoneNumberChange}
                                        value={paperCheckInfo.phoneNumber}
                                        error={!!paperCheckError.phoneNumber}
                                        errorMessage={paperCheckError.phoneNumber}
                                    />
                                    <div className='customInput' style={{ height: '68px' }}>
                                        <div className='addressLine' style={!!paperCheckError?.addressLine ? { borderBottomColor: '#e32020' } : {}} >
                                            <div style={{ marginRight: '16px' }}>
                                                <House />
                                            </div>
                                            <LoadScript
                                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                                libraries={["places"]}

                                            >
                                                <Autocomplete
                                                    onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                                                    onPlaceChanged={placeChangeHandler}
                                                    className="width100"

                                                >
                                                    <input
                                                        id='address-line'
                                                        placeholder='Address line'
                                                    />
                                                </Autocomplete>
                                            </LoadScript>
                                        </div>
                                        {
                                            !!paperCheckError?.addressLine && (
                                                <div
                                                    className="errorMessage"
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        left: 0,
                                                        height: '28px',
                                                    }}
                                                >
                                                    {paperCheckError?.addressLine}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <CustomInput
                                        placeholder='ZIP Code'
                                        StartAdornment={Mail}
                                        onChange={onZipCodeChange}
                                        value={paperCheckInfo.zipCode ?? ''}
                                        error={!!paperCheckError.zipCode}
                                        errorMessage={paperCheckError.zipCode}
                                    />
                                    <CustomInput
                                        placeholder='City'
                                        StartAdornment={Location}
                                        onChange={onCityChange}
                                        value={paperCheckInfo.city ?? ''}
                                        error={!!paperCheckError.city}
                                        errorMessage={paperCheckError.city}
                                    />
                                    <CustomInput
                                        placeholder='State'
                                        StartAdornment={Location}
                                        onChange={onStateChange}
                                        value={paperCheckInfo.state ?? ''}
                                        error={!!paperCheckError.state}
                                        errorMessage={paperCheckError.state}
                                    />
                                    <CustomInput
                                        placeholder='Country'
                                        StartAdornment={Location}
                                        onChange={onCountryChange}
                                        value={paperCheckInfo.country ?? ''}
                                        error={!!paperCheckError.country}
                                        errorMessage={paperCheckError.country}
                                    />
                                </div>
                            )
                        }
                    </RadioGroup>
                </div>
                <CustomButton
                    title='Save'
                    onClick={onSaveClick}
                    disabled={disabled || isLoadingPaperCheck || isLoadingPayPal}
                    classNames='recivingSaveButton'
                    loading={isLoadingPaperCheck || isLoadingPayPal}

                />
                {
                    isLoading && <Loading />
                }
            </div>
            <Dialog
                open={confirm}
                onClose={() => setConfirm(false)}
                disableScrollLock={true}
                classes={dialogClasses}
            >
                <div className='page'>
                    <Feedback
                        title="Thank you"
                        firstDescription='Thank you for providing your information'
                        secondDescription='We kindly inform you that you will receive collected funds via chosen method during 1-3 business days. If you have any questions, please use the Contact Us page to contact admins.'
                        button={{
                            feedbackTitle: 'All campaigns',
                            feedbackAction: () => history.push('/campaigns'),
                            disable: false
                        }}
                        Image={Celebration}
                        titleFontSize={24}
                    />
                </div>
            </Dialog>
        </div>
    )
}

export default RecievingMethod;