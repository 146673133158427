import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../uiKit/CustomTable/customTable";
import { getUserDonations } from "../../../redux/state/paymentSlice";
import RowRenderer from "./tableRow";

const MyDonationsTable = () => {

    const [paginationDataDonations, setPaginationDataDonations] = useState({
        pageSize: 8,
        page: 1
    });

    const donations = useSelector(state => state.payment.userDonations, shallowEqual);

    const isLoading = useSelector(state => state.payment.isLoading.getUserDonations, shallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!!paginationDataDonations) {
            dispatch(getUserDonations({ ...paginationDataDonations }))
        }
    }, [paginationDataDonations, dispatch])

    return (
        <div className='donationTable'>
            <CustomTable
                RowRenderer={RowRenderer}
                headerArray={[
                    { name: '' },
                    { name: 'Campaign name' },
                    { name: 'Community name' },
                    { name: 'Date and time' },
                    { name: 'Donation amount' },
                ]}
                data={donations}
                url='/settings/my-donations'
                isLoading={isLoading}
                setPaginationData={setPaginationDataDonations}
            />
        </div>
    )
}

export default MyDonationsTable;