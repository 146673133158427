import API from '../API/main';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialNotificationState = {
    isLoading: {
        subscribe: false,
        getSubscriptionStatus: false,
        contactUs: false,
        getInvitationMessage: false,
        getSubscriptionTypes: false,
        getSubscriptions: false,
        updateSubscriptions: false,
        getNewNotifications: false,
        getArchivedNotifications: false,
    },
    subscriptionStatus: null,
    invitationMessage: null,
    types: null,
    subscriptions: null,
    notifications: {
        new: null,
        countNew: 0,
        archive: null,
        countArchive: 0
    },
}

export const getNewNotifications = createAsyncThunk(
    'notifications/new',
    async (data, thunkAPI) => {
        const response = await API.NotificationAPI.getNewNotifications(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getCountNewNotifications = createAsyncThunk(
    'notifications/new/count',
    async (data, thunkAPI) => {
        const response = await API.NotificationAPI.getCountNewNotifications(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getArchivedNotifications = createAsyncThunk(
    'notifications/archive',
    async (data, thunkAPI) => {
        const response = await API.NotificationAPI.getArchiveNotifications(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getCountArchivedNotifications = createAsyncThunk(
    'notifications/archive/count',
    async (data, thunkAPI) => {
        const response = await API.NotificationAPI.getCountArchiveNotifications(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const readNotifications = createAsyncThunk(
    'notifications/read',
    async (data, thunkAPI) => {
        const response = await API.NotificationAPI.readNotifications(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getSubscriptions = createAsyncThunk(
    'notification/subscriptions',
    async (_, thunkAPI) => {
        const response = await API.NotificationAPI.getSubscriptions();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateSubscriptions = createAsyncThunk(
    'notification/subscriptions/update',
    async (data, thunkAPI) => {
        const response = await API.NotificationAPI.updateSubscriptions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getSubscriptionTypes = createAsyncThunk(
    'notification/subscription/types',
    async (_, thunkAPI) => {
        const response = await API.NotificationAPI.getSubscriptionTypes();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getSubscriptionStatus = createAsyncThunk(
    'notification/subscription/status',
    async (_, thunkAPI) => {
        const response = await API.NotificationAPI.getSubscriptionStatus();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const subscribe = createAsyncThunk(
    'notification/subscribe',
    async (_, thunkAPI) => {
        const response = await API.NotificationAPI.subscribe();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const unsubscribe = createAsyncThunk(
    'notification/unsubscribe',
    async (_, thunkAPI) => {
        const response = await API.NotificationAPI.unsubscribe();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const contactUs = createAsyncThunk(
    'navigation/contactus',
    async (data, thunkAPI) => {
        const response = await API.NotificationAPI.contactUs(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getInvitationMessage = createAsyncThunk(
    '/notification/invitation/message',
    async (communityId, thunkAPI) => {
        const response = await API.NotificationAPI.getInvitationMessage(communityId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response.content;
    }
)

export const getCampaignMessage = createAsyncThunk(
    'notification/campaign/message',
    async (data, thunkAPI) => {
        const response = await API.NotificationAPI.getCampaignMessage(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { message: response.content }
    }
)

const notificationSlice = createSlice({
    initialState: initialNotificationState,
    name: 'notification',
    reducers: {
        clearSubscriptions(state) {
            state.subscriptions = initialNotificationState.subscriptions;
        },
        clearNotifications(state){
            state.notifications =initialNotificationState.notifications;
        }
    },
    extraReducers: {
        [getNewNotifications.pending]: (state) => {
            state.isLoading.getNewNotifications = true;
        },
        [getNewNotifications.fulfilled]: (state, action) => {
            state.isLoading.getNewNotifications = false;
            state.notifications.new = action.payload;
        },
        [getNewNotifications.rejected]: (state) => {
            state.isLoading.getNewNotifications = false;
        },
        [getCountNewNotifications.pending]: (state) => {
            state.isLoading.getCountNewNotifications = true;
        },
        [getCountNewNotifications.fulfilled]: (state, action) => {
            state.isLoading.getCountNewNotifications = false;
            state.notifications.countNew = action.payload;
        },
        [getCountNewNotifications.rejected]: (state) => {
            state.isLoading.getCountNewNotifications = false;
        },
        [getArchivedNotifications.pending]: (state) => {
            state.isLoading.getArchivedNotifications = true;
        },
        [getArchivedNotifications.fulfilled]: (state, action) => {
            state.isLoading.getArchivedNotifications = false;
            state.notifications.archive = action.payload;
        },
        [getArchivedNotifications.rejected]: (state) => {
            state.isLoading.getArchivedNotifications = false;
        },
        [getCountArchivedNotifications.pending]: (state) => {
            state.isLoading.getCountArchivedNotifications = true;
        },
        [getCountArchivedNotifications.fulfilled]: (state, action) => {
            state.isLoading.getCountArchivedNotifications = false;
            state.notifications.countArchive = action.payload;
        },
        [getCountArchivedNotifications.rejected]: (state) => {
            state.isLoading.getCountArchivedNotifications = false;
        },
        [getSubscriptions.pending]: (state) => {
            state.isLoading.getSubscriptions = true;
        },
        [getSubscriptions.fulfilled]: (state, action) => {
            state.isLoading.getSubscriptions = false;
            state.subscriptions = action.payload;
        },
        [getSubscriptions.rejected]: (state) => {
            state.isLoading.getSubscriptions = false;
        },
        [updateSubscriptions.pending]: (state) => {
            state.isLoading.updateSubscriptions = true;
        },
        [updateSubscriptions.fulfilled]: (state) => {
            state.isLoading.updateSubscriptions = false;
        },
        [updateSubscriptions.rejected]: (state) => {
            state.isLoading.updateSubscriptions = false;
        },
        [getSubscriptionTypes.pending]: (state) => {
            state.isLoading.getSubscriptionTypes = true;
        },
        [getSubscriptionTypes.fulfilled]: (state, action) => {
            state.isLoading.getSubscriptionTypes = false;
            state.types = action.payload;
        },
        [getSubscriptionTypes.rejected]: (state) => {
            state.isLoading.getSubscriptionTypes = false;
        },
        [getSubscriptionTypes.pending]: (state) => {
            state.isLoading.common = true;
        },
        [getSubscriptionStatus.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.subscriptionStatus = action.payload;
        },
        [getSubscriptionStatus.rejected]: (state) => {
            state.isLoading.common = false;
        },
        [subscribe.pending]: (state) => {
            state.isLoading.common = true;
        },
        [subscribe.fulfilled]: (state) => {
            state.isLoading.common = false;
        },
        [subscribe.rejected]: (state) => {
            state.isLoading.common = false;
        },
        [unsubscribe.pending]: (state) => {
            state.isLoading.common = true;
        },
        [unsubscribe.fulfilled]: (state) => {
            state.isLoading.common = false;
        },
        [unsubscribe.rejected]: (state) => {
            state.isLoading.common = false;
        },
        [contactUs.pending]: (state) => {
            state.isLoading.contactUs = true;
        },
        [contactUs.fulfilled]: (state) => {
            state.isLoading.contactUs = false;
        },
        [contactUs.rejected]: (state) => {
            state.isLoading.contactUs = false;
        },
        [getInvitationMessage.pending]: (state, action) => {
            state.isLoading.getInvitationMessage = true;
        },
        [getInvitationMessage.fulfilled]: (state, action) => {
            state.isLoading.getInvitationMessage = false;
            state.invitationMessage = action.payload;
        },
        [getInvitationMessage.rejected]: (state, action) => {
            state.isLoading.getInvitationMessage = false;
        },
    }
})

export const { 
    clearSubscriptions,
    clearNotifications,
 } = notificationSlice.actions;
export default notificationSlice.reducer;