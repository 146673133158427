import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { PASSWORD_ERROR } from "../../helpers/constants";
import { validatePassword } from "../../helpers/validation";
import { changePassword } from "../../redux/state/accountSlice";
import { Eye } from "../../uiKit/assets/svg/icons";
import CustomButton from "../../uiKit/Button/CustomButton";
import CustomInput from "../../uiKit/Input/CustomInput";
import Title from "../../uiKit/Title/Title";
import './login.scss';

const NewPassword = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();

    const query = useLocation().search;
    const token = encodeURIComponent(new URLSearchParams(query).get('token'));
    
    if (token === 'null') history.push('/login');

    const userId = new URLSearchParams(query).get('userId');

    const onPasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        if (!validatePassword(value)) {
            setPasswordError(PASSWORD_ERROR);
        } else {
            setPasswordError(null);
        }
    }

    const onConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
        if (password !== value) {
            setConfirmPasswordError('Password does not match');
        } else {
            setConfirmPasswordError(null);
        }
    }

    const resetPasswordHandler = (e) => {
        e.preventDefault();

        setIsLoading(true);
        dispatch(changePassword({ userId, password, confirmPassword, token }))
            .then(unwrapResult)
            .then(() => {
                setIsLoading(false);
                window.localStorage.clear();
                history.push('/login')
            })
            .catch(({ title }) => {
                setIsLoading(false);
                toast.info(`Reseting password failed: ${title}`)
            })
    }

    return (
        <>
            <div className='signUpBackground' />
            <form
                onSubmit={resetPasswordHandler}
                className='landingContentWrapper'
            >
                <Title
                    text='New Password'
                    fontSize={32}
                    lineHeight={40}
                    style={{ marginBottom: '32px' }}
                />
                <CustomInput
                    label='New Password'
                    type={showPassword ? 'text' : 'new-password'}
                    onChange={onPasswordChange}
                    value={password}
                    error={!!passwordError}
                    errorMessage={passwordError}
                    EndAdornment={Eye}
                    endAdormentClick={() => setShowPassword(!showPassword)}
                />
                <CustomInput
                    label='Confirm Password'
                    type={showPassword ? 'text' : 'new-password'}
                    onChange={onConfirmPasswordChange}
                    value={confirmPassword}
                    error={!!confirmPasswordError}
                    errorMessage={confirmPasswordError}
                    EndAdornment={Eye}
                    endAdormentClick={() => setShowPassword(!showPassword)}
                />
                <div className='buttonsWrapper'>
                    <CustomButton
                        title='Confirm'
                        classNames='marginTop24To0'
                        disabled={!!confirmPasswordError || !!passwordError || isLoading}
                        loading={isLoading}
                        type='submit'
                    />
                </div>
            </form></>
    )
}

export default NewPassword;