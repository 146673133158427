import React from "react";
import './balance.scss';

import { Bank } from "../../uiKit/assets/svg/icons";
import Title from "../../uiKit/Title/Title";
import { useHistory } from "react-router-dom";

const Balance = ({ balance, title = 'Our Balance', clickable = true, }) => {

    const history = useHistory();

    return (
        <div
            className='mobileBalance'
            onClick={clickable ? () => history.push('/our-balance') : () => { }}
        >
            <Bank />
            <div style={{ marginLeft: '16px' }}>
                <Title
                    text={balance}
                    fontSize={16}
                />
                <Title
                    text={title}
                    fontWeight={400}
                />
            </div>
        </div>
    )
}

export default Balance;