import React from 'react';
import './feedback.scss';
import Title from '../../uiKit/Title/Title';
import CustomButton from '../../uiKit/Button/CustomButton';

const Feedback = ({
    Image,
    title,
    firstDescription,
    secondDescription,
    button,
    secondButton,
    onClose,
    titleFontSize = 16,
    customDescription = {
        available: false,
        firstTitle: '',
        secondTitle: '',
        thirdTitle: '',
    }
}) => {

    const { feedbackTitle, feedbackAction, disable, loading } = button;
    const { available, firstTitle, secondTitle, thirdTitle } = customDescription;

    return (
        <div className='feedback' >
            <div className='feedbackContainer'>
                <div className='feedbackImageContainer'>
                    <Image />
                </div>
                <div className='description'>
                    {
                        !!title && (
                            <Title
                                text={title}
                                fontSize={titleFontSize}
                                lineHeight={32}
                                style={{ marginBottom: '16px' }}
                            />
                        )
                    }
                    <div>
                        {
                            !!firstDescription && (
                                <Title
                                    text={firstDescription}
                                    fontWeight={400}
                                    style={{ marginBottom: '16px' }}
                                />
                            )
                        }
                        {
                            !!available && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%'
                                    }}
                                >
                                    <Title
                                        text={firstTitle}
                                        style={{ marginBottom: '16px', wordBreak: 'break-word' }}
                                    />
                                    <Title
                                        text={secondTitle}
                                        style={{ marginBottom: '16px', wordBreak: 'break-word' }}
                                    />
                                    <Title
                                        text={thirdTitle}
                                        style={{ marginBottom: '16px', wordBreak: 'break-word' }}
                                    />
                                </div>
                            )
                        }
                        {
                            !!secondDescription && (
                                <Title
                                    text={secondDescription}
                                    fontWeight={400}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '24px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                <CustomButton
                    onClick={feedbackAction}
                    title={feedbackTitle}
                    disabled={disable}
                    loading={loading}
                />
                {
                    !!secondButton && <CustomButton
                        onClick={onClose}
                        title='Decline'
                        isWhite
                        style={{ marginTop: '16px' }}

                    />
                }
            </div>
        </div>
    )
}

export default Feedback;