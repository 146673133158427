import { FetchAPI } from "./main";

export const PaymentAPI = {
    //Balance
    getCommunityBalance: (communityId) => FetchAPI.getJson(`/payment/communities/${communityId}/balance`),
    getCampaignBalance: ({
        communityId,
        campaignId
    }) => FetchAPI.getJsonPublic(`/payment/communities/${communityId}/campaigns/${campaignId}/balance`),
    getTotalDonation: () => FetchAPI.getJson(`/payment/donations/total-amount`),
    getCampaignNetBalance: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/payment/communities/${communityId}/campaigns/${campaignId}/net-balance`),
    //Payments
    getTransfers: ({
        communityId,
        pageSize,
        page
    }) => FetchAPI.getJson(`/payment/communities/${communityId}/transfers/${pageSize}/${page}`),
    getCommunityDonations: ({
        communityId,
        pageSize,
        page,
        isAdmin = false,
    }) => FetchAPI.getJson(`/payment/communities/${communityId}/donations/${pageSize}/${page}${isAdmin ? '/full-info' : ''}`),
    getCampaignDonations: ({
        communityId,
        campaignId,
        pageSize,
        page,
        isAdmin = false,
    }) => FetchAPI.getJson(`/payment/communities/${communityId}/campaigns/${campaignId}/donations/${pageSize}/${page}${isAdmin ? '/full-info' : ''}`),
    getCampaignDonationsPublic: ({
        communityId,
        campaignId,
        pageSize,
        page
    }) => FetchAPI.getJsonPublic(`/payment/communities/${communityId}/campaigns/${campaignId}/donations/${pageSize}/${page}`),
    getUserDonations: ({
        pageSize,
        page
    }) => FetchAPI.getJson(`/payment/donations/${pageSize}/${page}`),
    transferFromOurBalance: ({
        communityId,
        campaignId,
        data
    }) => FetchAPI.postJson(`/payment/communities/${communityId}/campaigns/${campaignId}/transfers`, data),

    getReceivingMethod: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/payment/communities/${communityId}/campaigns/${campaignId}/recipient/receiving-method`),
    receivePayPal: ({
        communityId,
        campaignId,
        email
    }) => FetchAPI.postJson(`/payment/communities/${communityId}/campaigns/${campaignId}/recipient/receiving-method/paypal`, { email }),
    receivePaperCheck: ({
        communityId,
        campaignId,
        data
    }) => FetchAPI.postJson(`/payment/communities/${communityId}/campaigns/${campaignId}/recipient/receiving-method/paper-check`, data),

    //stripe
    ourBalancePaymentIntent: ({
        communityId,
        body
    }) => FetchAPI.postJson(`/payment/stripe/communities/${communityId}/payment-intent`, body),
    campaignPaymentIntent: ({
        communityId,
        campaignId,
        body
    }) => FetchAPI.postJson(`/payment/stripe/communities/${communityId}/campaigns/${campaignId}/payment-intent`, body),
    getUserCard: () => FetchAPI.getJson('/payment/stripe/saved-payment-method'),
    ourBalancePaymentIntentSavedCard: ({
        communityId,
        paymentMethodId,
        body
    }) => FetchAPI.postJson(`/payment/stripe/communities/${communityId}/payment-intent/${paymentMethodId}`, body),
    campaignPaymentIntentSavedCard: ({
        communityId,
        campaignId,
        paymentMethodId,
        body
    }) => FetchAPI.postJson(`/payment/stripe/communities/${communityId}/campaigns/${campaignId}/payment-intent/${paymentMethodId}`, body),
    savePaymentCard: () => FetchAPI.postJson('/payment/stripe/setup-intent'),

    //subscriptions

    cancelSubscription: (subscriptionId) => FetchAPI.postJson(`/payment/stripe/subscriptions/${subscriptionId}/cancel`),
    getSubscriptions: ({
        pageSize,
        page
    }) => FetchAPI.getJson(`/payment/subscriptions/${pageSize}/${page}`),

    //user

    getPaymentSetting: () => FetchAPI.getJson('/payment/account/payment-settings'),
    updatePaymentSetting: (data) => FetchAPI.postJson('/payment/account/payment-settings', data)
}