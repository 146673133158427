import React from "react";
import moment from "moment";
import Title from "../Title/Title";
import { green300 } from "../assets/colors/colors";

const NotificationBar = ({
    title,
    date
}) => {

    return (
        <div className='progressWrapper' style={{ display: 'flex' }}>
            <div style={{
                height: '20px',
                width: '8px',
                padding: '6px, 0px, 6px, 0px',
                margin: '10px'
            }}>
                <div style={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '50%',
                    backgroundColor: green300
                }}
                />
            </div>
            <div>
                <Title
                    text={title}
                    fontSize={16}
                    style={{marginBottom: '4px'}}
                />
                <Title
                    text={`${moment(date).format('MM/DD/YYYY')} ${moment(date).format('h:mm A')}`}
                    fontWeight={400}
                />
            </div>
        </div>
    );
}

export default NotificationBar;