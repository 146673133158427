import { FetchAPI } from './main';

export const NotificationAPI = {

    //messages

    getCountNewNotifications: (communityId) => FetchAPI.getJson(`/notification/communities/${communityId}/notifications/unread/count`),
    getNewNotifications: ({
        communityId,
        pageSize,
        page
    }) => FetchAPI.getJson(`/notification/communities/${communityId}/notifications/unread/${pageSize}/${page}`),
    getCountArchiveNotifications: (communityId) => FetchAPI.getJson(`/notification/communities/${communityId}/notifications/archive/count`),
    getArchiveNotifications: ({
        communityId,
        pageSize,
        page
    }) => FetchAPI.getJson(`/notification/communities/${communityId}/notifications/archive/${pageSize}/${page}`),
    readNotifications: (communityId) => FetchAPI.postJson(`/notification/communities/${communityId}/notifications/read`),

    //subscriptions
    getSubscriptionTypes: () => FetchAPI.getJson('/notification/subscription/types'),
    getSubscriptions: () => FetchAPI.getJson('/notification/subscription'),
    updateSubscriptions: (data) => FetchAPI.putJson('/notification/subscription', data),
    getSubscriptionStatus: () => FetchAPI.getJson('/notification/subscription'),
    subscribe: () => FetchAPI.postJson('/notification/subscription/subscribe'),
    unsubscribe: () => FetchAPI.postJson('/notification/subscription/unsubscribe'),

    //messages
    getInvitationMessage: (communityId) => FetchAPI.getJson(`/notification/communities/${communityId}/invitation`),
    getCampaignMessage: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/notification/communities/${communityId}/campaigns/${campaignId}/invitation`),

    //contact-us
    contactUs: (body) => {

        const {
            userName,
            email,
            communityId,
            message,
            attachments
        } = body
        const formData = new FormData();

        formData.append('name', userName);
        formData.append('email', email);
        formData.append('message', message);
        if (!!communityId) {
            formData.append('communityId', communityId)
        }

        if (!!attachments) {
            attachments.map(attachment => formData.append('attachments', attachment));
        }

        return FetchAPI.post('/notification/contactus', formData)
    },
}
