import React from "react";
import { blue010, gray900 } from "../assets/colors/colors";
import Title from "../Title/Title";

const Loading = ({ top = '0', height = '100%', opacity = 0.85, backgroundColor = blue010 }) => (
    <>
        <div
            style={{
                position: 'absolute',
                top,
                left: 0,
                backgroundColor,
                opacity,
                width: '100%',
                height,
                zIndex: 1000
            }}
        />
        <Title
            text="Loading..."
            style={{
                position: 'absolute',
                top,
                left: 0,
                width: '100%',
                height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000
            }}
            color={gray900}
        />
    </>

)

export default Loading;