import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { makeStyles } from '@material-ui/core';
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { blue010 } from "../assets/colors/colors";
import { Arrow14 } from "../assets/svg/icons";
import CustomMobilePagination from "../CustomTable/mobilePagination";
import Loading from "../Loading/loading";
import Title from "../Title/Title";

export const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: `${blue010} !important`,
        boxShadow: 'none !important',
        margin: '0 !important',
        '&.Mui-expanded': {
            margin: '0 !important'
        }
    },
    expanded: {
        margin: '0 !important',
    }
}))

export const useSummeryStyles = makeStyles(() => ({
    root: {
        minHeight: '30px !important',
        '&.Mui-expanded': {
            minHeight: '30px !important'
        }
    },
    expanded: {
        margin: '0 !important',
    }
}))

const CustomAccordion = ({
    FirstDropdown,
    firstDropdownTitle,
    firstDropdownData,
    firstListLoading,
    FirstNoData,
    SeconDropdown,
    seconDropdownTitle,
    secondDropdownData,
    secondListLoading,
    SecondNoData,
    tableUrl,
}) => {

    const { search, pathname } = useLocation()
    const history = useHistory();

    const [expanded, setExpanded] = useState(true);
    const [items, setItems] = useState(2);

    const accordionClasses = useStyles();
    const summeryClasses = useSummeryStyles();

    const expandHandler = useCallback(() => {
        setExpanded(prev => !prev);
        const query = new URLSearchParams(search);
        query.set('page', 1)
        history.push({
            pathname,
            search: query.toString()
        })
    }, [search, history, pathname])

    useEffect(() => {
        const query = new URLSearchParams(search);

        query.set('dataType', expanded ? 0 : 1);

        history.push({
            pathname,
            search: query.toString()
        })
    }, [search, history, expanded, pathname])

    return (
        <div style={{ width: '100%' }}>
            <div style={{ position: 'relative' }}>
                {
                    !!firstDropdownData.items && (
                        <Accordion
                            expanded={expanded}
                            onChange={expandHandler}
                            classes={accordionClasses}
                            TransitionProps={{ unmountOnExit: true }}
                        >
                            <AccordionSummary
                                classes={summeryClasses}
                                expandIcon={<Arrow14 />}
                                style={{ padding: 0 }}
                            >
                                <Title
                                    text={firstDropdownTitle}
                                    fontSize={20}
                                    lineHeight={30}
                                />
                            </AccordionSummary>
                            <AccordionDetails style={{
                                flexDirection: 'column',
                                padding: 0,
                                marginTop: '16px'
                            }}
                            >
                                <FirstDropdown data={firstDropdownData.items} items={items} />
                                <CustomMobilePagination
                                    data={firstDropdownData}
                                    tableUrl={tableUrl}
                                    count={items}
                                    setItems={setItems}
                                    marginTop={16}
                                    NoData={FirstNoData}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                }
                {firstListLoading && <Loading />}
            </div>
            <div style={{ position: 'relative' }}>
                {
                    !!secondDropdownData.items && (
                        <Accordion
                            expanded={!expanded}
                            onChange={expandHandler}
                            classes={accordionClasses}
                            style={{ marginTop: '24px' }}
                            TransitionProps={{ unmountOnExit: true }}
                        >
                            <AccordionSummary
                                classes={summeryClasses}
                                expandIcon={<Arrow14 />}
                                style={{ padding: 0 }}
                            >
                                <Title
                                    text={seconDropdownTitle}
                                    fontSize={20}
                                    lineHeight={30}
                                />
                            </AccordionSummary>
                            <AccordionDetails style={{
                                flexDirection: 'column',
                                padding: 0,
                                marginTop: '16px'
                            }}
                            >
                                <SeconDropdown data={secondDropdownData.items} items={items} />
                                <CustomMobilePagination
                                    data={secondDropdownData}
                                    tableUrl={tableUrl}
                                    count={items}
                                    setItems={setItems}
                                    NoData={SecondNoData}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                }
                {secondListLoading && <Loading />}
            </div>
        </div>
    )
}

export default CustomAccordion;