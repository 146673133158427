import React from 'react';
import { Celebration } from '../../../uiKit/assets/svg/celebration.js'
import Feedback from '../../../components/Feedback/feedback';
import { useHistory } from 'react-router';

const MethodChosen = () => {

    const history = useHistory();

    return (
        <div className='createdPage'>
            <Feedback
                title="Receiving method"
                firstDescription="You've already provided the information about receiving method."
                button={{
                    feedbackTitle: 'All campaigns',
                    feedbackAction: () =>  history.push('/campaigns'),
                    disable: false
                }}
                Image={Celebration}
                titleFontSize={24}
            />
        </div>
    )
}

export default MethodChosen;