import React, { useEffect, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { validateEmail, validateName } from '../../../helpers/validation';

import { User, Mail } from '../../../uiKit/assets/svg/icons';
import CustomInput from '../../../uiKit/Input/CustomInput';
import CustomPhoneInput from "../../../uiKit/Input/CustomPhoneInput";

const AddMemberForm = ({
    id,
    setDisabled,
    disabled,
    updateMember,
    removeMember,
    member
}) => {

    const [currentMember, setCurrentMember] = useState(member);

    const [firstNameValue, setFirstNameValue] = useState(member.firstName);
    const [firstNameError, setFirstNameError] = useState('');

    const [lastNameValue, setLastNameValue] = useState(member.lastName);
    const [lastNameError, setLastNameError] = useState('');

    const [emailValue, setEmailValue] = useState(member.email);
    const [emailError, setEmailError] = useState('');

    const [phoneNumberValue, setPhoneNumberValue] = useState(member.phoneNumber);
    const [phoneNumberError, setPhoneNumberError] = useState('');

    useEffect(() => {
        updateMember(currentMember);
    }, [currentMember, updateMember])

    useEffect(() => {
        if (disabled) {
            if (!firstNameValue) {
                setFirstNameError('Required');
            }
            if (!lastNameValue) {
                setLastNameError('Required');
            }

            if (!emailValue && !phoneNumberValue) {
                setEmailError('Email or Phone required')
            }
        }
    }, [
        disabled,
        firstNameValue,
        lastNameValue,
        emailValue,
        phoneNumberValue
    ])

    useEffect(() => {
        if (!!firstNameError || !!lastNameError || !!emailError || !!phoneNumberError) {
            setCurrentMember(prev => ({ ...prev, error: true }))
        } else {
            setCurrentMember(prev => ({ ...prev, error: false }))
        }
    }, [
        firstNameError,
        lastNameError,
        emailError,
        phoneNumberError,
    ])

    const onFirstNameChange = (e) => {
        setFirstNameValue(e.target.value);
        setFirstNameError('');
        setDisabled(false);
    }

    const onLastNameChange = (e) => {
        setLastNameValue(e.target.value);
        setLastNameError('');
        setDisabled(false);
    }

    const onEmailChange = (e) => {
        setEmailValue(e.target.value);
        setDisabled(false);
        setEmailError('');
        setPhoneNumberError('');
    }

    const onPhoneNumberChange = (value) => {
        setPhoneNumberValue(value);
        setDisabled(false);
        setPhoneNumberError('');
    }

    const firstNameOutFocus = () => {
        if (validateName(firstNameValue)) {
            setCurrentMember(prev => ({ ...prev, firstName: firstNameValue }));
        } else {
            setDisabled(true);
            if (!firstNameValue) {
                setFirstNameError('Required')
            } else {
                setFirstNameError('Invalid first name')
            }
        }
    }

    const lastNameOutFocus = () => {
        if (validateName(lastNameValue)) {
            setCurrentMember(prev => ({ ...prev, lastName: lastNameValue }));
        } else {
            setDisabled(true);
            if (!lastNameValue) {
                setLastNameError('Required')
            } else {
                setLastNameError('Invalid last name')
            }
        }
    }

    const emailOutFocus = () => {
        if (validateEmail(emailValue)) {
            if (!phoneNumberValue) setPhoneNumberError('');
            if (currentMember.error) {
                setDisabled(true);
            }
            setCurrentMember(prev => ({ ...prev, email: emailValue }));

        } else {
            if (!emailValue && !phoneNumberError) {
                setEmailError('');
                setCurrentMember(prev => ({ ...prev, email: emailValue }));
            }

            if (!emailValue && !phoneNumberValue) {
                setEmailError('Email or Phone required');
            }

            if (!!emailValue) {
                setEmailError('Invalid email address');
            }
        }
    }

    const phoneNumberOutFocus = () => {
        if (!!phoneNumberValue && isPossiblePhoneNumber(phoneNumberValue)) {
            if (!emailValue) setEmailError('');
            setCurrentMember(prev => ({ ...prev, phoneNumber: phoneNumberValue }));
        } else {
            if (!emailValue && !phoneNumberValue) {
                setEmailError('Email or Phone required');
            }

            if (!emailError && !phoneNumberValue) {
                setPhoneNumberError('');
                setCurrentMember(prev => ({ ...prev, phoneNumber: phoneNumberValue }));
            }

            if (!!phoneNumberValue) {
                setPhoneNumberError('Invalid phone number');
            }
        }
    }

    return (
        <div className="inputWrapper" key={id} >
            <CustomInput
                StartAdornment={User}
                placeholder='First Name'
                type='text'
                onChange={onFirstNameChange}
                value={firstNameValue}
                outFocus={firstNameOutFocus}
                errorMessage={firstNameError}
                isRequired={true}
                error={!!firstNameError}
                setErrorMessage={setFirstNameError}
                maxLength={30}
            />
            <CustomInput
                StartAdornment={User}
                placeholder='Second Name'
                type='text'
                onChange={onLastNameChange}
                value={lastNameValue}
                outFocus={lastNameOutFocus}
                isRequired={true}
                error={!!lastNameError}
                errorMessage={lastNameError}
                setErrorMessage={setLastNameError}
                maxLength={30}
            />
            <CustomInput
                StartAdornment={Mail}
                placeholder='Email'
                type='email'
                onChange={onEmailChange}
                value={emailValue}
                outFocus={emailOutFocus}
                error={!!emailError}
                errorMessage={emailError}
            />
            <CustomPhoneInput
                placeholder='Phone'
                onChange={onPhoneNumberChange}
                value={phoneNumberValue}
                outFocus={phoneNumberOutFocus}
                error={!!phoneNumberError}
                errorMessage={phoneNumberError}
            />
            <span
                className='removeMember'
                onClick={() => removeMember(id)}
            >
                Remove
            </span>
        </div>
    )
}

export default AddMemberForm;