import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../../components/Header/header";
import { blue100 } from "../../uiKit/assets/colors/colors";
import CustomButton from "../../uiKit/Button/CustomButton";
import CustomMobilePagination from "../../uiKit/CustomTable/mobilePagination";
import ProgressBar from "../../uiKit/ProgressBar/ProgressBar";
import Title from "../../uiKit/Title/Title";
import CampaignsDesktopList from "./CampaignsDesktopList/campaignsDesktopList";

import {
    clearCommunity,
    getCommunityPublic,
    setCommunityId
} from "../../redux/state/communitySlice";
import {
    clearCampaigns,
    clearDrafts,
    clearSuggestions,
    getActiveCampaignsPublic
} from "../../redux/state/campaignSlice";
import { addMember, clearAdmins } from "../../redux/state/memberSlice";
import { clearLoadedOnChangeCommunity, setScreenWidth } from "../../redux/state/commonSlice";
import AnimatedLoading from "../../uiKit/Loading/animatedLoading";
import { clearNotifications } from "../../redux/state/notificationSlice";

const wrapperStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    zIndex: 100
}

const Campaigns = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname, state, search } = useLocation();
    const { communityId } = useParams();

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);
    const isLoadingCommunity = useSelector(state => state.community.isLoading.common, shallowEqual);
    const isLoadingJoin = useSelector(state => state.member.isLoading.addMember);

    const [imageURL, setImageUrl] = useState('');
    const [previousPath, setPreviousPath] = useState('')

    const communityName = useSelector(state => state.community.communityInfoPublic.name, shallowEqual);
    const description = useSelector(state => state.community.communityInfoPublic.description, shallowEqual);
    const images = useSelector(state => state.community.communityInfoPublic.images, shallowEqual);
    const communities = useSelector(state => state.community.memberCommunities, shallowEqual);

    const activeCampaigns = useSelector(state => state.campaign.activeCampaigns, shallowEqual);

    const [paginationDataActive, setPaginationDataActive] = useState(null);
    const [items, setItems] = useState(2);

    useEffect(() => {
        if (!state) {
            setPreviousPath('/')
            return;
        }

        setPreviousPath(`${state.pathname}${state.search}`);
    }, [state])

    useEffect(() => {
        window.addEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)));
        return () => window.removeEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)))
    }, [screenWidth, dispatch])

    useEffect(() => {
        if (!!communityId) {
            dispatch(getCommunityPublic(communityId))
        }
    }, [communityId, dispatch])

    useEffect(() => {

        if (!images) return;

        if (screenWidth < 1200) {
            setImageUrl(images.mobile)
        } else {
            setImageUrl(images.desktop)
        }
    }, [images, screenWidth])

    useEffect(() => {
        if (!!paginationDataActive) {
            dispatch(getActiveCampaignsPublic(paginationDataActive))
        }
    }, [paginationDataActive, dispatch])

    const joinCommunity = useCallback(() => {
        if (!!communityId) {
            dispatch(addMember(communityId))
                .then(() => {
                    dispatch(clearCommunity());
                    dispatch(clearCampaigns());
                    dispatch(clearAdmins());
                    dispatch(clearDrafts());
                    dispatch(clearSuggestions());
                    dispatch(clearNotifications());
                    dispatch(setCommunityId({ communityId }));
                    dispatch(clearLoadedOnChangeCommunity());
                    window.localStorage.setItem('communityId', communityId);
                    history.push('/campaigns')
                })
                .catch(({ title }) => toast.info(title))
        }
    }, [communityId, dispatch, history])

    const campaignClickHandler = useCallback((campaignId) => {

        if (!!communities && !!communities.length) {
            history.push({
                pathname: `/campaigns/campaign/active/${campaignId}`,
                state: { pathname, search },
                search: `?campaignCommunityId=${communityId}`
            })
            return;
        };

        history.push({
            pathname: `/community/${communityId}/campaign/${campaignId}`,
            state: { pathname, search }
        });

    }, [
        communities,
        history,
        communityId,
        pathname,
        search
    ])

    return (
        <div style={!communities?.length ? wrapperStyles : {}}>
            <div style={{
                maxWidth: '872px',
                width: screenWidth > 1200 ? 'calc(100% - 80px)' : '100%'
            }}
            >
                <Header
                    title='search community'
                    onClick={() => history.push(previousPath)}
                    hasMenu={!!communities?.length}
                />
            </div>
            <div
                className='communityInfoHeaderWrapper'
                style={{ marginBottom: screenWidth < 1200 ? '24px' : '60px', position: 'relative' }}
            >
                <div className='campaignMobileHeader'>
                    <div
                        className='photoWrapper'
                        style={{ backgroundImage: `url(${imageURL})` }}
                    />
                    <Title
                        text={communityName}
                        fontSize={screenWidth < 1200 ? 24 : 32}
                        lineHeight={screenWidth < 1200 ? 32 : 40}
                        className="ourBalanceTitle"
                    />
                </div>

                {
                    !!description && (
                        <Title
                            text={description}
                            style={{ marginBottom: '24px', marginTop: '20px' }}
                            fontWeight={400}
                        />
                    )
                }
                <CustomButton
                    title='Join'
                    classNames='maxContentButton'
                    onClick={joinCommunity}
                />
                <AnimatedLoading loading={isLoadingJoin || isLoadingCommunity} full={false} />
            </div>
            <div className='campaignsWrapper' style={{ position: 'relative' }}>
                <div className='campaignButton'>
                    {
                        !!activeCampaigns.items && (
                            <Title
                                text={`Active Campaigns (${activeCampaigns.totalNumberOfItems})`}
                                fontSize={20}
                                lineHeight={30}
                                className='userActiveCampaigns'
                                style={{ marginBottom: '16px' }}
                            />
                        )
                    }
                </div>
                <CampaignsDesktopList
                    communityId={communityId}
                    color={blue100}
                    data={activeCampaigns}
                    setData={setPaginationDataActive}
                    campaignType='active'
                    showNoData={false}
                    campaignClick={campaignClickHandler}
                    tableUrl={pathname}
                />
                {
                    screenWidth < 900 && !!activeCampaigns.items && (
                        <div style={{
                            display: "flex",
                            flexDirection: 'column',
                            width: '100%'
                        }}>
                            <Title
                                text={`Active Campaigns (${activeCampaigns.totalNumberOfItems})`}
                                fontSize={20}
                                lineHeight={30}
                                style={{ marginBottom: '16px' }}
                            />
                            {
                                activeCampaigns.items
                                    .slice(0, items)
                                    .map((campaign, index) => (
                                        <ProgressBar
                                            key={index}
                                            campaignId={campaign.id}
                                            communityId={campaign.communityId}
                                            title={campaign.name}
                                            target={campaign.amount}
                                            color={blue100}
                                            clickable={true}
                                            onClick={() => campaignClickHandler(campaign.id)}
                                        />
                                    ))
                            }
                            <CustomMobilePagination
                                data={activeCampaigns}
                                setPaginationData={setPaginationDataActive}
                                count={items}
                                setItems={setItems}
                                NoData={() => <></>}
                                tableUrl={pathname}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Campaigns;