import React from "react";
import './home.scss';

import Sidebar from '../../components/Sidebar/sidebar';
import ScrollToTopIcon from "../../components/ScrollToTopIcon/scrollToTopIcon";
import { shallowEqual, useSelector } from "react-redux";

const Home = ({ Component }) => {

    const loaded = useSelector(state => state.common.loaded, shallowEqual);

    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            opacity: Object.values(loaded).every(x => x) ? 1 : 0.4,
            transition: 'ease-in 0.5s',
            pointerEvents: Object.values(loaded).every(x => x) ? 'auto' : 'none',
        }}>
            <Sidebar />
            <div className='blueBackground'>
                <Component />
            </div>
            <ScrollToTopIcon />
        </div>
    )
}

export default Home;