import API from '../API/main';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialCommunityState = {
    isLoading: {
        getTypes: false,
        createCommunity: false,
        getApprovedCommunities: false,
        getCommunities: false,
        getCommunityIds: false,
        common: false,
    },
    types: null,
    communityId: '',
    communityInfoPublic: {
        id: '',
        name: '',
        location: '',
        description: '',
        images: {
            mobile: '',
            desktop: '',
        },
        communityTypeId: 0,
    },
    community: {
        id: null,
        name: null,
        location: null,
        images: {
            mobile: null,
            desktop: null
        },
        communityTypeId: 0,
        numberOfMembers: 0,
        numberOfActiveCampaigns: 0,
        ourBalance: 0,
        isConfirmed: false,
        role: 0
    },
    error: null,
    approvedCommunities: {
        items: null,
        numberOfItems: 0,
        page: 1,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    allCommunities: {
        items: null,
        numberOfItems: 0,
        page: 1,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    communityImageURL: null,
    memberCommunities: null,
    memberCommunityIds: null,
}

export const getCommunities = createAsyncThunk(
    '/communities',
    async (_, thunkAPI) => {
        const response = await API.CommunityAPI.getCommunities();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { communities: response }
    }
)

export const getCommunitiesShortInfo = createAsyncThunk(
    '/communities/short/info',
    async (_, thunkAPI) => {
        const response = await API.CommunityAPI.getCommunitiesShortInfo();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { communities: response, ids: response.map(x => x.id) };
    }
)

export const getTypes = createAsyncThunk(
    '/communities/types',
    async (_, thunkAPI) => {
        const response = await API.CommunityAPI.getCommunityTypes();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { types: response }
    }
)

export const createCommunity = createAsyncThunk(
    '/communities/creat',
    async (data, thunkAPI) => {
        const response = await API.CommunityAPI.createCommunity(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { community: response }
    }
)

export const getCommunity = createAsyncThunk(
    '/communities/id',
    async (communityId, thunkAPI) => {
        const response = await API.CommunityAPI.getCommunityInfo(communityId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { community: response }
    }
)

export const getCommunityPublic = createAsyncThunk(
    '/communities/id/public',
    async (communityId, thunkAPI) => {
        const response = await API.CommunityAPI.getCommunity(communityId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const checkCommunityStatus = createAsyncThunk(
    '/communities/info/status',
    async (communityId, thunkAPI) => {
        const response = await API.CommunityAPI.getCommunityInfo(communityId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { isConfirmed: response.isConfirmed }
    }
)

export const updateCommunity = createAsyncThunk(
    '/communities/update',
    async (data, thunkAPI) => {
        const response = await API.CommunityAPI.updateCommunity(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { community: response }
    }
)

export const leaveCommunity = createAsyncThunk(
    '/members/leave',
    async (communityId, thunkAPI) => {
        const response = await API.CommunityAPI.leaveCommunity(communityId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getApprovedCommunities = createAsyncThunk(
    '/communities/approved',
    async (data, thunkAPI) => {
        const response = await API.CommunityAPI.getApprovedCommunities(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { approvedCommunities: response }
    }
)

const communitySlice = createSlice({
    name: 'community',
    initialState: initialCommunityState,
    reducers: {
        setImageURL(state, action) {
            state.communityImageURL = action.payload;
        },
        setCommunityId(state, action) {
            state.communityId = action.payload.communityId;
        },
        clearCommunity(state) {
            state.community = initialCommunityState.community;
        },
        clearMemberCommunities(state) {
            state.memberCommunities = initialCommunityState.memberCommunities;
        },
        resetApprovedCommunities(state) {
            state.approvedCommunities = initialCommunityState.approvedCommunities;
        },
        clearId(state) {
            state.communityId = initialCommunityState.communityId;
        },
        clearIds(state) {
            state.memberCommunityIds = initialCommunityState.memberCommunityIds;
        }
    },
    extraReducers: {
        [getTypes.pending]: (state) => {
            state.isLoading.getTypes = true;
        },
        [getTypes.fulfilled]: (state, action) => {
            state.isLoading.getTypes = false;
            state.types = action.payload.types;
            state.error = null;
        },
        [getTypes.rejected]: (state, action) => {
            state.isLoading.getTypes = false;
            state.error = action.error || action.payload;
        },
        [getCommunitiesShortInfo.pending]: (state) => {
            state.isLoading.common = true;
            state.isLoading.getCommunityIds = true;
            state.isLoading.getCommunities = true;
        },
        [getCommunitiesShortInfo.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.isLoading.getCommunityIds = false;
            state.isLoading.getCommunities = false;
            state.memberCommunities = action.payload.communities;
            state.memberCommunityIds = action.payload.ids;
            state.error = null;
        },
        [getCommunitiesShortInfo.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.isLoading.getCommunityIds = false;
            state.isLoading.getCommunities = false;
            state.error = action.error || action.payload;
        },
        [createCommunity.pending]: (state) => {
            state.isLoading.createCommunity = true;
        },
        [createCommunity.fulfilled]: (state, action) => {
            state.isLoading.createCommunity = false;
            state.error = null;
        },
        [createCommunity.rejected]: (state, action) => {
            state.isLoading.createCommunity = false;
            state.error = action.error || action.payload;
        },
        [getCommunity.pending]: (state) => {
            state.isLoading.common = true;
        },
        [getCommunity.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.community = action.payload.community;
            state.error = null;
        },
        [getCommunity.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        },
        [getCommunityPublic.pending]: (state) => {
            state.isLoading.common = true;
        },
        [getCommunityPublic.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.communityInfoPublic = action.payload;
            state.error = null;
        },
        [getCommunityPublic.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        },
        [updateCommunity.pending]: (state) => {
            state.isLoading.common = true;
        },
        [updateCommunity.fulfilled]: (state) => {
            state.isLoading.common = false;
            state.error = null;
        },
        [updateCommunity.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        },
        [getApprovedCommunities.pending]: (state) => {
            state.isLoading.getApprovedCommunities = true;
        },
        [getApprovedCommunities.fulfilled]: (state, action) => {
            state.isLoading.getApprovedCommunities = false;
            state.approvedCommunities = action.payload.approvedCommunities;
            state.error = null;
        },
        [getApprovedCommunities.rejected]: (state, action) => {
            state.isLoading.getApprovedCommunities = false;
            state.error = action.error || action.payload;
        },
        [leaveCommunity.pending]: (state) => {
            state.isLoading.common = true;
        },
        [leaveCommunity.fulfilled]: (state) => {
            state.isLoading.common = false;
            state.error = null;
        },
        [leaveCommunity.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        },
    }
})

export const {
    setImageURL,
    setCommunityId,
    clearCommunity,
    clearMemberCommunities,
    resetApprovedCommunities,
    clearId,
    clearIds,
} = communitySlice.actions;
export default communitySlice.reducer;
