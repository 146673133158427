import React, { useEffect, useState } from "react";
import './adminsInfo.scss';
import ReactGA from 'react-ga4';

import { useSelector, shallowEqual } from 'react-redux';

import { Users } from "../../../uiKit/assets/svg/icons";
import Title from "../../../uiKit/Title/Title";
import AdminsTable from "./adminsTable";
import Header from "../../../components/Header/header";
import AdminsList from "./adminsList";

const AdminsInfo = () => {

    const [adminsCount, setAdminsCount] = useState(0);

    const community = useSelector(state => state.community.community, shallowEqual);
    const admins = useSelector(state => state.member.communityAdmins, shallowEqual);

    const imageURL = useSelector(state => state.community.communityImageURL, shallowEqual);

    useEffect(() => {
        if (!!admins) {
            setAdminsCount(admins.length)
        }
    }, [admins])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/admins` })
    }, [])

    return (
        <>
            <Header title='Community admins' clickable={false} />
            <div className='membersWrapper'>
                <div className='communityInfoWrapper'>
                    <div
                        className="photoWrapper"
                        style={{ backgroundImage: `url(${imageURL})` }}
                    >
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Title
                            text={community.name}
                            fontSize={32}
                            lineHeight={40}
                            fontWeight={600}
                        />
                        <div className='flexMargin'>
                            <div style={{ marginRight: '8px' }}><Users /></div>
                            <Title
                                text={`${adminsCount} Admins`}
                                fontWeight={400}
                            />
                        </div>
                    </div>
                </div>
                <AdminsTable />
                <div className='adminsList boxShadow'>
                    <AdminsList />
                </div>
            </div>
        </>

    )
}

export default AdminsInfo;