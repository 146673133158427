import React from "react";
import { Dialog, makeStyles } from "@material-ui/core";
import Feedback from "../../components/Feedback/feedback";

const useStyles = makeStyles(() => ({
    paper: {
        '&.MuiPaper-rounded': {
            borderRadius: '12px'
        }
    }
}))

const CustomDialog = ({
    Image,
    title,
    firstDescription,
    secondDescription,
    mainButtonTitle,
    mainButtonAction,
    isLoading = false,
    secondButton = false,
    onClose,
    confirmOpen,
    customDescription
}) => {

    const dialogClasses = useStyles();

    return (
        <Dialog
            open={confirmOpen}
            onClose={onClose}
            disableScrollLock={true}
            classes={dialogClasses}
        >
            <div className='page'>
                <Feedback
                    Image={Image}
                    button={{
                        feedbackTitle: mainButtonTitle,
                        feedbackAction: mainButtonAction,
                        loading: isLoading,
                        disable: isLoading
                    }}
                    secondButton={secondButton}
                    onClose={onClose}
                    title={title}
                    firstDescription={firstDescription}
                    secondDescription={secondDescription}
                    customDescription={customDescription}
                />
            </div>
        </Dialog>
    )
}

export default CustomDialog;