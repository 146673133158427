import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
    resendLink,
    startRegistration
} from "../../../redux/state/accountSlice";

import { blue300, gray900 } from "../../../uiKit/assets/colors/colors";
import CustomButton from "../../../uiKit/Button/CustomButton";
import CustomCheckbox from "../../../uiKit/CustomCheckbox/customCheckbox";
import CustomInput from "../../../uiKit/Input/CustomInput";
import Title from "../../../uiKit/Title/Title";
import '../LandingPage/landingPage.scss';
import Verification from "../../../components/Verification/verification";
import {
    VALIDATION_TEXT_REGISTER,
    VALIDATION_TEXT_REGISTER_SECOND
} from "../../../helpers/constants";
import AlternativeLogin from "../../../components/AlternativeLogin/alternativeLogin";

const SignUp = () => {

    const isLoading = useSelector(state => state.account.isLoading.startRegistration, shallowEqual);
    const isLoadingResendLink = useSelector(state => state.account.isLoading.resendLink, shallowEqual);

    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');
    const type = new URLSearchParams(search).get('type');

    const [agreed, setAgreed] = useState(false);
    const [verification, setVerification] = useState(false);

    const [email, setEmailValue] = useState(null);
    const [emailError, setEmailError] = useState(false);
    const errorMessage = useSelector(state => state.account.startRegistrationError, shallowEqual);

    const dispatch = useDispatch();
    const history = useHistory();

    const onEmailChange = (e) => {
        setEmailValue(e.target.value);
        setEmailError(false);
    }

    const signUpHandler = () => {
        dispatch(startRegistration({ email, invitationToken: !!token ? token : null }))
            .then(unwrapResult)
            .then(() => setVerification(true))
            .catch(() => setEmailError(true))
    }

    if (verification) {
        return (
            <Verification
                resendLink={resendLink}
                isLoading={isLoadingResendLink}
                data={{
                    email,
                    invitationToken: !!token ? token : null
                }}
                description={VALIDATION_TEXT_REGISTER}
                secondDescription={VALIDATION_TEXT_REGISTER_SECOND}
            />
        )
    }

    return (
        <>
            <div className='signUpBackground' />
            <div className='landingContentWrapper'>
                <Title
                    text='Create Account'
                    fontSize={32}
                    lineHeight={40}
                    style={{ marginBottom: '32px' }}
                />
                <CustomInput
                    label='Your email'
                    value={email}
                    error={emailError}
                    errorMessage={errorMessage}
                    onChange={onEmailChange}
                />
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                    <CustomCheckbox
                        checked={agreed}
                        style={{ margin: 0 }}
                        onChange={() => setAgreed(!agreed)}
                    />
                    <div style={{
                        fontWeight: 400,
                        lineHeight: '20px',
                        fontSize: '14px',
                        color: gray900
                    }}>
                        I agree to the <span
                            onClick={() => window.open('/terms-and-conditions', '_blank')}
                            style={{
                                color: blue300,
                                cursor: 'pointer'
                            }}>Terms and Conditions </span>
                        and <span
                            onClick={() => window.open('/privacy-and-policy', '_blank')}
                            style={{
                                color: blue300,
                                cursor: 'pointer'
                            }}>Privacy Policy</span>
                    </div>
                </div>
                <div className='buttonsWrapper'>
                    <CustomButton
                        title='Sign up'
                        onClick={signUpHandler}
                        disabled={!agreed || emailError || isLoading}
                        loading={isLoading}
                    />
                    <AlternativeLogin />
                    <div className='buttonDescription'>
                        <Title
                            text='Already have an account?'
                            fontWeight={400}
                        />
                        <Title
                            text='Log in'
                            color={blue300}
                            fontWeight={400}
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                            onClick={() => {
                                if (!!type) {
                                    history.push(`/login?type=${type}`);
                                    return null;
                                };
                                if (!!token) {
                                    history.push(`/login/${token}`);
                                } else {
                                    history.push('/login')
                                };
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp;