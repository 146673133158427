export const Vector01 = () => (
    <svg width="100%" height="30px" viewBox="0 0 580 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.0662231 14.8611L14.5 29.2948L28.9338 14.8611L14.5 0.427327L0.0662231 14.8611ZM580 12.3611L14.5 12.3611V17.3611L580 17.3611V12.3611Z" fill="#2087EF" />
    </svg>
)

export const Vector02 = () => (
    <svg width="100%" height="247" viewBox="0 0 467 247" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M212 2.86108H343.5C410.05 2.86108 464 56.8108 464 123.361V123.361C464 189.911 410.05 243.861 343.5 243.861H212H0" stroke="#2087EF" strokeWidth="5" />
    </svg>
)

export const Vector03 = () => (
    <svg width="100%" height="260" viewBox="0 0 478 260" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M477.435 14.8611L463.001 0.427327L448.567 14.8611L463.001 29.2948L477.435 14.8611ZM175.245 12.3611H124.001V17.3611H175.245V12.3611ZM124.001 259.361H175.245V254.361H124.001V259.361ZM175.245 17.3611H463.001V12.3611H175.245V17.3611ZM0.500977 135.861C0.500977 204.068 55.7938 259.361 124.001 259.361V254.361C58.5552 254.361 5.50098 201.307 5.50098 135.861H0.500977ZM124.001 12.3611C55.7938 12.3611 0.500977 67.6539 0.500977 135.861H5.50098C5.50098 70.4153 58.5552 17.3611 124.001 17.3611V12.3611Z" fill="#2087EF" />
    </svg>
)

export const Vector04 = () => (
    <svg width="100%" height="271" viewBox="0 0 836 271" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5672 14.8611L15.001 0.427327L29.4348 14.8611L15.001 29.2948L0.5672 14.8611ZM357.501 255.861L382.501 241.427V270.295L357.501 255.861ZM582.227 12.3611H712.501V17.3611H582.227V12.3611ZM712.501 258.361H568.469V253.361H712.501V258.361ZM582.227 17.3611H15.001V12.3611H582.227V17.3611ZM568.469 258.361H380.001V253.361H568.469V258.361ZM835.501 135.361C835.501 203.292 780.432 258.361 712.501 258.361V253.361C777.671 253.361 830.501 200.531 830.501 135.361H835.501ZM712.501 12.3611C780.432 12.3611 835.501 67.4301 835.501 135.361H830.501C830.501 70.1915 777.671 17.3611 712.501 17.3611V12.3611Z" fill="#2087EF" />
    </svg>
)

export const Vector01Mobile = () => (
    <svg width="100%" height="256" viewBox="0 0 325 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.453003 11.5947L12 0.0477209L23.547 11.5947L12 23.1417L0.453003 11.5947ZM151.028 9.59473H202V13.5947H151.028V9.59473ZM202 255.595H179V251.595H202V255.595ZM151.028 13.5947H12V9.59473H151.028V13.5947ZM325 132.595C325 200.526 269.931 255.595 202 255.595V251.595C267.722 251.595 321 198.317 321 132.595H325ZM202 9.59473C269.931 9.59473 325 64.6637 325 132.595H321C321 66.8728 267.722 13.5947 202 13.5947V9.59473Z" fill="#2087EF" />
    </svg>
)

export const Vector02Mobile = () => (
    <svg width="100%" height="257" viewBox="0 0 190 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M189.047 12.1191L177.5 0.572135L165.953 12.1191L177.5 23.6661L189.047 12.1191ZM169.231 10.1191H123.5V14.1191H169.231V10.1191ZM123.5 256.119H169.231V252.119H123.5V256.119ZM169.231 14.1191H177.5V10.1191H169.231V14.1191ZM0.5 133.119C0.5 201.05 55.569 256.119 123.5 256.119V252.119C57.7781 252.119 4.5 198.841 4.5 133.119H0.5ZM123.5 10.1191C55.569 10.1191 0.5 65.1881 0.5 133.119H4.5C4.5 67.3973 57.7781 14.1191 123.5 14.1191V10.1191Z" fill="#2087EF" />
    </svg>
)

export const Vector03Mobile = () => (
    <svg width="100%" height="257" viewBox="0 0 163 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.952988 12.1191L12.5 0.572135L24.047 12.1191L12.5 23.6661L0.952988 12.1191ZM19.4928 10.1191H39.5V14.1191H19.4928V10.1191ZM39.5 256.119H19.4928V252.119H39.5V256.119ZM19.4928 14.1191H12.5V10.1191H19.4928V14.1191ZM162.5 133.119C162.5 201.05 107.431 256.119 39.5 256.119V252.119C105.222 252.119 158.5 198.841 158.5 133.119H162.5ZM39.5 10.1191C107.431 10.1191 162.5 65.1881 162.5 133.119H158.5C158.5 67.3973 105.222 14.1191 39.5 14.1191V10.1191Z" fill="#2087EF" />
    </svg>
)

export const Vector04Mobile = () => (
    <svg width="100%" height="266" viewBox="0 0 312 266" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M311.5 254.119L291.5 242.572V265.666L311.5 254.119ZM174.62 12.1191L163.073 0.572135L151.526 12.1191L163.073 23.6661L174.62 12.1191ZM155.486 10.1191H123.5V14.1191H155.486V10.1191ZM123.5 256.119H293.5V252.119H123.5V256.119ZM155.486 14.1191H163.073V10.1191H155.486V14.1191ZM0.5 133.119C0.5 201.05 55.569 256.119 123.5 256.119V252.119C57.7781 252.119 4.5 198.841 4.5 133.119H0.5ZM123.5 10.1191C55.569 10.1191 0.5 65.1881 0.5 133.119H4.5C4.5 67.3973 57.7782 14.1191 123.5 14.1191V10.1191Z" fill="#2087EF" />
    </svg>
)