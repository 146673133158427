import React, { useState } from 'react';

import './mobileMenu.scss';
import { SwipeableDrawer } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { Close, MenuIcon } from '../../../uiKit/assets/svg/icons';
import { blue010 } from '../../../uiKit/assets/colors/colors';
import Title from '../../../uiKit/Title/Title';

import Settings from '../PersonalSettings/personalSettings';
import CommunityMenu from '../CommunityMenu/communityMenu';

const useStyles = makeStyles(() => ({
    paper: {
        width: '100%',
        backgroundColor: blue010,
    }
}))

const MobileMenu = () => {

    const drawerClasses = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className='mobileMenu'>
            <div onClick={() => setIsOpen(true)}>
                <MenuIcon />
            </div>
            <SwipeableDrawer
                anchor='right'
                open={isOpen}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                classes={drawerClasses}
            >
                <div className='sidebarMobile'>
                    <div className='mobileMenuHeader' >
                        <div className='mobileMenuTitle'>
                            Settings
                        </div>
                        <div onClick={() => setIsOpen(false)}>
                            <Close />
                        </div>
                    </div>
                    <div className='communityMenu'>
                        <Title
                            text='Community'
                            lineHeight={32}
                            fontSize={20}
                            style={{
                                padding: '8px 24px'
                            }}
                        />
                        <CommunityMenu closeMenu={setIsOpen}/>
                    </div>
                    <div className='personalSettings'>
                        <Title
                            text='Personal Settings'
                            lineHeight={32}
                            fontSize={20}
                            style={{
                                padding: '8px 24px'
                            }}
                        />
                        <Settings />
                    </div>
                </div>
            </SwipeableDrawer>
        </div>
    )
}

export default MobileMenu;