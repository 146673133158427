import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CustomButton from "../Button/CustomButton";
import Title from "../Title/Title";

const NoResult = () => (
    <div className='mobilePaginationWrapper' style={{ justifyContent: 'center' }}>
        <Title text='No results.' />
    </div>
)

const CustomMobilePagination = ({
    data,
    count,
    setItems,
    marginTop = 0,
    tableUrl,
    NoData = NoResult,
}) => {

    const {
        items,
        page,
        totalPages,
        totalNumberOfItems,
        hasPreviousPage,
        hasNextPage,
    } = data;

    const history = useHistory();
    const { search } = useLocation();

    useEffect(() => {
        if (count === 2) {
            const query = new URLSearchParams(search);
            query.set("page", 1);
            history.push({
                pathname: tableUrl,
                search: query.toString()
            });
        }
    }, [search, count, tableUrl, history])

    const onNextClick = () => {
        if (hasNextPage) {
            const query = new URLSearchParams(search);
            query.set("page", page + 1);
            history.push({
                pathname: tableUrl,
                search: query.toString()
            });
        }
    };

    const onPreviousClick = () => {
        if (hasPreviousPage) {
            const query = new URLSearchParams(search);
            query.set("page", page - 1);
            history.push({
                pathname: tableUrl,
                search: query.toString()
            });
        }

    }

    if (totalNumberOfItems === 0) {
        return <NoData />
    }

    return (
        count < 8 ?
            !!items &&
            page === 1 &&
            items.length > 2 &&
            (
                <CustomButton
                    title='Show more'
                    onClick={() => setItems(8)}
                    isWhite
                    style={{ marginTop: `${marginTop}px` }}
                />
            ) : (
                <div className='mobilePaginationWrapper'>
                    <CustomButton
                        disabled={!hasPreviousPage}
                        onClick={onPreviousClick}
                        title='Back'
                        isWhite
                        style={{
                            width: 'max-content'
                        }}
                    />
                    <div style={{
                        display: 'flex'
                    }}>
                        <Title text={page} />
                        <Title
                            text='OF'
                            style={{
                                marginLeft: '7px'
                            }}
                        />
                        <Title
                            text={totalPages}
                            style={{ marginLeft: '7px' }}
                        />
                    </div>
                    <CustomButton
                        disabled={!hasNextPage}
                        onClick={onNextClick}
                        title='Next'
                        style={{
                            width: 'max-content'
                        }}
                    />
                </div>
            )
    )
}

export default CustomMobilePagination;