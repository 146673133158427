import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { blue050, gray010, gray300, gray500, gray800, red900 } from '../assets/colors/colors';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Arrow } from '../assets/svg/icons';
import Title from '../Title/Title';
import Verify from '../Verify/verify';
import CustomInputInfo from './CustomInputInfo';
import CustomCheckbox from '../CustomCheckbox/customCheckbox';
import InfiniteScroll from 'react-infinite-scroll-component';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    getAllMembersExclude,
} from '../../redux/state/memberSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import Loading from '../Loading/loading';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        border: "1px solid",
        borderRadius: "10px !important",
        textTransform: "none",
        background: gray010,
        color: gray800,
        borderColor: gray300,
        height: "40px",
        padding: "0 20px !important",
        fontFamily: 'Poppins',
        borderBottom: `1px solid ${gray500}`,
        fontSize: '14px',
        marginBottom: '28px !important'
    },
    error: {
        borderBottom: `1px solid ${red900}`,
        borderRadius: '4px'
    },
    disabled: {
        border: "1px solid",
        borderRadius: "10px",
        textTransform: "none",
        background: blue050,
        color: gray010,
        borderColor: blue050,
    },
    input: {
        padding: '0 !important',
        fontFamily: 'Poppins !important',
        color: `${gray800} !important`,
        lineHeight: '20px !important',
        fontSize: '14px !important'

    },
    notchedOutline: {
        borderWidth: '0 !important'
    }
}));

const useAutoStyles = makeStyles(theme => ({
    listbox: {
        maxHeight: 'fit-content !important'
    },
    endAdornment: {
        position: 'static !important',
        top: '0 !important'
    }
}));

const ExcludeMembers = ({
    label,
    description,
    setCampaign,
    excluded,
    excludedIds
}) => {

    const classes = useStyles();
    const paperClasses = useAutoStyles();
    const dispatch = useDispatch();

    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    const membersData = useSelector(state => state.member.allMembers, shallowEqual);
    const loading = useSelector(state => state.member.isLoading.getAllMembersExclude, shallowEqual);

    const [open, setOpen] = useState(false);

    const [str, setStr] = useState('');

    const [excludedMembers, setExcludedMembers] = useState([]);
    const [excludedMembersIds, setExcludedMembersIds] = useState([]);
    const [excludedMembersData, setExcludedMembersData] = useState([]);
    const [excludedMembersValue, setExcludedMembersValue] = useState([]);
    const [disableExclude, setDisableExclude] = useState(false);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        if (!!excluded.length) {
            setExcludedMembersValue(excluded.map(member => ({ ...member, excluded: true })))
        }
    }, [excluded])

    useEffect(() => {
        if (!!excludedIds.length) {
            setExcludedMembersIds(excludedIds)
        }
    }, [excludedIds])

    useEffect(() => {
        if (!excludedMembersData.length) return;

        if (!!excludedMembersIds.length) {
            const data = excludedMembersData.map(member => {
                if (excludedMembersIds.includes(member.userId)) {
                    return { ...member, excluded: true }
                } else {
                    return { ...member, excluded: false }
                }
            })
            setExcludedMembers(data.sort((first, second) => Number(second.excluded) - Number(first.excluded)));
        } else {
            const data = excludedMembersData.map(member => ({ ...member, excluded: false }))
            setExcludedMembers(data);
        }
    }, [excludedMembersIds, excludedMembersData])

    useEffect(() => {
        if (excludedMembersIds.length < 3) {
            setDisableExclude(false);
        } else {
            setDisableExclude(true)
        }
    }, [excludedMembersIds.length])

    useEffect(() => {
        if (!!communityId) {
            dispatch(getAllMembersExclude({
                communityId,
                pageSize,
                page: 1,
                searchString: str
            })).then(unwrapResult)
                .then(response => {
                    const { items } = response;
                    const data = items.filter(item => !excludedMembersValue.some(member => member.userId === item.userId))
                    setExcludedMembersData([...excludedMembersValue, ...data])
                })
                .catch(({ title }) => toast.info(title));
        }
    }, [str, pageSize, dispatch, communityId, excludedMembersValue])

    useEffect(() => {
        if (!!setCampaign && !!excludedMembersIds) {
            setCampaign(prev => ({ ...prev, excludedMembers: excludedMembersIds }))
        }
    }, [excludedMembersIds, setCampaign])

    const onExcludeChange = (id, exclude) => {
        const data = excludedMembers.map(member => {
            if (member.userId === id) {
                if (!exclude) {
                    setExcludedMembersIds(prev => prev.filter(item => item !== id));
                    setExcludedMembersValue(prev => prev.filter(member => member.userId !== id))
                } else {
                    setExcludedMembersIds(prev => ([...prev, id]));
                    setExcludedMembersValue(prev => ([...prev, member]))
                }
                return { ...member, excluded: exclude }
            } else {
                return member;
            }
        })
        setExcludedMembers(data);
    }

    return (
        <div style={{ position: 'relative' }}>
            {
                !!label && <div className="label">{label}</div>
            }
            <Autocomplete
                PaperComponent={({ children }) => {
                    return (
                        <div style={{ position: 'relative' }}>
                            <InfiniteScroll
                                dataLength={membersData.numberOfItems}
                                next={() => setPageSize(prev => {
                                    if (membersData.totalNumberOfItems - prev < 20) {
                                        return prev + 20;
                                    } else {
                                        return prev + 10;
                                    }
                                })}
                                hasMore={membersData.hasNextPage}
                                scrollableTarget="scrollableDiv"
                                loader={<h4>Loading...</h4>}
                                style={{
                                    boxShadow: '0px 4px 10px rgba(224, 238, 242, 0.8)',
                                    backgroundColor: gray010,
                                }}
                                height={200}
                            >
                                {children}
                            </InfiniteScroll>
                            {
                                loading && <Loading />
                            }
                        </div>
                    )
                }}
                id="autocomplete"
                disableCloseOnSelect
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                classes={paperClasses}
                onClose={() => {
                    setOpen(false);
                }}
                style={{
                    minHeight: '40px',
                    maxHeight: '90px',
                    minWidth: '220px',
                    background: 'transparant',
                    width: '100%',
                    padding: 0
                }}
                value={excludedMembersValue}
                onFocus={() => setStr('')}
                onChange={(_, member) => {
                    if (!!member && !disableExclude) {
                        onExcludeChange(member.userId, !member.excluded);
                    }

                    if (!!member && excludedMembersIds.some(id => id === member.userId)) {
                        onExcludeChange(member.userId, !member.excluded)
                    }
                }}
                options={excludedMembers}
                getOptionLabel={(options) => {
                    if (Array.isArray(options)) {
                        return options.map(option => option.fullName).join(", ")
                    } else {
                        return options.fullName
                    }
                }}
                getOptionDisabled={(option) => {
                    if (!disableExclude) return false;
                    if (excludedMembersIds.some(id => option.userId === id)) {
                        return false;
                    } else {
                        return true;
                    }
                }}
                disableClearable
                renderOption={(props, option) => (
                    <div {...props}>
                        <CustomCheckbox
                            checked={option.excluded}
                            disabled={disableExclude && !option.excluded}
                            style={{ width: '20%' }}
                        />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            width: '80%'
                        }}
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginBottom: '4px'
                            }} >
                                <Title
                                    text={option.fullName}
                                />
                                <Verify status={option.isVerified} />
                            </div>
                            <Title
                                text={option.email}
                                fontWeight={400}
                                fontSize={12}
                                lineHeight={14}
                            />
                        </div>
                    </div>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        value={str}
                        onChange={debounce((e) => setStr(e.target.value), [1000])}
                        InputProps={{
                            ...params.InputProps,
                            classes,
                            endAdornment: (
                                <>
                                    {
                                        loading && (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        )
                                    }
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                popupIcon={<Arrow />}
            />
            <CustomInputInfo
                description={description}
            />
        </div>
    );
}

export default ExcludeMembers;