import { Avatar } from "@material-ui/core";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { nameInitials } from "../../../helpers/functions";
import { blue100, gray800 } from "../../../uiKit/assets/colors/colors";
import Title from "../../../uiKit/Title/Title";
import RelinquishRole from "./relinquishRole";

const AdminsList = () => {

    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);

    const admins = useSelector(state => state.member.communityAdmins, shallowEqual);
    const userId = window.localStorage.getItem('userId');

    return (
        !!admins && admins.map((admin, index) => (
            <div className='adminsListRow' key={index}>
                <Avatar
                    src={admin.imageName}
                    alt=''
                    style={{
                        borderRadius: '50%',
                        backgroundColor: blue100,
                        fontSize: '12px',
                        lineHeight: '14px',
                        fontWeight: 500,
                        width: '40px',
                        height: '40px',
                        marginRight: '12px'
                    }} >
                    {nameInitials(admin.fullName)}
                </Avatar>
                <div >
                    <Title
                        text={admin.fullName}
                        style={{ marginBottom: '4px' }}
                    />
                    <Title
                        text={admin.email}
                        fontSize={12}
                        lineHeight={14}
                        fontWeight={400}
                        color={gray800}
                        style={{
                            marginBottom: '4px',
                            overflow: 'visible',
                            wordBreak: 'break-all'
                        }}
                    />
                    <Title
                        text={admin.phoneNumber}
                        fontSize={12}
                        lineHeight={14}
                        fontWeight={400}
                        color={gray800}
                        style={{ marginBottom: '4px' }}
                    />
                    {
                        admin.userId === userId && !!isAdmin && (
                            <RelinquishRole item={admin} />
                        )
                    }
                </div>
            </div>
        ))
    )
}

export default AdminsList;