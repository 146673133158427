import React, { useEffect } from "react";
import './drafts.scss';
import ReactGA from 'react-ga4';

import Header from "../../components/Header/header";
import Title from "../../uiKit/Title/Title";

import Drafts from "./drafts";
import Suggestions from "./suggestions";

const DraftsWrapper = () => {

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/drafts` })
    }, [])

    return (
        <>
            <Header title='Drafts' clickable={false} />
            <div className="draftsProgressbar">
                <Title
                    text="Campaign drafts"
                    fontSize={32}
                    lineHeight={40}
                    style={{ marginBottom: '32px' }}
                />
                <div style={{ position: 'relative', width: '100%', height: '100%', minHeight: '400px' }}>
                    <Drafts />
                    <Suggestions />
                </div>
            </div>
        </>
    )
}

export default DraftsWrapper;