import React, { useCallback } from "react";
import ReactGA from 'react-ga4';

import CustomButton from "../../../uiKit/Button/CustomButton";
import Title from "../../../uiKit/Title/Title";
import TwoPeople from '../../../uiKit/assets/svg/twoGirls.svg';
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

const SignupSection = () => {

    const history = useHistory();

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const signUpHandler = useCallback(() => {
        ReactGA.event('sign_up_landing');
        history.push('/sign-up')
    }, [history]);

    return (
        <div className="signUpSection" >
            <div className="textContainer">
                <Title
                    text="The Better Way to Do Peer-To-Peer Giving"
                    fontWeight={700}
                    fontSize={screenWidth > 900 ? 56 : 40}
                    lineHeight={screenWidth > 900 ? 64 : 52}
                    style={{ overflow: 'visible' }}
                />
                <div style={{ marginTop: '10px' }}>
                    <Title
                        text="Make your impact where it matters most."
                        fontSize={screenWidth > 900 ? 24 : 24}
                        lineHeight={screenWidth > 900 ? 34 : 32}
                        fontWeight={300}
                        style={screenWidth > 900 ? { marginTop: '40px' } : { marginTop: '16px' }}
                    />
                    <Title
                        text="Fast. Simple. Deductible."
                        fontSize={screenWidth > 900 ? 31 : 21}
                        lineHeight={screenWidth > 900 ? 39 : 27}
                        fontWeight={700}
                        style={{ marginTop: '32px'}}
                    />
                    <CustomButton
                        title='Sign up'
                        classNames='landingSignUp'
                        onClick={signUpHandler}
                    />
                </div>
            </div>
            <div className="imageContainer">
                <img alt='' src={TwoPeople} style={{ width: '100%' }} />
            </div>
        </div>
    )
}

export default SignupSection;